import React, { useState, useEffect, useRef } from "react";
import { MainContainer } from "../../components/MainContainer";
import { Container } from "@prima-afp/prima-ui-elements/dist/layouts";
import { Steps } from "../../componentes-con-asesor/Steps";
import { StepsContent } from "../../componentes-con-asesor/StepsContent";
import { useGetDataApi } from "../../hooks/useGetDataApi";
import { useStateDigitalTransfersContext } from "../../context/DigitalTransfersContext";
import { SendButton as SendButtonIdentity } from "../../componentes-con-asesor/StepIdentity/SendButton";
import { HeaderStep } from "../../../App/pages/layout-con-asesor/HeaderSteps";
import { SendButton as SendButtonFundType } from "../../componentes-con-asesor/StepFundType/SendButton";
import { useLocation } from "react-router-dom";
import { useSubmitOnEnter } from "../../hooks/useSubmitOnEnter";
import { useHistory } from "react-router-dom";
import { WhatsApp } from "../../components/BtnWhatsapp/index";
import {updateUrl} from "../../constants/updateUrl";

export const StepsPageAF = () => {
  const { GetDataInitial, GetDataWithToken, GetWhatsappValue } =
    useGetDataApi();
  const { stepCurrent, legaltext, whatslink, startFlow, lastStep } =
    useStateDigitalTransfersContext();
  const search = useLocation().search;
  const token_query = new URLSearchParams(search).get("t");
  const document = new URLSearchParams(search).get("dn");
  const { buttonRef, handleKeyDown } = useSubmitOnEnter();
  const history = useHistory();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 445);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 445);
  };

  useEffect(() => {
    // Agregamos el event listener cuando el componente se monta
    window.addEventListener("resize", handleResize);

    // Limpiamos el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (whatslink == "") {
      GetWhatsappValue();
    }
    if (token_query != null) {
      GetDataWithToken(document, token_query);
    } else if (stepCurrent == 1) {
      GetDataInitial();
    }
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    if(window.location.href.includes("asesor")){
      if( stepCurrent == 0 && (!window.location.href.includes("pasos0") )){
        updateUrl(history, currentUrl, "/asesor/pasos0");
      }
    }
  }, []);

  return (
    <div id="wrapperStepsId" onKeyDown={handleKeyDown}>
      {!isMobile && (
        <Container id="idContainerHeader">
          <HeaderStep />
        </Container>
      )}

      <MainContainer id="idMainContainer">
        <Steps />

        <StepsContent buttonRef={buttonRef} />
      </MainContainer>

      <Container id="idContainerStepsPage">
        {stepCurrent === 1 && <SendButtonIdentity buttonRef={buttonRef} />}

        {stepCurrent === 2 && <SendButtonFundType buttonRef={buttonRef} />}
      </Container>

      {isMobile && (
        <Container id="idContainerHeader" className="MBtn">
          <HeaderStep isMobile={isMobile} />
        </Container>
      )}

      <WhatsApp />
    </div>
  );
};
