import { Button, CheckBox, Tooltip } from '@prima-afp/prima-ui-elements/dist/core';
import React, { useEffect, useState} from 'react';
import {DivStepI, DivHeader, DivContent, DivReco, DivRecoCont, MainTitle,ContentStepInitial, TextStyledStep0  } from '../Resources_stepInitial/styles';
import infoLogo from "../Resources_stepInitial/info.svg";
import camera from "../Resources_stepInitial/camaraFotografica.svg";
import { Text } from '../../../shared/components/Text';
import TooltipContent from '../../../shared/components/TooltipContent';
import iIcon from "../../assets/images-con-asesor/infos.svg"; 
import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext';
import { useHistory } from 'react-router-dom';
import { useGetDataApi } from '../../hooks/useGetDataApi';
import {WhatsApp} from '../../components/BtnWhatsapp/index';
import {sanitizar, sendToData} from '../../constants/sendToDataLayer';
import {updateUrl} from '../../constants/updateUrl';

export const HomePageAF = () => {
    
	const history = useHistory();

    const dispatch = useDispatchDigitalTransfersContext();
    const { userWeb, haveAsesor, stepCurrent } = useStateDigitalTransfersContext();
    const { initialCheckbox } = userWeb;
    const { GetWhatsappValue } = useGetDataApi();
    
    useEffect(() => {
        const metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = 'noindex, nofollow';
        document.head.appendChild(metaTag);
    
        return () => {
          document.head.removeChild(metaTag);
        };
      }, []);

    useEffect(() => {
        GetWhatsappValue();
    }, []);

    const [showButton, setShowButton] = useState(true);

    useEffect(() => {
      const updateVisibility = () => {
        const currentHour = new Date().getHours();
        const currentMinute = new Date().getMinutes();
        const isAfter1800 = currentHour > 17 || (currentHour === 17 && currentMinute >= 59);
  
        setShowButton(!isAfter1800);
      };
  
      // Llamamos a la función inicialmente para establecer la visibilidad del botón al cargar la página
      updateVisibility();
  
      // Actualizamos la visibilidad cada minuto
      const intervalId = setInterval(updateVisibility, 60000);
  
      // Limpieza del intervalo cuando el componente se desmonta
      return () => clearInterval(intervalId);
    }, []);



    useEffect(() => {
        if(window.location.href.includes('asesor')){
            dispatch({ type: "FIELD", payload: {field: "haveAsesor",value: true,},});
        }
      }, []);

    const sendToGTM = (eventNum) =>{
        const currentURL = window.location.href;
        const step = "paso 0";
        switch (eventNum) {
            case 1:
            var payload1 = {section: step, option: "consultalo aqui" };
            sendToData(currentURL, "link_click", "link_click", payload1);
            break;
                
            case 2:
            var payload2 = {section: step, option: "tipo de fondo (0,1,2 y 3) aqui" };
            sendToData(currentURL, "link_click", "link_click", payload2);
            break;

            case 3:
            var payload3 = {section: step, option: sanitizar("No pertenezco a la ONP y tengo más de 2 años afiliado en una AFP") };
            sendToData(currentURL, "box_click", "box_click", payload3);           
            break;

            case 4:
            var payload4 = {section: step, option: "empezar", count_0: 1 };
            sendToData(currentURL, "step", "paso_0", payload4, currentURL);      
            break;
        }
    };
     
    const Init = async () => {
        const currentURL = window.location.href;
        dispatch({ type: 'INITIAL_STATE', });
        dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'userWeb', value: true} });        
        dispatch({ type: 'FIELD', payload: { field: 'haveAsesor', value: window.location.href.includes('asesor') ? true: false } });
        dispatch({ type: 'FIELD', payload: { field: 'startFlow', value: true } });
        dispatch({ type: 'FIELD', payload: { field: 'urlFlow', value: currentURL } });   
        dispatch({ type: 'FIELD', payload: { field: 'lastStep', value: 1 } });  
        dispatch({ type: 'FIELD', payload: { field: 'stepCurrent', value: 1 } });  
        sendToGTM(4);
        if (window.location.href.includes('asesor')) {
           updateUrl(history, window.location.href, '/asesor/pasos1' );
        }else{
           updateUrl(history, window.location.href, '/pasos1' );
        }
    };
    const setConfirmation= () => {
        const value = !initialCheckbox;
        dispatch({
            type: 'FIELD_SECOND',
            payload: {
                field1: 'userWeb',
                field2: 'initialCheckbox',
                value
            }
        });
        if(value){
            sendToGTM(3);
        }
    }; 

    return (
        <>
            <DivStepI id="idDivStepI">
                <DivHeader style={{display: 'flex'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-end' }}>        
                            <TextStyledStep0>
                            Ayer, Hoy, Siempre
                            </TextStyledStep0>  
                        </div>                                           
                </DivHeader>

                <DivContent id="idDivContent">
                    <MainTitle>¡Cámbiate a Prima AFP!</MainTitle>
                    <div  id="idDivContent_2">
                        <Text id="text1Id" size="16" weight="400" line-height="20px" color="#696158" style={{marginBottom: '24px'}}> Antes de empezar, marca si cumples con los siguientes requisitos:</Text>
                        <ContentStepInitial id="idContentStepInitial">
                            <div id="divCard" style={{ alignItems: 'center'}}>
                                <CheckBox    id="IdCheckbox"                                                    
                                    value= {userWeb.initialCheckbox}
                                    checked={userWeb.initialCheckbox === true}                 
                                    onChange={() => setConfirmation()}
                                    style={{margin: '30px 32px 30px 32px'}}
                                >                                                                
                                </CheckBox>
                                <div id="divText">
                                    <b id="text2Id">No pertenezco a la ONP y tengo más de 2 años afiliado en una AFP</b>               
                                </div>
                                <Tooltip 
                                    id= "tooltip"
                                    theme="light"
                                    style={{display: 'flex'}}
                                    content={
                                    <TooltipContent 
                                        title={
                                        <>
                                        <p style={{color:'#696158'}}>¿Por qué te pedimos esto?
                                        </p>
                                        </>
                                        } 
                                        subtitle={
                                        <>
                                           <p style={{color:'#696158'}}>
                                                Para traspasarte entre AFP’s debes cumplir
                                                minimo con alguno de estos requisitos por ser la 
                                                primera vez que te afilias al Sistema Privado de 
                                                Pensiones (SPP).
                                            </p>
                                        </>
                                        } 
                                    />
                                    }>
                                    <img
                                        id="tooltipImg"
                                        src={iIcon}
                                        alt="tooltipPensioner"
                                    />
                                </Tooltip>   
                            </div>
 
                        </ContentStepInitial>
                    </div>                        
                    <Text id="text1" size="16" weight="400" line-height="20px" color="#696158"style={{ paddingTop: '24px'}}>¿No sabes estos datos? <a id="linkId" onClick={()=>sendToGTM(1)} className="orange" href="https://servicios.sbs.gob.pe/ReporteSituacionPrevisional/Afil_Consulta.aspx" target="_blank" rel="noreferrer">Consúltalo aquí</a></Text>
                    <div id="ButtonDiv1">
                            <Button id="button1" disabled={!userWeb.initialCheckbox} onClick={Init} > Empezar</Button>
                    </div>

                </DivContent>
                
                <DivReco>
                    <p style={{paddingBottom:'16px',color:'#696158', fontWeight: '700',  fontSize: '16px', lineHeight: '20px' }}>Te recomendamos: </p>
                    <DivRecoCont style={{display: 'flex', alignItems: 'stretch', paddingBottom: '24px'}}>                       
                        <div className="item1" style={{flex:'1 1 1%', display: 'flex', alignItems: 'center'}}>
                            <img className="logo" src={camera} alt="imagen option" />          
                            <p id="reco1Id" style={{fontSize:"14px", lineHeight:"16px", fontWeight:400, marginLeft:'16px'}}  >
                                Habilitar tu cámara para firmar tu traspaso por medio de validación biométrica.
                            </p>  
                        </div>     
                        <div className="separator" style={{borderLeft: "solid #696158", width:'1px',  marginLeft:'24px'}}></div>
                        <div className="item2" style={{flex:1, display: 'flex',  marginLeft:'23px', alignItems: 'center'}}>
                            <img className="logo" src={infoLogo} alt="imagen option" />          
                            <p id="reco2Id" style={{fontSize:"14px", lineHeight:"16px", fontWeight:400, marginLeft:'16px' }}  >
                                Infórmate más sobre cada <a className="orange" style={{color:"#F04C02" }} onClick={()=>sendToGTM(2)} href="https://www.prima.com.pe/public-zone/aportes-y-fondo/tipos-de-fondo/" target="_blank" rel="noreferrer"> tipo de fondo (0,1,2 y 3) aquí.</a>
                            </p>  
                        </div>           
                    </DivRecoCont> 
                </DivReco>  
                
                <WhatsApp />  

                <script id="CookieDeclaration" src="https://consent.cookiebot.com/6ac38517-8d7e-4d27-baae-1be1d5c325f9/cd.js" type="text/javascript" async></script>     
                <script id="CookiePolicy"      src="https://consent.cookiebot.com/6ac38517-8d7e-4d27-baae-1be1d5c325f9/cd.js" type="text/javascript" async></script>
            </DivStepI>
        </>
    );
};
