import styled, { css } from "styled-components";
import { Text } from "../../../App/pages/Text";
import { media } from "@prima-afp/prima-ui-elements/dist/utils/media/styledConfig";
import { Select } from "@prima-afp/prima-ui-elements/dist/core";
import flecha from "../../../App/assets/images/flecha2.svg"
import { mediaQuery } from "modules/utils/responsive";


export const CardAddress = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-color: rgba(231, 231, 232, 1);
    border-width: 0px;
    font-size: 16px;
    color: var(--Text-Gray-Default, #4e5258);
    font-weight: 600;
    letter-spacing: 0.02px;
    line-height: 24px;
    padding: 24px;
    margin-bottom: 32px;

    border-radius: 12px;
    box-shadow: 0px 16px 32px -4px rgba(69, 73, 78, 0.1), 0px 2px 4px 0px rgba(69, 73, 78, 0.04);
    background-color: #fff;


    .divCard-1{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
    }
    .divCard-2 {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .divCard-3 {
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Lexend, sans-serif;
        flex: 1;
    }
    .green{
        justify-content: center;
        border-radius: var(--Radius-Radius-999, 999px);
        background-color: var(--Background-Secondary-Subtle, #e6fafa);
        color: var(--Shape-Gray-Default, #4e5258);
        padding: 0 8px;
        font: 400 16px Calibri, sans-serif;
        width: 68%;
    }
    @media only screen and (max-width: 700px) {
        padding-top: 13px;
        width: 328px;
        .green{
            width: 77%;
        }
        .divCard-2 {
            height: 65px;
        }
    }
    @media only screen and (min-width: 701px) {
        width: 635px;
        .green{
            width: 68%;
        }
    }


`;

export const AddressForm = styled.div`
    
    margin-top: 40px;
    .inputAddress{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .btnRegister{
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        .cancelButton{
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Lexend, sans-serif;
          justify-content: center;
          border-radius: var(--Radius-Radius-12, 12px);
          color: var(--Text-Gray-Default, #4e5258);
          padding: 12px 20px;
        }
        .newUiStartButton{
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Lexend, sans-serif;
          justify-content: center;
          border-radius: var(--Radius-Radius-12, 12px);
          padding: 12px 20px;
        }
        
        .disabledBtn{
          border-radius: var(--Radius-Radius-16, 16px);
          background-color: var(--Background-Disabled-Subtle, #e7e7e8);
          color: var(--Text-Disable-Disabled, #84878b);
        }

        .enabledBtn{
                background-color: var(--orangeColorMain);
        color: #FFFFFF;
        }
    }
    .cancelButton{
        background-color: white;
        color: black;
        width: 198px !important;
    }
    .startButton{
        width: 198px !important;
    }
    .inputAddress{
        width: 279px;
    }

    .title-1{
        color: var(--Text-Secondary-Default, #04a6a2);
        font: 700 16px/125% Calibri, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    @media only screen and (max-width : 450px)  {
        .btnRegister{
            display: flex;
            justify-content: center;
            flex-direction: column-reverse;
            .cancelButton{
                width: 286px !important;
            }
            .startButton{
                width: 286px !important;
            }
        }
        width: 286px;
        .WrapperInput{
            width: 286px !important;
            .container{
                width: 286px !important;
            }
            .list-container {
                width: 286px !important;
            }
        }
    }

    @media only screen and (min-width : 451px)  {
        .gridInputs{
            margin-bottom: 20px;
            margin-top: 16px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .groupFA{
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
            .groupFrameAddress{
                margin-bottom: 24px;
            }
            .WrapperInput{
                width: 286px !important;
                .container{
                    width: 286px !important;
                }
                .list-container {
                    width: 286px !important;
                }
            }
        }
        .btnRegister{
            justify-content: center;
        }
    }


`;

export const SelectInline = styled(Select)`
    padding-bottom: 20px;
    &.little-select {
        max-width: 150px;
    }
    .rc-select-selector{
        border: 1px solid rgba(105,97,88,0.4);
    }
    .select-error-message {
        color: var(--redColorError);
        position: inherit;
        bottom: 0px;
        left: 8px;
        font-size: 14px;
        line-height: 16px;
    }
    @media only screen and (min-width : 1728px) {
        width: 392px;
    }
 
`;

export const TextGray = styled(Text)`
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #696158;
    @media only screen and (max-width : 450px) {   
        justify-content: flex-start;
    }
    @media only screen and (min-width : 451px) {
        text-align: left;
        justify-content: flex-start;
        display: flex;
    }
`;
