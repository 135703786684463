import React, { useState } from 'react';
import '../styles.jsx';

const TooltipCustom = ({item, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
      setShowTooltip(true);
    };
  
    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
  
    return (
      <div className="tooltip-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
        {showTooltip && <div className={`tooltip ${'tooltip'+item.id}`}>
          <div className="tooltip-title-container">{item.name}</div>  
          <div className="tooltip-descr-container">{item.content} <b>{item.content2}</b></div>             
        </div>}                                 
      </div>

    );

};

export default TooltipCustom;
