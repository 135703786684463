import React from "react"
import { Helmet } from "react-helmet"

export const SeoLanding = () => (
    <Helmet>
        <meta
          name="keywords"
          content="Cambio de AFP, Traspaso AFP, Prima AFP, AFP Credicorp, AFP Grupo Crédito, Traspaso Digital AFP"
        />
        <link rel="icon" type="image/jpg" href="/favicon.ico" />
        <title>Cambiate a Prima AFP | Es gratis, fácil y 100% Online</title>
        <meta
          name="description"
          content="Cambiar de AFP nunca ha sido tan fácil. Cambiarte a Prima AFP es gratis, fácil y 100% online. Recuerda que los cambios de AFP no afectan tu fondo!"
        />
        <link rel="canonical" href="https://traspaso.prima.com.pe/" />
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "Traspaso AFP - Cambiar de AFP",
          "image": "https://traspaso.prima.com.pe/static/media/slider2.52f7a31a7fdba841253d654d905acd73.svg",
          "description": "Cambiar de AFP nunca ha sido tan fácil. Cambiarte a Prima AFP es gratis, fácil y 100% online. Recuerda que los cambios de AFP no afectan tu fondo!",
          "brand": {
            "@type": "Brand",
            "name": "Prima AFP"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "1",
            "ratingCount": "99"
          }
        }`}
        </script>
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org",
          "@type": "Product",
          "description": "Cambiar de AFP nunca ha sido tan fácil. Cambiarte a Prima AFP es gratis, fácil y 100% online. Recuerda que los cambios de AFP no afectan tu fondo!",
          "name": "Traspaso Digital | Prima AFP",
          "review": [
            {
              "@type": "Review",
              "datePublished": "2024-03-04",
              "reviewBody": "Se preocupan por la estabilidad y la tranquilidad de sus afiliados",
              "author": {
                "@type": "Person",
                "name": "Maria"
              },
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "10",
                "ratingValue": "9",
                "worstRating": "1"
              }
            },
            {
              "@type": "Review",
              "datePublished": "2024-03-25",
              "reviewBody": "Confío en el grupo Credicorp y tienen una atención rápida",
              "author": {
                "@type": "Person",
                "name": "Anonimo"
              },
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "10",
                "ratingValue": "10",
                "worstRating": "1"
              }
            }
          ]
        }`}
        </script>
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "¿Cómo pasarme a Prima AFP?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Puedes realizar tu traspaso de AFP después de un mínimo de 2 años en tu AFP actual."
              }
            },
            {
              "@type": "Question",
              "name": "¿Qué pasa si me traslado de AFP?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Al cambiarte de AFP, trasladas el total de tus aportes obligatorios a la AFP que elijas."
              }
            },
            {
              "@type": "Question",
              "name": "¿El traspaso de AFP tiene algún costo?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "No, es 100% gratis. No tiene ningún costo ni cobro de comisión el traspasarte de una AFP a otra."
              }
            },
            {
              "@type": "Question",
              "name": "¿Quienes pueden hacer el trámite de traspaso?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Todos los afiliados en cualquier otra AFP pueden realizar el traspaso. Además, aquellos que no tengan ninguno de los siguientes trámites en proceso: traspaso anterior, desafiliación o nulidad, solicitud de evaluación y calificación de invalidez. Si ingresaste al SPP a partir del 01 de junio de 2013, debes cumplir adicionalmente uno de estos requisitos: tener un mínimo de dos años de afiliación en tu AFP o tener más de 180 días calendario afiliado y una rentabilidad menor al promedio del sistema (constancia emitida por tu AFP). (* 180 días calendario) (** Rentabilidad neta de comisiones calculada al momento de solicitar el traspaso, válida por 10 días calendario)."
              }
            },
            {
              "@type": "Question",
              "name": "¿Cómo puedes realizar la solicitud de traspaso?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Para realizar tu solicitud de traspaso a Prima AFP tienes dos opciones: 100% digital: Completa en solo 4 pasos tu solicitud desde tu PC, tablet o celular [aquí](https://traspaso.prima.com.pe/index.html). Correo electrónico: Escríbenos a traspaso@prima.com.pe y adjunta tu solicitud de traspaso firmada y escaneada, junto con una copia de tu documento de identidad vigente. Recibirás un correo de confirmación y te llamarán para validar los datos. En los siguientes 30 días calendario te informaremos por correo electrónico si tu solicitud ha sido aceptada."
              }
            },
            {
              "@type": "Question",
              "name": "¿Si me cambio de AFP pierdo dinero?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "No, los cambios de AFP no afectan tu fondo. Recuerda que puedes hacer tu cambio de AFP hacia Prima AFP aquí."
              }
            }
          ]
        }`}
        </script>
      </Helmet>
)