import PropTypes from "prop-types";
import React from "react";
import { Container } from "@prima-afp/prima-ui-elements/dist/layouts";
import { CardBody, Card } from "@prima-afp/prima-ui-elements/dist/core";
import { ErrorModal } from "../../../shared/components/ErrorModal";
import { ErrorModalNewUI } from "../../../shared/components/ErrorModalNewUI";
import { CallToModal } from "../../../shared/components/CallToModal";
import { TCModal } from "../../../shared/components/TCModal";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import Loading from "../../../shared/components/Loading";

export const MainContainer = ({ type, children }) => {
  const { modalOptions, TCmodalOptions, showLoading, modalOptionsCTA, stepCurrent } =
    useStateDigitalTransfersContext();
  const dispatch = useDispatchDigitalTransfersContext();

  return (
    <>
      <Container type={type} style={{ margin: "0px auto", maxWidth: "100%" }} className="ContainerStyle">
        {children}
      </Container>

      {
        window.location.href.includes('asesor') &&
        <ErrorModal
          settings={modalOptions}
          closeModal={() =>
            dispatch({
              type: "CLOSE_MODAL",
            })
          }
        />
      }

      {
        !window.location.href.includes('asesor') &&
        <ErrorModalNewUI
          settings={modalOptions}
          closeModal={() =>
            dispatch({
              type: "CLOSE_MODAL",
            })
          }
        />
      }

      {
        stepCurrent == 0 && window.location.href.includes('pasos0')  && !window.location.href.includes('asesor/pasos0') &&
        <div className="ModalCallToAction" style={{ display:'none' }}>
            <CallToModal         
            settings={modalOptionsCTA}
            closeModal={() =>
              dispatch({
                type: "CLOSE_MODAL_CTA",
              })
            }
          />
          </div>
      }

      <TCModal
        settings={TCmodalOptions}
        closeModal={() =>
          dispatch({
            type: "CLOSE_TC_MODAL",
          })
        }
      />

      <Loading show={showLoading} />
    </>
  );
};

MainContainer.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
};
