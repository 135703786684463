import React, { useState, useEffect } from "react";
import { Button } from "@prima-afp/prima-ui-elements/dist/core";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { ButtonsContainer } from "../StepIdentity/styles";
import info from "./resources/info.svg";
import { Step3Wrapper } from "./styles";
import { ListRecommendations } from "../../components/ListRecommendation";
import { useCatchFetch } from "../../hooks/useCatchFetch";import { SavePhotoReco, Abandon } from "../../services";
import { useHistory } from 'react-router-dom';
import { sendToData } from "../../constants/sendToDataLayer";
import { updateUrl } from "../../constants/updateUrl";


export const StepRecognition = () => {
  const { biometricVerified, userWebTransfer, userWeb } = useStateDigitalTransfersContext();
  const dispatch = useDispatchDigitalTransfersContext();

  const { SendFetch } = useCatchFetch();
  const [showModal, setShowModal] = useState(false);
  const [biometricUrl, setBiometricUrl] = useState('/pasos3validacion-biometrica');
  const history = useHistory();
  const [valuesDevice, setValuesDevice] = useState("");
  const [userAgentValue, setUserAgentValue] = useState("");

  useEffect(() => {
    if (!biometricVerified) {
      setShowModal(true);
      if (window.location.href.includes('asesor')) {
        setBiometricUrl('/asesor/pasos3validacion-biometrica');
      }
    }
  }, []);

  useEffect(() => {
      // Agregar un event listener cuando el componente se monta
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Eliminar el event listener cuando el componente se desmonta
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
  }, []);

  const handleBeforeUnload = async (e) =>{
      const message = "¿Seguro que quieres salir?";
      e.returnValue = message;
      const userResponse = window.confirm(message);
      try {
          var BodyPayload = { step: 3, documentNumber: userWeb.documentNumber, documentType: "00", active: true };
          var abandonRes = await SendFetch(Abandon(BodyPayload));
        } catch (error) {
          console.error('error', error);
          // Maneja el error de manera adecuada
      }  
  };

  const getDeviceInfo = () => {
    // Tipo de dispositivo
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const deviceType = isMobile ? 'Mobile' : 'Desktop';
    let mobileOS = 'Unknown';
    if (isMobile) {
      if (/Android/i.test(navigator.userAgent)) {
        mobileOS = 'Android';
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        mobileOS = 'iOS';
      }
    }
    // Sistema operativo
    const os = navigator.platform;
    // Navegador
    const browser = (() => {
      const userAgent = navigator.userAgent;
      const browsers = {
        Chrome: /Chrome/i,
        Firefox: /Firefox/i,
        Edge: /Edg/i,
        Safari: /Safari/i,
        Opera: /Opera|OPR/i,
        IE: /Trident|MSIE/i,
      };
      for (const key in browsers) {
        if (browsers[key].test(userAgent)) {
          return key;
        }
      }
      return 'Unknown';
    })();
    var concatVal = deviceType + '|' + mobileOS + '|' + os + '|' + browser;
    setValuesDevice(concatVal);
    setUserAgentValue(navigator.userAgent);
    if (isMobile) {
        var OS = mobileOS.toLowerCase();
        dispatch({ type: "FIELD", payload: { field: "device", value: OS, }, });
    }else{
        dispatch({ type: "FIELD", payload: { field: "device", value: 'desktop', }, });
    }
    dispatch({ type: "FIELD", payload: { field: "userAgent", value: navigator.userAgent, }, });    
    dispatch({ type: "FIELD", payload: { field: "deviceInfo", value: concatVal, }, });    
  };

  const startValidation = async () => {

    try{
      var body={
        documentType :  userWebTransfer.documentType,
        documentNumber: userWebTransfer.documentNumber
      };
      SendFetch(SavePhotoReco(body)).then((res) => { 
          if(res.code == 1 ){
            getDeviceInfo();
            dispatch({ type: "FIELD", payload: { field: "stepCurrent", value: 3.5 } });
            dispatch({
              type: "FIELD",
              payload: { field: "showDivBenefits", value: false },
            });
            const currentUrl = window.location.href;
            var payload = {
                section: 'paso 3', 
                option: 'tomar foto',
            };
            sendToData(currentUrl, "button_click", "button_click", payload);  
            window.location.href.includes('asesor') ? updateUrl(history, currentUrl,'/asesor/pasos3validacion-biometrica') :updateUrl(history, currentUrl,'/pasos3validacion-biometrica');
          }
      });

    }catch(e){
      console.log('error', e);
    }


  };

  return (
    <Step3Wrapper>
      <p className="titleStep3">Firma tu solicitud con una foto</p>
      <p className="descriptionStep3">
        Toma una foto de tu rostro para validar tu identidad. Con esto, declaras
        expresamente tu <b>decisión de traspasar tu fondo</b> a Prima AFP
      </p>
      <div className="cardStep3">
        <ListRecommendations />
      </div>
      <div className="alertStep3">
        <div className="icon">
          {" "}
          <img src={info} style={{ width: "24px", height: "24px" }} />{" "}
        </div>
        <span className="alertText">
          {" "}
          Esta información solo será utilizada para tu solicitud.
        </span>
      </div>
      <ButtonsContainer className="wrapperButton">
        <Button id="start-biometric" data-testid="input-field" onClick={startValidation}>
            Validar identidad
        </Button>

      </ButtonsContainer>
    </Step3Wrapper>
  );
};
