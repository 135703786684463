import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { CheckboxGroup } from "./styles";
import { CheckBox } from "@prima-afp/prima-ui-elements/dist/core";
import React  from "react";
import { sendToData } from "../../constants/sendToDataLayer";

export const FrameAuthorization = () => {
  const dispatch = useDispatchDigitalTransfersContext();
  const { userWeb } = useStateDigitalTransfersContext();

  const setValueADP = () => {
    const newValue = !userWeb.adpConfirmation;
    if(!userWeb.adpConfirmation) {
      var payload = {section: '0', option: "autorizo los usos adicionales de mis datos personales" };
      sendToData(window.location.href, "box_click", "box_click", payload);     
    }
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "adpConfirmation",
        value: newValue,
      },
    });
  };

  const sendInfo = (option) => {
    const currentUrl = window.location.href;
    var payload = {
        section: 'paso 0', 
        option: option,
    };
    sendToData(currentUrl, "link_click", "link_click", payload);  
  };

  const OpenModal_TC = () => {
    const title = "Cláusula de protección de datos informativa";
    const type = "TC";
    dispatch({
      type: "SHOW_TCMODAL",
      payload: {
        title: title,
        type: type,
        changeLoading: false,
        value: userWeb.tcConfirmation,
      },
    });
    sendInfo("terminos y condiciones y el aviso de privacidad");
  };

  const OpenModal_ADP = () => {
    const title = "Cláusula de protección de Datos Usos Adicionales";
    const type = "ADP";
    dispatch({
      type: "SHOW_TCMODAL",
      payload: {
        title: title,
        type: type,
        changeLoading: false,
        value: userWeb.adpConfirmation,
      },
    });
    sendInfo("uso de mis datos personales");
  };

  const openPagePolitica = () => {
    window.open(
      "https://www.prima.com.pe/public-zone/politica-de-privacidad/#tratamiento-y-transferencia-de-tus-datos-personales",
      "_blank",
      "noopener"
    );
  };

  return (
    <CheckboxGroup>

      <div className="ContentFrameAutho" >
        <CheckBox
          checked={userWeb.adpConfirmation === true}
          onChange={setValueADP}
          fillColor="#04A6A2"
        ></CheckBox>
        <label className="textCheckBox">
          <span onClick={setValueADP} data-testid="setValueADP" style={{color:'rgba(78, 82, 88, 1)'}}>
          Acepto los {" "}
          </span>

          <span
            onClick={OpenModal_ADP}
            className="textBold"
            data-testid="OpenModal_ADP"
          >
            Usos adicionales de mis Datos Personales
          </span>
        </label>
      </div>

      <div className="ContentFrameAutho" >
        <div style={{ display: "flex" }}>
          <label className="textCheckBox" style={{color:'var(--Text-Gray-Subtle, #8f9295)'}}>
            <span>Al cliquear en "Continuar" declaro aceptar los </span>
            <span onClick={OpenModal_TC} className="textBold2">
               Términos y Condiciones y Política de Privacidad
            </span>
            
          </label>
        </div>
      </div>

    </CheckboxGroup>
  );
};
