import styled from "styled-components";

export const QueryPageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .titleQuery {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #696158;
    padding-top: 56px;
    padding-bottom: 32px;
  }
  .breadcrumbT {
    width: 100%;
    display: grid;
    align-items: center;
    border-bottom: 1px solid #e4e5e5;
  }
  .contentBreadcrumb {
    display: flex;
    height: 48px;
    flex-shrink: 0;
    align-items: center;
    justify-content: left;
    background-color: #fff;
  }
  .contentFijo {
    padding-left: 17px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .cursorPointer {
    cursor: pointer;
  }
  .imgHome {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .imgFlecha {
    width: 16px;
    height: 15.695px;
    margin-left: 10.4px;
  }

  .textBreadCrumb {
    color: #696158;
    font-family: Calibri;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 11.77px;
    margin: 0px;
    height: 20px;
  }
  .bodyQuery {
    max-width: 600px;
    box-sizing: border-box;
    padding-top: 16px;
    .textBody {
      font-family: "Calibri";
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #696158;
      margin-bottom: 34px;
    }
    .inputDocument {
      width: 412px;
    }
  }
  .contentReset {
    padding: 0px 20px;
    .reset {
      margin: 32px 0px;
      @media only screen and (max-width: 450px) {
        width: 100%;
      }
    }

    @media only screen and (max-width: 450px) {
      width: 100%;
    }
  }
  .footerQuery {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
  }
  @media only screen and (max-width: 450px) {
    padding-bottom: 32px;
    .titleQuery {
      max-width: 226px;
      padding: 0px !important;
      padding-top: 44px !important;
      margin: 0px !important;
    }
    .breadcrumbT {
      .contentFijo {
        padding-left: 0px;
      }
    }
    .bodyQuery {
      max-width: 335px;
      padding-top: 32px;

      .textBody {
      }
      .inputDocument {
        width: 335px;
      }
    }
  }
  @media only screen and (min-width: 451px) and (max-width: 768px) {
    margin-bottom: 24px;
    .titleQuery {
      padding-top: 72px;
      padding-bottom: 32px;
    }
  }
`;

export const QueryPageResult = styled.div`
  border: 1px solid #e2e2e2;
  max-width: 636px;
  width: 100%;
  margin-top: 40px;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
  padding: 40px 42px;
  .textRegards {
    font-family: "FS Emeric";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    display: flex;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #696158;
    p {
      width: 100%;
    }
    @media only screen and (max-width: 450px) {
      text-align: center;
    }
  }
  .parrafo {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    text-align: center;
    color: #696158;
    width: 100%;
    p {
      width: inherit;
    }
  }
  .informationContent {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #696158;
    margin-top: 32px;
  }
  .contentButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    button {
    }
  }

  @media only screen and (max-width: 450px) {
    max-width: 335px;
    margin: 34px 20px 0px 20px;
    padding: 40px 32px;
    .textRegards {
      padding-bottom: 16px;
    }
  }
`;

export const BiometricWrapper = styled.div`
  margin-top: 32px;
  .titleStep3 {
    font-family: "FS Emeric";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #00a499;
    margin-bottom: 48px;
    text-align: center;
  }
  .subtitleStep3 {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #696158;
  }
  .helpSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  .helpButton {
    max-width: 238px;
    min-height: 54px;
    width: 100%;
    border-radius: 32px;
    box-shadow: 2px 4px 32px 2px rgba(225, 225, 225, 1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconHelp {
    display: flex;
  }

  .alertHelpText {
    max-width: 119px;
    font-family: FS Emeric;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(0, 164, 153, 1);
  }

  .LKfZl {
    margin-bottom: 0px;
  }

  .alertStep3 {
    display: flex;
    width: 413px;
    height: 56px;
    background: #eefbf9;
    border-radius: 16px;
    margin: auto;
    margin-bottom: 32px;
  }
  .icon {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .alertText {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: "Calibri";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #696158;
  }
  .ditfrl {
    margin-bottom: 0px !important;
  }

  .modal-content-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .modal-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 16px;
  }

  .modal-title {
    font: 600 24px/108% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--Text-Gray-Default, #4e5258);
    padding-bottom: 16px;
  }

  .modal-description {
    max-width: 252px;
    font-family: Calibri;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 47px;
  }

  // .modal-button {
  //   max-width: 252px;
  //   width: 100%;
  //   padding: 16px 32px 16px 32px;
  //   border-radius: 8px;
  //   gap: 10px;
  //   background: rgba(255, 79, 0, 1);
  //   color: rgba(255, 255, 255, 1);
  // }
  .modal-button{
    justify-content: center;
    border-radius: var(--Radius-Radius-16, 16px);
    white-space: nowrap;
    text-align: center;
    letter-spacing: 0.02px;
    padding: 16px 20px;
    font: 400 16px / 133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
    background-color: var(--orangeColorMain);
    color: #FFFFFF;
  }


  @media only screen and (max-width: 450px) {
    margin-top: 8px;
    .titleStep3 {
      width: 253px;
      height: 64px;
      margin: auto;
    }
    .subtitleStep3 {
      margin-top: 24px;
    }
    .alertStep3 {
      width: 295px;
      margin-bottom: 64px;
      height: 40px;
    }
    .icon {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .alertText {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    margin-top: 16px;
    .titleStep3 {
      margin-bottom: 96px;
    }
  }
`;

export const ContainerFinishPage = styled.div`
  width: 635px;
  .addressDiv{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
  .titleDescription{
    font-weight: 400;
    width: 100%;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Calibri, sans-serif;
    color: var(--Text-Gray-Default, #4e5258);
  }
  .addressDescription{
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Lexend, sans-serif;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.02px;
    align-self: center;
    margin-top: 8px;
    color: var(--Text-Gray-Default, #4e5258);
    text-align: center;
  }
  .butFinish {
    width: 258px;
    height: 40px;
    display: flex;
    justify-content: center;
    background-color: #04A6A2;
  }
  .wrapperFinishButton{
    margin-top: 32px;
    margin-bottom: 104px;
    display: flex;
    justify-content: center;
  }
  .iconFinish{
    height: 114px;
    width: 104px;
    margin-left: 17px;
    margin-bottom: 12px;
  }

  .buttonFinish{
    justify-content: center;
    border-radius: var(--Radius-Radius-12, 12px);
    background-color: var(--Background-Secondary-Default, #04a6a2);
    display: flex;
    gap: 8px;
    font-size: 18px;
    color: var(--Text-White-Default, #fff);
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.02px;
    line-height: 133%;
    display: flex;
    align-items: center;
  }
  .spanTextBtnFinish{
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Lexend, sans-serif;
  }
  #text1Finish {
    font-family: Lexend, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #00a499;
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;
    margin-top: 24px;
  }

  #text2Finish {
    font-family: "FS Emeric";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #696158;
    margin-bottom: 4px;
  }

  #text3Finish {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #696158;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 450px) {
    margin: auto;
    width: 100%;
    margin-top: 32px;
    align-items: center;
    display: flex;
    flex-direction: column;
    #text1Finish {
      text-align: center;
    }
    #text2Finish {
      text-align: center;
      margin-bottom: 8px;
    }
    #text3Finish {
      text-align: center;
    }
    .butFinish {
      width: 312px;
      height: 48px;
    }
    .wrapperFinishButton{
      margin-bottom: 54px;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    margin: auto;
    margin-top: 176px;
    align-items: center;
    display: flex;
    flex-direction: column;
    #text1Finish {
      text-align: center;
    }
    .butFinish {
      width: 285px;
      height: 40px;
    }
    .showSectionAddress{
      max-width: 635px;
      width: 635px;
    }
  }

  @media only screen and (min-width: 1001px) {
    .butFinish {
      width: 285px;
      height: 40px;
    }
    .showSectionAddress{
      max-width: 635px;
      width: 635px;
    }
  }
`;
