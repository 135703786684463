import styled from "styled-components";
import { Text } from "../Text";

export const MainTitle = styled(Text)`
    font-family: "FS Emeric";
    font-weight: 600;
    font-size: 24px;
    color: var(--orangeColorMain);
    margin-bottom: 2rem;
`;


export const MainTitleGreen = styled(Text)`
    color: #00A499;
    font-family: FS Emeric, sans-serif;
    font-weight: 600;
    font-size: 24px;
    opacity: 1;
    margin-bottom: 32px;
    line-height: 32px;
    @media only screen and (max-width : 376px) {   
        font-weight: 600;
    }
    @media only screen and (max-width : 1000px) {   
        text-align: center;
    }
    @media only screen and (min-width : 1728px) {
        font-size: 32px;
    }
`;
