import React from 'react'
import { Circle, CircleDescriptionWrapper, DateStep, Description, Line, Step, StepWrapper, TimelineWrapper } from './Style'
import x from '../../images/x.svg'
import check from '../../images/Check.svg'

export const StatusLineTime = ({ steps }) => {
  return (
    <TimelineWrapper>
      { steps && steps.map((step, index) => (
        <React.Fragment key={index}>
          <StepWrapper>
            <CircleDescriptionWrapper>
              <Circle status={step.status} color={step.color}>
                {
                  step.finished === true ? (
                    <img src= { check } alt='aceptada' />
                  ) : step.finished === false ? (
                    <img src= { x } alt='rechazada' />
                  ) : (
                    step.number
                  ) 
                }
              </Circle>
              <Description status={step.status} color={step.color}>{step.description}</Description>
            </CircleDescriptionWrapper>
            <DateStep>{step.date}</DateStep>
          </StepWrapper>
          {index < steps.length - 1 && <Line color={step.color}/>}
        </React.Fragment>
      ))}
    </TimelineWrapper>
  )
}
