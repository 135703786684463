import React from 'react';
import ReactDOM from 'react-dom';
// Redux

// Main Page
import PublicTransactionsApp from './PublicTransactionsApp.jsx';

// Polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './polyfills';

// SW
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <PublicTransactionsApp />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
