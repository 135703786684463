import React, { useState, useEffect } from 'react';
import { useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext';
import {WraperCard} from './styles';
import fund0 from './fund0.svg';
import fund1 from './fund1.svg';
import fund2 from './fund2.svg';
import fund3 from './fund3.svg';

export default function PrimaCards ({ options=[], initialOption, saveCardSelection }) {
  const [selected, setSelected] = useState(initialOption);
  const { userWeb } = useStateDigitalTransfersContext();

  useEffect(() => {
    setSelected(initialOption);
  }, [initialOption]);

  const handleClick = (option) => {
    setSelected(option.type);
    saveCardSelection(option);
  };

  const getImage = (value) => {
      switch(value){   
        case 0: return fund0;
        case 1: return fund1;
        case 2: return fund2;
        case 3: return fund3;
    }
  };

  return (
    <WraperCard >
        {options.map(option => (
            <div className={option.type === selected || option.type === userWeb.fundType ? 'cardSelected': 'cardNoSelected'} key={option.type} id={'card'+option.type} onClick={() => handleClick(option)}>
                <div className="cardContent" >

                    <div className="cardImgContainer">
                        <img
                            loading="lazy"
                            src={getImage(option.type)}
                            className="cardImg"
                        />
                        <div className={option.type === selected || option.type === userWeb.fundType ? 'circleSelected': 'circleNoSelected'} />
                    </div>

                    <div className="cardLabel">{option.label}</div>

                    <div className="cardDescription">
                    {option.description}<strong>{option.strongDescription}</strong> 
                    </div>

                </div>
            </div>
        ))}
    </WraperCard>
  );
}
