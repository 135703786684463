import styled from "styled-components";
import { media } from "@prima-afp/prima-ui-elements/dist/utils/media/styledConfig";
import { mediaQuery } from "modules/utils/responsive";

export const WrapperModalCTA = styled.div`
  .textBold{
    color: #FF4F00;
    text-decoration: underline;
    cursor: pointer;
    padding-right: 5px;
    padding-left: 5px;
  }
  .ModalGrayBackground{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      outline: 0;
      z-index: 1072;btn1
      overflow-x: hidden;
      background: rgba(0,0,0,0.6);
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .Modal{
    width: 479px;
    @media only screen and (max-width: 450px) {
      height: 90%;
    }
  }
  .ModalWrapper{
      padding: 0px 0px 0px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      box-shadow: 0px 10.3987px 20.7974px rgba(44,39,56,0.04),0px 20.7974px 41.5948px rgba(44,39,56,0.08);
      border-radius: 1rem;
      outline: 0;

      .emailSendContent{
        padding-top: 24px;
        border-bottom-width: 24px;
        padding-bottom: 24px;
        padding-left: 24px;
        padding-right: 24px;
        .emailSendDescription{
          padding-bottom: 16px;
          padding-top: 16px;
        }
      }
    }
  }

  .imgModal{
    background-size: cover;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    height: 190px;
    @media only screen and (max-width: 450px) {
      height: 160px;
    }
  }
  
  .greenText{
    font-family: FS Emeric;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: #00A499;
  }
`;

export const FooterButton = styled.div`
  .buttonNCTA{
    background-color: #00CCCC !important;
  }
  @media only screen and (max-width : 450px) {   
    height: 128px !important;
    justify-content: center !important;
    #btn1{
      margin-bottom: 10px;    
      width: 234px;
    }
    #btn2{
      width: 234px;
    }
  }
  @media only screen and (min-width : 451px) {
    .wrapperButtonCTA{
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
  }
`;


export const ModalContent = styled.div`
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-right: 32px;
  padding-bottom: 24px;
  padding-left: 32px;
  .title1{
    font-weight: 600;
  }
  .title2{
    font-weight: 400;
  }
  .greenText{
    font-family: FS Emeric;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: #00A499;
  }
  #textTC{    
    text-align: justify;
    text-align: justify;
    color: #AAA9A9;
    padding-top: 12px;
  }
  @media only screen and (max-width : 450px) {   
    padding-right: 16px;
    padding-left: 16px;
    .greenText{
      font-size: 20px;
    }
    #textTC{
      padding-top: 32px;
      padding-bottom: 24px;
    }
    .textCheckBox{
      text-align: justify !important;
      width: 100%;
    }
  }
  @media only screen and (min-width : 451px) and (max-width: 1000px) {
    height: 340px !important;
  }
  .buttonWrapperCTA{
    width: 302px;
  }
`;


const _buttonMobile = `
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    justify-content: normal;

    button:last-child {
        width: 100%;
        order: -1;
        height: 48px;
        margin-bottom: 1rem;
    }
`;

export const ButtonClose = styled.div`
    text-align: right;
    color:#000;
    font-weight: bold;
    margin-top: 0.5rem;
    margin-right: 1rem;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    top: 0;
    right: 0;

`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    ${media.sm`
        ${_buttonMobile}
    `};
    
    ${media.xs`
        ${_buttonMobile}
    `};

    #btn1{
      color: #FFF;
      border: 1px solid #00CCCC;
      text-align: center;
      background-color: #00CCCC !important;
    }

    @media only screen and ${mediaQuery.mobileS}{
      .buttonStep1{
        width: 76% !important;
      }
      #btn-fund-type-send{
        width: 93vw;
      }
    }


    @media only screen and (max-width : 450px) {   
        margin-top: 24px  !important;
        #buttonCTAM{
          margin-bottom: 10px;
        }
        #btn-no-show-types{
          margin-bottom: 56px;
        }
      }
    }

    @media only screen and (min-width : 451px) and (max-width: 1000px) {
        margin-top: 0px !important;
        margin-bottom: 24px !important;
        max-width: 704px !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center;
        .buttonStep1{
            width: 160px !important;
        }
        #btn-fund-type-send{
          width: 160px;
          height: 48px;
        }
        #btn-no-show-types{
          width: 160px;
          height: 48px;
        }
    }

    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        #identity-btn-env{
            width:142px;
            height: 40px;
            margin-top: 40px;
        }
        #btn-fund-type-send{
          margin-bottom: 56px;
        }
        #btn-no-show-types{
          margin-top: 48px;
          margin-bottom: 56px;
        }
    }
    
    @media only screen and (min-width : 1728px) {
        margin-top: 48px !important;
        margin-bottom: 64px;
        #btn-no-show-types{
          margin-top: 48px;
          margin-bottom: 56px;
        }
    }

`;
