import React, { useEffect, useState } from 'react';
import { DivWhats  } from './styles';
import iconWhatsapp from "../../assets/images/iconWhatsapp.svg"; 
import close from './Resources_stepInitial/closeWhatsapp.svg';
import parse from 'html-react-parser';
import { useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext';

export const WhatsApp = () => {
    const [showWsp, setShowWhatsapp] = useState(false);
    const { whatslink } = useStateDigitalTransfersContext();
    const [showButton, setShowButton] = useState(false);
    const [hideButton, setHideButton] = useState(true);

    useEffect(() => {
        const updateVisibility = () => {
            
            if(window.location.href.includes('asesor') ){
                setShowButton(false);
            }else{
                const currentTime = new Date();
                const hours = currentTime.getHours();
                const minutes = currentTime.getMinutes();
                const currentTotalMinutes = hours * 60 + minutes;
                // Definir el límite de tiempo (18:00 o 6:00 PM)
                const limitTotalMinutes = 18 * 60;            
                if (currentTotalMinutes >= limitTotalMinutes) {
                  // Pasada la hora límite, ocultar el botón
                  setShowButton(false);
                }else{
                  setShowButton(true);
                }

            }

        };
    
        // Llamamos a la función inicialmente para establecer la visibilidad del botón al cargar la página
        updateVisibility();
    
        // Actualizamos la visibilidad cada minuto
        const intervalId = setInterval(updateVisibility, 6000);
    
        // Limpieza del intervalo cuando el componente se desmonta
        return () => clearInterval(intervalId);
      }, []);

    const showContentWhatsapp = () => {
        setShowWhatsapp(false);
    };
    return (
        <DivWhats>
            {
                !hideButton && <div className="contenedor">
                <div className='container'>
                    <a className={`element boton-x ${showWsp ? '' : 'hidden'}`} onClick={showContentWhatsapp} data-testid='input-field'>
                        <img src={close} />
                    </a>
                    <a href={parse(whatslink)} className={`boton-contacto ${showWsp ? '' : 'hidden'}`} target="_blank" rel="noreferrer noopener " >Contáctanos</a>
                    <a href={parse(whatslink)} className="boton-whatsapp" target="_blank" rel="noreferrer noopener">
                        <img src={iconWhatsapp}  alt="WhatsApp" />
                    </a>
                </div>
            </div>
            }
        </DivWhats> 
    );
};
