import React from "react";
import { TypeCard, DivTextCard, TextCard, DivTypeCard, Check } from "./styles";
import checkGreen from "../../assets/images/checkGreen.svg";
import uncheck from "../../assets/images/uncheck.svg";
import {
  useStateDigitalTransfersContext,
  useDispatchDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import { SendSaveEmployer } from "../../services";

const types = [
  {
    id: 1,
    value: "D",
    content: "Trabajo de forma dependiente para una empresa",
  },
  { id: 2, value: "I", content: "Trabajo de forma independiente" },
];

export const TypesCard = () => {
  const { userWeb } = useStateDigitalTransfersContext();
  const dispatch = useDispatchDigitalTransfersContext();
  const { SendFetch } = useCatchFetch();

  const saveWorkerType = async (item) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "workerType", value: item.value },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "rucEmployer", value: null },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "tradeNameEmployer", value: null },
    });
    dispatch({
      type: "FIELD",
      payload: { field: "showTypesFound", value: true },
    });
    const bodySave = {
      documentType: userWeb.documentType,
      documentNumber: userWeb.documentNumber,
      workerType: item.value,
      rucEmployer: null,
      nameEmployer: null,
    };
    await SendFetch(SendSaveEmployer(bodySave)).then((res) => {
      if(res.code == 0){
        dispatch({
          type: "FIELD_SECOND",
          payload: { field1: "userWeb", field2: "workerType", value: null },
        });
        dispatch({
          type: 'SET_CONFIG_MODAL',
          payload: {
              idModal:  "btn-step2-error-employeer-send",
              content: 'Seleccione su fondo de nuevo',
          }
        });
      }
 
    }).catch((error) => {
        console.log(error);
        dispatch({
          type: 'SET_CONFIG_MODAL',
          payload: {
              idModal:  "btn-step2-error-employeer-send",
              content: 'Seleccione su fondo de nuevo',
          }
        });
    });
  };

  return (
    <DivTypeCard id="idDivTypeCard">
      {types.map((item) => (
        <TypeCard
          id={"id" + item.id}
          data-testid="input-saveWorker"
          key={item.value}
          active={item.value == userWeb.workerType}
          onClick={() => saveWorkerType(item)}
        >
          {item.value == userWeb.workerType && (
            <Check src={checkGreen} alt="check image" />
          )}
          {item.value != userWeb.workerType && (
            <Check src={uncheck} alt="check image" />
          )}
          <DivTextCard>
            <TextCard id={"span" + item.id} className="descriptionTypeCard">{item.content}</TextCard>
          </DivTextCard>
        </TypeCard>
      ))}
    </DivTypeCard>
  );
};
