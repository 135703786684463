import styled from "styled-components";
import { Text as BaseText } from "@prima-afp/prima-ui-elements/dist/core";

export const ContentTooltipStyled = styled.div`
  padding: 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  

  @media only screen and (max-width : 376px) {
    /* width: 327px; */
}
`;

export const TextStyled = styled(BaseText)`
  font-family: Calibri,sans-serif;
  font-weight: 400;
  font-size: 16px; 
  line-height: 20px;
  color: var(--grayColorText);
  display: block;
  &.bold {
    font-weight: bold;
  }
  ${(props) => props.title && `
    font-family: "FS Emeric";
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;

  `}
  ${(props) => props.subtitle && `
    color: #fff;
    text-align: left;
  `}
`;