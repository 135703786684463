import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { StyledDotsWrapper, Slide } from "./style";

const MainSlider = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    appendDots: (dots) => <StyledDotsWrapper>{dots}</StyledDotsWrapper>, // Puntos personalizados
  };

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <Slide key={index} >
          {slide.content}
        </Slide>
      ))}
    </Slider>
  );
};

export default MainSlider;
