import { useDispatchDigitalTransfersContext } from '../context/DigitalTransfersContext';

export const useCatchFetch = () => {

    const dispatch = useDispatchDigitalTransfersContext();

    const SendFetch = (promise, terminateLoading = false) => {

        promise.catch(GeneralCatch);

        if (!terminateLoading) {
            promise.finally(GeneralFinally);
        }
        return promise;
    };

    const SendFetchTokenReturnProspect = (promise, terminateLoading = false ) => {
        promise.catch(GeneralCatch);
        dispatch({
            type: 'FIELD',
            payload: {
                field: 'showLoading',
                value: true
            }
        });
        return promise;
    };

    const SetLoading = (val) => {
        dispatch({
            type: 'FIELD',
            payload: {
                field: 'showLoading',
                value: val
            }
        });
    };

    const GeneralCatch = (err) => {
        var   userMessage= "Ocurrió un error interno, por favor vuelva a intentar en unos momentos.";
        dispatch({
            type: 'SET_CONFIG_MODAL',
            payload: {
                content: userMessage,
            }
        });
    };

    const GeneralFinally = () => {
        dispatch({
            type: 'FIELD',
            payload: {
                field: 'showLoading',
                value: false
            }
        });
    };

    return {
        GeneralCatch,
        GeneralFinally,
        SendFetch,
        SendFetchTokenReturnProspect,
        SetLoading,
    };
};