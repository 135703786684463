import styled from "styled-components";

export const WrapperRadioButtons = styled.div`
    @media only screen and (max-width : 450px) { 
        width: 314px;
        .option{
            width: 153px;
        }
    }
    @media only screen and (min-width : 451px) { 
        width: 202px;
    }

    display: flex;
    gap: 20px;
    font-size: 18px;
    color: var(--Text-Gray-Default, #4e5258);
    font-weight: 400;
    white-space: nowrap;
    letter-spacing: 0.02px;
    line-height: 133%;
    justify-content: space-between;
    
    .option{
        border-radius: var(--Radius-Radius-24, 24px);
        border-color: rgba(240, 240, 241, 1);
        border-style: solid;
        border-width: 1px;
        background-color: var(--Background-White-Default, #fff);
        display: flex;
        gap: 8px;
        padding: 12px 20px 12px 16px;
    }

    .labelOption{
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Lexend, sans-serif;
    }

    .circleSelected{
        cursor: pointer;
        border-radius: var(--Radius-Radius-full, 999px);
        border-color: rgba(0, 204, 204, 1);
        border-style: solid;
        border-width: 7px;
        background-color: var(--Background-Secondary-Subtle, #e6fafa);
        width: 24px;
        height: 24px;
    }

    .circleNoselected{
        cursor: pointer;
        border-radius: var(--Radius-Radius-full, 999px);
        border-color: rgba(231, 231, 232, 1);
        border-style: solid;
        border-width: 2px;
        background-color: var(--Background-White-Default, #fff);
        width: 24px;
        height: 24px;
    }
`;