import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Content } from "../../components/Banner/styles";

let user = null;

export const UserContext = React.createContext(user);

const PublicPagesLayoutNoBanner = ({ children }) => {
  const contentRef = useRef(null);

  return (
    <>
      <Content ref={contentRef} id="app-main-content">
        <div>{children}</div>
      </Content>
    </>
  );
};

PublicPagesLayoutNoBanner.propTypes = {
  children: PropTypes.any,
};

export default PublicPagesLayoutNoBanner;
