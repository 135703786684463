import React, { useEffect, useState } from 'react';
import {ProgressBarDiv} from './styles';

export default function ProgressBar  ( {colorBar, colorBackground})  {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 30); // 3000ms / 100 steps = 30ms per step

    return () => clearInterval(interval);
  }, []);

  return (
    <ProgressBarDiv className="progress-bar-container" colorBar={colorBar} colorBackground={colorBackground}>
      <div
        className="progress-bar"
        style={{ width: `${progress}%` }}
      />
    </ProgressBarDiv>
  );
};

