import styled from "styled-components";
import { Text } from "../../../shared/components/Text";
import { mediaQuery } from "modules/utils/responsive";

export const MainTitle = styled(Text)`
    color: var(--orangeColorMain);
    font-family: 'FS Emeric';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 8px;    
    @media only screen and (max-width : 376px) {
        width: 62%;
    }
    @media only screen and (min-width : 377px) and (max-width: 450px) {
        width: 110%;
        font-size: 30px;
    }
    @media only screen and (min-width : 451px) and (max-width: 1000px) {
        width: 100%;
        font-size: 32px;        
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
    }


`;
export const TextStyledStep0 = styled(Text)`
    color: #FF4F00;
    font-weight: 700;
    font-size: 16px;    
    line-height: 20px;
`;


export const DivStepI = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    height: 90%;
    @media only screen and ${mediaQuery.mobileS}{
        width: 90% !important;
        margin-left: 14px !important;
        margin-right: 14px !important;
    }
    /* Medidas responsivas */
    /* Small Devices, Phones */ 
    @media only screen and (max-width : 476px) {
        width: 87%;
        height: 85%;
        display: flex;
        margin: auto;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: 40px;
        margin-left: 24px;
        margin-right: 24px;
    }

    
    @media only screen and (max-width : 376px) {    
        #idDivContent_2{
            width: 100% !important; 
        }

    }
    @media only screen and (min-width : 377px) and (max-width: 450px) {    
        #divCard{ 
            width: 100% !important; 
            display: flex !important; 
            align-items: center !important; 
            margin-top: 24px !important; 
            margin-bottom: 24px !important; 
            margin-right: 16px !important; 
            margin-left: 16px !important; 
        }
    }
    @media only screen and (min-width : 451px) and (max-width: 1000px) {
        width: 82% !important; 
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: 72px;
        margin-left: 67px;
        margin-right: 67px;
        margin-bottom: 40px;
        #idDivContent{
            align-items: center;
            align-content: center;
            margin-bottom: 66px;
        }
        #idDivContent_2{
            margin-left: 66px;
            margin-right: 66px;
        }
        #text1Id{
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        #ButtonDiv1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            width= 240px important!;
        }
        #idContentStepInitial{
            width: 522px !important; 
            height: 96px !important; 
        }
        #divCard{
            display: flex;
            align-items: center;
            margin-top: 28px !important; 
            margin-bottom: 28px !important; 
            margin-right: 32px !important; 
            margin-left: 32px !important; 
        }
        #tooltipImg{    
            margin-left: 24px !important; 
            margin-right: 0px  !important; 
        }
        #button1{
            width: 240px;
            height: 48px;
        }

    }
    @media only screen and (min-width : 1001px) and (max-width: 1727px) {

    }
    @media only screen and (min-width : 1728px) {

    }
    
`;

export const DivHeader = styled.div`
    height: 20px;
    justify-content: end;
    /* Medidas responsivas */
    /* Small Devices, Phones */ 
    @media only screen and (max-width : 1001px) {
        display: none !important; 
    }
`;

export const DivContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;

    div.buttonDiv1{
        padding-bottom:32px;  
        padding-top: 16px;
    }

    #ButtonDiv1 {
        margin-top: 32px;
        width: 100%;
    }
    @media only screen and ${mediaQuery.mobileS}{
        #text1{
            font-size: 12px !important;
        }
    }
    
    @media only screen and (max-width : 450px) {
        margin-bottom: 32px;
        #button1 {
            width:100%;
            padding-top: 15px;
            padding-bottom: 15px;
            line-height: 18px;
        }
        #text1{
            width:100%;
            display:flex;
            flex-direction:row;
            justify-content:center;
        }   
        #linkId{
            padding-left: 5px !important; 
        }
        
    }

    @media only screen and (min-width : 1727px) {    
        #text1Id{
            font-size: 18px !important; 
            line-height: 20px !important; 
        }
    }

`;

export const DivReco = styled.div`
    @media only screen and (max-width : 476px) {
        margin-top: 20px;
        margin-bottom: 40px;
    }
    @media only screen and (min-width : 1727px) {    
    }

`;

export const DivRecoCont = styled.div`
    height: 64px;
    @media only screen and (max-width : 450px) {
        flex-direction: column;
        padding-bottom: 0px !important; 
        height: auto;
        div.item1{
            display: none;
        }
        div.separator{
            display: none;
        }
        div.item2{
            margin-top: 12px
            margin-left: 0px !important; 
        }
        img.logo{
            width: 24px;
            height: 24px;
        }        
        #reco1Id{
            margin-left: 10.5px !important; 
        }
        #reco2Id{ 
            margin-left: 10.95px !important; 
        }
    }

    @media only screen and (min-width : 477px) and (max-width: 1000px) {
        .item1{
            flex: none !important; 
            width: 300px !important;
        }
    }

    @media only screen and (min-width : 1727px) {    
        .item1{
            flex: none !important; 
        }
        #reco1Id{
            flex: none;
            font-size: 16px !important; 
            line-height: 20px !important; 
            width: 280px  !important; 
        }
        #reco2Id{ 
            font-size: 16px !important; 
            line-height: 20px !important; 
            width: 280px  !important; 
        }
    }

`;

export const ContentStepInitial = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    opacity: 1;
    border: 1px solid rgba(232,232,232,1);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    width: 568px;
    height: 80px;

    #text2Id{
        color: #696158;
    }

    .CheckBox__CheckBoxContainer-sc-g6vvb8-0{
        padding-left: 36px;
    }

    .styles__ContainerTooltipText-sc-k5wj9h-0{
        border-radius: 16px;
        width: 391px;
        margin-left: -192px !important;
    }

    #divCard{
        display: flex;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 32px;
        margin-left: 32px;
    }
    #tooltipImg {
        margin-left: 12px;
    }
    #text2Id{
        color: #696158;
    }
    @media only screen and ${mediaQuery.mobileS}{
        #divText{
            width: 180px !important;
        }
    }

    @media only screen and (max-width : 376px){
        #tooltipImg{    
            width: 16px;
            margin-left: 16px;
        }
        #divText{
            width: 227px;
        }
    }

    @media only screen and (min-width : 377px) and (max-width: 1365px) {
        #tooltipImg{    
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    @media only screen and (max-width : 450px) {
        display: flex;
        width: 100%;

        #tooltip{           
            width: 32px;
        }

        .styles__ContainerTooltipText-sc-k5wj9h-0{
            margin-left: -285px !important;
            margin-bottom: 16px;
            width: 320px;
            height: 202px;
        }
        .csxRtE:after {
            margin-left: 136px !important;
            width: 25px;
            height: 16px;
            left: 42%;
            border-width: 14px;
        }
    }

    @media only screen and (min-width : 451px) and (max-width: 1000px) {
        .styles__ContainerTooltipText-sc-k5wj9h-0{
            margin-left: -240px !important;
            height: 182px;
        }
        .csxRtE {
            bottom: 220%;
        }
        .csxRtE:after {
            left: 62%;
            border-width: 17px;
        }
    }


    @media only screen and (min-width : 1727px) {    
        width: 100% !important; 
        #text2Id{
            font-size: 18px !important; 
            line-height: 20px !important; 
        }
    }

`;

