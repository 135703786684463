import styled from "styled-components";
import { mediaQuery } from "modules/utils/responsive";

export const WrapperSlider = styled.div`
 

    @media only screen and ${mediaQuery.mobileS} { 
      width: 100% !important;
      .line_back_slider{
        width: 87% !important;
      }
    }
    @media only screen and (max-width : 450px) { 
      margin-top: 24px;
      margin-bottom: 32px;
      .slider{
        margin-top: 8px;
        margin-bottom: 0px !important;
      }
      .line_back_slider {
        width: 90%;
        height: 6px;
        background-color: #ECECEC;
        flex: 1 1;
        top: 16px;
        margin: auto;
        position: relative;
      }
      .tooltip{
        top: 35px;
      }

      .tooltip1{
        left: 195%;
        top: 36px;
      }
      .tooltip1:after{
        top: 0%;
        right: 80%;
      }

      .tooltip2{
        left: 50% !important;
        top: 36px !important;
      }
      .tooltip2:after{
        top: 0% !important;
        right: 46% !important;
      }

      .tooltip3{
        left: -100% !important;
        top: 36px !important;
      }
      .tooltip3:after{
        top: 0% !important;
        right: 10% !important;
      }

      .tooltip4{
        left: -100%;
        top: 36px;
      }
      .tooltip4:after{
        top: 0%;
        right: 10%;
      }

    }

    /* @media only screen and (min-width : 451px) and (max-width: 700px) {
      max-width:90vw;
      width:90vw;
    } */

    @media only screen and (min-width : 700px) and (max-width: 1000px) {
      margin: auto;
      margin-top: 16px;
      margin-bottom: 32px;
      width: 530px;
      .slider{
        margin: auto;
      }
      .line_back_slider {
        width: 500px;
        height: 6px;
        background-color: #ECECEC;
        flex: 1 1;
        top: 16px;
        margin: auto;
        position: relative;
      }
      .tooltip1:after{
        top: 29%;
      }
      .tooltip3{
        left: -200%;
      }
      .tooltip3:after{
        right: -3%;
      }
      .tooltip4{
        left: -200%;
      }
      .tooltip4:after{
        right: -3%;
      }


    }
    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
      width: 530px;
      .tooltip1:after{
        top: 29%;
      }
      .tooltip3:after{
        top: 30%;
      }
      .tooltip4{
        left: -200%;
      }
      .tooltip4:after{
        top: 33%;
        right: -3%;
      }

    }
    @media only screen and (min-width : 1728px) {
      width: 530px;
      .tooltip1:after{
        top: 33%;
      }
      .tooltip3:after{
        top: 30%;
      }
      .tooltip4{
        left: -200%;
      }
      .tooltip4:after{
        top: 33%;
        right: -3%;
      }

    }
  }
  .slider {
    display: flex;
    align-items: center;  
    width: 100%;
    margin-top: 8px;
    margin-bottom: 0px;
  }

  .description{
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
   
  .circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ECECEC;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .lineMain{
    height: 6px;
    background-color: #ECECEC;
    flex: 1;
    top: -10px;
    position: relative;
  }

  .line {
    height: 1px;
    background-color: #ECECEC;
    flex: 1;
    top: -13px;
    position: relative;
  }

  .line_back_slider{
    width: 90%;
    height: 6px;
    background-color: #ECECEC;
    flex: 1 1;
    top: 24px;
    margin-left: 15px;
    position: relative;
  }
  
  .circle.selected {
    background-color: #00A499;
  }

  .description.selected{
    color: #696158;
    font-family: Calibri,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  
  .tooltip-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 54px;
  }
  
  .tooltip {
    position: absolute;
    top: -45px;
    left: 300%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    padding: 24px 24px;
    border-radius: 16px;
    width: 228px;
    z-index: 1;
    box-shadow: 2px 4px 32px 2px #E1E1E1;
    text-align: center;
  }

  .tooltip-title-container{
    text-align: justify;
    align-items: flex-start;
    display: flex;
    font-family: Calibri,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #696158;
  }

  .tooltip-descr-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: initial;
    font-family: Calibri,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;    
    color: #696158;
    margin-top: 4px;
  }
  
  .tooltip:after {
    content: "";
    position: absolute;
    top: 33%;
    right: 92%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
    width: 25.58px;
    height: 16px;
    background: #FFFFFF;
    transform: rotate(134deg);
}

.wrapperSlider{
  width: 100%;
  @media only screen and (max-width : 450px) {
    margin-bottom: 0px !important;
  }
  @media only screen and (min-width : 451px) and (max-width: 1000px) {
    width: 530px;
    margin: auto;
    margin-bottom: 32px;
    margin-top: 16px;
  }


  
`;
