import { useState, useEffect } from 'react';

const useIsMobile = (breakpoint = 480) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const checkIsMobile = () => {
        setIsMobile(window.innerWidth <= breakpoint);
      };

      checkIsMobile(); // Check on mount

      window.addEventListener('resize', checkIsMobile);

      return () => {
        window.removeEventListener('resize', checkIsMobile);
      };
    }
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
