import styled from "styled-components";
import { media } from "@prima-afp/prima-ui-elements/dist/utils/media/styledConfig";
import { mediaQuery } from "modules/utils/responsive";
import { Text } from "../../../App/pages/Text";


export const ContarinerStep2 = styled.div`
  .sliderAlert {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-left: 16px;
    background-color: #eefbf9;
  }
  .epKWNl path {
    fill: #00a499 !important;
  }
  #divStep2 {
    display: flex;
    flex-direction: column;
  }
  #foundTypeP{
    display: flex;
    gap: 4px;
    font-size: 18px;
    color: #4e5258;
    font-weight: 700;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 40px;
    width: 314px;
    #foundTypeP{
      margin-bottom: 0px;
      font-size: 16px;
    }
    .inputStep2 {
      width: 335px !important;
      height: 64px;
      margin-bottom: 16px;
    }
    .step2ButtonContainer {
    }
    .fundType {
      --margin-top: 16px;
    }
    #titleStep2 {
      margin-bottom: 0px !important;
    }
    .searchButton {
      margin-top: 16px !important;
    }
    #WrapperTypeFoundD {
      margin-top: 24px;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 700px) {
    max-width: 100vw;
    margin-top: 40px;
    #divStep2{
      max-width: 100vw;
      width: 90vw;
    }
    .fundType{
      width: 90vw;
    }
  }

  @media only screen and (min-width : 700px) and (max-width: 1000px) {
    margin-top: 40px;
    .searchButton {
      margin-top: 40px !important;
      #searchButtonP {
        text-align: initial;
        margin-bottom: 8px;
      }
    }
    #foundTypeP {
      text-align: initial;
    }
    .inputStep2 {
      width: 316px !important;
      height: 64px;
      margin-bottom: 16px;
    }
    .fundType {
      --margin-top: 40px;
    }
    #titleStep2 {
      text-align: inherit;
    }
    #WrapperTypeFoundD {
      margin-top: 24px;
    }
  }

  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    margin-top: 40px;
    width: 635px;
    #buttonWrapper {
      display: flex;
    }
    .inputStep2 {
      width: 269px !important;
      margin-right: 32px;
    }
    .secondRadio {
      margin-left: 40px;
    }
    .searchButton {
      margin-top: 40px;
    }
    #searchButtonP {
      text-align: initial;
      margin-bottom: 8px;
    }
    .fundType {
      --margin-top: 40px;
    }
    #foundTypeP {
      text-align: initial;
    }
  }

  @media only screen and (min-width: 1728px) {
    margin-top: 40px;
    width: 816px;
    #buttonWrapper {
      display: flex;
    }
    #WrapperTypeFoundD {
      margin-top: 40px;
    }
    .inputStep2 {
      width: 364px !important;
      margin-right: 32px;
    }
    .secondRadio {
      margin-left: 40px;
    }
    .searchButton {
      margin-top: 40px;
    }
    #searchButtonP {
      text-align: initial;
      margin-bottom: 8px;
    }
    .fundType {
      --margin-top: 40px;
    }
    #foundTypeP {
      text-align: initial;
    }
  }
`;

export const DivBackButton = styled.div`    
    justify-content: center;
    border-radius: var(--Radius-Radius-12, 12px);
    display: flex;
    gap: 8px;
    font-size: 18px;
    color: var(--Text-Gray-Default, #4e5258);
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    letter-spacing: 0.02px;
    line-height: 133%;
    padding: 12px 10px;
    height: 56px;
    cursor: pointer;
  .imgDivBackButton {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
  }
  .div-2DivBackButton {
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Lexend, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cancelButton{
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Lexend, sans-serif;
    justify-content: center;
    border-radius: var(--Radius-Radius-12, 12px);
    color: var(--Text-Gray-Default, #4e5258);
    padding: 12px 20px;
  }
`;

export const DivTextCard = styled.div`
  @media only screen and (max-width: 450px) {
    width: 255px;
  }
  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    display: flex !important;
    justify-content: space-between !important;
  }
  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    width: 204px;
    text-align: left;
  }
  @media only screen and (min-width: 1728px) {
    margin-top: 0px;
    text-align: left;
  }
`;

export const TextCard = styled.p`
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #696158;
  @media only screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    width: 203px;
    height: 40px;
    text-align: initial;
  }
  @media only screen and (min-width: 1728px) {
    width: 280px;
    font-size: 18px;
  }
`;

export const TypeCard = styled.div`
    text-align: center;
    background: #fff;
    box-shadow: 2px 4px 10px 2px #EBEBEB;
    border-radius: 16px;
    padding-top: 8px;
    padding-bottom: 36px;
    padding-right: 32px;
    padding-left: 32px;
    cursor: pointer;
    position: relative;
    height: 112px;
    width: 301px;
    ${(props) => props.active && "border: 2px solid var(--greenColor)"};

    @media only screen and ${mediaQuery.mobileS}{
      width: 100% !important;
      .descriptionTypeCard{
        width: 76% !important;
      }
    }

  @media only screen and (max-width: 376px) {
    width: 335px;
    height: 88px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    justify-content: center;
  }

  @media only screen and (min-width: 377px) and (max-width: 450px) {
    width: 100%;
    height: 88px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    justify-content: center;
  }

    @media only screen and (min-width : 451px) and (max-width: 700px) {
      width: 90vw;
      max-width: 93vw;
      height: 88px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;
      flex-direction: column;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 24px;
      padding-bottom: 24px;
      justify-content: center;
    }

    @media only screen and (min-width : 701px) and (max-width: 1000px) {
      width: 340px;
      height: 112px;
      padding-top: 36px;
      #id2{
        padding-top: 46px;
        padding-bottom: 46px;
      }
      #span2{
        width: 238px;
        height: 20px;
      }
    }

  @media only screen and (min-width: 1728px) {
    width: 392px;
    padding-top: 36px;
  }
`;

export const Check = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const _buttonMobile = `
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    justify-content: normal;

    button:last-child {
        width: 100%;
        order: -1;
        height: 48px;
        margin-bottom: 1rem;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    #btn-fund-type-send-testA{
      display: none;
    }
    #btn-fund-type-send-testB{
      display: none;
    }
    #btn-fund-type-send-testC{
      display: none;
    }
    .newUiBtn{
        justify-content: center;
        border-radius: var(--Radius-Radius-16, 16px);
        white-space: nowrap;
        text-align: center;
        letter-spacing: 0.02px;
        padding: 16px 20px;
        font: 400 18px / 133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
        margin-bottom: 10px;
    }
    .selfieButton{
      display: none;
    }
    .disabledBtn{
          border-radius: var(--Radius-Radius-16, 16px);
          background-color: var(--Background-Disabled-Subtle, #e7e7e8);
          color: var(--Text-Disable-Disabled, #84878b);
    }
    .enabledBtn{
        background-color: var(--orangeColorMain);
        color: #FFFFFF;
    }

    @media only screen and (max-width : 450px) {   
        margin-top: 32px  !important;
        .newUiBtn{
            margin-bottom: 0px;
            width: 312px;
        }
        #btn-skip-reco{
          width: 312px;
        }
        #btn-fund-type-send{
          margin-bottom: 10px;
          width: 312px;
        }
        #identity-btn-env-12{
          width: 312px;
        }
        #btn-no-show-types{
          margin-bottom: 56px;
        }
      }
    }

    @media only screen and (min-width : 451px) and (max-width: 1000px) {
        margin-top: 0px !important;
        margin-bottom: 48px !important;
        max-width: 704px !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center;
        .newUiBtn{
            margin-bottom: 56px;
            width: 200px;
        }
        .buttonStep1{
            width: 160px !important;
        }
        #btn-fund-type-send{
          width: 160px;
          height: 48px;
        }
        #btn-no-show-types{
          width: 160px;
          height: 48px;
        }
        #wrapperButtonsStep2{
          justify-content: flex-end;
        }
    }

    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        width: 100%;
        .newUiBtn{
            margin-bottom: 56px;
            width: 200px;
        }
        #wrapperButtonsStep2{
          justify-content: flex-end;
        }
        #identity-btn-env{
            width:142px;
            height: 40px;
            margin-top: 40px;
        }
        #btn-fund-type-send{
          margin-bottom: 56px;
        }
        #btn-no-show-types{
          margin-top: 48px;
          margin-bottom: 56px;
        }
    }
    
    @media only screen and (min-width : 1728px) {
        margin-top: 48px !important;
        .newUiBtn{
            width: 200px;
        }
        margin-bottom: 64px;
        #btn-no-show-types{
          margin-top: 48px;
          margin-bottom: 56px;
        }
        #wrapperButtonsStep2{
          justify-content: flex-end;
        }
    }

`;

export const DivFramePEP = styled.div`
  .textfield-input {
    border-width: 1px;
  }

  .styles__ContainerTooltipText-sc-k5wj9h-0 {
    width: 70vw;
    max-width: 320px;
  }
  #FramePEPid {
    font-size: 18px;
    margin-bottom: 24px;
  }
  #disclaimerSpan {
    color: var(--Gray-Gray-Prima, #696158);
    max-width: 635px;
    font: 400 14px/16px Calibri, -apple-system, Roboto, Helvetica,sans-serif;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 8px;
    .wrapperButton {
      display: flex;
      flex-direction: column;
      margin-top: 0px !important;
      margin-bottom: 14px;
    }
    .radioDiv {
      margin-bottom: 8px;
    }
    #disclaimer {
      margin-top: 24px;
      margin-bottom: 8px;
    }
    #FramePEPid {
      text-align: initial;
      margin-bottom: 24px;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    margin-top: 24px;
    #FramePEPid {
      text-align: initial;
      margin-bottom: 24px;
    }
    .wrapperButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 0px !important;
    }
    #disclaimer {
      margin-top: 24px;
      margin-bottom: 48px;
    }
    #wrapperInput {
      display: flex;
      margin-top: 14px;
      justify-content: space-between;
    }
    .input-wrapper {
      width: 340px !important;
    }
  }

  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    margin-top: 40px;
    margin-bottom: 48px;
    #disclaimer {
      margin-top: 24px;
    }
    #wrapperInput {
      display: flex;
      justify-content: space-between;
    }
    .textfield-input {
      width: 301px;
    }
    .wrapperButton {
      width: 635px !important;
      margin-top: 0px !important;
      margin-bottom: 14px;
      .box {
        min-width: 40px;
      }
    }
    .radioDiv {
      margin-top: 8px;
    }
  }
  @media only screen and (min-width: 1728px) {
    --margin-top: 40px;
    .radioDiv {
      margin-top: 8px;
    }
    #disclaimer {
      margin-bottom: 40px;
      margin-top: 24px;
    }
    #FramePEPid {
      margin-top: 40px;
    }
    #wrapperInput {
      display: flex;
      justify-content: space-between;
    }
    .wrapperButton {
      margin-bottom: 14px;
    }
  }
`;

export const DivFrameAttention = styled.div`
  .textfield-input {
    border-width: 1px;
  }

  @media only screen and (max-width: 376px) {
    .inputFrameAttention {
      margin-top: 8px !important;
      margin-bottom: 16px !important;
    }
  }

  @media only screen and (min-width: 377px) and (max-width: 450px) {
    .inputFrameAttention {
      margin-top: 8px !important;
      margin-bottom: 16px !important;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    .inputFrameAttention {
      width: 340px;
    }
    #messageFrame {
      margin-bottom: 14px;
      text-align: initial;
    }
  }

  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    #messageFrame {
      margin-bottom: 14px;
    }
    .textfield-input {
      width: 301px;
    }
  }

  @media only screen and (min-width: 1728px) {
    #messageFrame {
      margin-bottom: 14px !important;
    }
    .textfield-input {
      width: 392px;
    }
  }
`;

export const DivTypeCard = styled.div`
    margin-top: 24px;
    @media only screen and ${mediaQuery.mobileS}{
      width: 100%;
    }

    @media only screen and (min-width : 451px) and (max-width: 700px) {
      width: 100%;
      display: grid;
      justify-content: space-between;
    }
    @media only screen and (min-width : 701px) and (max-width: 1000px) {
        display: flex !important;
        justify-content: space-between !important;
        display: flex !important;
        justify-content: space-between !important;
    }
    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        display: flex !important;
        justify-content: space-between !important;
        #id1{
            padding-top: 36px;
        }  
        #id2{
            padding-top: 46px;
            padding-bottom: 46px;
        }        
        #span2{
            width: 238px;
        }
    }
    @media only screen and (min-width : 1728px){
      display: flex !important;
      justify-content: space-between !important;
    }
`;

export const TextRegards = styled(Text)`
    font-family: 'FS Emeric';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-bottom: 16px;
    color: var(--Text-Primary-Default, #ff4f00);
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: 0.03px;
    max-width: 236px;
    font: 600 24px/133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
    @media only screen and (max-width : 450px) {   
        text-align: center;
        margin-bottom: 40px;
    }    
`;

export const BodyModalReco =styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right-width: 36px;
  padding-right: 36px;
  padding-left: 36px;
  padding-bottom: 20px;
  .modal-image-skipReco{
      width: 88px;
      height: 88px;
  }
  .title{
      position: relative;
      color: var(--Text-Gray-Default, #4e5258);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font: 600 24px/108% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
  }
  .content{
      position: relative;
      color: var(--Text-Gray-Default, #4e5258);
      text-align: center;
      margin-top: 25px;
      width: 523px;
      font: 400 16px/20px Calibri, -apple-system, Roboto, Helvetica, sans-serif;
      margin-bottom: 32px;
  }
  .newUiBtn{
      justify-content: center;
      border-radius: var(--Radius-Radius-16, 16px);
      white-space: nowrap;
      text-align: center;
      letter-spacing: 0.02px;
      padding: 16px 20px;
      font: 400 18px / 133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
  }
  .disabledBtn{
        border-radius: var(--Radius-Radius-16, 16px);
        background-color: var(--Background-Disabled-Subtle, #e7e7e8);
        color: var(--Text-Disable-Disabled, #84878b);
  }
  .enabledBtn{
      background-color: var(--orangeColorMain);
      color: #FFFFFF;
  }   
  .modal-content-inner-skipReco{  
    padding-right: 20px;
    padding-left: 20px;
  }
  .gifPreventive{
    margin-bottom: 32px;
    width: 280px;
    height: 160px;
  }

  @media only screen and (max-width : 451px) {  
    padding-right: 0px;
    padding-left: 0px; 
    padding-bottom: 0px;
    .title{
      width: 280px;
    }   
    .prevModal{
      width: 280px !important;
    } 
    .newUiBtn{
      width: 252px;
    }  
    .content{
      width: 252px;
      margin-top: 20px;
      margin-bottom: 20px !important;
    }    
    .gifWarning{
      width: 247px;
      height: 315px;
      margin-bottom: 32px;
    } 
  }

  @media only screen and (min-width : 451px) {        
    .newUiBtn{
      width: 252px;
    } 
    .gifWarning{
      margin-bottom: 32px;
      width: 308px;
    } 
    .prevDesktopT{
      height: 64px;
      width: 363px;
    }
    .content{
      width: 307px;
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
    .prevModal{
      margin-bottom: 0px !important;
    }
    .gifWarning{
      border-radius: 16px;
    }
    .contentWarning{
      width: 550px;
    }
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }
`;