/* eslint-disable react/no-unknown-property */
import React from 'react';

export const TermCon = () => {

    return (
        <div className='contentP'>
            <p><strong className='titleP'>1. INFORMACI&Oacute;N SOBRE EL TRATAMIENTO DE TUS DATOS PERSONALES</strong></p>
            <br />
            <p align="justify">En Prima AFP nos preocupamos por la protecci&oacute;n y privacidad de los datos personales de nuestros usuarios. Por ello, garantizamos la absoluta confidencialidad de tus datos y empleamos altos est&aacute;ndares de seguridad.</p>
            <p align="justify">Estamos legalmente autorizados a tratar la informaci&oacute;n necesaria (personal, financiera, de contacto -como el n&uacute;mero de celular, tel&eacute;fono o correo electr&oacute;nico-, localizaci&oacute;n y biometr&iacute;a &ndash;como reconocimiento facial o huella digital-, entre otros) y de car&aacute;cter obligatorio que tenga por finalidad preparar y/o ejecutar la relaci&oacute;n contractual que mantenemos y que nos entregues para tales efectos en los documentos correspondientes, o aquella a la que accedamos de manera leg&iacute;tima a fin de actualizarla y completarla. Para garantizar la adecuada ejecuci&oacute;n de nuestra relaci&oacute;n contractual, es necesario que tu informaci&oacute;n se encuentre siempre actualizada. Por tanto, deber&aacute;s mantener actualizada tu informaci&oacute;n, sin perjuicio que en cumplimiento del Principio de Calidad nosotros la actualicemos, validemos o complementemos a partir de fuentes leg&iacute;timas p&uacute;blicas o privadas (incluyendo redes sociales) a las que podamos tener acceso en el curso regular de nuestras operaciones.</p>
            <p align="justify">Las comunicaciones que te podremos remitir en el marco de la ejecuci&oacute;n de la relaci&oacute;n contractual y/o su preparaci&oacute;n, pueden estar relacionadas a informaci&oacute;n sobre uso de canales, consejos de seguridad en el uso de sus productos previsionales, acceso a los diferentes canales de atenci&oacute;n o autoatenci&oacute;n, estados de cuenta, resultado de la evaluaci&oacute;n crediticia, mantenimiento de la relaci&oacute;n comercial, encuestas de satisfacci&oacute;n, entre otros. Asimismo, para dar cumplimiento a las obligaciones y/o requerimientos que se generen en virtud de las normas vigentes en el ordenamiento jur&iacute;dico peruano y/o en normas internacionales que le sean aplicables, incluyendo, pero sin limitarse a las vinculadas al sistema de prevenci&oacute;n de lavado de activos y financiamiento del terrorismo y normas prudenciales, podremos dar tratamiento y eventualmente transferir su informaci&oacute;n a autoridades y terceros autorizados por ley.</p>
            <p align="justify">De acuerdo con la Ley N.&ordm; 29733 &ndash; Ley de Protecci&oacute;n de Datos Personales y su Reglamento aprobado por el Decreto Supremo N&ordm;003-2013-JUS, as&iacute; como las normas que las modifican o sustituyan, te informamos que tus datos personales ser&aacute;n almacenados en el banco de datos denominado &ldquo;Clientes&rdquo; y &ldquo; que se encuentra registrado ante la Autoridad de Protecci&oacute;n de Datos Personales bajo el n&uacute;mero de registro RNPDP-PJP N.&deg;378 que se encuentra registrado ante la Autoridad de Protecci&oacute;n de Datos Personales, domiciliado en Calle Chinch&oacute;n 980, San Isidro, provincia y departamento de Lima. Prima AFP conservar&aacute; y tratar&aacute; tu informaci&oacute;n mientras se mantenga nuestra relaci&oacute;n contractual y luego de diez (10) a&ntilde;os de finalizada.</p>
            <p align="justify">Para el tratamiento de tu informaci&oacute;n, Prima AFP utilizar&aacute; diversos encargados ubicados en el Per&uacute; y en el extranjero (respecto de los cuales se realizar&aacute; una transferencia al pa&iacute;s donde est&aacute;n ubicados). Esta informaci&oacute;n se encuentra tambi&eacute;n disponible en https://www.prima.com.pe/public-zone/sobre-prima-afp/politica-de-privacidad/#empresas-y-socios-comerciales y podr&aacute;s acceder a ella en cualquier momento.</p>
            <p align="justify">Prima AFP podr&aacute; modificar cualquier disposici&oacute;n contenida en la presente secci&oacute;n informativa.</p>
            <p align="justify">Puedes ejercer los derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n, revocaci&oacute;n y oposici&oacute;n dirigi&eacute;ndote a servicios@prima.com.pe con el asunto Derechos Protegidos.</p>
            <p align="justify">En caso tu pedido no haya sido atendido puedes presentar una reclamaci&oacute;n ante la Autoridad Nacional de Protecci&oacute;n de Datos Personales, dirigi&eacute;ndose a la Mesa de Partes del Ministerio de Justicia y Derechos Humanos (Calle Scipion Llona 350, Miraflores, Lima, Per&uacute;).</p>
            <p align="justify">Podr&aacute;s consultar nuestra pol&iacute;tica de privacidad en <a style={{color: "orangered"}}  href="https://www.prima.com.pe/public-zone/politica-de-privacidad/#tratamiento-y-transferencia-de-tus-datos-personales" target="_blank" rel="noreferrer">https://www.prima.com.pe/public-zone/sobre-prima-afp/politica-de-privacidad/</a></p>
        </div>
    );
};