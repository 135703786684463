import styled, { css } from "styled-components";
import { mediaQuery } from "modules/utils/responsive";
import { media } from "@prima-afp/prima-ui-elements/dist/utils/media/styledConfig";

export const Step3Wrapper = styled.div`
    margin-top: 0px;
    #btnStep3{
        justify-content: center !important;
        display: flex;
        flex-direction: column;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .helpSection {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }

    .modal-button{
        justify-content: center;
        border-radius: var(--Radius-Radius-16, 16px);
        white-space: nowrap;
        text-align: center;
        letter-spacing: 0.02px;
        padding: 16px 20px;
        font: 400 16px / 133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
        background-color: var(--orangeColorMain);
        color: #FFFFFF;
    }

    .modal-content-inner{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .modal-title{
        font: 600 24px/108% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 26px;
        -webkit-letter-spacing: 0em;
        -moz-letter-spacing: 0em;
        -ms-letter-spacing: 0em;
        letter-spacing: 0em;
        text-align: center;
        color: var(--Text-Gray-Default, #4e5258);
        padding-bottom: 16px;
    }
    .modal-description{    
        max-width: 252px;
        font-family: Calibri;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        -webkit-letter-spacing: 0em;
        -moz-letter-spacing: 0em;
        -ms-letter-spacing: 0em;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 47px;
    }
    .linkHelp{
        cursor: pointer;
        color: var(--Text-Primary-Default, #ff4f00);
        text-align: center;
        letter-spacing: 0.02px;
        text-decoration-line: underline;
        font: 700 16px/150% Calibri, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    img{
        max-width: none !important;
    }
    textStep3{
        color: var(--Text-Gray-Default, #4e5258);
        max-width: 540px;
        font: 700 16px/125% Calibri, -apple-system, Roboto, Helvetica, sans-serif;
    }
    .titleStep3{
        font-family: 'FS Emeric';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #00A499;
        margin-bottom: 10px;
    }
    .descriptionStep3{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        color: #696158;
        padding-bottom: 24px;
    }
    .cardStep3{
        box-sizing: border-box;
        width: 100%;
        height: 308px;
        border: 1px solid #D6D6D6;
        border-radius: 16px;
        padding: 32px 88px;    
        margin-bottom: 24px;
    }

    .alertStep3{
        display:flex;
        width: 100%;
        height: 56px;
        background: #EEFBF9;
        border-radius: 16px;
        margin-bottom: 32px;
    }
    .recoStep3{
        display:flex;
        width: 100%;
        height: 40px;
        font-size: 14px;
    }
    #reco1{
        margin-bottom: 24px;
    }
    #reco2{
        width: 284px !important;
        margin-bottom: 24px;
    }
    #start-biometric{
        width: 281px;
        height: 48px;
        margin-bottom: 32px; 
    }
    .wrapperReco{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 22px;
    }
    .recoTitle{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #696158;
        justify-content: center;
        padding: 0px !important;
    }
    .recotext{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #696158;
    }
    .icon{
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .alertText{
        padding-top: 20px;
        padding-bottom: 20px;
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #696158;
    }
    .wrapperApproved{
        margin-top: 136px;
        text-align: center;
    }
    .msgApproved{
        font-size: 24px;
        margin-bottom: 16px;
    }
    .spanApproved{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #696158;
    }
    .buttonSend{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 48px;
    }
    .wrapperAlert{
        margin-top: 48px;
        text-align: center;
    }
    .imgApproved{
        width: 56px;
        height: 56px;
        margin-bottom: 24px;
    }
    .msgAlert{
        font-family: 'FS Emeric';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #696158;
        width: 413px;
        margin: auto;
        margin-bottom: 24px;
    }
    .buttonReset{
        width: 224px;
        height: 40px;
        margin-top: 16px;
    }
    .imgAlert{
        width: 56px;
        height: 56px;
        margin-bottom: 24px;
    }

    .contentImg{        
        height: 100%; 
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imgSigning{
        width: 193.98px;
        height: 178px;
        margin: 24px;
    }

    .wrapperRecords{
        padding-right: 106px;
        width: 450px;
    }
    .col1{
        width: 75%;
    }



    @media only screen and (min-width : 1728px){
        max-width:100%;
    }

    @media only screen and (min-width : 1366px) and (max-width: 1727px) {
        
        .col1{
            width: auto;
        }
        .cardStep3{
            padding: 32px 40px;    
            margin-bottom: 24px;
        }
        .wrapperRecords{
            padding-right: 32px;
        }        
        .col1{
            width: 68%;
        }
        .recoTitle{
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        }
        .recotext{
            font-size: 16px;
            line-height: 20px;
        }
    }

    @media only screen and (min-width : 451px) and (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        margin: auto;  
        .col1{
            width: auto;
        }
        .titleStep3{
            margin-top: 8px;
            margin-bottom: 10px;
            text-align: center;
        }
        .descriptionStep3{
            max-width: 478px;
            height: 40px;
            text-align: center;
            margin: auto;
            margin-bottom: 24px;
        }
        .cardStep3{
            box-sizing: border-box;
            width: 100%;
            height: 308px;
            padding: 32px 16px;            
        }
        .alertStep3{
            display:flex;
            width: 100%;
            height: 56px;
            margin-bottom: 40px;
        }
        .recoStep3{
            display:flex;
            width: 100%;
            font-size: 14px;
        }
        #reco1{
            margin-bottom: 24px;
        }
        #reco2{
            width: 284px !important;
            margin-bottom: 24px;
        }
        #start-biometric{
            width: 160px;
            height: 48px;    
        }
        .wrapperReco{
            display:flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 22px;
        }
        .wrapperRecords{
            width: 100% !important;
            padding-right: 2% !important;
        }
        .recoTitle{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #696158;
            padding-top: 0px !important; 
        }
        .recotext{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #696158;
        }
        .icon{
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 16px;
            padding-right: 16px;
        }
        .alertText{
            padding-top: 20px;
            padding-bottom: 20px;
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #696158;
        }
        .wrapperApproved{
            margin-top: 216px;
        }
        .msgApproved{
            font-size: 24px;
            margin-bottom: 16px;
        }
        .buttonSend{
            display: flex !important;
            text-align: center !important;
            justify-content: space-around !important;
            width: 100% !important;
            padding-bottom:80px;
        }
        #btn-biometric-send{
            width: 164px;
            height: 40px;
        }
        .wrapperAlert{
            margin-top: 216px;
        }
        .imgApproved{
            width: 104px;
            height: 104px;
            margin-bottom: 16px;
        }
        .msgAlert{
            font-family: 'FS Emeric';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #696158;
            width: 704px;
            margin: auto;
            margin-bottom: 24px;
        }
        .buttonReset{
            width: 224px;
            height: 40px;
        }
        .imgAlert{
            width: 56px;
            height: 56px;
            margin-bottom: 24px;
        }
        .wrapperButton{
            margin-top: 40px !important;
            margin-bottom: 40px !important;
            width: 100% !important;
            display: flex;
            justify-content: center;
        }
    } 

    @media only screen and (max-width : 450px) {  
        margin-top: 25px;
        .titleStep3{
            width: 247px;
            height: 64px;
            margin: auto;
            text-align: center;
        }
        .descriptionStep3{
            text-align: center;
            margin-top: 10px;
        }
        .cardStep3{
            width: 100%;
            height: 499px;
            padding: 24px; 
        }
        .alertStep3{
            width: 100%;
            height: 64px;
        }
        .alertText{
            padding-top: 16px;
            padding-bottom: 16px;
        }
        .icon{
            padding-left: 19px;
            padding-right: 19px;
            padding-top: 19px;
        }
        .wrapperButton{
            margin-top: 32px !important;
            display: flex;
            justify-content: center;
        }
        .recoStep3{
            display:flex;
            width: 100%;
            height: 40px;
            font-size: 14px;
        }
        #reco1{
            margin-bottom: 24px;
        }
        #reco2{
            width: 284px !important;
            margin-bottom: 24px;
        }
        #spanReco2{
            width: 228px;
        }
        #start-biometric{
            width: 312px !important;
            height: 56px !important;   
            margin-bottom: 0px !important; 
        }
        .wrapperReco{
            display:flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 22px;
        }
        .wrapperRecords{
            width: 100% !important;
            padding-right: 0px !important;
        }

        .recoTitle{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #696158;
            padding: 0px !important;
        }
        .recotext{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #696158;
        }
        .imgInfo{
            width: 18px;
            height: 18px;
        }
        .wrapperApproved{
            margin-top: 72px;
        }
        .imgApproved{
            width: 104px;
            height: 104px;
            margin-bottom: 16px;
        }
        .msgApproved{
            width: 291px;
            margin-bottom: 8px;
            text-align: center;
            margin: auto;
            margin-bottom: 8px;
        }
        .buttonSend{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
        .wrapperAlert{
            margin-top: 48px;
        }
        .msgAlert{
            width: 291px;
            margin-bottom: 8px;
            text-align: center;
            margin: auto;
            margin-bottom: 8px;
        }
        .buttonReset{
            width: 224px;
            height: 40px;
            margin-bottom: 48px;
        }
        .imgAlert{
            width: 56px;
            height: 56px;
            margin-bottom: 24px;
        }        
        #btn-biometric-send{
            width: 100%;
            height: 40px;
            margin-top: 100px;
            margin-bottom: 0px !important;
        }
        .col1{
            width: auto;
            padding: 0px !important;
            padding-top: 24px !important;
        }
        .col2{
            padding: 0px !important;
            padding-top: 24px !important;
        }
        .div-weapper{
            flex-direction: column-reverse;
            margin-top: 0px !important;
          }
          
    }

    @media only screen and ${mediaQuery.mobileS} { 
        .msgApproved{
          width: 100% !important;
        }
      }

`;

export const ABButton = styled.button`
  font-variant-numeric: lining-nums proportional-nums;
  align-self: stretch;
  border-radius: 16px;
  background-color: rgba(255, 79, 0, 1);
  width: 100%;
  gap: 8px;
  overflow: hidden;
  color: var(--Text-White-Default, #fff);
  padding: 16px 20px;
  font: 400 18px/1 Lexend, sans-serif;
  border: none;
  cursor: pointer;
  @media only screen and (min-width : 451px){
    width: 231px;
    height: 48px;
    margin-bottom: 32px;
  }
  @media only screen and (max-width : 450px){
    width: 312px;
    height: 56px;
    margin-bottom: 32px;
  }
`;

const _buttonMobile = `
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    justify-content: normal;

    button:last-child {
        width: 100%;
        order: -1;
        height: 48px;
        margin-bottom: 1rem;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    
    ${media.sm`
        ${_buttonMobile}
    `};
    
    ${media.xs`
        ${_buttonMobile}
    `};

    .newUiBtnBiometric{
        font-variant-numeric: lining-nums proportional-nums;
        justify-content: center;
        align-items: center;
        border-radius: var(--Radius-Radius-16, 16px);
        background-color: var(--Background-Primary-Default, #ff4f00);
        max-width: 297px;
        color: var(--Text-White-Default, #fff);
        text-align: center;
        letter-spacing: 0.02px;
        padding: 16px 20px;
        font: 400 18px/133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
    } 

    .newUiBtn{
        justify-content: center;
        border-radius: var(--Radius-Radius-16, 16px);
        white-space: nowrap;
        text-align: center;
        letter-spacing: 0.02px;
        padding: 16px 20px;
        font: 400 18px / 133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
        margin-bottom: 10px;
    }
    
    @media only screen and ${mediaQuery.mobileS} {  
        margin-top: 40px  !important;
        margin-bottom: 10px !important;
        #identity-btn-env{
            width: 93vw !important;
        }
        .identity-btn-send{
            width: 76% !important;
        }
        .buttonStep1{
            width: 90vw !important;
            margin-bottom: 0px !important;
        }
    }


    @media only screen and (min-width : 376px) and (max-width: 451px) {   
        .buttonStep1{
            width: 100% !important;
        }
        #identity-btn-env{
            margin-bottom: 0px !important;
        }
    }

    @media only screen and (min-width : 451px) and (max-width: 700px) {
        
        .identity-btn-send{
            margin-top: 40px;
            margin-bottom: 40px;
        }

        #identity-btn-env{
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    @media only screen and (min-width : 701px) and (max-width: 1000px) {
        margin-top: 48px !important;
        margin-bottom: 152px !important;
        width: 704px !important;
        display: flex !important;
        flex-direction: row !important;
        --justify-content: flex-end !important;
        justify-content: center;
        .buttonStep1{
            width: 160px !important;
        }
    }

    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        #identity-btn-env{
            margin-top: 40px;
            margin-bottom: 40px;
            width:142px;
            height: 40px;
        }
        .identity-btn-send{
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
    
    @media only screen and (min-width : 1728px) {
        margin-top: 48px !important;
        margin-bottom: 64px;
    }

`;

export const DivBackButton = styled.div`    
    justify-content: center;
    border-radius: var(--Radius-Radius-12, 12px);
    display: flex;
    gap: 8px;
    font-size: 18px;
    color: var(--Text-Gray-Default, #4e5258);
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    letter-spacing: 0.02px;
    line-height: 133%;
    padding: 12px 10px;
    height: 56px;
    cursor: pointer;
  .imgDivBackButton {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
  }
  .div-2DivBackButton {
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Lexend, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BodyModalReco =styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-right-width: 36px;
    padding-right: 36px;
    padding-left: 36px;
    padding-bottom: 20px;


    .modal-image-skipReco{
        width: 88px;
        height: 88px;
    }
  .title{
      position: relative;
      color: var(--Text-Gray-Default, #4e5258);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      align-self: stretch;
      margin-top: 19px;
      font: 600 24px/108% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
  }
  .content{
      position: relative;
      color: var(--Text-Gray-Default, #4e5258);
      text-align: center;
      margin-top: 25px;
      width: 523px;
      font: 400 16px/20px Calibri, -apple-system, Roboto, Helvetica, sans-serif;
      margin-bottom: 32px;
  }
  .newUiBtn{
      justify-content: center;
      border-radius: var(--Radius-Radius-16, 16px);
      white-space: nowrap;
      text-align: center;
      letter-spacing: 0.02px;
      padding: 16px 20px;
      font: 400 18px / 133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
  }
  .disabledBtn{
        border-radius: var(--Radius-Radius-16, 16px);
        background-color: var(--Background-Disabled-Subtle, #e7e7e8);
        color: var(--Text-Disable-Disabled, #84878b);
  }
  .enabledBtn{
      background-color: var(--orangeColorMain);
      color: #FFFFFF;
  }
    

  @media only screen and (max-width : 451px) {       
    .newUiBtn{
      width: 252px;
    }  
    .content{
      width: 252px;
    }     
  }

  @media only screen and (min-width : 451px) {        
    .newUiBtn{
      width: 252px;
    } 
  }
`;