import styled from "styled-components";

export const DivCircleSteps = styled.div`
    .current{
        span{
           color: #696158;
        }
    }
    span{
        color: #D6D6D6;
    }
    li div {
        width: 92px !important;
        margin: 0 auto !important;
    }
    li.active:before {
        background: #D6D6D6;
        color:white;
        border: none !important;
        line-height: 41px;
    }
    li.active + li:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.13rem;
        background:  #D6D6D6;
        top: 20px;
        left: -50%;
        z-index: -1;
    }
    .gAyQKI{
        font-family: Calibri,sans-serif  !important;
    }
    @media only screen and (max-width : 376px) {   
        margin-bottom: 0px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        li{
            width: 72px; 
        }

        ol {
            display: flex;
            justify-content: center;

            margin-top: 0px !important;
            margin-bottom: 24px !important;
        }

        StepsCircle__StepContainer-sc-eimnv1-0{
            width: 248px;
        }

        li.active:before {
            line-height: 34px;
        }

        li.current:before{       
            width: 32px;
            height: 32px;
            line-height: 26px;
            margin-left: 20px !important;
            margin-right: 20px !important;
            margin-top: 4px !important;
        }
        li:before{            
            width: 32px;
            height: 32px;
            line-height: 26px;
            margin-left: 20px !important;
            margin-right: 20px !important;
            margin-top: 4px !important;
        }
    }
    @media only screen and (min-width : 377px) and (max-width: 450px) {  
        margin-bottom: 0px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        li{
            width: 72px; 
        }

        ol {
            display: flex;
            justify-content: center;

            margin-top: 0px !important;
            margin-bottom: 24px !important;
        }

        StepsCircle__StepContainer-sc-eimnv1-0{
            width: 248px;
        }

        li.active:before {
            line-height: 34px;
        }

        li.current:before{       
            width: 32px;
            height: 32px;
            line-height: 26px;
            margin-left: 20px !important;
            margin-right: 20px !important;
            margin-top: 4px !important;
        }
        li:before{            
            width: 32px;
            height: 32px;
            line-height: 26px;
            margin-left: 20px !important;
            margin-right: 20px !important;
            margin-top: 4px !important;
        }
    }

    @media only screen and (min-width : 451px) and (max-width: 700px) {
        width: 90vw !important;
    }

    @media only screen and (min-width : 700px) and (max-width: 1000px) {
        width: 704px !important;
        .current{
            span{
               color: #696158;
            }
        }
        span{
            color: #D6D6D6;
        }
        li div {
            width: 96px !important;
            margin: 0 auto !important;
            display: grid !important;
            font-size: 14px !important;
        }

    }
    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        width: 635px !important;
        .gAyQKI{
            font-weight: 700;
        }
    }
    @media only screen and (min-width : 1728px) {
        margin-bottom: 80px !important;
        .gAyQKI{
            font-weight: 700;
        }
    }
`;