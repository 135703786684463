import React from "react";

import { AppModule, DigitalTransfersModule } from "../modules";
const { ApplicationRouter } = AppModule.router;


const PublicTransactionsAppRouter = () => {
  return (
    <ApplicationRouter
      publicModules={[
        DigitalTransfersModule(),
      ]}
    />
  );
};

export default PublicTransactionsAppRouter;
