import { prop } from 'ramda';
import { TOKEN_REQUEST_OPTIONS, STORAGE_ACCESS_TOKEN_NAME } from './constants';
import {ServiceFetcherCreatePublic} from '@prima-afp/common-libs/dist/libs/http';

export default ServiceFetcherCreatePublic({  
  baseUrl: process.env.REACT_APP_API_URL_DMENTES_WEB,
  tokenUrl: process.env.REACT_APP_API_URL_GENERATION,
  tokenRequestOptions: TOKEN_REQUEST_OPTIONS,
  accessTokenGetter: () => localStorage.getItem(STORAGE_ACCESS_TOKEN_NAME),
  onError: (errorMessage) => {
    console.error(`[ServiceFetcher][onError]: ${errorMessage}`);
  },
  onGetToken: (response) => {
    localStorage.setItem(STORAGE_ACCESS_TOKEN_NAME, prop('access_token', response));
  }
});