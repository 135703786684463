import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    position: relative;
    background-color: white;
    padding: 32px 20px 32px 20px;
    border-radius: 16px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 20px;
    color: #999;
  }

  .headerModal{
    min-width: 100%;
  }
  .bodyModal{
    min-width: 100%;
  }
  @media only screen and (min-width : 451px) {      
    .modalWarning{  
      width: 550px;
      margin-right: 20px;
      margin-left: 20px;
    }
  }
`;
