import {
  Input,
  RadioButton,
  Tooltip,
} from "@prima-afp/prima-ui-elements/dist/core";
import React from "react";
import { StepTitleGray } from "../../../shared/components/StepTitle";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { DivFramePEP } from "./styles";
import TooltipContent from "../../../shared/components/TooltipContent";
import iIcon from "../../assets/images-con-asesor/infos.svg";

export const FramePEP = () => {
  const { heldPosition, userWeb } = useStateDigitalTransfersContext();
  const dispatch = useDispatchDigitalTransfersContext();

  const openPage = (url) =>{
    window.open(
        url,
        "_blank",
        "noopener"
      );
  };

  return (
    <DivFramePEP>
      <div style={{ display: "inline" }}>
        <StepTitleGray id="FramePEPid">
          ¿Has tenido un trabajo en el gobierno o en una institución pública en
          los últimos 5 años, ya sea en Perú o en otro país?*
          <Tooltip
            id="tooltip"
            className="tooltipWork"
            theme="light"
            style={{ display: "inline-flex", alignItems: "center" }}
            content={
              <TooltipContent
                title={
                  <>
                    <p style={{ color: "#696158" }}>
                      ¿Por qué te pedimos esto?
                    </p>
                  </>
                }
                subtitle={
                  <>
                    <p style={{ color: "#696158" }}>
                      Si no estas muy seguro, revisa si has trabajado para
                      alguno de estos{" "}
                      <a
                        style={{ textDecoration: "inline" }}
                        onClick={()=>{openPage("https://www.bcrp.gob.pe/sitios-de-interes/organismos-publicos.html")}}
                      >
                         organismos públicos.
                      </a>
                    </p>
                  </>
                }
              />
            }
          >
            <img
              id="tooltipImg"
              src={iIcon}
              alt="tooltipPensioner"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            />
          </Tooltip>
        </StepTitleGray>
      </div>

      <div className="wrapperButton" style={{ display: "flex" }}>
        <RadioButton
          label="Si"
          className="radioDiv"
          checked={heldPosition === true}
          fillColor="#FF4F00"
          onChange={() =>
            dispatch({
              type: "FIELD",
              payload: { field: "heldPosition", value: true },
            })
          }
          data-testid="input-field"
        ></RadioButton>
        <RadioButton
          label="No"
          className="radioDiv"
          checked={heldPosition === false}
          fillColor="#FF4F00"
          onChange={() =>
            dispatch({
              type: "FIELD",
              payload: { field: "heldPosition", value: false },
            })
          }
          data-testid="input-field2"
        ></RadioButton>
      </div>

      {heldPosition && (
        <div id="wrapperInput">
          <Input
            id="institutionInput"
            type="text"
            label="Ingresa el nombre de la institución"
            placeholder="Ingresa el nombre de la institución"
            value={userWeb.pepInstitutionName}
            onChange={(e) =>
              dispatch({
                type: "FIELD_SECOND",
                payload: {
                  field1: "userWeb",
                  field2: "pepInstitutionName",
                  value: e.target.value,
                },
              })
            }
            data-testid="input-tres"
          />
          <Input
            id="positionInput"
            type="text"
            label="Ingresa el cargo desempeñado"
            placeholder="Ingresa el cargo desempeñado"
            value={userWeb.pepPositionHeld}
            onChange={(e) =>
              dispatch({
                type: "FIELD_SECOND",
                payload: {
                  field1: "userWeb",
                  field2: "pepPositionHeld",
                  value: e.target.value,
                },
              })
            }
            data-testid="input-field4"
          />
        </div>
      )}

      <div id="disclaimer" className="">
        <p id="disclaimerSpan">
          (*)Declaro que la información proporcionada se ajusta a la verdad y ha
          sido brindada según lo solicitado por la Resolución SBS N°2660-2015 y
          sus modificatorias, por lo cual asumo total responsabilidad.
        </p>
      </div>
    </DivFramePEP>
  );
};
