// src/Toast.js
import React, { useEffect, useState } from 'react';
import {ToastDiv} from  './styles';
import LikeIcon from './resources/LikeIcon.svg';
import AlertRed from './resources/AlertRed.svg';
import ProgressBar from './ProgressBar';
//import './style.css';

export default function Toast ({ type, message, subtitle, duration, resetKey, colorBackground, colorBar, colorText}) {
  const [visible, setVisible] = useState(true);
  const [iconImg, setIcon] = useState(LikeIcon);

  useEffect(() => {
    switch (type) {
      case 'likeGreen': setIcon(LikeIcon);
      break;          
      case 'redAdlert':setIcon(AlertRed);
      break;
      default: setIcon(LikeIcon);
  }
  }, []);

  useEffect(() => {
    setVisible(true); // Reset visibility to true when resetKey changes
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, resetKey]);
  
  return (
    <ToastDiv id="idToastDiv" show={visible} hide={!visible} colorBackground={colorBackground} colorText={colorText}>
        <ProgressBar colorBackground={colorBackground} colorBar={colorBar}/>
        <div className="div-31">
             <img
                 loading="lazy"
                 src={iconImg}
                 className="img1"
             />
            <div className="div-41">{message}</div>
          {
            subtitle && <div className="div-41">{message}</div>
          }
        </div>
    </ToastDiv>
  );
}
