import styled from "styled-components";
import { Text } from "../Text";

export const StepTitle = styled(Text)`
    font-family: "FS Emeric";
    font-weight: 600;
    font-size: 18px;
    color: var(--greenColor);
`;

export const StepTitleGray = styled(Text)`
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #696158;

    @media only screen and (max-width : 376px) {   
        margin-bottom: 8px;
    }
    @media only screen and (min-width : 451px) and (max-width: 700px) {
        padding-bottom: 8px;
        text-align: left;
    }
    @media only screen and (min-width : 700px) and (max-width: 1000px) {
        text-align: center;
    }
    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        font-size: 16px;
    }
    @media only screen and (min-width : 1728px) {
        font-size: 18px;
    }
}
`;