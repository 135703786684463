/* eslint-disable react/no-unknown-property */
import React from 'react';

export const ADP = () => {
    const url1 = ()=>{ window.open("https://www.prima.com.pe/public-zone/files/2022/pdf/Lista_empresas_socios_comerciales.pdf","_blank", "noopener"); };
    const url2 = ()=>{ window.open("https://www.prima.com.pe/public-zone/politica-de-privacidad/#tratamiento-y-transferencia-de-tus-datos-personales", "_blank", "noopener"); };
    return (
        <div className='contentP'>
            <p><strong className='titleP'>2.AUTORIZACIONES PARA USOS ADICIONALES Y TRANSFERENCIA DE INFORMACI&Oacute;N</strong></p>
            <br />
            <p align="justify">Autorizas que Prima AFP, de manera directa o a trav&eacute;s de sus encargados, te oferte por cualquier medio escrito, verbal, electr&oacute;nico y/o inform&aacute;tico lo siguiente:</p>
            <ul style={{paddingLeft: '20px'}}>
            <li align="justify">Los productos y servicios que brindamos, o cualquier otro que desarrollemos en el futuro cualquiera de los productos o servicios que Prima brinde o pueda brindar en el futuro.</li>
            <li align="justify">Informaci&oacute;n sobre los beneficios y programas de fidelizaci&oacute;n que Prima AFP o sus aliados comerciales, que se encuentran disponibles en: <a>https://www.prima.com.pe/public-zone/sobre-prima-afp/politica-de-privacidad/#empresas-y-socios-comerciales</a> tienen para ti.</li>
            <li align="justify">La posibilidad de participar en estudios, investigaciones de mercado, encuestas y/o focus groups.</li>
            </ul>
            <p align="justify">Las comunicaciones o mensajes con la informaci&oacute;n, ofertas y/o publicidades podr&aacute;n ser remitidas a trav&eacute;s de medios escritos (por ejemplo, comunicaciones f&iacute;sicas), verbales (por ejemplo, mensajes de voz o llamadas telef&oacute;nicas) o electr&oacute;nicos/inform&aacute;ticos (por ejemplo, mensajes de texto, push notificaciones, WhatsApp, correo electr&oacute;nico u otras plataformas de mensajer&iacute;a).</p>
            <p align="justify">Prima AFP podr&aacute; transferir tu informaci&oacute;n a las empresas del Grupo Credicorp y socios comerciales (“Terceros Autorizados”) cuya identidad y ubicaci&oacute;n aceptas haber tenido a tu disposici&oacute;n al momento de contratar, y que tambi&eacute;n se encuentra disponible en <a>https://www.prima.com.pe/public-zone/sobre-prima-afp/politica-de-privacidad/#empresas-y-socios-comerciales</a>. Los Terceros Autorizados podr&aacute;n usar tu informaci&oacute;n, directamente o a trav&eacute;s de encargados, para realizar estudios de mercado, perfiles de compra, env&iacute;o de publicidad, promociones y ofertas de los productos y/o servicios que estas ofrecen y ejecutar o preparar las relaciones comerciales que podr&iacute;an mantener contigo.</p>
            <p align="justify">Asimismo, nos autorizas a solicitar a las empresas del Grupo Credicorp, y a &eacute;stas a transferirnos, los datos personales de tu titularidad que tuvieren. Ello a fin de cumplir con las finalidades descritas en la presente autorizaci&oacute;n. Mediante la emisi&oacute;n de esta autorizaci&oacute;n reconoces haber recibido informaci&oacute;n relevante de las empresas del Grupo Credicorp que se mantienen a tu disposici&oacute;n en el siguiente <a onClick={url1} style={{textDecoration: "underline"}}> enlace</a>.</p>
            <p align="justify">Declaras tener conocimiento que (i) fruto de la transferencia de la informaci&oacute;n entre Prima AFP y los Terceros Autorizados, tu informaci&oacute;n podr&aacute; ser enriquecida a trav&eacute;s de fuentes p&uacute;blicas y/o privadas, y; (ii) dicha informaci&oacute;n enriquecida podr&aacute; ser compartida entre Prima AFP y los Terceros Autorizados. </p>
            <p align="justify">Prima AFP y los Terceros Autorizados podr&aacute;n conservar y tratar tu informaci&oacute;n en los t&eacute;rminos establecidos en esta autorizaci&oacute;n, mientras se mantenga nuestra relaci&oacute;n contractual y hasta de diez (10) años de finalizada la misma.</p>
            <p align="justify">Prima AFP podr&aacute; modificar los t&eacute;rminos de la presente autorizaci&oacute;n, para lo cual deber&aacute; informarte con una anticipaci&oacute;n m&iacute;nima de 45 d&iacute;as calendario, a partir de los cuales la modificaci&oacute;n surtir&aacute; efecto. En caso no est&eacute;s de acuerdo con la modificaci&oacute;n realizada podr&aacute;s revocar el consentimiento a trav&eacute;s de los medios que hemos puesto a tu disposici&oacute;n. Cualquier modificaci&oacute;n unilateral que realicemos no podr&aacute; tener relaci&oacute;n directa con datos sensibles (como, por ejemplo, datos biom&eacute;tricos, ingresos econ&oacute;micos, informaci&oacute;n de salud), seg&uacute;n estos han sido regulados en la Ley Nº29733 – Ley de Protecci&oacute;n de Datos Personales y su Reglamento aprobado por el Decreto Supremo Nº003-2013-JUS.</p>
            <p align="justify">A esta autorizaci&oacute;n le resultan aplicables los t&eacute;rminos señalados en la secci&oacute;n informativa del Tratamiento de tus Datos Personales, as&iacute; como lo indicado en nuestra <a onClick={url2} style={{textDecoration: "underline"}}> Pol&iacute;tica de Privacidad</a>.</p>
            <p align="justify">La aceptaci&oacute;n o no de esta autorizaci&oacute;n para “Usos Adicionales y Transferencia de Informaci&oacute;n” (ajenos a la ejecuci&oacute;n de la relaci&oacute;n contractual) no condiciona la prestaci&oacute;n del servicio que est&aacute;s contratando. </p>
        </div>
    );
};
