//import * as React from "react";
import React, { useState, useEffect } from 'react';

import {WrapperRadioButtons} from './styles3';
export default function  RadioButtonInput({value, saveSelection}) {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const selectOption = (option) => {
    setSelected(option);
    saveSelection(option);
  };
  
  return (
    <WrapperRadioButtons>
      <div className="option">
        <div className={selected == true ? "circleSelected" : "circleNoselected" }   onClick={() =>selectOption(true)} />
        <div className="labelOption">Sí</div>
      </div>
      <div className="option">
        <div className={selected == false ? "circleSelected" : "circleNoselected" }  onClick={() =>selectOption(false)} />
        <div className="labelOption">No</div>
      </div>
      
      <style jsx>{`
        .div {
          display: flex;
          gap: 20px;
          font-size: 18px;
          color: var(--Text-Gray-Default, #4e5258);
          font-weight: 400;
          white-space: nowrap;
          letter-spacing: 0.02px;
          line-height: 133%;
          justify-content: space-between;
        }
        .div-2 {
          border-radius: var(--Radius-Radius-24, 24px);
          border-color: rgba(240, 240, 241, 1);
          border-style: solid;
          border-width: 1px;
          background-color: var(--Background-White-Default, #fff);
          display: flex;
          gap: 8px;
          padding: 12px 20px 12px 16px;
        }
        .div-3 {
          border-radius: var(--Radius-Radius-full, 999px);
          border-color: rgba(231, 231, 232, 1);
          border-style: solid;
          border-width: 2px;
          background-color: var(--Background-White-Default, #fff);
          width: 24px;
          height: 24px;
        }
        .div-4 {
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Lexend, sans-serif;
        }
        .div-5 {
          border-radius: var(--Radius-Radius-24, 24px);
          border-color: rgba(240, 240, 241, 1);
          border-style: solid;
          border-width: 1px;
          background-color: var(--Background-White-Default, #fff);
          display: flex;
          gap: 8px;
          padding: 12px 20px 12px 16px;
        }
        .div-6 {
          border-radius: var(--Radius-Radius-full, 999px);
          border-color: rgba(0, 204, 204, 1);
          border-style: solid;
          border-width: 7px;
          background-color: var(--Background-Secondary-Subtle, #e6fafa);
          width: 24px;
          height: 24px;
        }
        .div-7 {
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Lexend, sans-serif;
        }
      `}</style>
    </WrapperRadioButtons>
  );
}