import styled from "styled-components"

export const AcordionContent = styled.div`
  margin: 0 auto;
  .accordion-item {
    .accordion-title {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 12px;
      width: 100%;
      min-height: 56px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 8px;
      padding: 0px 18px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 1px 4px 0px rgba(69, 73, 78, 0.16);
      .contentHead {
        padding: 16px 0px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        h3 {
          color: var(--Text-Gray-Default, rgba(78, 82, 88, 1));
          font-family: Lexend;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.0012em;
          text-align: left;
        }
        .icon-row {
          display: flex;
          align-items: center;
        }
      }
      .accordion-content {
        width: 100%;
        border-radius: 8px;
        display: flex;
        align-items: center;
      }
      .hideResponse {
        display: none;
      }
      .showResponse {
        display: block;
      }
    }
  }

  .accordion-title p {
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
    flex: 1;
  }

  .accordion-content {
    padding-bottom: 16px;
    
    .linksTraspasos{
      text-decoration: underline;
      color: #ff4f00;
    }
    .custom-list-num {
      padding: 0px;
      margin: 0px;
      font-family: Calibri;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;
      color: var(--Text-Gray-Default, #4E5258);
      li {
        list-style-type: disc;
      }
    }
    .subTitle {
      list-style: none !important;
      font-family: Calibri;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;
      color: var(--Text-Gray-Default, rgba(78, 82, 88, 1));
    }
    p {
      font-family: Calibri;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;
      margin-bottom: 0px !important;
    }
  }

  @media screen and (max-width: 768px) {
    .accordion-content,
    .accordion-title {
      width: 100%;
      .contentHead{
        padding: 16px 0px;
        h3{
          max-width: 258px;
          font-size: 18px !important;
        }
        .imageMas{
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`
