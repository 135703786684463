/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { SelectButton, ButtonLabel, Arrow, OptionItem, Label } from './styledComponents';
import './styles.scss';

export const allColors = {
    colorBlack: '#000000',
    colorGrayBorder: '#E8E8E8',
    colorGrayComponents: '#F9F9FB',
    colorGrayHover: '#F4F4F4',
    colorGrayDisabled: '#BBBBBB',
    colorGrayCard: '#F7F7F8',
    colorGrayText: '#696158',
    colorGrayCardDashed: '#D7D9D9',
    colorGrayLineDashed: '#BABABA',
    colorGrayStep: '#A29D98',
    colorGreen: '#00A499',
    colorGreensuccess: ' #00ae12',
    colorGreenBalance: '#00AE99',
    colorGreenHover: '#059F8D',
    colorGreenSelectCard: '#EEFBF9',
    colorPinkCardBackground: '#ffe5d9',
    colorOrangeHover: '#EA4800',
    colorOrangeMain: '#FF4F00',
    colorOrangeMainActive: '#F04C02',
    colorRedError: '#FF4B4B',
    colorTooltip: '#C4C4C4',
    colorWhiteBase: '#FFFFFF',
    colorPlaceholder: '#9D9994',
    colorGrayLight: '#d6d6d6',
    colorBlueInfo: '#0049bf',
};

const SelectOptions = ({ options, onClickOption, currentValue }) => {
	return options.map((option) => (		
		<OptionItem
			isActive={option.value === currentValue.value}
			isPlaceholder={option.placeholder}
			key={option.value}
			onClick={() => onClickOption(option)}
			name={option.value}
			role='option'
			className={`ng-binding ng-scope ${option.value === currentValue.value ? 'active' : ''}`}
			tabIndex='-1'
			aria-selected='true'>
			{option.description}
		</OptionItem>
	));
};

const CustomSelect = ({
	optionsContainerStyles = {
		'max-width': '100%;',
		'padding-top': '0px;'		
	},
	selectWidth,
	selectOptions = [],
	onChange,
	placeholder,
	fontFamily = 'Calibri',
	name = '',
	initialValue,
	register,
	reset,
	disabled = false,
}) => {
	const placeholderItem = {
		textContent: placeholder,
		value: 'placeholder',
		shortContent: placeholder,
		placeholder: true,
	};
	const defaultOption = { code: '', description: '' };

	const getDefaultValue = () => {
		if (!selectOptions || !selectOptions.length) { return defaultOption;}
		if (initialValue) { return selectOptions.find((item) => item.code === initialValue);}
		return selectOptions[0];
	};
	
	const getOptions = () => {
		if (!selectOptions || !selectOptions.length) return [defaultOption];
		return placeholder ? [placeholderItem, ...selectOptions] : selectOptions;
	};

	const defaultValue = getDefaultValue();
	const optionsList = getOptions();

	const [activeSelect, setActiveSelect] = useState(false);
	const [selectValue, setSelectValue] = useState(defaultValue || {});

	useEffect(() => {
       if (reset) setSelectValue(defaultValue);
	}, [reset]);

	const handleSelect = () => {
		!disabled && setActiveSelect(!activeSelect);
	};

	const onClickOption = (newSelectValue) => {
		setSelectValue(newSelectValue);
		handleSelect();
		register(newSelectValue);
	};

	useEffect(() => {
		const newDefaultOption = getDefaultValue();
		setSelectValue(newDefaultOption);
	}, [selectOptions]);

	useEffect(() => {
		if (onChange) onChange(selectValue.placeholder ? null : selectValue);
	}, [selectValue]);

	const useOutsideClick = (callback) => {
        const ref = React.useRef();   
        React.useEffect(() => {
          const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
              callback();
            }
          };   
          document.addEventListener('click', handleClick, true);   
          return () => {
            document.removeEventListener('click', handleClick, true);
          };
        }, [ref]);    
        return ref;
    };

    const handleClickOutside = () => {
        setActiveSelect(false);        
    };

    const ref = useOutsideClick(handleClickOutside);

	return (
		<>
			<select
				style={{ display: 'none' }}
				onChange={(e) => register(e)}
				name={name}
				id={name}
				value={selectValue.code}>
				{optionsList.map((optionObj) => (
					<option key={optionObj.description} value={optionObj.code}>
						{optionObj.description }
					</option>
				))}
			</select>
			<SelectButton width={selectWidth} onClick={handleSelect} type='button' className='select-box'>
				<ButtonLabel fontFamily={fontFamily} isPlaceholder={selectValue.placeholder}>
					<Label>{selectValue.description }</Label>
				</ButtonLabel>
				<Arrow>
					<span className={`arr ${activeSelect == true ? 'arr-up' : 'arr-down'}`}></span>
				</Arrow>
			</SelectButton>
			{selectOptions.length > 0 && activeSelect && (
				<div ref={ref} id="lista1" type='button' className={`md-select ${activeSelect == true ? 'active' : ''}`} style={optionsContainerStyles}>
					<ul role='listbox' className='option-list'>
						<SelectOptions options={optionsList} onClickOption={onClickOption} currentValue={selectValue} /> 
					</ul>
				</div>
			)}
		</>
	);
};

export default CustomSelect;
