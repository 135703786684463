import React, { useState, useRef, useEffect } from 'react';
import step1 from './step1.svg';
import step2 from './step2.svg';
import step3 from './step3.svg';
import step4 from './step4.svg';
import {WrapperStepper} from './styles';

export default function Stepper({stepList = [], currentStep, }) {
    const [title, setTitle] = useState('Cambio');
    const [subtitle, setSubtitle] = useState('subt');
    const [image, setImage] = useState(step1);

    useEffect(() => {
        const step = stepList.find((element) => { return element.id === currentStep;}) ;        
        step ? setTitle(step.title): null;
        step ? setSubtitle(step.subtitle): null;
        switch(currentStep){            
            case 0:setImage(step1);
            break;            
            case 1:setImage(step1);
            break;
            case 2:setImage(step2);
            break;
            case 3:setImage(step3);
            break;
            case 4:setImage(step4);
            break;
        }
    }, [currentStep]);

    return (
        <>
        <WrapperStepper >
            <img
            src={image}
            className="imgStepper"
            />
            <div className="wrapperContent">
                <div className="titleStepper">{title}</div>
                <div className="subtitleStepper">{subtitle}</div>
            </div>
        </WrapperStepper>

        </>
    );
}

