import React from "react";
import PropTypes from "prop-types";

import PublicRouter from "./PublicRouter";

import { AppRouter } from "@prima-afp/prima-business-elements/dist/businessElements/application";

const ApplicationRouter = ({
  publicModules = [],
}) => {
  const existSessions = () => {
    return false;
  };
  return (
    <AppRouter
      publicRouter={<PublicRouter publicModules={publicModules} />}
      sessionValidator={existSessions}
    />
  );
};

ApplicationRouter.propTypes = {
  privateModules: PropTypes.array,
  publicModules: PropTypes.array,
  config: PropTypes.object,
  children: PropTypes.node,
  userModule: PropTypes.object,
};

export default ApplicationRouter;
