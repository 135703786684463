import { useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext';
import React from 'react';
import { StepFundType } from '../StepFundType';
import { StepIdentity } from '../StepIdentity';
import { StepRecognition } from '../StepsRecognition';
import { PhoneVerificationPage } from '../../pages/paginas-con-asesor/PhoneVerificationPage';


export const StepsContent = ({ buttonRef }) => {

    const { stepCurrent } = useStateDigitalTransfersContext();

    if (stepCurrent === 1 && window.location.href.includes('pasos1')) return <div><StepIdentity /></div>;

    if (stepCurrent === 2 && window.location.href.includes('pasos2')) return <div><StepFundType /></div>;

    if (stepCurrent === 3 && window.location.href.includes('pasos3')) return <div><StepRecognition /></div>;

    if (stepCurrent === 4 && window.location.href.includes('pasos4')) return <div><PhoneVerificationPage buttonRef = { buttonRef }/></div>;

    return <div></div>;
};
