import React from "react";
import { SecondBannerContainer } from "./styled";
import { redirectURL } from "modules/landing/constants/functions";
import imgBanner2 from "../../../assetss/image/imgBanner2.png";
const SecondBanner = () => {
  return (
    <SecondBannerContainer>
      <div className="contents">
        <div className="contentText">
          <h1 className="title">
            Somos la primera AFP en aceptar{" "}
            <p className="textRed">aportes como independiente desde Yape</p>
          </h1>
          <div className="btnsChange">
            <button
              className="btnSlide"
              onClick={() =>
                redirectURL("traspaso", {
                  event: "button_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "banner",
                    option: "cambiate ya",
                    position_banner: "banner_2"
                  },
                })
              }
            >
              Cámbiate a Prima AFP
            </button>
            <button
              className="btnSlideSecundary"
              onClick={() =>
                redirectURL("traspasoAsesor", {
                  event: "button_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "banner",
                    option: "me estan asesorando",
                    position_banner: "banner_2"
                  },
                })
              }
            >
              Me están asesorando
            </button>
          </div>
        </div>
        <div className="contentImage">
          <img src={imgBanner2} />
        </div>
      </div>
    </SecondBannerContainer>
  );
};

export default SecondBanner;
