import styled from "styled-components";

export const WrapperStepper = styled.div`
    @media only screen and (max-width : 450px) { 
        margin-top: 24px;
        flex-wrap: wrap;
        align-self: stretch;
        display: flex;
        gap: 16px;
        font-size: 18px;
        color: var(--Text-Gray-Default, #4e5258);
        font-weight: 600;
        letter-spacing: 0.02px;
        line-height: 24px;
        width: 312px;
        .imgStepper{
            aspect-ratio: 1 !important;
            object-fit: auto !important;
            object-position: center !important;
            width: 56px !important;
        }
        .titleStepper{
            font-variant-numeric: lining-nums proportional-nums;
            font-family: Lexend, sans-serif;
            margin: auto 0;
            font-size: 18px !important;
        }
        .subtitleStepper{
            display:none;
        }
        .wrapperContent {
            padding: 0px 0px !important;
        }
    }

    @media only screen and (min-width : 451px) { 
        .wrapperContent {
            max-width: 100%;
        }
        .titleStepper {
            max-width: 100%;
        }
        .subtitleStepper {
            max-width: 100%;
        }
    }

    justify-content: center;
    align-self: stretch;
    display: flex;
    gap: 20px;
    color: var(--Text-Gray-Default, #4e5258);

    .imgStepper {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 72px;
    }
    .wrapperContent {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: auto 0;
    padding: 0 20px;
    }

    .titleStepper {
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: 0.03px;
    font: 600 22px/127% Lexend, -apple-system, Roboto, Helvetica,
        sans-serif;
    }

    .subtitleStepper {
    letter-spacing: 0.02px;
    margin-top: 4px;
    font: 400 16px/150% Calibri, -apple-system, Roboto, Helvetica,
        sans-serif;
    }
    
    `;