import styled from "styled-components";


export const DivModal = styled.div`
  #id1{
    padding: 0px !important;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const DivModalTC = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;

  #buttonDiv{
    margin-left: 15px;
    margin-right: 15px;
  }

  @media only screen and (max-height : 700px) {
    height: 418px !important;
    #contentDiv{
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
`;

export const Father = styled.div`
  position:relative;
  background-color: green;
`;

export const Son = styled.div`
  position: absolute;
  background-color: red;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 20px;
`;

export const RecordWrapper = styled.div`
  .recoTitle{    
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #696158;
    padding-top: 32px;
    padding-left: 40px;
  }

  .videorecord{
      width: 181px;
      height: 175.03px;
      border: none !important;
  }

  .recotextbold{
      font-weight: 900 !important;
  }

  .imgReco{
    width: 32px;
    height: 32px;
    color: var(--orangeColorMain);
  }

  .imgbold{
      fill: var(--orangeColorMain) !important;
  }

  .div-weapper{
      display: flex;
      margin-top: 24px;
  }

  .column {
    display: flex;
    align-items: center; /* Centra verticalmente los elementos hijos */
    justify-content: center; /* Centra horizontalmente los elementos hijos */
  }
  
  .column img {
    max-width: 100%;
  } 

  .recoStep3{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .recotext{
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: var(--grayColorText);
    justify-content: flex-start !important;
    text-align: left !important;
  }
 
`;