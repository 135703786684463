import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ToastDiv = styled.div`
      @media only screen and (max-width : 450px) { 
        top: 74px;
        width: 343px;        
        right: 16px !important;
      } 
      @media only screen and (min-width : 451px) { 
        width: 371px;              
        .div-41 {
          flex: 1;
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 16px
        }
      } 
      border-radius: 16px;
      overflow: hidden;
      position: fixed;
      top: 31px;
      right: 38px;
      background-color: ${(props) => props.colorBackground || 'rgba(230, 250, 250, 1)'};
      display: flex;
      width: 343px;
      padding-bottom: 16px;
      flex-direction: column;
      font-size: 18px;
      color: ${(props) => props.colorText || '#04a6a2'};
      font-weight: 600;
      line-height: 133%;
      width: 343px;
      opacity: 1;
      transition: opacity 0.5s ease-out;        
      ${props => 
        props.hide && 
        css`
          opacity: 0;
        `
      }     
      .div-21 {
        background-color: ${(props) => props.colorBackground || 'var(--Turquesa-Sec1-80, #0cc)'};
        min-height: 4px;
        width: 100%;
      }
      .div-31 {
        align-self: start;
        display: flex;
        gap: 8px;
        margin: 16px 0 0 15px;
      }
      .img1 {
        aspect-ratio: 1;
        object-fit: auto;
        object-position: center;
        width: 24px;
      }
      .div-41 {
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Rozanova GEO, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
`;

export const  ProgressBarDiv = styled.div`
  width: 100%;
  background-color: ${(props) => props.colorBackground ||'#E6FAFA'};
  height: 4px;
  border-radius: 5px;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  .progress-bar {
    height: 100%;
    background-color: ${(props) => props.colorBar || '#00CCCC'};
    transition: width 0.03s linear; /* Ensure smooth transition */
  }
`;
