import { useRef } from 'react';

export const useSubmitOnEnter = () => {
    const buttonRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key && event.key === 'Enter') {
            event.preventDefault();
            buttonRef.current.click();
        }
    };

    return { buttonRef, handleKeyDown };
};
