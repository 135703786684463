import { Button } from "@prima-afp/prima-ui-elements/dist/core";
import React, { useEffect, useRef, useState } from "react";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import { SendQueryIdentity } from "../../services";
import ReCAPTCHA from "react-google-recaptcha";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { QueryPageContainer } from "./Style";
import { useSubmitOnEnter } from "../../hooks/useSubmitOnEnter";
import { sendToData } from "../../constants/sendToDataLayer";
import { InputSearch } from "./Component/Input/InputSearch";
import { ResponseDesktop } from "./Component/Response/ResponseDesktop";
import { ResponseMovile } from "./Component/Response/ResponseMovile";
import Loading from "../../../shared/components/Loading";
import logoPrimaOrange from "./images/logoPrimaOrange.svg";
import imageMessage1 from "./images/imgMessage1.svg";
import imageMessage2 from "./images/imgMessage2.svg";
import imageMessage3 from "./images/imgMessage3.svg";
import imageMessage4 from "./images/imgMessage4.svg";
import imageMessage5 from "./images/imgMessage5.svg";
import imageMessage6 from "./images/imgMessage6.svg";
import imgPage1 from "./images/imgPage1.svg";
import imgPage2 from "./images/imgPage2.svg";
import { ErrorModal } from "../../../shared/components/ErrorModal/index";

export const QueryPage = () => {
  const reCaptchaRef = useRef();
  const dispatch = useDispatchDigitalTransfersContext();
  const documentType = "00";
  const [documentNumber, setDocumentNumber] = useState("");
  const [response, setResponse] = useState({});
  const { SendFetch, SetLoading } = useCatchFetch();
  const { modalOptions, showLoading, showQueryResponse } =
    useStateDigitalTransfersContext();
  const { buttonRef, handleKeyDown } = useSubmitOnEnter();

  const [dniBack, setDniBack] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    document.title =
      "Consulta el estado de tu solicitud de traspaso | Prima AFP";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Consulta aquí el estado de tu solicitud de traspaso digital con Prima AFP. Solo ingresa tu documento de identidad y en poco segundos ten tu respuesta."
      );
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute(
        "href",
        "https://traspaso.prima.com.pe/traspasos-digitales/consulta"
      );
    }
  }, []);

  useEffect(() => {
    if (dniBack === documentNumber) {
      setIsButtonDisabled(true);
    }
    if (showQueryResponse) {
      SetLoading(false);
    }
  }, [showQueryResponse]);

  const Send = async () => {
    dispatch({ type: "INITIAL_STATE" });
    SetLoading(true);
    const currentUrl = window.location.href;
    let payload = { section: "Landing consulta", option: dniBack ? "vuelve a consultar" : "consulta"  };
    sendToData(currentUrl, "button_click", "button_click", payload);
    reCaptchaRef.current.reset();
    const recaptchaToken = await reCaptchaRef.current.executeAsync();

    const body = {
      documentType,
      documentNumber,
      recaptchaToken,
    };

    SendFetch(SendQueryIdentity(body))
      .then((res) => {
        setDniBack(body.documentNumber);
        setResponse(armMessage(res));
        dispatch({
          type: "FIELD",
          payload: {
            field: "showQueryResponse",
            value: true,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "NOT_VALID_DOCUMENT",
          payload: {
            idModal: "btn-error-Query",
            content:
              "Hubo un inconveniente con la consulta de tu documento de identidad, por favor vuelve a intentarlo más tarde.",
            changeLoading: false,
          },
        });
      });
  };

  const armMessage = (res) => {
    let name = res.firstName;
    let subTitle = "";
    let messageTitle = "";
    let message = "";
    let messageBoton = "";
    let imageMessage = null;
    let noRequest = false;
    let flagBoton = true;
    let flagInformation = false;
    let currentUrlMessage = window.location.href;
    let steps = [];
    switch (res.status) {
      case "RD":
      case "RO":
      case "RP":
      case "AN": // RECHAZADA
        subTitle = (
          <p>
            tu solicitud N°{" "}
            <span className="textBoldQuery">{res.requestNumber} </span> se
            encuentra...
          </p>
        );
        messageTitle = (
          <p>
            Tu solicitud fue <span className="textRedQuery">RECHAZADA</span>
          </p>
        );
        message = (
          <p>
            Para mayor información llámanos al{" "}
            <span className="textBoldContactQuery">615-7272</span> o al{" "}
            <span className="textBoldContactQuery">0-801-18010</span> en
            provincias.
          </p>
        );
        imageMessage = imageMessage5;
        if (window.innerWidth <= 480) {
          steps = [
            {
              number: 1,
              description: "Registrada",
              status: "registrada",
              date: res.registerDate,
              finished: true,
              color: "#04A6A2",
              backgroundColor: "#e6fafa",
            },
            {
              number: 2,
              description: "En proceso",
              status: "enProceso",
              finished: true,
              color: "#04A6A2",
              backgroundColor: "#e6fafa",
            },
            {
              number: 3,
              description: "Rechazada",
              status: "rechazada",
              finished: false,
              color: "#FF0000",
              backgroundColor: "#FFE6E6",
            },
            {
              number: 0,
              backgroundColor: "#FFF",
            },
          ];
        } else {
          steps = [
            {
              number: 1,
              description: "Registrada",
              status: "registrada",
              date: res.registerDate,
              finished: true,
              color: "#04A6A2",
            },
            {
              number: 2,
              description: "En proceso",
              status: "enProceso",
              finished: true,
              color: "#04A6A2",
            },
            {
              number: 3,
              description: "Rechazada",
              status: "rechazada",
              finished: false,
              color: "#FF0000",
            },
          ];
        }

        flagBoton = false;
        flagInformation = true;
        sendToData(currentUrlMessage, "status_consulta", "status_consulta", {
          section: "Landing consulta",
          option: "su solicitud ha sido rechazada",
        });
        break;
      case "CO":
      case "RM":
      case "RE": // REGISTRADA - INGRESADA
        if (res.requestNumber && res.requestNumber !== "null") {
          // REGISTRADA
          subTitle = (
            <p>
              tu solicitud N°{" "}
              <span className="textBoldQuery">{res.requestNumber} </span> se
              encuentra...
            </p>
          );
          messageTitle =
            "Gracias por confiar en nosotros para administrar tu fondo de pensión";
          message =
            "Enviaremos tu solicitud a tu AFP de origen para que pueda validar su traspaso.";
          imageMessage = imageMessage2;
          if (window.innerWidth <= 480) {
            steps = [
              {
                number: 1,
                description: "Registrada",
                status: "registrada",
                date: res.registerDate,
                finished: true,
                color: "#04A6A2",
                backgroundColor: "#e6fafa",
              },
              {
                number: 0,
                backgroundColor: "#FFF",
              },
              {
                number: 2,
                description: "En proceso",
                status: "enProceso",
                backgroundColor: "#FFF",
              },
              {
                number: 3,
                description: "Atendida",
                status: "atendida",
                backgroundColor: "#FFF",
              },
            ];
          } else {
            steps = [
              {
                number: 1,
                description: "Registrada",
                status: "registrada",
                date: res.registerDate,
                finished: true,
                color: "#04A6A2",
              },
              { number: 2, description: "En proceso", status: "enProceso" },
              { number: 3, description: "Atendida", status: "atendida" },
            ];
          }
          sendToData(currentUrlMessage, "status_consulta", "status_consulta", {
            section: "Landing consulta",
            option: "su solicitud ha sido registrada satisfactoriamente",
          });
        } else {
          // INGRESADA
          subTitle = "hemos recibido tu solicitud";
          messageTitle = "Estamos validando tu solicitud";
          message =
            "Te informaremos el N° de solicitud cuando haya sido registrada en nuestro sistema.";
          imageMessage = imageMessage1;
          if (window.innerWidth <= 480) {
            steps = [
              {
                number: 1,
                description: "Registrada",
                status: "registrada",
                backgroundColor: "#FFF",
              },
              { number: 0, backgroundColor: "#FFF" },
              {
                number: 2,
                description: "En proceso",
                status: "enProceso",
                backgroundColor: "#FFF",
              },
              {
                number: 3,
                description: "Atendida",
                status: "atendida",
                backgroundColor: "#FFF",
              },
            ];
          } else {
            steps = [
              {
                number: 1,
                description: "Registrada",
                status: "registrada",
              },
              { number: 2, description: "En proceso", status: "enProceso" },
              { number: 3, description: "Atendida", status: "atendida" },
            ];
          }
          sendToData(currentUrlMessage, "status_consulta", "status_consulta", {
            section: "Landing consulta",
            option: "su solicitud ha sido ingresada",
          });
        }

        flagBoton = false;
        break;
      case "AC":
      case "EU":
      case "PG":
      case "CE":
      case "VA":
      case "GE":
      case "RA":
      case "AP": // PROCESO
        subTitle = (
          <p>
            tu solicitud N°{" "}
            <span className="textBoldQuery">{res.requestNumber} </span> se
            encuentra...
          </p>
        );
        messageTitle =
          "Tu solicitud de traspaso está siendo validada por tu AFP de origen y la SBS";
        message =
          "No te preocupes, te avisaremos cuando la confirmación de tu traspaso esté lista.";
        imageMessage = imageMessage3;
        if (window.innerWidth <= 480) {
          steps = [
            {
              number: 1,
              description: "Registrada",
              status: "registrada",
              date: res.registerDate,
              finished: true,
              color: "#04A6A2",
              backgroundColor: "#e6fafa",
            },
            {
              number: 2,
              description: "En proceso",
              status: "enProceso",
              color: "#4E5258",
              backgroundColor: "#FFF",
            },
            { number: 0, backgroundColor: "#FFF" },
            {
              number: 3,
              description: "Atendida",
              status: "atendida",
              backgroundColor: "#FFF",
            },
          ];
        } else {
          steps = [
            {
              number: 1,
              description: "Registrada",
              status: "registrada",
              date: res.registerDate,
              finished: true,
              color: "#04A6A2",
            },
            {
              number: 2,
              description: "En proceso",
              status: "enProceso",
              color: "#4E5258",
            },
            { number: 3, description: "Atendida", status: "atendida" },
          ];
        }

        flagBoton = false;
        sendToData(currentUrlMessage, "status_consulta", "status_consulta", {
          section: "Landing consulta",
          option: "su solicitud se encuentra en proceso",
        });
        break;
      case "CI": // ACEPTADA
        subTitle = (
          <p>
            tu solicitud N°{" "}
            <span className="textBoldQuery">{res.requestNumber} </span> se
            encuentra...
          </p>
        );
        messageTitle = (
          <p>
            Tu solicitud fue <span className="textColorQuery">APROBADA</span>
          </p>
        );
        message =
          "Comenzaremos con la transferencia de tu fondo desde tu anterior AFP.";
        imageMessage = imageMessage4;
        if (window.innerWidth <= 480) {
          steps = [
            {
              number: 1,
              description: "Registrada",
              status: "registrada",
              date: res.registerDate,
              finished: true,
              color: "#04A6A2",
              backgroundColor: "#e6fafa",
            },
            {
              number: 2,
              description: "En proceso",
              status: "enProceso",
              finished: true,
              color: "#04A6A2",
              backgroundColor: "#e6fafa",
            },
            {
              number: 3,
              description: "Aprobada",
              status: "Aprobada",
              finished: true,
              color: "#04A6A2",
              backgroundColor: "#e6fafa",
            },
            {
              number: 0,
              backgroundColor: "#FFF",
            },
          ];
        } else {
          steps = [
            {
              number: 1,
              description: "Registrada",
              status: "registrada",
              date: res.registerDate,
              finished: true,
              color: "#04A6A2",
            },
            {
              number: 2,
              description: "En proceso",
              status: "enProceso",
              finished: true,
              color: "#04A6A2",
            },
            {
              number: 3,
              description: "Aprobada",
              status: "Aprobada",
              finished: true,
              color: "#04A6A2",
            },
          ];
        }

        flagBoton = false;
        sendToData(currentUrlMessage, "status_consulta", "status_consulta", {
          section: "Landing consulta",
          option: "su solicitud ha sido aprobada",
        });
        break;
      default:
        message = "No cuentas con una solicitud de traspaso registrada";
        imageMessage = imageMessage6;
    }

    if (!res.status) {
      noRequest = true;

      message = <p>No cuentas con una solicitud de traspaso registrada</p>;
      flagBoton = true;
      sendToData(currentUrlMessage, "status_consulta", "status_consulta", {
        section: "Landing consulta",
        option: "no cuentas con una solicitud",
      });
    }

    return {
      message,
      messageBoton,
      flagBoton,
      noRequest,
      flagInformation,
      name,
      subTitle,
      messageTitle,
      imageMessage,
      steps,
    };
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,8}$/.test(value)) {
      setDocumentNumber(value);
      if (value.length === 8) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <QueryPageContainer isMovil={window.innerWidth <= 480}>
        <div className="headLogos">
          <a href="https://www.prima.com.pe/">
            <img src={logoPrimaOrange} alt="prima" />
          </a>
          <p>Ayer, Hoy, Siempre</p>
        </div>

        <div className="containerBackColor">
          <div className="containerCenter">
            <div className="divCenter">
              <div className="titleQuery">
                Hazle seguimiento a tu solicitud de traspaso a Prima AFP
              </div>
              <div className="divSearch">
                <div className="titleSearch">
                  Ingresa tu documento de identidad
                </div>
                <div className="componentSearch">
                  <div className="contentInputSearch">
                    <InputSearch
                      placeholder="N° de documento"
                      maxLength={8}
                      value={documentNumber}
                      onChange={handleChange}
                    />
                  </div>

                  <Button
                    onClick={Send}
                    disabled={isButtonDisabled}
                    ref={buttonRef}
                    className={
                      isButtonDisabled
                        ? "disabledBtn btnSearch"
                        : "enabledBtn btnSearch"
                    }
                  >
                    {dniBack ? "Volver a consultar" : "Consultar el estado"}
                  </Button>
                </div>
              </div>
              {window.innerWidth <= 480 && showQueryResponse && (
                <ResponseMovile response={response} />
              )}
              {window.innerWidth > 480 && showQueryResponse && (
                <ResponseDesktop response={response} />
              )}
              <ReCAPTCHA
                className="captcha"
                ref={reCaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                size="invisible"
              />
            </div>
          </div>
        </div>
        {window.innerWidth > 480 && (
          <div className="containerWidth">
            <img src={imgPage1} alt="img" className="imgPage1" />
            <img src={imgPage2} alt="img" className="imgPage2" />
          </div>
        )}
      </QueryPageContainer>
      <Loading show={showLoading} />
      <ErrorModal
        settings={modalOptions}
        closeModal={() =>
          dispatch({
            type: "CLOSE_MODAL",
          })
        }
      />
    </div>
  );
};
