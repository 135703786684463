import styled from "styled-components";

export const ContentMovileDesktop = styled.div`
  .contentResponseQuery {
    padding-top: 40px;
    .titleResponseQuery {
      color: #04a6a2;
      text-align: center;
      font-family: Lexend;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.0012em;
    }
    .descriptionResponseQuery {
      color: #4e5258;
      font-family: Lexend;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.0012em;
      text-align: center;
    }
    .statusResponseQuery {
      padding-top: 40px;
    }
    .messageResponseQuery {
      background-color: #fbfbfb;
      border-radius: 24px;
      display: flex;
      margin-top: 33px;
    }
    .messageResponseQuery img {
      max-width: 100%;
      height: auto;
    }
    .contentImageQuery {
      align-items: end;
      display: flex;
      padding-left: 49px;
      padding-right: 26px;
      padding-top: 17.79px;
    }
    .messageQuery {
      flex: 1;
      padding: 10px;
      align-self: center;
      max-width: 383px;
    }
    .titleMessageQuery {
      font-family: Lexend;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;
      color: #4e5258;
      padding-bottom: 16px;
    }
    .contentMessageQuery {
      font-family: Calibri;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;
      color: #4e5258;
    }
    .contentMessageQueryNo{
      max-width: 285px;
      height: 48px;
      gap: 0px;
      opacity: 0px;
      color: #4E5258;//styleName: Componentes - Apoyo/Text compt L - 18pt - Lexend - Semibold;
      font-family: Lexend;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;

    }
  }
`;
