import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: 16px;
    margin-bottom: 16px;
    
    @media only screen and (max-width : 451px) {       
        width: 100% !important;        
    }

    @media only screen and (min-width : 451px) and (max-width: 1000px) {
        margin: auto;
    }
`;