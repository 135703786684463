import styled, { css } from "styled-components";

export const RecoSelfieDiv = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    
    .textA{
        display: inline
    }
    .textB{
        display: inline
    }
    .counterDiv{
        font: 600 24px / 1 FS Emeric, sans-serif;
    } 
    .messageDiv{
        color: #696158;
        text-align: center;
        letter-spacing: 0.02px;
        font: 400 16px / 24px var(--Font-Family-Body, Calibri);
    }
    @media only screen and (max-width : 450px)  {
        width: 312px;
        marginTop: 20px;
        .imgRecoSelfie{        
            margin-bottom: 24px;
        }
        .counterDiv{
            font-family: Lexend, sans-serif;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 16px;
        }     
    }
    @media only screen and (min-width : 451px)  {
        margin-top: 39px;
        .messageDiv{
            width: 345px;
            margin-bottom: 32px;
            margin-top: 23px;
        }
        .counterDiv{
            margin-top: 11px;
        }
    }

`;

export const CarouselWrapper = styled.div` 
    text-align: center; 

    .textA{
        margin-right: 5px;
        display: inline-block
        color: #4E5258;
    }
    .textB{
        color:#00A499;
        display: inline-block
    }
    
    .counterDiv{
        font-variant-numeric: lining-nums proportional-nums;
        width: 100%;
        text-align: center;
        letter-spacing: 0.03px;
        font: 600 24px/32px var(--font-family-subtitle, Lexend);
        margin-bottom: 20px;
    }

    .circlesCarousel{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    .recoCarousel{
        text-align: center;
        /* letter-spacing: 0.02px; */
        font: 700 18px / 24px Calibri, sans-serif;
    }

    @media only screen and (max-width : 450px)  {
        margin-top: 20px;
        .imgCarousel{
            width: 169px;
            height: 169px;
        }
        .counterDiv{
            font-size: 24px
            width: 278px;
        }   
        .recoCarousel{
            margin-top: 28px;
        }
    }
    @media only screen and (min-width : 451px)  {
        margin-bottom: 40px;
        margin-top: 48px;
        .imgCarousel{
            width: 195px;
            height: 195px;
        }
    }



`;