import React from "react"
import { TestimonialStyle } from "./style"
import { starts } from "../../../assets"
import Slider from "react-slick"
import { settingsTestimonial, testimonialsItems } from "../../../constants/data"

const TestimonialSlider = () => {

  return (
    <TestimonialStyle>
      <Slider {...settingsTestimonial}>
        {testimonialsItems.map(item => (
          <div className="contentItem" key={item.id}>
            <div className="contentGlobo">
              <p>{item.text}</p>
            </div>
            <div className="contentUser">
              <div className="imgAvatar">
                <img src={item.avatar} alt={item.name} />
              </div>
              <div className="nameUser">
                <p>{item.name}</p>
                <img src={starts} alt="starts" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </TestimonialStyle>
  )
}

export default TestimonialSlider
