import background from "./Images/StepOne/background_desktop.jpg";
import React from "react";
import logoPrima from "..//../assets/images/logoPrima2.svg"
import { DivBenefits, TextStyledMobile, Disclaimer } from "./styles";
import { useStateDigitalTransfersContext } from '../../../digital-transfers/context/DigitalTransfersContext';
import parse from 'html-react-parser';
export const Banner = () => {  
 const { legaltext  } = useStateDigitalTransfersContext();  
 return (
    
    <DivBenefits id="idDivBenefits" className="benefitImg" style={{                          
        backgroundImage: "url(" + background + ")",  
        mixBlendMode: 'normal',      
        backgroundSize: 'cover',
        backgroundBlendMode: 'multiply',
        flexDirection: 'column',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        left: '0px',
        top: '72px',
        backgroundPosition: 'center top',
      }}>    
        <div className="headerDesktopBanner" style={{marginTop: '40px', marginLeft: '40px', marginRight: '24px'}}>
            <div style={{flex:1, display: 'flex'}}>
                <img className="logoPrima" alt="logoPrima" src={logoPrima} style={{width: '90px' , height: '17px'}} /> 
            </div> 
        </div> 
        <DivBenefits id="benedfitid_content" style={{                          
          
          mixBlendMode: 'normal',      
          backgroundSize: 'cover',
          backgroundBlendMode: 'multiply',
          flexDirection: 'column',
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'flex-end',
          left: '0px',
          top: '72px',
        }}>     

            <div className="headerMobile" style={{display: 'none', marginTop: '27px', marginLeft: '24px', marginRight: '24px'}}>
              <div style={{flex:1, display: 'flex'}}>
                  <img className="logoPrima" alt="logoPrima" src={logoPrima} style={{width: '90px' , height: '17px'}} /> 
              </div>     
              <div style={{display: 'flex', justifyContent: 'flex-end' }}>        
                  <TextStyledMobile>
                  Ayer, Hoy, Siempre
                  </TextStyledMobile>  
              </div>  
            </div>
                  <Disclaimer>
                      <div className="contentDisclaimer">
                        {/* <div>{parse(legaltext)}</div> */}
                      </div>

                    </Disclaimer>
        </DivBenefits>
      </DivBenefits> 
  );
};
