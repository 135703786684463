import styled from "styled-components";
import { Text } from "../Text";
import flecha from "../../../App/assets/images/flecha2.svg";
import { mediaQuery } from "modules/utils/responsive";

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--grayColorCard);
  overflow-x: hidden;
  .fade-in {
    margin-top: 30px;
  }
`;

export const ContentStepInitial = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #ffffff;
  overflow-x: hidden;
  .fade-in {
    margin-top: 30px;
  }

  @media only screen and ${mediaQuery.mobileS} {
    #idBenefitImg {
      display: none;
      height: 290px;
    }
    #wrapperStepsId {
      width: 100%  !important;
    }
    #idContainerHeader {
      width: 100%  !important;
    }
    .backButtonMobile {
      width: 100%;
    }
  }

  @media only screen and (max-width: 376px) {
    flex-direction: column;
    flex-wrap: nowrap;
    .headerLogo {
      display: none !important;
    }
    .benefitImg {
      width: 100%;
      height: 290px;
    }
    .rec-carousel-wrapper {
      height: 248px;
    }
    .rec-pagination {
      margin-bottom: 11px;
    }
    #app-main-content-steps {
      display: flex;
      flex-direction: column-reverse;
    }
    #idDivContentStep {
      margin-bottom: 0px;
    }
    #idContainerHeader {
      height: 24px;
      margin-bottom: 24px !important;
      margin-right: 16px !important;
      padding-left: 16px !important;
    }
    .MBtn {      
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
    }
    .Container-sc-1k9kuuz-0 {
      margin: 0px !important;
      padding: 0px !important;
      width: 335px !important;
    }
    .Card-module_card-component__xGCGj {
      box-sizing: none !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      margin: 0px !important;
      padding: 0px !important;
    }
    .Card-module_card-body__Jgo9P {
      margin-bottom: 0px;
      padding: 0px !important;
    }
    #idBenefitImg {
      display: none;
      ${(props) =>
        props.showDivBenefits == false && "display: none !important;"};
      height: 290px;
    }
  }
  @media only screen and (min-width: 377px) and (max-width: 450px) {
    flex-direction: column;
    flex-wrap: nowrap;
    .headerLogo {
      display: none !important;
    }
    .benefitImg {
      width: 100%;
      height: 290px;
    }
    .rec-carousel-wrapper {
      height: 248px;
    }
    .rec-pagination {
      margin-bottom: 16px;
    }
    #app-main-content-steps {
      display: flex;
      flex-direction: column-reverse;
    }
    #idDivContentStep {
      margin-bottom: 8px;
    }
    #idBenefitImg {
      display: none;
      ${(props) =>
        props.showDivBenefits == false && "display: none !important;"};
      height: 290px;
    }
    #idContainerHeader {
      height: 24px;
      margin-bottom: 24px !important;
    }
    .Card-module_card-component__xGCGj {
      box-sizing: none !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      margin: 0px !important;
      padding: 0px !important;
    }
    .Container-sc-1k9kuuz-0 {
      margin: 0px !important;
      padding: 0px !important;
      width: 100% !important;
    }
    .Card-module_card-body__Jgo9P {
      margin-bottom: 0px;
      padding: 0px !important;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 700px) {
    flex-direction: column;
    flex-wrap: nowrap;
    
    .benefitImg {
      width: 100%;
      height: 400px !important;
    }
    .headerLogo {
      display: none;
    }
  }

  @media only screen and (min-width: 700px) and (max-width: 1000px) {
    .headerLogo {
      display: none !important;
    }
    flex-direction: column;
    flex-wrap: nowrap;
    #benedfitid_content {
      flex-flow: column nowrap !important;
      display: flex !important;
      justify-content: space-between !important;
      mix-blend-mode: normal;
    }
    div.headerMobile {
      display: flex !important;
      margin-left: 40px !important;
      margin-right: 40px !important;
      margin-top: 32px !important;
    }
    #idBenefitImg {
      display: flex;
      ${(props) =>
        props.showDivBenefits == false && "display: none !important;"};
    }
    .benefitImg {
      width: 100%;
      height: 400px !important;
      justify-content: space-between !important;
    }
    .rec-carousel-wrapper {
      height: 268px;
      margin-bottom: 32px !important;
    }
    #idDivPage {
      flex: 62.184210526;
      display: flex;
    }
    .rec-slider-container {
      margin: 0 0px !important;
    }
    .DbenefitContent {
      width: 100% !important;
      margin-left: 56px !important;
      margin-right: 56px !important;
    }

    #idDivContentStep {
      margin-bottom: 0px;
      #wrapperStepsId {
        width: 100%;
      }
    }
    #idContainerHeader {
      width: 704px !important;
      height: 24px;
      margin-bottom: 24px !important;
    }
    #idContainerStepsPage {
      max-width: 100% !important;
    }
    .Container-sc-1k9kuuz-0 {
      margin: 0px auto;
      padding: 0px !important;
      width: 704px !important;
    }
    .Card-module_card-component__xGCGj {
      box-sizing: none !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      margin: 0px !important;
      padding: 0px !important;
      width: 704px !important;
    }
    .Card-module_card-body__Jgo9P {
      margin-bottom: 0px;
      padding: 0px !important;
      width: 704px !important;
    }
  }

  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    #idContainerHeader {
      height: 24px;
      margin-bottom: 56px !important;
    }
    #idHeader {
      height: 24px;
      margin-bottom: 0px !important;
    }
    .Container-sc-1k9kuuz-0 {
      padding: 0px !important;
    }
    .Card-module_card-component__xGCGj {
      box-sizing: none !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      margin: 0px !important;
      padding: 0px !important;
    }
    .Card-module_card-body__Jgo9P {
      margin-bottom: 0px;
      padding: 0px !important;
    }
    .headerLogo {
      margin-top: 40px;
      margin-left: 40px;
    }
    #benedfitid_content {
      justify-content: space-between !important;
    }
    #idBenefitImg {
      top: 0px !important;
      position: fixed !important;
      width: 33% !important;
    }
    #idDivPage {
      position: relative !important;
      margin-left: 445px !important;
    }
  }
  @media only screen and (min-width: 1001px) and (max-width: 1300px) {
    #idDivPage {
      margin-left: 341px !important
    }
  }
  @media only screen and (min-width: 1728px) {
    #wrapperStepsId {
      width: 100%;
    }
    #idContainerHeader {
      height: 24px;
      margin-bottom: 56px !important;
      max-width: 1000px;
    }
    #idHeader {
      height: 24px;
      margin-bottom: 0px !important;
    }
    .Container-sc-1k9kuuz-0 {
      margin: 0px !important;
      padding: 0px !important;
      max-width: 1000px;
    }
    .Card-module_card-component__xGCGj {
      box-sizing: none !important;
      border-radius: 0px !important;
      box-shadow: none !important;
      margin: 0px !important;
      padding: 0px !important;
    }
    .Card-module_card-body__Jgo9P {
      margin-bottom: 0px;
      padding: 0px !important;
    }
    .headerLogo {
      margin-top: 48px;
      margin-left: 72px;
    }
    #benedfitid_content {
      justify-content: space-between !important;
    }
    #idBenefitImg {
      top: 0px !important;
      position: fixed !important;
      width: 600px !important;
    }
    #idDivPage {
      position: relative !important;
      margin-left: 565px !important;
    }
  }

  //especial
  @media only screen and (max-width: 450px) {
    .benefitImg {
      justify-content: space-between !important;
      left: 0px !important;
      top: 58px !important;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;

  @media only screen and (max-width: 376px) {
    #app-main-content-steps {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  /* FYAURIBA */
  @media only screen and (min-width: 377px) and (max-width: 700px) {
    #app-main-content-steps {
      display: flex;
      flex-direction: column-reverse !important;
      flex-flow: column;
    }
  }
  @media only screen and (min-width: 700px) and (max-width: 1000px) {
    #app-main-content-steps {
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;

export const DbenefitContent = styled.div`
  width: 73%;
  margin: auto;

  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const DbenefitLogo = styled.div`
  margin-bottom: 24px;
  @media only screen and (max-width: 376px) {
    .logoBenefit {
      width: 40px;
      height: 40px;
    }
  }
  @media only screen and (max-width: 450px) {
    margin-bottom: 8px !important;
    height: 40px;
  }
  @media only screen and (min-width: 1727px) {
    .logoBenefit {
      width: 64px !important;
      height: 64px !important;
    }
  }
`;

export const Pbenefits = styled.p`
  font-family: "FS Emeric";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #ffffff;
  margin-bottom: 32px;

  @media only screen and (max-width: 450px) {
    font-family: "Calibri";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-bottom: 7px;
  }

  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 1727px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const DivBenefits = styled.div`
  flex: 32.576866764;
  min-width: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;

  .headerMobile_1 {
    display: none;
  }
  .rec-pagination {
    margin-top: 0px;
    button {
      background: #ffffff;
      opacity: 0.5;
      box-shadow: none;
    }
    .rec-dot_active {
      background: #ffffff !important;
      opacity: 1 !important;
      display: none !important;
    }
  }
  @media only screen and (max-width: 376px) {
    height: 248px;
    flex-direction: column;
    .rec-slider-container {
      margin-left: 32px;
      margin-right: 32px;
    }

    .headerMobile_1 {
      display: flex;
    }
  }

  @media only screen and (min-width: 377px) and (max-width: 450px) {
    justify-content: space-between !important;

    div.benefit {
      width: 94% !important;
    }

    div.headerMobile {
      display: flex !important;
    }

    .headerMobile_1 {
      display: flex;
    }

    div.logo {
      margin: 12px 12px 12px 12px !important;
    }

    .rec-carousel-wrapper {
      margin-bottom: 16px !important;
    }

    .logoBenefit {
      width: 40px !important;
      height: 40px !important;
    }

    .rec-item-wrapper {
      height: 108px !important;
      margin-bottom: 16px !important;
    }

    .rec-slider-container {
      margin: 0 32px !important;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    height: 268px !important;
    min-height: 268px;

    .headerMobile_1 {
      display: flex;
    }
  }

  @media only screen and (min-width: 1700px) {
    width: 700px;
    min-width: auto;
  }
`;

export const TextStyledMobile = styled.p`
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
`;

export const DivPage = styled.div`
  flex: 67;
  display: grid;
  min-width: 300px;
  .divContenedorInfoCard {
    display: none !important;
    width: 100%;
  }
  @media screen and ${mediaQuery.mobileS}{//, (min-width: 375px) and (max-width: 449px) {
    .divContenedorInfoCard {
      display: flex !important;
      width: 100%;
    }
  }

  @media screen and ${mediaQuery.mobileM} {
    .divContenedorInfoCard {
      display: flex !important;
      width: 100%;
    }
  }
`;

export const DivStep = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  height: 90%;
  justify-content: flex-start;
  flex-wrap: nowrap;

  @media only screen and ${mediaQuery.mobileS} {
    width: 95vw !important;
  }

  @media only screen and (max-width: 370px) {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 32px;
    margin-left: 12px;
    margin-right: 12px;
  }

  /* Medidas responsivas */
  /* Small Devices, Phones */
  @media only screen and (min-width: 371px) and (max-width: 700px) {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 32px;
  }
  @media only screen and (min-width: 700px) and (max-width: 1000px) {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 40px;
    margin-left: 32px;
    margin-right: 32px;
  }
  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    width: 636px;
  }
  @media only screen and (min-width: 1728px) {
    width: 816px;
  }
`;

export const DivHeader = styled.div`
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.08);

  #idDivBtn {
    width: 24px;
    height: 24px;
    background: url(${flecha});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    opacity: 1;
    margin-right: 8px;
  }

  #idDivBtnText {
    width: 34px;
    color: rgba(255, 79, 0, 1);

    font-family: Calibri, sans-serif;
    font-weight: Regular;
    font-size: 16px;
    opacity: 1;
    text-align: left;
  }

  @media only screen and (max-width: 450px) {
    display: flex !important;
    height: 64px;
    width: 100%;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 24px;
    padding-right: 20px;
  }
  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    display: flex !important;
    height: 64px;
    width: 100%;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 24px;
    padding-right: 20px;
  }
`;

export const TextStyledStep0 = styled(Text)`
  color: #ff4f00;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;

export const DivContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;

  div.buttonDiv1 {
    padding-bottom: 32px;
    padding-top: 16px;
  }

  #ButtonDiv1 {
    margin-top: 32px;
    width: 100%;
  }

  @media only screen and (max-width: 450px) {
    margin-bottom: 32px;
    #button1 {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      line-height: 18px;
    }
    #text1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    #linkId {
      padding-left: 5px !important;
    }
  }

  #idContainerHeader {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
  }

  @media only screen and (min-width: 1727px) {
    #text1Id {
      font-size: 18px !important;
      line-height: 20px !important;
    }
  }
`;

export const Disclaimer = styled.div`
  width: 100%;
  height: 389px;
  background: linear-gradient(
    179.9deg,
    #000000 0.08%,
    rgba(0, 0, 0, 0.53985) 32.68%,
    rgba(0, 0, 0, 0) 87.08%
  ) !important;
  transform: rotate(-180deg);
  position: absolute;

  .contentDisclaimer {
    padding: 15px 20px;
    font-family: "Calibri";
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #d6d6d6 !important;
    mix-blend-mode: normal;
    opacity: 0.8 !important;
    transform: rotate(-180deg);
  }

  @media only screen and (max-width: 450px) {
    position: relative;
    height: 155px;
    margin-top: 93px;
    background: none;
    .contentDisclaimer {
      position: absolute !important;
      font-family: "Calibri" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 8px !important;
      line-height: 10px !important;
      color: #d6d6d6;
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 12px;
      .last {
        font-family: "Calibri" !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 8px !important;
        line-height: 9px !important;
        color: #d6d6d6 !important;
      }
      .linkTerms {
        color: #fff;
        font-family: Calibri, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        text-decoration-line: underline;
      }
      span {
        display: block;
      }
    }
  }

  @media only screen and (min-width: 477px) and (max-width: 1000px) {
    position: relative;
    background: none;
    height: 400px;
    .contentDisclaimer {
      position: absolute;
      width: 100%;
      padding: 20px 24px !important;
      font-size: 12px !important;
    }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1366px) {
    bottom: 0;
    .contentDisclaimer {
      position: absolute;
      width: 100%;
      padding: 16px 14px !important;
      font-size: 12px !important;
    }
  }

  @media only screen and (min-width: 1366px) {
    bottom: 0;
    .contentDisclaimer {
      position: absolute;
      width: 100%;
      padding-right: 26px;
      padding-bottom: 20px;
      font-size: 12px !important;
    }
  }
`;
