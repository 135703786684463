import styled from "styled-components";
import { Modal } from "@prima-afp/prima-ui-elements/dist/core";

export const StyledModal = styled(Modal)`
  div {
    margin-top: 60px;
    background-color: initial;
    box-shadow: none;
  }
`;

export const GifBiometric = styled.div`
    width: 636px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    .imgLoading{
      width: 150px;
    }
    .spanGif{
          font-family: Calibri, sans-serif;
          margin-top: 27px;
          width: 100%;
          display: flex;
          justify-content: center;
    }

    @media only screen and (max-width : 450px) { 
      margin-top: 100px;
      width: 100%;
    }
`;

