import { sendToDataLanding } from "../../digital-transfers/constants/sendToDataLayer";

export const redirectURL = (type, objectDataLayer = {}) => {
  if (objectDataLayer.event) sendToDataLanding(objectDataLayer);
  let url;
  if (type === "traspaso") {
    url = `/pasos0`
  } else if (type === "query") {
    url = `/traspasos-digitales/consulta`
  } else if (type === "traspasoAsesor") {
    url = `/asesor/pasos0`
  } else if (type === "cuenta") {
    url = `https://login.prima.com.pe/561a94b0-8b8f-46fb-81a0-d47ea3e463e0/b2c_1a_signin_ciam/oauth2/v2.0/authorize?client_id=33f486d9-39b3-4c2b-bc07-963d9685032c&scope=openID%20https%3A%2F%2Fprimaciab2cprod01.onmicrosoft.com%2Fmepa-api%2FApi.Read.Write.All%20openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Fmiespacio.prima.com.pe%2F&client-request-id=83d9fcfb-6ba8-40e1-98a9-d8313f11f9b1&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.31.0&client_info=1&code_challenge=R1ZC3j7ETpruOAyUnTX-DsaqXNxl58HQDM_XVSIl6E8&code_challenge_method=S256&nonce=af254650-132f-4c62-95fc-b04aac40e3db&state=eyJpZCI6IjllYTgyMWU5LTgyOGMtNDkwMS04MjUzLTBiZmE3YjAwMjQzNiIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D`
  }
  if(url) window.open(url, "_blank", "noopener noreferrer")
}
