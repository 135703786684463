import React, { useEffect, useState } from 'react';
import { MainContainer } from '../../components/MainContainer';
import { Text } from '../../../shared/components/Text';
import FinishIcon from '../../assets/images/solicitud_finalizada.svg';
import { Button } from '@prima-afp/prima-ui-elements/dist/core';
import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext'; 
import { HeaderStep } from '../../../App/pages/layout-con-asesor/HeaderSteps';
import { Container } from '@prima-afp/prima-ui-elements/dist/layouts';
import { ContainerFinishPage } from '../Style';
import { useHistory } from 'react-router-dom';
import { sendToData } from '../../constants/sendToDataLayer';
import { updateUrl } from '../../constants/updateUrl';


export const FinishPageAF = () => {

    const dispatch = useDispatchDigitalTransfersContext();
    const { userWeb  } = useStateDigitalTransfersContext();
    const history = useHistory();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 445);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 445);
    };

    useEffect(() => {
        // Agregamos el event listener cuando el componente se monta
        window.addEventListener('resize', handleResize);
    
        // Limpiamos el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []); 

    useEffect(() => {
        const currentUrl = window.location.href;
        if(userWeb.documentType != "00" || (userWeb.documentNumber == null || userWeb.documentNumber == "") ){
            if (window.location.href.includes('asesor')) {
                updateUrl(history, currentUrl,'/asesor/pasos0');
            }else{
                updateUrl(history, currentUrl,'/pasos0');
            }
        }else{
            localStorage.clear();
            dispatch({ type: 'INITIAL_STATE', });
            dispatch({ type: 'FIELD', payload: { field: 'showDivBenefits', value: false } });
            dispatch({ type: 'FIELD', payload: { field: 'stepCurrent', value: 0 } });
        }
    }, []);

    const sendToGTM = (eventNum) =>{
        switch (eventNum) {
            case 1:
                var currentUrl = window.location.href;
                var payload = {
                    section: 'paso 5', 
                    option: ("volver al inicio"),
                };
                sendToData(currentUrl, "button_click", "button_click", payload);  
            break;
        }
    };

    return (
        <>
            {
                !isMobile &&<Container id="idContainerHeader">
                    <HeaderStep />
                </Container>
            }
            <MainContainer>

                <ContainerFinishPage id="idContainerFinishPage">

                        <img id="iconFinish" src={FinishIcon} alt="finish icon" />
         
                        <Text id="text1Finish">Gracias por ingresar tu solicitud</Text>

                        <Text id="text3Finish">Recibirás un correo electrónico confirmando la información registrada.</Text> 
          
                        <Text id="text3Finish"> Puedes revisar el estado de tu solicitud <a style={{paddingLeft: '5px'}} href={`${process.env.REACT_APP_BASE_URL_TRASPASO}`+"/traspasos-digitales/consulta"}  rel="noreferrer"> aquí</a></Text>

                        <div style={{ marginTop: '16px'}}>
                            <a href="https://www.prima.com.pe/public-zone/" onClick={()=>sendToGTM(1)}  rel="noreferrer"><Button className={'butFinish'}>Volver al inicio</Button></a>
                        </div>

                </ContainerFinishPage>

            </MainContainer>

        </>
    );
};
