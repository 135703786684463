import React, {useState, useEffect, useRef} from 'react';
import { MainContainer } from '../../components/MainContainer';
import { useConfigBiometric } from '../../hooks/useConfigBiometric';
import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext';
import { HeaderStep } from '../../../App/pages/layout-con-asesor/HeaderSteps';
import { Container } from '@prima-afp/prima-ui-elements/dist/layouts';

import { Step3Wrapper } from '../../componentes-sin-asesor/StepsRecognition/styles';
import info from '../../componentes-sin-asesor/StepsRecognition/resources/info.svg';
import ApprovedImg from '../../assets/images/aprobed.svg';
import AlertImg from '../../assets/images/alertRed.svg';
import ResetImg from '../../assets/images/reset.svg';
import { ButtonsContainer } from '../../componentes-sin-asesor/StepIdentity/styles';
import { Text } from '../../../shared/components/Text';
import { Button } from '@prima-afp/prima-ui-elements/dist/core';
import { useHistory } from "react-router-dom";
import { FPhi as FPhiSelphi } from '@prima-afp/selphi-widget-component';
import { ContainerCaptureSelfieDigitalTransfers } from '@prima-afp/prima-security/module/component';
import { BiometricWrapper } from '../Style';
import { useCatchFetch } from '../../hooks/useCatchFetch';
import { Abandon } from "../../services";
import {WhatsApp} from '../../components/BtnWhatsapp/index';
import {sendToData} from '../../constants/sendToDataLayer';
import {updateUrl} from '../../constants/updateUrl';


export const BiometricSelfiePageAF = () => {

    const config = useConfigBiometric();
    const dispatch = useDispatchDigitalTransfersContext();
    const { showLoading, imageBiometric, biometricVerified, userWeb, AttemptsQ, urlFlow, lastStep, stepCurrent, messagePage  } = useStateDigitalTransfersContext();
    const history = useHistory();
    const enabledModal = useRef(false);
    const [textAttempts, setTextAttempts] = useState('Tienes 3(tres) intentos.');
    const [textAttemptsQ, setTextAttemptsQ] = useState('3 intentos');
    const { SendFetch, SetLoading } = useCatchFetch();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 445);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 445);
    };

    useEffect( () => {
        console.log('enabledModal,', enabledModal);
    }, [enabledModal]);

    useEffect(() => {
        // Agregamos el event listener cuando el componente se monta
        window.addEventListener('resize', handleResize);
    
        // Limpiamos el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []); 
    
    useEffect(() => {
        const currentUrl = window.location.href;
        if(userWeb.documentType != "00" || (userWeb.documentNumber == null || userWeb.documentNumber == "") ){           
            if (window.location.href.includes('asesor')) {
                updateUrl(history, currentUrl,'/asesor/pasos0');
            }else{
                updateUrl(history, currentUrl,'/pasos0');
            }
        }
    }, []); 

    useEffect(() => {
        if (AttemptsQ == 2){
            setTextAttempts('Tienes 2(dos) intentos.');
            setTextAttemptsQ('dos intentos');
        } 
        if (AttemptsQ == 1){
            setTextAttempts('Tienes 1(un) intento.');
            setTextAttemptsQ('un intento');
        } 
    }, [AttemptsQ]);
  
    
    const getValue = (str, currentURL) => {
        const startIndex = currentURL.indexOf(str);
        if (startIndex !== -1) {
          // Encontrar la posición de "&" después de "utm_source="
          const endIndex = currentURL.indexOf("&", startIndex);    
          // Extraer la subcadena entre "utm_source=" y "&"
          const strValue = endIndex !== -1 ? currentURL.substring(startIndex + str.length, endIndex) : currentURL.substring(startIndex + str.length);
          return strValue;
        } else {
          return "not set";
        }       
    };

    const CompleteStep = () => { 
        if (!biometricVerified) return;
        dispatch({
            type: 'COMPLETE_BIOMETRIC_STEP',
        });
        dispatch({ type: 'FIELD', payload: { field: 'showDivBenefits', value: true } });
        
        const currentUrl = window.location.href;
        var payload = {
            section: "paso 3", option: "continuar",
            count_3: 1, 
            dni: userWeb.documentNumber,
            telefono: userWeb.phoneNumber,
            email: userWeb.email,};
        sendToData(currentUrl, "step", "paso_3", payload,urlFlow );  
        if (lastStep < 4) {  dispatch({ type: 'FIELD', payload: { field: 'lastStep', value: 4 } });}   
        window.location.href.includes('asesor') ? updateUrl(history, currentUrl,'/asesor/pasos4') : updateUrl(history, currentUrl,'/pasos4');               
    };

    const reset =()=>{
        dispatch({ type: 'FIELD', payload: { field: 'biometricVerified', value: false } });
        dispatch({ type: 'FIELD', payload: { field: 'imageBiometric', value: null } });
        const currentUrl = window.location.href;
        var payload = {
            section: 'paso 3',             
            message: messagePage,
            option: ("reintentar"),
        };
        payload.dni =  userWeb.documentNumber;
        sendToData(currentUrl, "button_click", "button_click", payload);  
    };

    const backReco = async (type)=>{
        enabledModal.current= true;
        const currentUrl = window.location.href;
        if(type =='clickBtnNoCamera'){
            var payload = { section: "paso 3", message: "necesitamos acceder a tu camara", option:"entendido"};
            sendToData(currentUrl, "button_click", "button_click", payload);  
            var BodyPayload = { step: 4, documentNumber: userWeb.documentNumber, documentType: "00", active: true };
            var abandonRes = await SendFetch(Abandon(BodyPayload));
            if(abandonRes.code == 1){
              window.location.reload(true);
            }
        }else if(type =='ShowModalNoCamera'){
            var payload2 = { section: "paso 3", message: "necesitamos acceder a tu camara", option:"entendido"};
            sendToData(currentUrl, "mensaje_click", "mensaje", payload2);  
        }        
    };

    useEffect(() => {
        // Agregar un event listener cuando el componente se monta
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Eliminar el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [imageBiometric , biometricVerified]);
    

    const handleBeforeUnload = async (e) =>{
        if(enabledModal.current == false){
            const message = "¿Seguro que quieres salir?";
            e.returnValue = message;
            const userResponse = window.confirm(message);
            try {
                var currentStep = 0;
                if(imageBiometric == null && biometricVerified  ==false){currentStep = 4;}
                if(imageBiometric != null && (biometricVerified  ==false || biometricVerified  ==true )){currentStep = 5;}
                var BodyPayload = { step: currentStep, documentNumber: userWeb.documentNumber, documentType: "00", active: true };
                var abandonRes = await SendFetch(Abandon(BodyPayload));
            } catch (error) {
                console.error('error:', error);
            } 
        }  
    };

    return (
        <>
            {
                !isMobile &&<Container id="idContainerHeader">
                    <HeaderStep />
                </Container>
            }
            <MainContainer id="mainid">
            {
                (imageBiometric === null && !biometricVerified) &&
                <>
                    <BiometricWrapper id="wrapperid">
                            <p className='titleStep3'>Firma tu solicitud con una foto</p>
                            <div className='wrapperWidgetSelfie'>
                                <ContainerCaptureSelfieDigitalTransfers config={config} loading={showLoading} biometric={{ FPhiSelphi }} backNoCamera={backReco}/>                             
                            </div>
                            <div className='alertStep3'>
                                <div className='icon'> <img src={info} style={{width: '24px' , height: '24px'}} alt="infoIcon"/>  </div>
                                <span className='alertText'> {textAttempts}</span>
                            </div>             
                    </BiometricWrapper> 
                </>


            }

            {
                (imageBiometric && biometricVerified) &&
                <>
                    <Step3Wrapper>
                        <div className="wrapperApproved">
                            <img src={ApprovedImg} alt="approved" className='imgApproved' />
                            <Text color="var(--greenColor)" weight="600" size="24" className="msgApproved">¡Hemos confirmado tu identidad!</Text>
                            <span className="spanApproved">Puedes continuar con tu trámite</span>
                            <ButtonsContainer className="buttonSend">
                                <Button id="btn-biometric-send"  disabled={!biometricVerified} onClick={CompleteStep}>
                                    Continuar 
                                </Button>
                            </ButtonsContainer>
                        </div>
                    </Step3Wrapper>
                </>
            }

            {
                (imageBiometric && !biometricVerified && !showLoading) &&
                <>
                    <Step3Wrapper>
                        <div className="wrapperAlert">
                            <img src={AlertImg} alt="alert" className='imgAlert'/>

                            <Text color="#696158" weight="600" className="msgAlert" >{messagePage}</Text>

                            {
                                AttemptsQ != null && <Text color="#696158" weight="400" size="16" style={{marginBottom: '24px'}}><b>Tienes {textAttemptsQ} más</b> para validar tu identidad.</Text>
                            }
                            <Button id="btn-biometric-retry" className="buttonReset" onClick={reset}>
                                <div style={{display: 'flex'}} >
                                    <img src={ResetImg} alt="reset" />
                                    <Text color="#fff" style={{marginLeft: '8px'}}>Reintentar</Text>
                                </div>
                            </Button>
                    
                        </div>
                    </Step3Wrapper>
                </>

            }
            
            </MainContainer>


            <WhatsApp />

        </>
    );
};
