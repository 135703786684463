/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import './styles.scss';
import CustomSelect from '../CustomSelect';
import close from '../../../assets/images/close.svg';
import {
  useDispatchDigitalTransfersContext
} from "../../../context/DigitalTransfersContext";

export const allColors = {
    colorBlack: '#000000',
    colorGrayBorder: '#E8E8E8',
    colorGrayComponents: '#F9F9FB',
    colorGrayHover: '#F4F4F4',
    colorGrayDisabled: '#BBBBBB',
    colorGrayCard: '#F7F7F8',
    colorGrayText: '#696158',
    colorGrayCardDashed: '#D7D9D9',
    colorGrayLineDashed: '#BABABA',
    colorGrayStep: '#A29D98',
    colorGreen: '#00A499',
    colorGreensuccess: ' #00ae12',
    colorGreenBalance: '#00AE99',
    colorGreenHover: '#059F8D',
    colorGreenSelectCard: '#EEFBF9',
    colorPinkCardBackground: '#ffe5d9',
    colorOrangeHover: '#EA4800',
    colorOrangeMain: '#FF4F00',
    colorOrangeMainActive: '#F04C02',
    colorRedError: '#FF4B4B',
    colorTooltip: '#C4C4C4',
    colorWhiteBase: '#FFFFFF',
    colorPlaceholder: '#9D9994',
    colorGrayLight: '#AAA9A9',
    colorBlueInfo: '#0049bf',
};

const DropdownContainer = styled.div`
  padding-top: ${props => props.noPadding || '1em'};
  padding-bottom: ${props => props.noPadding || '0.7em'};
  width: ${props => props.percentageWidth || 100}%;
  height: 48px;
  .md-select {
    max-width: 100% !important;
}
.disabled{
  background-color: #ECECEC !important;
}
  .btn-close{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
  }

  @media only screen and (max-width : 450px){
    .btn-close{
      // padding-right: 12px;
    }
  }
`;

const SpanError = styled.div`
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #FF4646;
  position: relative;
  top: -18px;
  left: 8px;
`;

export default function DropdownInput({
  containerStyles = {},
  percentageWidth,
  label,
  placeholder,
  selectOptions = [],
  onChange,
  noPadding = false,
  selectWidth = '104px',
  name = '',
  maxlength= 500,
  registerSelect,
  selectName = '',
  onlyNumbers = false,
  defaultValueSelect = '',
  defaultValueInput = '',
  className = '',
  resetInputWhenSelectChange = false,
  capitalizeInput = false,
  error,
  errorText,
  clearButon = false,
  disabled = false,
  reset = false
}) {
    const dispatch = useDispatchDigitalTransfersContext();
    const [labelColor, setLabelColor] = useState(allColors.colorPlaceholder);
    const [inputColor, setInputColor] = useState(allColors.colorGrayLight);
    const [borderWidth, setBorderWidth] = useState('1px');

    const [styleLabel, setStyleLabel] = useState({});
    const [labelValue, setLabelValue] = useState(label);
    const [labelValueOriginal, setLabelValueO] = useState(label);
    const [placeValue, setPlaceValue] = useState('');

    const [inputValue, setInputValue] = useState('');
    const [selectValue, setSelectValue] = useState();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); 

    useEffect(() => {
      if(windowWidth < 300){
        setLabelValue(label.substring(0, 8));
      }else{
        setLabelValue(labelValueOriginal);
      }
    }, [windowWidth]); 

    useEffect(() => {
      if (error === true){
        setLabelColor(allColors.colorRedError);
        setInputColor(allColors.colorRedError);
        setBorderWidth('2px');
      } 
    }, [error]);  //useEffect para manejo de errores

    useEffect(() => {
      if (onChange) onChange(getComponentState());
    }, [inputValue]);

    useEffect(() => {
      if (reset) {
        setInputValue("");
      }
    }, [reset]);

    useEffect(() => {
      setInputValue(defaultValueInput);
      if(defaultValueInput.length >0){
        setStyleLabel(
          {
            position: 'relative',
            fontFamily: 'Calibri',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            height: '15px',
            zIndex: '1',
            top: '-67px',
            left: '0px',
            color: '#AAA9A9',
            padding: '0 10px',
            pointerEvents: 'none',
            transition: 'ease-in-out 0.2s all',
          }
        );
      }else{
        setStyleLabel(
          {
            position: 'relative',
            fontFamily: 'Calibri',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '20px',
            height: '15px',
            zIndex: '1',
            top: '-35px',
            left: '110px',
            color: '#B4B4B4',
            padding: '0 10px',
            pointerEvents: 'none',
            transition: 'ease-in-out 0.2s all',
          }
        );
      }

    }, []); //setear variable por defecto

    const getComponentState = () => ({
        target: {
          name,
          value: inputValue,
          type: 'text'
        }
    });

    const handleOnFocus = () => {
        setLabelColor(allColors.colorOrangeMain);
        setInputColor(allColors.colorOrangeMain);
        setBorderWidth('2px');
        setPlaceValue(placeholder);
        setStyleLabel(
          {
            position: 'relative',
            fontFamily: 'Calibri',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            height: '15px',
            zIndex: '1',
            top: '-67px',
            left: '0px',
            color: allColors.colorOrangeMain,
            padding: '0 10px',
            pointerEvents: 'none',
            transition: 'ease-in-out 0.2s all',
          }
        );
    };

    const handleOnBlur = () => {
        setLabelColor(inputValue ? allColors.colorGrayLight : allColors.colorPlaceholder);
        setInputColor(allColors.colorGrayLight);
        setBorderWidth('1px');
        setStyleLabel(
          {
            position: 'relative',
            fontFamily: 'Calibri',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '20px',
            height: '15px',
            zIndex: '1',
            top: '-35px',
            left: '110px',
            color: '#B4B4B4',
            padding: '0 10px',
            pointerEvents: 'none',
            transition: 'ease-in-out 0.2s all',
          }
        );
        if(inputValue.length >0){
          setStyleLabel(
            {
              position: 'relative',
              fontFamily: 'Calibri',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '14px',
              lineHeight: '20px',
              height: '15px',
              zIndex: '1',
              top: '-67px',
              left: '0px',
              color: '#AAA9A9',
              padding: '0 10px',
              pointerEvents: 'none',
              transition: 'ease-in-out 0.2s all',
            }
          );
        }else{
          setPlaceValue('');
        }
    };
    const [inputText, setInputText] = useState(false);
    const handleChange = (event) => {
      const valNumbers =  /^[0-9\b]+$/;
      (event.target.value == "")? setInputText(false) : setInputText(true);
      if (!onlyNumbers) return setInputValue(capitalizeInput ? event.target.value.toUpperCase() : event.target.value);
      if (valNumbers.test(Number(event.target.value))) return setInputValue(event.target.value);
    };

    const clear = () => {
      setInputText(false);
      setInputValue("");
      dispatch({
        type: 'FIELD',
        payload: {
            field: 'showQueryResponse',
            value: false
          }
      });
    };
   
  return (
    <DropdownContainer style={containerStyles} noPadding={noPadding} percentageWidth={percentageWidth}>
         <div style={{ borderColor: inputColor, borderLeftStyle: 'solid', borderWidth: borderWidth}} className={`group_custom ${className}`} data-testid='input-field'>     
         
           <CustomSelect
             fontFamily={'FS Emeric'}
             register={registerSelect}
             onChange={setSelectValue}
             initialValue={defaultValueSelect}
             selectOptions={selectOptions}
             selectWidth={selectWidth}
             optionsContainerStyles={{ marginTop: '50px'}}
             name={selectName}
            />  
           
            <input
              id={`input-dropdown-${name}`}
              className={`dropdown-input ${disabled? "disabled": ""}`}
              aria-labelledby={`input-dropdown-${name}`}
              style={{ borderColor: inputColor, borderLeftStyle: 'solid', borderLeftWidth: borderWidth, width:'65%', backgroundColor: 'transparent'}}
              placeholder={placeValue}
              value={inputValue}
              onChange={handleChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              type={'text'}
              name={name}
              maxLength={maxlength}
              disabled = {disabled}
            />  
            {
              clearButon && inputText && <div className='btn-close' >
                <img style={{
                  textAlign: 'end',
                  cursor: 'pointer'
                }} src={ close } onClick={ clear } />
              </div> 
            }
                 
        </div>

        <span style={styleLabel}>{labelValue}</span>
        { error && <SpanError>{errorText}</SpanError>}
    </DropdownContainer>
  );
}