import * as React from "react";
import reco1 from './reco1.gif';
import reco2 from './reco2.gif';
import reco3 from './reco3.gif';
import {WrapperReco} from './styles';
import { useState, useEffect } from "react";

export default function SelfieRecomendations({type}) {

  const [title, setTitle] = useState('Tu selfie se validará solo para esta solicitud. Por favor, sigue las recomendaciones:');
  useEffect(() => {       
    type == 'testAB' ? setTitle('Consejos para tomarte la foto'): null;
  }, []);

  return (
    <WrapperReco>
      <div className={type == 'testAB' ? 'ABtext1Reco': "text1Reco"  }>
        {title}
      </div>
      <div className="containerReco">
        <div className="tableReco">
          <div className="column0">
            <div className="contentColumn">
              <img
                loading="lazy"
                src={reco1}
                className={type == 'testAB' ? "imgReco testABimg": "imgReco" }
              />
              <div className="textReco">
                Busca un lugar iluminado que tenga un fondo plano
              </div>
            </div>
          </div>
          <div className="column0">
            <div className="contentColumn">
            <img
                loading="lazy"
                src={reco2}
                className={type == 'testAB' ? "imgReco testABimg": "imgReco" }
              />
              <div className="textReco">
                Tu rostro debe estar centrado, descubierto y sin lentes
              </div>
            </div>
          </div>
          <div className="column0">
            <div className="contentColumn">
              <img
                loading="lazy"
                src={reco3}
                className={type == 'testAB' ? "imgReco testABimg": "imgReco" }
              />
              <div className="textReco">
                Evita moverte y mira de frente a la cámara
              </div>
            </div>
          </div>
        </div>
      </div>
    </WrapperReco>
  );
}


