import styled from "styled-components";

export const WraperCard = styled.div`
    @media only screen and (max-width : 450px) { 
      display:none
    }
    @media only screen and (min-width : 451px) { 
        width: 635px;
    }
    display: flex;
    justify-content: space-around;
    .cardNoSelected {
        cursor: pointer;
        border-radius: var(--Radius-Radius-16, 16px);
        border-color: rgba(231, 231, 232, 1);
        border-style: solid;
        border-width: 2px;
        display: flex;
        max-width: 200px;
        flex-direction: column;
        justify-content: center;
      }
      .cardSelected{
          cursor: pointer;
          border-radius: var(--Radius-Radius-16, 16px);
          border-color: rgba(0, 204, 204, 1);
          border-style: solid;
          border-width: 2px;
          display: flex;
          max-width: 200px;
          flex-direction: column;
          justify-content: center;
      }
      .cardContent {
        border-radius: 16px;
        background-color: var(--Background-White-Default, #fff);
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 20px 24px;
      }
      .cardImgContainer {
        display: flex;
        gap: 20px;
        justify-content: space-between;
      }
      .cardImg {
        aspect-ratio: 1;
        object-fit: auto;
        object-position: center;
        width: 56px;
      }
      .circleNoSelected {
        border-radius: var(--Radius-Radius-full, 999px);
        border-color: rgba(231, 231, 232, 1);
        border-style: solid;
        border-width: 2px;
        background-color: var(--Background-White-Default, #fff);
        align-self: start;
        margin-top: 4px;
        width: 24px;
        height: 24px;
      }
      .cardLabel {
        color: var(--Text-Gray-Default, #4e5258);
        font-variant-numeric: lining-nums proportional-nums;
        letter-spacing: 0.02px;
        margin-top: 8px;
        font: 600 16px/150% Lexend, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      .cardContent {
        color: var(--Prima-Gris, #696158);
        margin-top: 8px;
        font: 400 16px/20px Calibri, -apple-system, Roboto, Helvetica,
          sans-serif;
      }
      .circleSelected{
          border-radius: var(--Radius-Radius-full, 999px);
          border-color: rgba(0, 204, 204, 1);
          border-style: solid;
          border-width: 7px;
          background-color: var(--Background-Secondary-Subtle, #e6fafa);
          align-self: start;
          margin-top: 4px;
          width: 24px;
          height: 24px;
      }
`;