import React from 'react';
import PublicTransactionsAppRouter from './router/PublicTransactionsAppRouter';

import { GlobalStyle } from '@prima-afp/prima-ui-elements/dist/layouts';

const PublicTransactionsApp = () => {
  return (
    <GlobalStyle>
      <PublicTransactionsAppRouter />
    </GlobalStyle>
  );
};
export default PublicTransactionsApp;
