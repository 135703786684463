import styled from "styled-components";

export const DivWhats = styled.div`
    position: absolute;
    // z-index: 2247483646!important;
    .element {
        // background-color: #f0f0f0;
        overflow: hidden;
        transform: translate(0%);
        transition: width 1s ease; 
        opacity: 1;
    }

    .hidden {
        width: 0;
        opacity: 0;
    }



    .contenedor {
        position: fixed;
        right: 26px;
        bottom: 28px !important;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 40px;
        box-sizing: border-box;
      }

      .container{
        position: relative;
      }
     
      .boton-x {
        position: absolute;
        cursor: pointer;
        left: -14px;
        top: -14px;
      }
      
      .boton-contacto {
        border-radius: 16px;
        background: #4FCC5D;
        color: #FFF;
        text-align: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: FS Emeric, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding: 8px 64px 8px 24px;
        cursor: pointer;
        height: 40px;
        display: flex;
      }
      
      .boton-whatsapp img {
        position: absolute;
        cursor: pointer;
        right: -14px;
        bottom: -13px;
      }
     

      @media only screen and (max-width: 460px){
        .contenedor {
          right: 18px;
          bottom: 24px !important;
        }
      }
      @media only screen and (min-width: 461px) and (max-width: 1000px) 
      {
        .contenedor {
            //top: 92%;
        }
      }
      @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        .contenedor {
          //top: 93%;
        }
      }
      @media only screen and (min-width : 1728px) {
    
      }

`;