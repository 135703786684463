import PropTypes from "prop-types";
import React from "react";
import { Container } from "./styles";
import checkGreen from "../../../digital-transfers/assets/images/checkGreen.svg";
import errorIcon from "../../../digital-transfers/assets/images/error.svg";
import { Text } from "../../../shared/components/Text";
import { Input } from "@prima-afp/prima-ui-elements/dist/core";

export const OtpInput = ({ value = "", SetCode, flagVerifiedCode }) => {
  const onChange = (e) => {
    SetCode(e);
  };

  return (
    <>
      <div id="wrapperVericationSMS" style={{ display: "flex", flexDirection: 'column'}}>
        <Container id="verificationSMS">
          <Input
            id="idsms"
            label="Ingresa el código de validación"
            placeholder="Ingresa el código de validación"
            className="inputVerification"
            type="text"
            maxLength="6"
            value={value}
            onChange={(e) => onChange(e.target.value.replace(/[^0-9]/g, ""))}
            error={flagVerifiedCode == false ? false : null}
          />
        </Container>
        {flagVerifiedCode == true && (
          <div className="verifiedDiv">
            <img
              src={checkGreen}
              style={{ width: "16px", height: "16px", marginRight: "8px" }}
            />{" "}
            <Text className="verifiedCode">Código válido </Text>
          </div>
        )}
        {flagVerifiedCode == false && (
          <div className="verifiedDiv">
            <img
              src={errorIcon}
              style={{ width: "16px", height: "16px", marginRight: "8px" }}
            />{" "}
            <Text className="incorrectCode">Código incorrecto o vencido </Text>
          </div>
        )}
      </div>
    </>
  );
};

OtpInput.propTypes = {
  SetCode: PropTypes.func,
  value: PropTypes.string,
  flagVerifiedCode: PropTypes.bool,
};
