import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import { map, prop } from "ramda";
import React from "react";

const PublicRouter = ({ publicModules = [] }) => {
  return (
    <Switch>
      {map(
        (module) => (
          <Route
            path={prop("pathBase", module)}
            key={prop("pathBase", module)}
            component={() => <module.componentRouter />}
          />
        ),
        publicModules
      )}
      <Redirect from={"*"} to={"/"} />
    </Switch>
  );
};

PublicRouter.propTypes = {
  publicModules: PropTypes.array
};

export default PublicRouter;
