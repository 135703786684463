import React, { useState, useRef, useEffect } from 'react';
import { WrapperInput } from './styles4';
import selectArrow from './selectArrow.svg';

export default function SelectPrima({
  listOption = [],
  defaultSelect,
  value,
  saveSelectOption,
  saveValueInput,
  flagError,
  errorMessage,
  status,
  inputType,
  maxLength,
  label,
  type,
  placeholder
}) {
  const [selectedOption, setSelectedOption] = useState({ id: 0, description: label });
  const [inputValue, setInputValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const inputRef = useRef(null);

  const [inputColor, setInputColor] = useState('#E7E7E8');
  const [borderWidth, setBorderWidth] = useState('2px');

  // Seteo de valores
  useEffect(() => {    
    selectedOption.code == 0 ? setSelectedOption({ id: 0, text: label }): null;
    setInputValue(value);
  }, [label, value]);

  // Seteo con el contexto
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Detectar error
  useEffect(() => {
    if (flagError === false) {
      setInputColor('#E7E7E8');
      setBorderWidth('1px');
    } else if (flagError === true) {
      setInputColor('#FF4B4B');
      setBorderWidth('2px');
    }
  }, [flagError]);

  // Detectar clic fuera del componente
  const handleClickOutside = () => {
    setDropdownOpen(false);
  };

  const handleFocus = () => {
    inputRef.current.classList.add('focused');
    setInputColor('#FF4F00');
    setBorderWidth('2px');
  };

  const handleBlur = () => {
    inputRef.current.classList.remove('focused');
    setInputColor('#E7E7E8');
    setBorderWidth('1px');
  };

  const useOutsideClick = (callback) => {
    const ref = React.useRef();
    React.useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);
    return ref;
  };

  const ref = useOutsideClick(handleClickOutside);

  // Detectar cuando se hace clic en el dropdown
  const toggleDropdown = () => {
    if (status !== 'disabled') {
      setDropdownOpen(!dropdownOpen);
    }
  };

  // Guardar opción seleccionada
  const handleClick = (item) => {
    setDropdownOpen(false);
    setSelectedOption(item);
    saveSelectOption(item.code);
  };

  return (
    <WrapperInput className='WrapperInput'>
      <div className="label">{label}</div>
      <div
        className="container"
        style={{
          borderColor: inputColor,
          borderLeftStyle: 'solid',
          borderWidth: borderWidth,
          backgroundColor: status === 'disabled' ? '#E7E7E8' : 'white'
        }}
      >
        <div className="dropdown" onClick={toggleDropdown} style={{ cursor: status === 'disabled' ? 'not-allowed' : 'pointer' }}>
          <div className="dropdownText">{selectedOption.text ? selectedOption.text : selectedOption.description}</div>
          <img className="dropdownIcon" src={selectArrow} />
        </div>
        {dropdownOpen && (
          <div className="list-container" ref={ref}>
            {listOption.map((item) => (
              <div
                key={item.id ? item.id : item.code}
                className="list-item"
                onClick={() => handleClick(item)}
              >
                {item.text ? item.text : item.description}
              </div>
            ))}
          </div>
        )}
      </div>
      {flagError && <span className="spanError">{errorMessage}</span>}
    </WrapperInput>
  );
}