import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { CheckboxGroup } from "./styles";
import { CheckBox } from "@prima-afp/prima-ui-elements/dist/core";
import { sendToData } from "../../constants/sendToDataLayer";
import React, { useState, useEffect } from "react";

export const FrameAuthorization = () => {
  const dispatch = useDispatchDigitalTransfersContext();
  const { userWeb } = useStateDigitalTransfersContext();
  const [show3Checkbox, setShowButton] = useState(true);

  useEffect(() => {
    if (window.location.href.includes("asesor")) {
      setShowButton(false);
    }
  }, []);

  const setValueTC = () => {
    const newValue = !userWeb.tcConfirmation;
    dispatch({
      type: "FIELD",
      payload: {
        field: "confirmationTC",
        value: newValue,
      },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "tcConfirmation",
        value: newValue,
      },
    });
  };
  const setValueADP = () => {
    const newValue = !userWeb.adpConfirmation;
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "adpConfirmation",
        value: newValue,
      },
    });
  };
  
  const setValue3checkbox = () => {
    const newValue = !userWeb.minimumConfirmation;
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "minimumConfirmation",
        value: newValue,
      },
    });
  };

  const sendInfo = (option) => {
    const currentUrl = window.location.href;
    var payload = {
        section: 'paso 1', 
        option: option,
    };
    sendToData(currentUrl, "link_click", "link_click", payload);  
  };
  const OpenModal_TC = () => {
    const title = "Cláusula de protección de datos informativa";
    const type = "TC";
    dispatch({
      type: "SHOW_TCMODAL",
      payload: {
        title: title,
        type: type,
        changeLoading: false,
        value: userWeb.tcConfirmation,
      },
    });
    sendInfo("terminos y condiciones y el aviso de privacidad");
  };

  const OpenModal_ADP = () => {
    const title = "Cláusula de protección de Datos Usos Adicionales";
    const type = "ADP";
    dispatch({
      type: "SHOW_TCMODAL",
      payload: {
        title: title,
        type: type,
        changeLoading: false,
        value: userWeb.adpConfirmation,
      },
    });
    sendInfo("uso de mis datos personales");
  };

  const openPagePolitica = ()=>{
    window.open("https://www.prima.com.pe/public-zone/politica-de-privacidad/#tratamiento-y-transferencia-de-tus-datos-personales", "_blank", "noopener");
  };

  return (
    <>
      <CheckboxGroup>
        <div style={{ display: "flex", paddingBottom: "16px" }}>
          <div style={{ display: "flex" }}>
            <CheckBox
              checked={userWeb.tcConfirmation === true}
              onChange={setValueTC}
            ></CheckBox>
            <label className="textCheckBox">
              <span onClick={setValueTC}>Acepto los </span>
              <span onClick={OpenModal_TC} className="textBold">
              <b> Términos y Condiciones</b>
              </span>{" y declaro haber leído la "}

              <span className="textBold"><b> <a onClick={openPagePolitica}>Política de Privacidad de Prima AFP</a></b></span>
              {' '}(Obligatorio)
            </label>
          </div>
        </div>

        <div style={{ display: "flex", paddingBottom: '16px' }}>
          <CheckBox
            checked={userWeb.adpConfirmation === true}
            onChange={setValueADP}
          ></CheckBox>
          <label className="textCheckBox">
            <span onClick={setValueADP} data-testid="setValueADP">Autorizo los </span>

            <span onClick={OpenModal_ADP} className="textBold" data-testid="OpenModal_ADP">
              <b> usos adicionales</b>
            </span>

            <span onClick={setValueADP} data-testid="setValueADP" style={{paddingLeft: '5px'}}>de mis datos personales</span>
          </label>
        </div>
      </CheckboxGroup>
    </>
  );
};
