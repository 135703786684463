import styled from 'styled-components';

export const TimelineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const CircleDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  `;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${({ color }) => (color ? '#fff' : '#C5C7C8')}; 
  border: 1px solid ${({ color }) => (color ||'#C5C7C8')};
  background-color: ${({ color }) => (color ? color : '#fff')};
  font-weight: bold;
  font-family: Calibri;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0012em;
  text-align: center;

`;

export const Line = styled.div`
  width: 109px;
  height: 1px;
  margin: 0 8px;
  margin-top: 13.5px;
  background-color: ${({ color }) => (color === "#04A6A2"? color : '#C5C7C8')};
`;

export const Description = styled.div`
  margin-left: 8px;
  white-space: nowrap;
  color: ${({ color }) => (color ||'#C5C7C8')};
  font-weight: ${({ status }) => (status === 'cancelada' ? 'bold' : 'normal')};
  font-family: Lexend;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0012em;
  text-align: left;
`;

export const DateStep = styled.div`
  color: #8F9295;
  font-family: Calibri;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0012em;
  text-align: left;

`;