import React from "react";
import { HeadBandContainer } from "./style";
import { likeDark } from "../../../assets";

const Headband = () => {
  return (
    <HeadBandContainer>
      <div className="container">
        <img src={likeDark} alt="like" />
        <p className="text">
          <span className="bold">
            Somos la AFP con más traspasos a septiembre 2024
          </span>{" "}
          (Boletín SBS, julio 2024). ¿Qué esperas?
        </p>
      </div>
    </HeadBandContainer>
  );
};

export default Headband;
