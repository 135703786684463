import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useDispatchDigitalTransfersContext } from "../../../digital-transfers/context/DigitalTransfersContext";
import { TermCon } from "./TermCon";
import { ADP } from "./ADP";
import {
  DivModal,
  DivTitle,
  DivModalTC,
  ButtonsContainer,
  ButtonClose,
} from "./styles";
import download from "../../assets/images/download.svg";
import PDFTerminoCondiciones from "../../../digital-transfers/assets/images/pdfs/Clausula_de_proteccion_de_Datos_Informativa.pdf";
import PDFUsosAdicionales from "../../../digital-transfers/assets/images/pdfs/Clausula_de_proteccion_de_Datos_Usos_Adicionales.pdf";
import { sendToData } from "../../../digital-transfers/constants/sendToDataLayer";
import {
  Modal as BaseModal,
  Typography,
  Button,
} from "@prima-afp/prima-ui-elements/dist/core";

export const Download = (type) => {
  if (type == "ADP") {
    const link = document.createElement("a");
    link.setAttribute("href", PDFUsosAdicionales);
    link.setAttribute(
      "download",
      "Clausula_de_proteccion_de_Datos_Usos_Adicionales"
    );
    link.click();
  } else {
    const linkTC = document.createElement("a");
    linkTC.setAttribute("href", PDFTerminoCondiciones);
    linkTC.setAttribute(
      "download",
      "Clausula_de_proteccion_de_Datos_Informativa"
    );
    linkTC.click();
  }
  const valType = type == 'ADP' ?  "clausula de proteccion de datos usos adicionales": "clausula de proteccion de datos informativa";
  var currentUrl = window.location.href;
  var step = window.location.href.includes('pasos0')? '0' : '1';
  var payload = {
      section: ("paso "+ step+ " - pop up - "+ valType),
      option: ("descargar"),
  };
  sendToData(currentUrl, "button_click", "button_click", payload);  

};

export function TCModal(props) {
  const { type, isActive, title } = props.settings;
  const dispatch = useDispatchDigitalTransfersContext();
  const contentScroll = useRef(null);

  const closeModal = (type) => {
    var step = window.location.href.includes('pasos0')? '0' : '1';
    const valType = type == 'ADP' ?  "clausula de proteccion de datos usos adicionales": "clausula de proteccion de datos informativa";
    var currentUrl = window.location.href;
    var payload = {
        section: ("paso "+ step+ " - pop up - "+ valType),
        option: ("entendido"),
    };
    sendToData(currentUrl, "button_click", "button_click", payload);  
    dispatch({
      type: "CLOSE_TC_MODAL",
    });
  };

  return (
    <div>
      <BaseModal id="id1" show={isActive} large={true} className={"basewidth"}>
        <ButtonClose onClick={() => closeModal()}>&times;</ButtonClose>
        <DivModal id="id2">
          <DivTitle id="id3">
            <Typography variant="h3" style={{ color: '#4E5258', fontFamily: 'Lexend, sans-serif', fontSize: '22px'}}>{title}</Typography>
          </DivTitle>
          <DivModalTC
            id="id4"
            ref={contentScroll}
            className={"viewScroll"}
            style={{textAlign: 'center'}}
          >
            <div
              id="contentDiv"
              style={{ marginLeft: "15px", marginRight: "15px" }}
            >
              {type == "TC" && <TermCon />}
              {type == "ADP" && <ADP />}
            </div>
          </DivModalTC>
        </DivModal>
        <ButtonsContainer id="buttonDiv">
          {type == "TC" && (
            <>
              <Button onClick={()=>Download("TC")} icon={download} hierarchy="secundary">
                Descargar
              </Button>

              <Button onClick={() => closeModal("TC")}>Entendido</Button>
            </>
          )}

          {type == "ADP" && (
            <>
              <Button onClick={()=>Download("ADP")} icon={download} hierarchy="secundary">
                Descargar
              </Button>

              <Button onClick={() => closeModal("ADP")}>Entendido</Button>
            </>
          )}
        </ButtonsContainer>
      </BaseModal>
      <style>
        {`
                    @media only screen and (max-width: 450px) {
                        .basewidth{                     
                            max-width: 335px !important; 
                        }
                    }
                    @media only screen and (min-width : 450px) and (max-width: 769px) {
                        .basewidth{                      
                            max-width: 704px !important; 
                        }
                    }
                    @media only screen and (min-width : 800px) and (max-width: 1000px) {
                        .basewidth{                            
                            max-width: 704px !important; 
                        }
                        
                    }
                    @media only screen and (min-width : 1000px) and (max-width: 1367px) {
                        .basewidth{                            
                            max-width: 858px !important;  
                        }
                    }
                    @media only screen and (min-width : 1367px)  {
                        .basewidth{                            
                            max-width: 858px !important;       
                            min-width: none !important;
                        }
                    }
                `}
      </style>
    </div>
  );
}

TCModal.propTypes = {
  closeModal: PropTypes.any,
  saveModal: PropTypes.any,
  settings: PropTypes.shape({
    content: PropTypes.any,
    isActive: PropTypes.any,
    title: PropTypes.any,
    type: PropTypes.string,
    textButton: PropTypes.string,
    value: PropTypes.any,
  }),
};
