import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from "../context/DigitalTransfersContext";
import { BiometricValidation } from "../services";
import { FPhi } from '@prima-afp/selphi-widget-component';
import {sendToData} from '../constants/sendToDataLayer';

export const useConfigBiometric = () => {

    const { userWebTransfer,userWeb, device, messagePage } = useStateDigitalTransfersContext();
    const dispatch = useDispatchDigitalTransfersContext();

    const sendToGTM = (eventNum, message) =>{
        const currentUrl = window.location.href;
        var payload = {section: 'paso 3', };
        payload.dni =  userWebTransfer.documentNumber;
        if(eventNum == -1){
            payload.message = ("aviso lo sentimos, estamos trabajando para solucionar el incoveniente");
            payload.option="cerrar";
        }else if(eventNum == 1){
            payload.message = message; 
        }else if(eventNum == 2){
            payload.message = message;
        }else if(eventNum == 3){
            payload.message = ("aviso has superado la cantidad de validaciones por biometria");
            payload.option="entendido";
        }
        sendToData(currentUrl, "mensaje_click", "mensaje", payload);  
    };

    const fetchValidationPhoto = async (image) => {
        var bestImage = image.detail.bestImage;
        var bestImageTokenized = image.detail.bestImageTokenized;

        dispatch({ type: 'FIELD', payload: { field: 'showLoading', value: true } });
        FPhi.Selphi.Component.generateTemplateRawFromByteArray(
            process.env.REACT_APP_BIOMETRIC_SELPHI_BUNDLE_PATH,
            bestImage,
            (templateRawToken) => {
              const userObject = {
                userTemplate: new File(
                    [bestImageTokenized],
                    `userTemplate-${userWebTransfer?.documentNumber}.txt`,
                    { type: "text/plain" }
                  ),
                tokenTemplate: new File(
                    [templateRawToken],
                    `tokenTemplate-${userWebTransfer?.documentNumber}.txt`,
                    { type: "text/plain" }
                  ),
              };

              
              const formdata = new FormData();
              formdata.append("userTemplate", userObject.userTemplate ? userObject.userTemplate  : 'userTemplateNoCapturadoporFront');
              formdata.append("tokenTemplate", userObject.tokenTemplate ? userObject.tokenTemplate : 'tokenTemplateNoCapturadoporFront' );            
              var body = formdata;

              const fetchValidationPhoto2 = async () => {                
                try {
                    const response = await BiometricValidation(body, userWeb.documentType, 
                    userWeb.documentNumber, 
                    userWeb.phoneNumber, userWeb.email, userWeb.firstName == undefined? "" : userWeb.firstName );    
                    response.availableAttempts != null? dispatch({ type: 'FIELD', payload: { field: 'AttemptsQ', value: response.availableAttempts} }): null;
                    if((response.availableAttempts == 0 || response.availableAttempts == null) && response.authStatus == 'Negative'){
                        sessionStorage.clear();
                        dispatch({ type: 'FIELD', payload: { field: 'AttemptsQ', value: null} });
                        dispatch({
                            type: 'SET_CONFIG_MODAL', payload: {
                                idModal:  "btn-step3-attempts-biometric", 
                                content: response.message,
                                textButton: "Entendido",
                                returnStep: "STEP_0",
                                showX: false,
                            }
                        });
                        sendToGTM(3);
                    }
                    if(response.code == null || response.code == undefined){
                        if(response.availableRetry === false && (response.authStatus != 'Positive' && response.authStatus != 'Pending')){ 
                            sessionStorage.clear();
                            dispatch({
                                type: 'SET_CONFIG_MODAL', payload: {
                                    idModal:  "btn-step3-attempts-biometric", 
                                    content: response.message,
                                    textButton: "Entendido",
                                    returnStep: "STEP_0",
                                    showX: false,
                                }
                            });
                            sendToGTM(3);
                        }
                        else if(response.message == "Lo sentimos, la imagen no cumple la prueba de vida."){
                            dispatch({
                                type: 'SET_CONFIG_MODAL', payload: {
                                    idModal:  "btn-step3-life-test", 
                                    content: response.message
                                }
                            });                        
                            sendToGTM(2);        
                            failedValidationSelfie(99);
                        }
                        else if(response.authStatus === 'Positive' || response.authStatus === 'Pending') {
                            successValidationSelfie(response);
                        } else {
                            sendToGTM(1); 
                            failedValidationSelfie(response.availableAttempts);
                        }
                    }else{
                        if(response.code === -1){
                            dispatch({
                                type: 'SET_CONFIG_MODAL', payload: {                            
                                    idModal:  "btn-step3-error-validation", 
                                    content: response.message
                                }
                            });
                            sendToGTM(-1); 
                            failedValidationSelfie(99);
                        }else if(response.code === 1){
                            if(response.authStatus === 'Positive' || response.authStatus === 'Pending') {
                                successValidationSelfie(response);
                            } else {
                                dispatch({ type: 'FIELD', payload: { field: 'messagePage', value: response.message } });
                                sendToGTM(1, response.message);
                                failedValidationSelfie(response.availableAttempts);
                            }
                        }
                        else if(response.code === 2){
                            dispatch({ type: 'FIELD', payload: { field: 'messagePage', value: response.message } });
                            sendToGTM(2, response.message);
                            failedValidationSelfie(99);
                        }else if(response.code === 3){
                            sessionStorage.clear();
                            dispatch({ type: 'FIELD', payload: { field: 'AttemptsQ', value: null} });
                            dispatch({
                                type: 'SET_CONFIG_MODAL', payload: {
                                    idModal:  "btn-step3-attempts-biometric", 
                                    content: response.message,
                                    textButton: "Entendido",
                                    returnStep: "STEP_0",
                                    showX: false,
                                }
                            });
                            sendToGTM(3);

                        }else{
                            failedValidationSelfie(response.availableAttempts);
                            sendToGTM(1);
                        }
                    }

                } catch (error) {
                    dispatch({
                        type: 'SET_CONFIG_MODAL', payload: {                            
                            idModal:  "btn-step3-error-validation", 
                            content: error.message
                        }
                    });
                    sendToGTM(-1); 
                    failedValidationSelfie(99);
                } finally {
                    dispatch({ type: 'FIELD', payload: { field: 'showLoading', value: false } });
                }
              };
              fetchValidationPhoto2();
            }
        );
        
            
    };

    const onSaveImage = (image) => {
        dispatch({ type: 'FIELD', payload: { field: 'imageBiometric', value: image } });
    };

    const successValidationSelfie = (response) => {
        dispatch({ type: 'FIELD_SECOND',  payload: { field1: 'userWeb', field2: 'biometricStatus', value: response.authStatus} });
        // go to next step
        dispatch({ type: 'VALIDATION_BIOMETRIC', payload: response });
        //Guardar campo Biometrico
        dispatch({
            type: 'FIELD_SECOND',
            payload: {
                field1: 'userWeb',
                field2: 'biometric',
                value: response.biometric
            }
        });
        dispatch({ type: 'FIELD', payload: { field: 'stepCurrent', value: 4 } });
    };

    const failedValidationSelfie = (quantity) => {
        dispatch({ type: 'NOT_VALIDATION_BIOMETRIC' });
        dispatch({ type: 'FIELD', payload: { field: 'stepCurrent', value: 3.5 } });
        if(quantity != 99) { dispatch({ type: 'FIELD', payload: { field: 'AttemptsQ', value: quantity} }); }
    };

    return {
        onSaveImage,
        fetchValidationPhoto,
        pathBack: window.location.href.includes('asesor') ? "/asesor" : "/",
        device: device
    };
};