/* eslint-disable react/prop-types */
import React from 'react';

export const RecoSelfie = ({img, title, className = 'recotext'}) => {
  return (
    <div className='recoStep3'> 
        <div style={{marginRight: '16px'}}>
            {img}
        </div>
        <div style={{paddingTop: '8px',  paddingBottom: '8px'}}>
            <span className= { className }>{ title }</span>
        </div>    
    </div>
  );
};
