import styled from "styled-components";

export const InputContainerSearch = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  .input-container {
    display: flex;
    align-items: center;
    border: 1.5px solid #e7e7e8;
    border-radius: 12px;
    height: 56px;
    .dni-prefix {
      padding-left: 16px;

      pointer-events: none;
      color: #292b2e;
      font-family: Lexend;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;
    }
    .dni-input {
      border: none;
      outline: none;
      width: 100%;
      padding-left: 12px;
      font-family: Lexend;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;
    }
    .dni-input::placeholder {
      color: #4e5258;
    }
    .dni-clear {
      min-width: 44px;
      margin-left: auto;
      cursor: pointer;
      color: #555;
      font-family: Lexend;
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (max-width: 480px) {
    .input-container {
      border-radius: 16px;
    }
  }
`;
