import styled from "styled-components"
import "./vars.css"

export const FooterStyle = styled.div`
  color: white;
  background-color: #ff4f00;
  padding: 42px 0px 22px;
  .justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .logo {
    display: block;
    width: 132px;
    margin: 0 0 24px 24px;
  }

  .logoMovil {
    display: none;
  }

  .mainDisclaimerBook {
    display: flex;
    justify-content: center;
    flex-direction: column;
    a {
      text-align: left;
      color: rgba(255, 255, 255, 1);
      text-decoration: none;
      span {
        display: block;
        font-family: Lexend;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        font-feature-settings: "pnum" on, "lnum" on;
        margin: 0 0 10px 0;
      }
      .reclamaciones {
        width: 95px;
        -webkit-filter: opacity(0.9) drop-shadow(0 0 0 white);
        filter: opacity(0.9) drop-shadow(0 0 0 white);
        display: block;
      }
    }
  }

  .prime {
    position: relative;
    display: inline-block;
    width: 80px !important;
  }

  .social {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .socialicon {
    width: 30px;
    display: inline-block;
    height: 100%;
    margin-left: var(--s-2);
    margin-right: var(--s-2);
  }

  .extralinks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: var(--s-3);
    grid-row-gap: var(--s-3);
  }
  .mainLinksItem {
    margin-bottom: 8.5px;
    a {
      color: rgba(255, 255, 255, 1);
      font-family: Lexend;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      line-height: 24px;
      text-align: left;
      text-decoration: none;
    }
  }
  .nav_ico_right {
    display: inline-block;
  }

  .listTitleMobileClick {
    margin: 0px;
    margin-bottom: 17px;
    font-family: Lexend;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: "pnum" on, "lnum" on;
    display: flex;
    justify-content: space-between;
  }

  .secLinksItem {
    margin-bottom: var(--s-2);
    font-family: "Calibri";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .listTitle {
    margin-bottom: 13px !important;
    font-family: "Calibri";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: "pnum" on, "lnum" on;
    display: none;
  }

  .footerTop {
    padding-bottom: 51px !important;
  }

  .footerBottom {
    border-top: 1px solid #efeff0;
    border-bottom: 1px solid #efeff0;
    padding: 22px 0;
    margin-bottom: 20px;
  }

  .socialSiguenos {
    display: none;
    align-items: center;
    font-family: "Calibri";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    font-feature-settings: "pnum" on, "lnum" on;
  }

  .font-family-secondary {
    font-family: "Calibri";
  }
  .tw-f-16 {
    font-size: 16px;
    line-height: 20px !important;
  }
  .fw-6 {
    font-weight: 600;
    margin: 20px auto 80px !important;
  }
  .footerCopy {
    font-family: Lexend;
    font-size: 14px;
    font-weight: 350;
    line-height: 24px;
    text-align: center;
    display: none;
    color: white;
    padding: 22px 0 0;
    border-top: 1px solid #efeff0;
    margin-top: 22px;
  }

  .navSecondLevel {
    list-style-type: none;
    padding: 0px;
    animation: navSecondLevel 0.1s ease-in;
    line-height: 16.87px;
    font-family: Calibri;
    .mb-1 {
      margin-bottom: 4.25px;
    }
    .textPhone {
      font-family: Calibri;
      a {
        font-family: Calibri;
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .submenu_open {
    overflow: hidden;
    max-height: 700px;
    transition: all 0.6s ease-in;
  }

  .submenu_close {
    margin: 0px !important;
    overflow: hidden;
    max-height: 0;
    transition: all 0.6s ease-out;
  }

  .footerColumn {
    padding: 22px 24px 0;
    border-top: 1px solid #efeff0;
  }

  @media screen and (max-width: 950px) {
    padding: 0px;
    .logoMovil {
      display: block;
      padding: 36px 0px 24px 16px;
    }

    .footerColumn {
      padding-top: 22px;
      padding-left: 16px;
    }

    .footerTop {
      padding-bottom: 0px !important;
    }

    .navSecondLevel {
      padding: 0px;
    }

    .listTitleMobileClick {
      margin-top: 0px;
    }

    .logo {
      display: none !important;
    }
    .mainDisclaimerBook {
      align-items: center;
      justify-content: center;
    }
    .footerCopy {
      margin-top: 0px;
      padding-bottom: 22px;
      display: block;
    }
  }
  @media screen and (min-width: 950px) {
    font-size: var(--f--1);
    padding: 64px 0 42px;
    .footerColumn {
      padding: 0 0 0;
      border-top: none;
    }

    .footerColumn:nth-child(4) {
      width: 270px;
    }

    .footerTop {
      display: flex;
      justify-content: space-between;
      padding: 0 0 51px;
    }
    .footerBottom {
      display: flex;
      justify-content: space-between;
      padding: 42px 0 0;
      border-bottom: 0px;
      margin-bottom: 0px;
    }
    .footerCopy {
      text-align: left;
      color: white;
      padding: 3px 0 0 20px;
      border-top: none;
      margin-top: 0;
    }
    .socialSiguenos {
      display: flex;
    }
    .mainPhone {
      margin: 0 0 35px 0;
      display: block;
    }
    .mainDisclaimerBook {
      justify-content: flex-start;
    }
    .mainDisclaimerBook span {
      font-size: 16px;
      line-height: 24px;
    }
    .reclamaciones {
      width: 81px !important;
      display: inline-block;
      margin: 12px 0 0 0;
    }
    .social {
      justify-content: flex-start;
      width: auto;
    }
    .socialicon {
      margin-left: var(--s-2);
      margin-right: 0;
    }

    .submenu_close {
      max-height: 700px;
      z-index: 2;
    }

    .listTitleMobile {
      margin: 0 0 8px 0;
      display: block;
    }
    .mainLinksItem {
      padding: 0;
    }
  }

  .footerRUC {
    margin: 20px auto 80px;
  }

  @media screen and (min-width: 1025px) {
    .footerRUC {
      margin-top: 170px;
      margin-bottom: 0;
    }
    #footerBottom {
      .containerWrap {
        display: flex;
        align-items: center;
        .footerLogo {
          img {
            margin: 0 !important;
          }
          .footerCopy {
            display: flex;
          }
        }
      }
    }
    .containerWrap {
      width: 1080px;
      margin: 0 auto;
      padding: 0;
    }
  }

  @media only screen and (max-width: 540px) {
    padding: 42px 0px 22px !important;
    .todosDerechos {
      visibility: visible !important;
    }
    .footerCopy {
    }
    .textRuc {
      margin-top: 48px !important;
      margin-bottom: 80px !important;
    }
    .conteinerLibro {
      text-align: center !important;
    }
    .logoMovil {
      width: 132px;
      height: 48.837px;
      margin: 0 0 24px 24px;
      padding: 0px !important;
    }
    .reclamaciones {
      margin: 22px auto 0 !important;
    }
    .listTitleMobileClick {
      font-size: 18px;
      line-height: 20px;
    }
  }
`
