import styled from "styled-components";
const defaultColor = "#9B9DA0";

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  .timeline-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;
  }

  .item-content {
    flex-grow: 1;
  }
`;

export const ContentTimeLine = styled.div`
  padding: 18px 32px;
  gap: 16px;
  opacity: 0px;
  background-color: ${({ backColor }) => backColor || "#fff"};
  border-radius: 12px;
  min-width: 200px;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px 0px rgba(69, 73, 78, 0.04),
    0px 24px 48px -8px rgba(69, 73, 78, 0.12);
  font-family: Lexend;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0012em;
  text-align: left;
  color: ${({ color }) => color || "rgba(143, 146, 149, 1)"};
`;

export const NumberTimeLine = styled.div`
  border-radius: 75px;
  opacity: 0px;
  border: 2px solid ${({borderCircleColor}) => borderCircleColor || "#84878B"};
  width: 28px;
  height: 28px;
  background-color: ${({ circleColor }) => circleColor || "#fff"};
  color: ${({ numberCircleColor }) => numberCircleColor || "rgba(143, 146, 149, 1)"};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    height: 16px;
  }

  @media screen and (max-width: 540px) {
    font-size: 16px;
    font-weight: 700;
    min-width: 28px;
  }
`;

export const LineBetween = styled.div`
  width: 2px;
  height: 40px;
  background-color: ${({ lineColor }) => lineColor || defaultColor};
  position: absolute;
  top: 100%;
  left: 45px;
`;
