import React, { useEffect, useState } from "react";
import { ContainerCards } from "./style.jsx";

export const Cards = ({ items }) => {
  const [image, setImage] = useState(items[0].image);
  const [description, setDescription] = useState(items[0].description);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setImage(items[currentIndex].image);
      setDescription(items[currentIndex].description);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [currentIndex, items.length]);

  return (
    <ContainerCards>
          <div className="cardT">
            <div className="bodyCard">
              <div className="imgCard">
                <img src={image} alt="" />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: description }}
                className="textCard"
              />
            </div>
            
          <div className="footerCard">
            {items.map((item) => {
              return (
                <div
                  key={item.id}
                  className={`circuloCard ${
                    item.description == description ? "circuloCardSelect" : ""
                  }`}
                ></div>
              );
            })}
          </div>
          </div>
    </ContainerCards>
  );
};
