import { mediaQuery } from "modules/utils/responsive";
import styled from "styled-components";

export const ContainerCards = styled.div`
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;

.cardT {
  max-width: 335px;
  width: 100%;
  margin: 20px 20px 0px 20px;
}

  .bodyCard {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 7px;
    padding: 10px;
    box-shadow: 2px 2px 10px 2px #ebebeb;
    mix-blend-mode: multiply;
    background-color: #fff;
    min-height: 91px !important;
    cursor: pointer;
  }

.imgCard {
  min-width: 100px;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textCard {
  color: var(--gris-dark-100, #45494e);
  font-family: Calibri, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 70%;
  max-width: 170px;
}

  .footerCard {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .circuloCard {
    width: 8.104px;
    height: 8.104px;
    border-radius: 8.104px;
    background: #ececec;
    margin-right: 10px;
  }

  .circuloCard:last-child {
    margin-right: 0; 
  }

  .circuloCardSelect {
    background: #00a499;
  }

  @media screen and ${mediaQuery.mobileS} {
    display: flex;
    justify-content: center;
    .cardT {
      width: calc(100% - 10px);
      max-width: none;
      margin: 10px;
    }
    .bodyCard{
      width: 100%;
      min-width: 70%;
    }
  }

`;
