import React from "react";
import { ContentTimeLine, LineBetween, NumberTimeLine, TimelineContainer } from "./Style";
import { themes } from "./themes";

const TimeLine = ({ items }) => {
  let number = 1;
  return (
    <TimelineContainer> 
      {items.map((item, index) => {
        let itemTheme = themes?.find(theme => theme.name === item.theme);
        if (item.noNumber) {
          return (
            <div key={index} className="timeline-item">
              <ContentTimeLine backColor={itemTheme?.backColor} color={itemTheme?.color}>
                <div>{item.content}</div>
              </ContentTimeLine>
              {index < items.length - 1 && (
                <LineBetween lineColor={itemTheme?.lineColor} />
              )}
            </div>
          );
        } else {
          const currentNumber = number++;
          return (
            <div key={index} className="timeline-item">
              <ContentTimeLine backColor={itemTheme?.backColor} color={itemTheme?.color}>
                <NumberTimeLine circleColor={itemTheme?.circleColor} color={itemTheme?.color} borderCircleColor={itemTheme?.borderCircleColor} numberCircleColor={itemTheme?.numberCircleColor} >
                  { itemTheme?.imgItem ? (
                    <img src={itemTheme?.imgItem} alt="timeline item" />
                  ) : (
                    currentNumber
                  )}
                </NumberTimeLine>
                {item.content}
              </ContentTimeLine>
              {index < items.length - 1 && (
                <LineBetween lineColor={itemTheme?.lineColor} />
              )}
            </div>
          );
        }
      })}
    </TimelineContainer>
  );
};

export default TimeLine;
