import React, { useEffect, useState } from 'react';
import { Text } from '../../../shared/components/Text';
import { ContainerPhoneVerification } from './styles';
import { VerificationCode } from '../../../shared/components/VerificationCode';
import { Button } from '@prima-afp/prima-ui-elements/dist/core';
import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext';
import { useCatchFetch } from '../../hooks/useCatchFetch';
import { SendCodeVerification, ReSendCodeVerification, CreateTransfer, Abandon } from '../../services';
import { useHistory } from 'react-router-dom';
import timerIcon from './timer.svg';
import {sendToData} from '../../constants/sendToDataLayer';
import {updateUrl} from '../../constants/updateUrl';
 

const LIMIT_TIMER = 60;

export const PhoneVerification = ({ buttonRef }) => {

    const { userWebTransfer, userWeb, urlFlow, haveAsesor, lastStep, stepCurrent } = useStateDigitalTransfersContext();
    const dispatch = useDispatchDigitalTransfersContext();
    const [timer, setTimer] = useState(LIMIT_TIMER);
    const [code, setCode] = useState("");
    const [loadingButton, setLoadingButton] = useState(false);
    const [showTimer, setShowTimer] = useState(true);
    const [createFlag, setCreateFlag] = useState(null);
    const { SendFetch, SetLoading } = useCatchFetch();
    const history = useHistory();

    useEffect(() => {
        
        let interval = setInterval(() => {
            setTimer(prev => prev > 0 ? prev - 1 : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        timer == 0 && setShowTimer(false); 
    }, [timer]);

    useEffect(() => {
        // Agregar un event listener cuando el componente se monta
        window.addEventListener('beforeunload', handleBeforeUnload);
        // Eliminar el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, [createFlag]);
    
      const handleBeforeUnload = async (e) =>{
          const message = "¿Seguro que quieres salir?";
          e.returnValue = message;
          const userResponse = window.confirm(message);
          try { 
              var currentStep = 0;
              if(createFlag == null || createFlag == false ){currentStep = 6;}
              if(createFlag == true){currentStep = 7;}
                var BodyPayload = { step: currentStep, documentNumber: userWeb.documentNumber, documentType: "00", active: true };
                var abandonRes = await SendFetch(Abandon(BodyPayload));
            } catch (error) {
              console.error( error);
          }
    };

    const formatSeconds = (timer) => {
        return String(timer).padStart(2, '0');
    };
    
    const reSendCode = () => {
        SetLoading(true);
        const body = {
            phoneNumber: userWebTransfer.phoneNumber,
            documentNumber: userWebTransfer.documentNumber,
            documentType: userWebTransfer.documentType,
            email: userWebTransfer.email,
            name: userWebTransfer.firstName == undefined ? "" : userWebTransfer.firstName
        };
        SendFetch(ReSendCodeVerification(body))
            .then((res) => {
                if(res.sendSms){
                    setShowTimer(true);
                    setCode('');
                    setTimer(LIMIT_TIMER);
                    setCreateFlag(null);
                    setLoadingButton(false);
                    const currentUrl = window.location.href;
                    var payload = {section: "paso 4", option: "reenviar"};
                    sendToData(currentUrl, "link_click", "link_click", payload);  
                }
        });

    };

    const validCode = () => {

        SetLoading(true);
        const body = {
            documentType: userWeb.documentType , 
            documentNumber: userWeb.documentNumber , 
            phoneNumber: userWeb.phoneNumber , 
            securityCode: code,
            biometric: userWeb.biometric,
        };

        const currentUrl = window.location.href;
        var payload = {section: "paso 4", option: "validar codigo"};
        sendToData(currentUrl, "button_click", "button_click", payload);  

        SendFetch(SendCodeVerification(body))
        .then((res) => {
            setShowTimer(false); 
            if (res.code === 1) {
                setLoadingButton(false);
                setCreateFlag(true);
            }else if(res.message.includes("seguridad")){
                setLoadingButton(false);
                setCreateFlag(false);       
            }
            else if(res.message.includes("ErrorDetail:")){
                setLoadingButton(false);
                setCode('');
                dispatch({
                    type: 'SET_CONFIG_MODAL',
                    payload: {
                        idModal:  "btn-step4-have-request", 
                        content: 'Ya tienes una solicitud, tu traspaso hacia Prima AFP está en proceso, para mayor información llámanos al 615-7272 en Lima o al 0-801-18010 en provincias',
                    }
                });                        
            } else {
                SetLoading(false);
                setLoadingButton(false);
                setCode('');
                dispatch({
                    type: 'SET_CONFIG_MODAL',
                    payload: {
                        idModal:  "btn-step4-error-validation-code", 
                        content: res.message,
                    }
                });        
            }
        });      
    };
    
    const getValue = (str, currentURL) => {
        //const currentURL = window.location.href;
        const startIndex = currentURL.indexOf(str);
        if (startIndex !== -1) {
          // Encontrar la posición de "&" después de "utm_source="
          const endIndex = currentURL.indexOf("&", startIndex);    
          // Extraer la subcadena entre "utm_source=" y "&"
          const strValue = endIndex !== -1 ? currentURL.substring(startIndex + str.length, endIndex) : currentURL.substring(startIndex + str.length);
          return strValue;
        } else {
          return "not set";
        }       
    };
    
    const createDT= () => {

        SetLoading(true);
        const bodyToCreate = {documentType: userWeb.documentType, documentNumber: userWeb.documentNumber, byAssessor: true};
        SendFetch(CreateTransfer(bodyToCreate))
            .then((res) => {
                if (res.code === 1 || res.code === 2) {
                    SetLoading(false);              
                    dataLayer.push({
                        RegisteredDocUser: userWeb.documentNumber,
                    });   
                    dispatch({ type: 'FIELD', payload: { field: 'showDivBenefits', value: false } });
                    dispatch({
                        type: 'GO_TO_FINISH_PAGE',
                    });

                    const currentUrl = window.location.href;
                    var payload = {
                        section: "paso 4", option: "finalizar solicitud", 
                        count_4: 1, 
                        dni: userWeb.documentNumber,
                        telefono: userWeb.phoneNumber,
                        email: userWeb.email,};               
                    sendToData(currentUrl, "step", "paso_4", payload, urlFlow);                      
                    if(lastStep < 5)  {dispatch({ type: 'FIELD', payload: { field: 'lastStep', value: 5 } });}
                    if (window.location.href.includes('asesor')) {
                        updateUrl(history, currentUrl,'/asesor/finalizacion-solicitud');
                    }else{
                        updateUrl(history, currentUrl,'/finalizacion-solicitud');
                    }                   
                }else {
                    SetLoading(false);
                    setTimer(0);
                    setCode('');
                    dispatch({
                        type: 'SET_CONFIG_MODAL',
                        payload: {
                            idModal: 'btn-step4-error-createDT',
                            content: res.message,
                        }
                    });
                }
            });

    };


    return (
        <>
            <ContainerPhoneVerification id="ContainerPhoneVerification">

                <Text className="titlePV">Validemos tus datos de contacto</Text>

                <span id="paragraph1">Hemos enviado un código de verificación al <b style={{color: '#696158', marginLeft: '5px', marginRight: '5px'}}> {userWeb.phoneNumber}</b> y a tu correo electrónico <b style={{color: '#696158', marginLeft: '5px', marginRight: '5px'}}>{userWeb.email}</b> para validar tus datos de contacto.</span>

                <VerificationCode value={code} SetCode={setCode} flagVerifiedCode={createFlag}/>

                {showTimer && <div id="wrapper1">
                        <img src={timerIcon} style={{width: '24px' , height: '24px', marginRight: '8px'}} />
                        <Text className="contentPV2">El código vencerá en 0:{formatSeconds(timer)}  segundos</Text>  
                    </div>
                }

                <Text id="text3" className="contentPV">¿No te llegó el código?
                 <a onClick={reSendCode} style={{color: '#FF4F00', marginLeft: '5px' }} data-testid='input-field'>Reenviar</a> </Text>

                <div className="wrapperButton">
                    { !createFlag && !loadingButton &&
                        <Button id="btn-sms-val-send" className="btn-sms-val-send" 
                            onClick={validCode} ref={buttonRef} disabled={code.trim().length !== 6}>
                            Validar código
                        </Button>
                    }
                    { !createFlag && loadingButton &&<Button className="btn-sms-val-send" disabled={true}>
                        Validando
                    </Button>
                    }
                    { createFlag && 
                        <Button id="btn-create-transfer" className="btn-sms-val-send" 
                            data-testid='input-createDT'
                            onClick={createDT} ref={buttonRef} >
                            Finalizar solicitud
                        </Button>
                    }
                </div>

            </ContainerPhoneVerification>
        </>
    );
};
