import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from "../context/DigitalTransfersContext";
import { SendGetDepartments,ReturnProcess, SendGetDistricts, SendGetMain, SendGetProvinces, SendGetSectors, SendGetVias, SendGetParameters  } from "../services";
import { useCatchFetch } from "./useCatchFetch";
import { ParseResponseToken } from '../constants/parse';

export const useGetDataApi = () => {

    const { listGenerals } = useStateDigitalTransfersContext();
    const dispatch = useDispatchDigitalTransfersContext();
    const { SendFetch,SendFetchTokenReturnProspect, SetLoading } = useCatchFetch();

    const GetLegalsText = async () => {
        const response = await SendFetch(
            SendGetParameters('TEXTO_LEGAL')
        );  
        const legaltext = response.parameters.length > 0 ?  response.parameters.find((element) => { return element.name === 'TL';}) : { description:''};
        dispatch({ type: 'FIELD', payload: { field: 'legaltext', value: legaltext?.description } });
    };

    const GetWhatsappValue = async () => {
        const response = await SendFetch(
            SendGetParameters('WHATSAPP')
        );  
        const whatslink = response.parameters.length > 0 ?  response.parameters.find((element) => { return element.name === 'LINK';}) : { description:''};
        if(whatslink != undefined && whatslink != null){dispatch({ type: 'FIELD', payload: { field: 'whatslink', value: whatslink?.description } });}
    };

    const GetDataInitial = async () => {
        SetLoading(true);
        const MainOptions = await SendFetch(SendGetMain());
        const listDocumentsTypes = MainOptions.documentTypeList.filter(i => i.code == "00");
        dispatch({
            type: 'SET_LIST_GENERAL',
            payload: {
                MainOptions,
                listDocumentsTypes,
            }
        });
        const listVias = await SendFetch(SendGetVias(), true);
        const listSectors = await SendFetch(SendGetSectors(), true);
        const listDepartments = await SendFetch(SendGetDepartments(), true);
        dispatch({
            type: 'SET_LIST_GENERAL',
            payload: {
                listVias,
                listSectors,
                listDepartments,
            }
        });
    };

    const GetDataWithToken = async (document, token_query) => {
        SetLoading(true);
        const MainOptions = await SendFetchTokenReturnProspect(SendGetMain(), true);
        const listVias = await SendFetchTokenReturnProspect(SendGetVias(), true);
        const listSectors = await SendFetchTokenReturnProspect(SendGetSectors(), true);
        const listDepartments = await SendFetchTokenReturnProspect(SendGetDepartments(), true); 
        
        const listDocumentsTypes = MainOptions.documentTypeList.filter(i => i.code == "00");

        dispatch({
            type: 'SET_LIST_GENERAL',
            payload: {
                MainOptions,
                listDocumentsTypes,
                listVias,
                listSectors,
                listDepartments,
            }
        });

        SendFetch(ReturnProcess(document, token_query), true)
        .then(res => {
            if (!res.message) {                    
                res = ParseResponseToken(res);
                
                dispatch({
                    type: 'RETURN_STEP_1',
                    payload: {
                        userWebTransfer: res.userWebTransfer,
                        userWeb: res.userWeb, 
                        isAddressDNI: res.reference ? false: true,  
                    }
                });
                dispatch({ type: 'FIELD', payload: { field: 'tokenStep', value: token_query } });
                
                SetLoading(false);

            } else {                    
                dispatch({ type: 'INITIAL_STATE', });
                dispatch({
                    type: 'SET_CONFIG_MODAL',
                    payload: {
                        content:res.message, 
                        changeLoading: false,
                        returnStep: "STEP_0" ,
                    },
                });
            } 
 
        }).catch((error) => {
            dispatch({ type: 'INITIAL_STATE', });
            dispatch({
                type: 'SET_CONFIG_MODAL',
                payload: {
                    content:'Ocurrió un error interno, por favor vuelva a intentar en unos momentos.',
                    changeLoading: false,
                    returnStep: "STEP_0" ,
                },
            });
        });

    };

    const GetDocumentsType = () => {
        return listGenerals.listDocumentsTypes || [];
    };

    const GetVias = () => {
        return listGenerals.listVias || [];
    };

    const GetSectors = () => {
        return listGenerals.listSectors || [];
    };

    const GetDepartments = () => {
        return listGenerals.listDepartments || [];
    };

    const GetProvinces = async (departmentId) => {
        if (!departmentId) return [];

        const listProvinces = await SendFetch(SendGetProvinces(departmentId));

        return listProvinces || [];
    };

    const GetDistricts = async (departmentId, provinceId) => {
        if (!departmentId) return [];
        if (!provinceId) return [];

        const listDistricts = await SendFetch(SendGetDistricts(departmentId, provinceId));

        return listDistricts || [];
    };

    const GetListEmployedTypes = () => {
        return [
            { code: "D", description: "Dependiente" },
            { code: "I", description: "Independiente" },
        ];
    };

    return {
        GetLegalsText,
        GetWhatsappValue,
        GetDataInitial,
        GetDataWithToken,

        GetDocumentsType,
        GetVias,
        GetSectors,

        GetDepartments,
        GetProvinces,
        GetDistricts,

        GetListEmployedTypes,
    };
};
