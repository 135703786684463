export const buildBodyStep1 = ({ isAddressDNI, isConsultedReniec, userWeb, userWebTransfer }) => {
    const personalData = isConsultedReniec ?
        {
            maritalStatus: "",
            countryCodeBirth: "",
            genre: "M",
            birthDateString: "",
        } :
        {
            maritalStatus: userWeb.maritalStatus,
            countryCodeBirth: userWeb.countryCodeBirth,
            genre: userWeb.genre,
            birthDateString: userWeb.birthDateString,

        };
    
        userWebTransfer.tcConfirmation= userWeb.tcConfirmation == true? true:false;
        userWebTransfer.adpConfirmation= userWeb.adpConfirmation == true? true:false;
        userWebTransfer.reniecIndicator = "S";
        userWebTransfer.fingerprintIndicator= "N";
        userWebTransfer.webIndicator= "S";
        userWebTransfer.usePersonalData= "";
        userWebTransfer.email= userWeb.email;
        userWebTransfer.phoneNumber= userWeb.phoneNumber;
        userWebTransfer.documentType = userWeb.documentType;
        userWebTransfer.documentNumber = userWeb.documentNumber;

    return {
        userWebTransfer,

        lastName: userWeb.lastName,
        sureName: userWeb.sureName,
        firstName: userWeb.firstName,
        email: userWeb.email,
        phoneNumber: userWeb.phoneNumber,

        addressType: isAddressDNI ? "direccion" : "nuevadireccion",
        codeVia: userWeb.codeVia,
        descriptionVia: userWeb.descriptionVia,
        codeHousing: userWeb.codeHousing,
        descriptionHousing: userWeb.descriptionHousing,
        addressReference: userWeb.addressReference,
        departmentCode: userWeb.departmentCode,
        provinceCode: userWeb.provinceCode,
        districtCode: userWeb.districtCode,
        
        ...personalData,

        usePersonalData: "", //????????

        reniecIndicator: "S",
        fingerprintIndicator: "N",
        webIndicator: "S",
    };
};

export const BuildBodyForTC = (listVias, listSectors, userWeb, isAddressDNI, haveAsesor, validSecontReniecActive) => {
    var body = {};
    body.documentType= userWeb.documentType;
    body.documentNumber= userWeb.documentNumber;
    body.byAssessor = haveAsesor;
    if(!isAddressDNI && validSecontReniecActive){
        var via = listVias.find((element) => { return element.code === userWeb.codeVia;});
        var sector = listSectors.find((element) => { return element.code === userWeb.codeHousing;});
        //
        body.otherAddress = true;
        body.departmentCode = userWeb.departmentCode;     
        body.provinceCode = userWeb.provinceCode;     
        body.districtCode = userWeb.districtCode; 
        body.codeVia = userWeb.codeVia;    
        body.codeViaValue= via.description;
        body.descriptionVia = userWeb.descriptionVia; 
        body.codeHousing = userWeb.codeHousing;    
        body.codeHousingValue= sector.description;
        body.descriptionHousing = userWeb.descriptionHousing;     
        body.addressReference = userWeb.addressReference;

    }else{
        body.otherAddress = false;
    }

    body.tcConfirmation= userWeb.tcConfirmation;
    body.adpConfirmation= userWeb.adpConfirmation;
    return body;
};

export const buildBodyToCreateTransfer = ({ userWeb, userWebTransfer, heldPosition, isAddressDNI  }) => {
    var body ={};
    body.documentType = userWebTransfer.documentType;
    body.documentNumber = userWebTransfer.documentNumber;
    body.phoneNumber = userWebTransfer.phoneNumber;
    body.email = userWebTransfer.email;
    body.tcConfirmation= userWebTransfer.tcConfirmation;
    body.adpConfirmation= userWebTransfer.adpConfirmation;    
    body.otherAddress = false;
    if(!isAddressDNI){
        body.otherAddress = true;
        body.departmentCode = userWeb.departmentCode;     
        body.provinceCode = userWeb.provinceCode;     
        body.districtCode = userWeb.districtCode; 
        body.codeVia = userWeb.codeVia;    
        body.descriptionVia = userWeb.descriptionVia;    
        body.codeHousing = userWeb.codeHousing;     
        body.descriptionHousing = userWeb.descriptionHousing;         
        body.addressReference = userWeb.addressReference;     
    }
    body.workerType = userWeb.workerType;
    if(body.workerType == "D" ){
        body.rucEmployer = userWeb.rucEmployer; 
        body.tradeNameEmployer = userWeb.tradeNameEmployer; 
    }
    body.codeFundType = userWeb.fundType;
    body.promoterCode= userWeb.promoterCode;
    body.heldPosition = heldPosition;  
    body.pepInstitutionName = userWeb.pepInstitutionName;
    body.pepPositionHeld = userWeb.pepPositionHeld;

    return {
        body
    };
};