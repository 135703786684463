import styled from "styled-components";

export const HeaderCont = styled.div`
  .headerLanding {
    width: 100%;
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    background-color: #fff;
    border-bottom: 1px solid #e4e5e5;
    box-shadow: 0px 4px 8px 0px #69615826;
    .contentHeader {
      width: 100%;
      display: flex;
      align-items: center;
      max-width: 1318px;
      img {
        width: 120.59px;
        height: 22.81px;
      }
      .btnsHeader {
        padding-left: 63px;
        padding-right: 48px;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: end;
        margin-left: auto;
        margin-right: auto;
        a {
          font-family: Lexend, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #45494e;
          font-style: normal;
          text-align: center;
          padding-left: 32px;
          cursor: pointer;
        }
      }

      .btnHeader {
        color: #fff;
        text-align: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Lexend, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        max-width: 194px;
        width: 100%;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: #ff4f00;
        border: none;
        margin-left: auto;
      }
    }
  }

  .helpHeader {
    height: 70px;
  }

  @media screen and (max-width: 540px) {
    .headerLanding {
      box-shadow: none;
      .contentHeader {
        align-items: center;
        justify-content: space-between;
        img {
          width: 105.73px;
          height: 20px;
          padding-left: 0px;
        }
        .btnsHeader {
          display: none;
        }
        .btnHeader {
          margin-left: 0px;
          padding: 8px 19px;
          max-width: 148px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media screen and (min-width: 541px) and (max-width: 1000px) {
    .headerLanding {
      .contentHeader {
        .btnsHeader {
          display: none;
          a {
            padding-left: 0px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    .contentHeader {
      margin: 0px 20px;
    }
  }

  @media screen and (min-width: 1500px) {
    .contentHeader {
      max-width: 1404px !important;
    }
  }

  @media screen and (min-width: 1600px) and (max-width: 1727px) {
    .headerLanding {
      .contentHeader {
        .btnHeader {
          padding: 12px 32px;
          border-radius: 8px;
        }
      }
    }
  }

  @media screen and (min-width: 1728px) {
    .headerLanding {
      .contentHeader {
        .btnHeader {
          padding: 12px 32px;
          border-radius: 8px;
        }
      }
    }
  }
`;
