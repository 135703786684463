import React, { useEffect, useRef, useState } from "react";
import { StepsCircle } from "@prima-afp/prima-ui-elements/dist/core";
import { useStateDigitalTransfersContext } from "../../context/DigitalTransfersContext";
import { DivCircleSteps } from "./style";

const initialState = [
  {
    id: 1,
    title: "Datos de contacto",
    current: false,
    active: false,
  },
  {
    id: 2,
    title: "Información para tu cuenta",
    current: false,
    active: false,
  },
  {
    id: 3,
    title: "Validación de identidad",
    current: false,
    active: false,
  },
  {
    id: 4,
    title: "Código de Verificación",
    current: false,
    active: false,
  },
];

export const Steps = () => {
  const { stepCurrent } = useStateDigitalTransfersContext();
  const [steps, setSteps] = useState(initialState);

  useEffect(() => {
    setSteps((prev) =>
      prev.map((item) => ({
        ...item,
        current: item.id === stepCurrent,
        active: item.id < stepCurrent,
      }))
    );
  }, [stepCurrent]);

  return (
    <DivCircleSteps style={{ marginBottom: "48px", width: "100%" }}>
      <StepsCircle
        steps={steps}
        colorBorderActive={"#00A499"}
        colorBorder={"#00A499"}
        colorText={"#00A499"}
      />
    </DivCircleSteps>
  );
};
