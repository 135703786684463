import { Button } from '@prima-afp/prima-ui-elements/dist/core';
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { TraspasoModal } from "../../../shared/components/Modal";
import { BodyModalReco } from "./styles";
import { sendToData } from '../../constants/sendToDataLayer';
import { updateUrl } from '../../constants/updateUrl';
import { ValidStepFundType } from '../../constants/validate';
import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from '../../context/DigitalTransfersContext';
import { useCatchFetch } from '../../hooks/useCatchFetch';
import { SendSaveEmployer, SendSaveFundType } from '../../services';
import leftArrow from './leftArrow.svg';
import { ButtonsContainer, DivBackButton } from './styles';
import Bowser from 'bowser';
import platform from 'platform';
import androidUnlock from "./resources/androidUnlock.gif";
import androidAsk from "./resources/androidAsk.png";
import iosUnlock from "./resources/iosUnlock.gif";
import iosAsk from "./resources/iosAsk.png";
import desktopAsk from "./resources/desktopAsk.png";
import desktopUnlock from "./resources/desktopUnlock.gif";
export const SendButton = ({buttonRef}) => {

    const { userWeb, userWebTransfer, codeValidateDocument, heldPosition, urlFlow, lastStep } = useStateDigitalTransfersContext();
    const { SendFetch, SetLoading } = useCatchFetch();
    const dispatch = useDispatchDigitalTransfersContext();    
    const history = useHistory();  
    const [formDisabled, setFormDisabled] = useState(true);    
    const [permissionsWarning, setPermissionsWarning] = useState(null);
    const [showPreventiveModal, setShowPreventiveModal] = useState(null);
    const variantes = {5: 'tipo_a', 6: 'tipo_b', 7: 'tipo_c' };

    useEffect(() => {
        if (!userWeb.workerType || userWeb.codeFundType == null) { setFormDisabled(true);}
        else if (userWeb.codeFundType == null) { setFormDisabled(true);}
        else if (heldPosition == null) { setFormDisabled(true);}
        else if (heldPosition && (userWeb.pepInstitutionName == '' || userWeb.pepPositionHeld == '' )) {setFormDisabled(true);}   
        else{setFormDisabled(false);}
    }, [userWeb, heldPosition]);

    const getOperatingSystem = () => {
        // Obtener información sobre el sistema operativo
        const os = platform.os;
        return `${os.family} ${os.version}`;
    };
    const getBrowserInfo = () => {
        const browserInfo = Bowser.getParser(window.navigator.userAgent);
        return {
            name: browserInfo.getBrowserName(),
            version: browserInfo.getBrowserVersion(),
        };
    };
    const getDataDevice = () => {
        // Obtenemos información del dispositivo y del navegador
        const os = getOperatingSystem();
        const browserInfo = getBrowserInfo();    
        var deviceInfo =  os + "|" + browserInfo.name + "|" + browserInfo.version;
        return deviceInfo.toLowerCase();
    };
    const deviceInfo = getDataDevice();

    const BackStep = () =>{
        dispatch({
            type: 'BACK_FUND_TYPE_STEP',
        });
        dispatch({ type: 'FIELD', payload: { field: 'stepCurrent', value: 1 } });
        updateUrl(history, window.location.href, '/pasos0');
    };

    const checkCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            // Detener el stream de video para liberar la cámara
            stream.getTracks().forEach(track => track.stop());
            return true;
        } catch (error) {
            return false;
        }
    };

    const askPermissisons = async () => {
        SetLoading(true);
        var payload = {
            section: 'paso2_modal_solicitud_permiso', 
            option: "mostrar_alerta_navegador",
            dni: userWeb.documentNumber,
        };
        sendToData(window.location.href, "button_click", "button_click", payload, urlFlow); 
        const statusPermisos = await checkCameraPermission();            
        setShowPreventiveModal(false); 
        if(statusPermisos == false){                      
            dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'flagPermisssionCamera', value: false}});
            setShowPreventiveModal(false);
            setPermissionsWarning(false);
            var payloadNocamera = {
                section: 'paso 2', 
                message: "Necesitamos acceder a tu cámara",
                option: 'entendido',
                dni: userWeb.documentNumber,
            };
            sendToData(window.location.href, "mensaje_click", "mensaje", payloadNocamera, urlFlow); 
            SetLoading(false);
        } else if(statusPermisos == true) {
            dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'flagPermisssionCamera', value: true}});
            dispatch({
                type: 'COMPLETE_FUND_TYPE_STEP',
                payload: userWebTransfer
            });
            SetLoading(false);
            updateUrl(history, urlFlow,'/pasos3');
        } 
    };

    const Send = async (type) => {


        SetLoading(true);
        const validation = ValidStepFundType(userWeb, heldPosition, false);
        if (validation == null) {      
            const bodySaveEmployeer = {
                documentType: userWeb.documentType,
                documentNumber: userWeb.documentNumber,
                workerType: 'I',
                rucEmployer: null,
                nameEmployer: null,
            };
            await SendFetch(SendSaveEmployer(bodySaveEmployeer)).then((resSaveEmployeer) => {
                if(resSaveEmployeer.code == 0){
                    dispatch({
                      type: "FIELD_SECOND",
                      payload: { field1: "userWeb", field2: "workerType", value: null },
                    });
                    dispatch({
                      type: 'SET_CONFIG_MODAL',
                      payload: {
                          idModal:  "btn-step2-error-employeer-send",
                          content: 'Seleccione su fondo de nuevo',
                      }
                    });
                }else{
                    const bodySave = {
                        documentType: userWeb.documentType,
                        documentNumber: userWeb.documentNumber,
                        fundType: userWeb.fundType,
                        promoterCode: userWeb.promoterCode,
                        heldPosition: heldPosition,
                        pepInstitutionName: userWeb.pepInstitutionName,
                        pepPositionHeld: userWeb.pepPositionHeld
                    };

                    SendFetch(SendSaveFundType(bodySave))
                    .then(async (res) => {
                        dispatch({
                            type: "FIELD_SECOND",
                            payload: { field1: "userWeb", field2: "testABOption", value: type },
                        });

                         //Seccción para enviar atm.event
                        const metadata_1_ans = userWeb.workerType == "D"?  "trabajo de forma dependiente para una empresa" : "trabajo de forma independiente";
                        const metadata_2_ans = heldPosition?  "si" : "no";
                        const metadata_3_ans = userWeb.fundType;
                        const currentUrl = window.location.href;
                        var payload = {
                          section: 'paso 2', 
                          metadata_1: "¿que tipo de trabajador eres? - " + metadata_1_ans,
                          metadata_2: "¿ha desempeñado un cargo publico en el peru o en el extranjero durante los ultimos 5 años? - " + metadata_2_ans,
                          metadata_3: "tipo de fondo - fondo " + metadata_3_ans,
                          option: 'siguiente', 
                          count_2: 1,
                          dni: userWeb.documentNumber,
                          telefono: userWeb.phoneNumber,
                          email: userWeb.email,
                          status: codeValidateDocument == 4? 'no_reniec': 'reniec_activo',
                          variante: variantes[type] || 'original',
                        };
                        sendToData(currentUrl, "step", "paso_2", payload, urlFlow);     
                        if (lastStep < 3) {  dispatch({ type: 'FIELD', payload: { field: 'lastStep', value: 3 } });}  

                        //sección para  generar userWeb en sessionStorage
                        const payloadContext = {
                            "age": userWeb.age,
                            "documentType": userWeb.documentType,
                            "documentNumber": userWeb.documentNumber,
                            "tcConfirmation": userWeb.tcConfirmation,
                            "adpConfirmation": userWeb.adpConfirmation,
                            "pepInstitutionName": userWeb.pepInstitutionName,
                            "pepPositionHeld": userWeb.pepPositionHeld,
                            "heldPosition": userWeb.heldPosition,
                            "firstName": userWeb.firstName,
                            "email": userWeb.email,
                            "phoneNumber": userWeb.phoneNumber,
                            "address": userWeb.address,
                            "workerType": userWeb.workerType,
                            "rucEmployer": userWeb.rucEmployer,
                            "tradeNameEmployer": userWeb.tradeNameEmployer,
                            "codeFundType": userWeb.codeFundType,
                            "fundType": userWeb.fundType,
                            "testABOption": type,
                        };
                        const secretKey = sessionStorage.getItem("timestampFirstQuery");
                        const objetoEncriptado = CryptoJS.AES.encrypt(JSON.stringify(payloadContext), secretKey).toString();              
                        sessionStorage.setItem("userWeb", objetoEncriptado);      
                                                 

                        //EXPERIMENTO
                        if(type ==1 ){
                            dispatch({
                                type: 'COMPLETE_FUND_TYPE_STEP',
                                payload: userWebTransfer
                            });
                            updateUrl(history, urlFlow,'/pasos3');
                        }else if(type == 2){
                            dispatch({
                                type: 'COMPLETE_FUND_TYPE_STEP',
                                payload: userWebTransfer
                            });
                            dispatch({ type: 'FIELD', payload: { field: 'skipReco', value: true } });
                            updateUrl(history, urlFlow,'/pasos3validacion-biometrica');
                        }else if(type == 3){
                            const statusPermisos = await checkCameraPermission();
                            if(statusPermisos == false){          
                                dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'flagPermisssionCamera', value: false}});
                                setPermissionsWarning(false);
                                var payloadNocamera = {
                                    section: 'paso 2', 
                                    message: "Necesitamos acceder a tu cámara",
                                    option: 'entendido',
                                    dni: userWeb.documentNumber,
                                };
                                sendToData(window.location.href, "mensaje_click", "mensaje", payloadNocamera, urlFlow); 

                            } else if(statusPermisos == true) {
                                dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'flagPermisssionCamera', value: true}});
                                dispatch({
                                    type: 'COMPLETE_FUND_TYPE_STEP',
                                    payload: userWebTransfer
                                });
                                updateUrl(history, urlFlow,'/pasos3');
                            } 
                        }else if(type==4 || type==5 || type==6 || type==7){
                            navigator.permissions.query({ name: 'camera' })
                            .then((permissionStatus) => {
                                if(permissionStatus.state === 'granted'){
                                    dispatch({
                                        type: 'COMPLETE_FUND_TYPE_STEP',
                                        payload: userWebTransfer
                                    });
                                    updateUrl(history, urlFlow,'/pasos3');
                                }else if(permissionStatus.state === 'denied'){
                                    dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'flagPermisssionCamera', value: false}});
                                    setPermissionsWarning(false);
                                    var payloadNocamera = {
                                        section: 'paso 2', 
                                        message: "Necesitamos acceder a tu cámara",
                                        option: 'entendido',
                                        dni: userWeb.documentNumber,
                                    };
                                    sendToData(window.location.href, "mensaje_click", "mensaje", payloadNocamera, urlFlow); 
                                }
                                else if(permissionStatus.state === 'prompt' || permissionStatus.state === null){
                                    var payloadAsk = {
                                        section: 'paso 2', 
                                        message: "Activa tu cámara para un traspaso 100% digital",
                                        option: 'entendido',
                                        dni: userWeb.documentNumber,
                                    };
                                    sendToData(window.location.href, "mensaje_click", "mensaje", payloadAsk, urlFlow); 
                                    setShowPreventiveModal(true);
                                }
                            })
                            .catch((error) => {
                              console.error('Error al comprobar el permiso de la cámara:', error);
                            });
                        }                                             

                    });
                }
            }).catch((error) => {
                console.log(error);
                dispatch({
                  type: 'SET_CONFIG_MODAL',
                  payload: {
                      idModal:  "btn-step2-error-employeer-send",
                      content: 'Seleccione su fondo de nuevo',
                  }
                });
            });              
        } else {
            dispatch({
                type: 'SET_CONFIG_MODAL',
                payload: {
                    idModal:  "btn-step2-error-fund-type-send", 
                    content: validation,
                }
            });
            SetLoading(false);
        }
    };

    const isDisabled = () => {
        if (!userWeb.workerType || userWeb.codeFundType == null) { return true;}
        if (userWeb.codeFundType == null) { return true; }
        if (heldPosition == null) { return true; }
        if (heldPosition && (userWeb.pepInstitutionName == '' || userWeb.pepPositionHeld == '' )) {return true;}
        return false;
    };

    const goNextStep= () => {
        var payload = {
            section: 'paso2_modal_advertencia_camara_bloqueada', 
            option: "entendido_camara_bloqueada",
            dni: userWeb.documentNumber,
        };
        sendToData(window.location.href, "button_click", "button_click", payload, urlFlow); 
        dispatch({
            type: 'COMPLETE_FUND_TYPE_STEP',
            payload: userWebTransfer
        });
        updateUrl(history, urlFlow,'/pasos3');
    };


    return (
        <ButtonsContainer>        

            {
                (window.innerWidth < 451) && 
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Button id="btn-fund-type-send" onClick={ ()=>Send(4)} ref={buttonRef} 
                        disabled={isDisabled()}
                        className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                    >               
                        Continuar
                    </Button> 
                    <Button id="btn-fund-type-send-testA" onClick={ ()=>Send(5)} ref={buttonRef} 
                        disabled={isDisabled()}
                        className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                    >               
                        Continuar
                    </Button> 
                    <Button id="btn-fund-type-send-testB" onClick={ ()=>Send(6)} ref={buttonRef} 
                        disabled={isDisabled()}
                        className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                    >               
                        Continuar
                    </Button> 
                    <Button id="btn-fund-type-send-testC" onClick={ ()=>Send(7)} ref={buttonRef} 
                        disabled={isDisabled()}
                        className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                    >               
                        Continuar
                    </Button> 
                    <DivBackButton  className={"newUiBtn"} onClick={BackStep}> Regresar  </DivBackButton>
                </div>
            }
            {
                (window.innerWidth > 451) && <div id="wrapperButtonsStep2" style={{width: "100%", display: "flex"}}>

                <Button id="btn-fund-type-send-test" onClick={ ()=>Send(4)} ref={buttonRef} 
                    disabled={isDisabled()}
                    className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                >               
                    Continuar
                </Button> 
                <Button id="btn-fund-type-send-testA" onClick={ ()=>Send(5)} ref={buttonRef} 
                    disabled={isDisabled()}
                    className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                >               
                    Continuar
                </Button> 
                <Button id="btn-fund-type-send-testB" onClick={ ()=>Send(6)} ref={buttonRef} 
                    disabled={isDisabled()}
                    className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                >               
                    Continuar
                </Button> 
                <Button id="btn-fund-type-send-testC" onClick={ ()=>Send(7)} ref={buttonRef} 
                    disabled={isDisabled()}
                    className={formDisabled? "newUiBtn disabledBtn": "newUiBtn enabledBtn" }
                >               
                    Continuar
                </Button> 
                </div>
            }      

            {permissionsWarning == false  &&            
                <TraspasoModal isOpen={true} btnClose={false}>
                    <BodyModalReco className="modal-content-inner-skipReco modalWarning">
                        <h2 className="title titleWarning">Necesitamos acceder a tu cámara</h2>
                        <p className="content contentWarning">
                            Puedes activar el permiso desde la configuración de tu navegador y luego volver a intentar.
                        </p>
                        {
                            deviceInfo.includes('android') &&  <img className="gifWarning" src={androidUnlock} />
                        }
                        {
                            deviceInfo.includes('ios') &&  <img className="gifWarning" src={iosUnlock} />
                        }              
                        {
                            (!deviceInfo.includes('ios') && !deviceInfo.includes('android')) &&  <img className="gifWarning" src={desktopUnlock} />
                        }
                        <Button onClick={goNextStep} className="newUiBtn prevModal">
                            Entendido
                        </Button>
                    </BodyModalReco>
            </TraspasoModal>
            }
            {showPreventiveModal  &&            
                <TraspasoModal isOpen={true} btnClose={false}>
                    <BodyModalReco className="modal-content-inner-skipReco">  
                        <h2 className="title prevDesktopT">Activa tu cámara para un traspaso 100% digital</h2>
                        <p className="content prevDesktopC">
                            Selecciona <b style={{fontWeight: '700'}}>'Permitir'</b> para poder confirmar que eres tú quien está realizando el traspaso.
                        </p>
                        {
                            deviceInfo.includes('android') &&  <img className="gifPreventive" src={androidAsk} />
                        }
                        {
                            deviceInfo.includes('ios') &&  <img className="gifPreventive" src={iosAsk} />
                        }              
                        {
                            (!deviceInfo.includes('ios') && !deviceInfo.includes('android')) &&  <img className="gifPreventive" src={desktopAsk} />
                        }
                        <Button onClick={askPermissisons} className="newUiBtn prevModal">
                            Entendido
                        </Button>
                    </BodyModalReco>
                </TraspasoModal>
            }
            
         </ButtonsContainer>
    );
};
