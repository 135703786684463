export const ParseResponse = (res) => {
    return {
        ...res,
        userWebTransfer: {
            ...(res.userWebTransfer),
            lastName: _trim(res.userWebTransfer.lastName),
            sureName: _trim(res.userWebTransfer.sureName),
            firstName: _trim(res.userWebTransfer.firstName),
            validReniec: res.userWebTransfer.consultReniec,
        },
        userWeb: {
            lastName: _trim(res.userWebTransfer.lastName),
            lastNameEnc: _trim(res.userWebTransfer.lastNameEnc),
            sureName: _trim(res.userWebTransfer.sureName),
            sureNameEnc: _trim(res.userWebTransfer.sureNameEnc),
            firstName: _trim(res.userWebTransfer.firstName),
            nameEnc: _trim(res.userWebTransfer.nameEnc),
            address: _trim(res.userWebTransfer.address),
            codeViaValue: _trim(res.userWebTransfer.codeViaValue),
            codeVia: _trim(res.userWebTransfer.codeVia),
            descriptionVia: _trim(res.userWebTransfer.descriptionVia),
            districtValue: _trim(res.userWebTransfer.districtValue),
            districtCode: _trim(res.userWebTransfer.districtCode),
        },
    };
};

export const ParseResponseToken = (res) => {
    return {
        ...res,
        userWebTransfer: {
            ...(res.userWebTransfer),
            lastName: _trim(res.lastName),
            sureName: _trim(res.sureName),
            firstName: _trim(res.firstName),
        },
        userWeb: {
            lastName: _trim(res.lastName),
            //lastNameEnc: _trim(res.lastNameEnc),
            sureName: _trim(res.sureName),
            //sureNameEnc: _trim(res.sureNameEnc),
            firstName: _trim(res.firstName),
            //nameEnc: _trim(res.nameEnc),
            documentType:_trim(res.documentType),
            documentNumber:_trim(res.documentNumber),
       
            email: _trim(res.email),
            emailConfirmation: _trim(res.email),
            phoneNumber: _trim(res.phone),

            codeVia: res.codeVia  ? res.codeVia :  '',
            descriptionVia: res.descriptionVia  ? res.descriptionVia :  '',
            codeHousing: res.codeHousing  ? res.codeHousing :  '',
            descriptionHousing: res.descriptionHousing  ? res.descriptionHousing :  '',
            addressReference: res.reference  ? res.reference :  '',
            departmentCode: res.departmentCode  ? res.departmentCode :  '',
            provinceCode: res.provinceCode  ? res.provinceCode :  '',
            districtCode: res.districtCode  ? res.districtCode :  '',
            address: res.address  ? res.address :  '', 
        },
    };
};

export const _trim = (val) => {
    if (!val) return "";
    return String(val).trim();
};

export const getMonthFromDate = (date) => {
    const month = String(date).split('-')[1];

    switch (parseInt(month)) {
        case 1: return 'Enero';
        case 2: return 'Febrero';
        case 3: return 'Marzo';
        case 4: return 'Abril';
        case 5: return 'Mayo';
        case 6: return 'Junio';
        case 7: return 'Julio';
        case 8: return 'Agosto';
        case 9: return 'Septiembre';
        case 10: return 'Octubre';
        case 11: return 'Noviembre';
        case 12: return 'Diciembre';
        default: return 'Mes no válido';
    }
};

export const getYearFromDate = (date) => {
    return String(date).split('-')[2];
};