import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { SliderContainer } from "./style"
import { flechaDerecha } from "../../../assets" 
import { itemsSliderTabs, settingsTabs } from "../../../constants/data"
import { sanitizar } from "../../../../digital-transfers/constants/sendToDataLayer"
import { redirectURL } from "../../../constants/functions"

const SliderTabs = () => {
  return (
    <SliderContainer>
      <div className="slider-container">
        <Slider {...settingsTabs}>
          {itemsSliderTabs.map(item => (
            <div id={`slider${item.id}`} className="slide-item" key={item.id}>
              <div className= {`containerItem ${item.classContainer}`}>
                <div className="globo">
                  <img src={item.iconGlobo} alt="bandera" className="imgLogo" />
                  {item.textGlobo}
                </div>
                <div className="titleSlider">{item.title}</div>
                <div className="textSlider">{item.text}</div>
                <a
                  className="redirecSlider"
                  onClick={() =>
                    redirectURL(item.link, {
                      event: "link_click",
                      payload: {
                        section: "landing traspaso",
                        sub_section: "proceso de traspaso",
                        option: sanitizar(item.textLink),
                      },
                    })
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.textLink}{" "}
                  <img src={flechaDerecha} alt="fleca derecha" />
                </a>
                <div className="conteinerImage">
                  <img
                    src={item.image}
                    alt="Slider hoy"
                    className="imageSlider"
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </SliderContainer>
  )
}

export default SliderTabs
