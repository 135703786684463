import { Input } from "@prima-afp/prima-ui-elements/dist/core";
import React, { useEffect, useState } from "react";
import { StepTitleGray } from "../../../shared/components/StepTitle";
import { FramePersonalData } from "./FramePersonalData";
import { ContainerIdentity, ContainerInputs } from "./styles";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { FrameAddress } from "./FrameAddress";
import { MainTitleGreen } from "../../../shared/components/MainTitle";
import DropdownInput from "../../components/InputSelect/DropdownInput";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import { validateReniec, Abandon } from "../../services";

export const StepIdentity = () => {
  const {
    listGenerals,
    showPersonalData,
    isAddressDNI,
    isConsultedReniec,
    userWeb,
    //stepCurrent,
    enabledAbandonmentStep1,
    //confirmationTC,
    validReniecActive,
    validSecontReniecActive
  } = useStateDigitalTransfersContext();
  const {
    email,
    emailConfirmation,
    phoneNumber,
    errorPhoneInput,
    errorEmailInput,
    errorEmailCinput,
    errorDocNumberInput,
  } = userWeb;
  const dispatch = useDispatchDigitalTransfersContext();

  const [style1, setStyle1] = useState({});
  const [style2, setStyle2] = useState({});
  const [style3, setStyle3] = useState({});
  const [documentResponse, setDocumentResponse] = useState([]);
  const { SendFetch, SetLoading } = useCatchFetch();

  useEffect(() => {
    setDocumentResponse(
      listGenerals?.listDocumentsTypes
        ? listGenerals.listDocumentsTypes
        : [{ code: "00", description: "DNI" }]
    );
    dispatch({ type: "FIELD", payload: { field: "validReniecActive", value: true}});
  }, []);

  useEffect(() => {
    errorDocNumberInput?
      setStyle1({ border: "2px solid red" })
      : setStyle1({});
    errorDocNumberInput?
      setStyle2({ color: "red !important" })
      : setStyle2({});
    errorDocNumberInput? 
      setStyle3({ borderLeftColor: "red !important" })
      : setStyle3({});
  }, [errorDocNumberInput]);

  const registerDocType = (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "documentType",
        value: e.code,
      },
    });
  };

  const onChangeDoc = (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "documentNumber",
        value: e.target.value.replace(/[^0-9]/g, ""),
      },
    });
  };

  useEffect(() => {
    // Agregar un event listener cuando el componente se monta
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [enabledAbandonmentStep1]);

  const handleBeforeUnload = async (e) =>{
      const message = "¿Seguro que quieres salir?";
      e.returnValue = message;
      const userResponse = window.confirm(message);
      try { 
          if(enabledAbandonmentStep1 == true){
            var BodyPayload = { step: 1, documentNumber: userWeb.documentNumber, documentType: "00", active: true };
            var abandonRes = await SendFetch(Abandon(BodyPayload));
          }
          
        } catch (error) {
          console.error(error);
      }
  };

  return (
    <ContainerIdentity>
      <MainTitleGreen>¡Empecemos con tu cambio a Prima AFP!</MainTitleGreen>

      <StepTitleGray>
        Por favor, cuéntanos cómo nos podemos comunicar contigo:
      </StepTitleGray>

      <ContainerInputs id="idStep1ContainerInputs">
        <div className="inputGroupStep1 row_1">
          {showPersonalData == false && (
            <div className="WrapperDropdownInput">
              <DropdownInput
                className=""
                onlyNumbers={true}
                onChange={onChangeDoc}
                registerSelect={registerDocType}
                noPadding={true}
                defaultValueSelect={userWeb.documentType}
                defaultValueInput={userWeb.documentNumber}
                name={"documentNumber"}
                selectName={"documentType"}
                selectOptions={documentResponse}
                label={"Número de documento"}
                placeholder={"N° de documento"}
                error={errorDocNumberInput}
                errorText={"Número de documento"}
                maxlength={8}
              />
            </div>
          )}
          {showPersonalData == true && (
            <Input
              id="docDisabled"
              className="inputStep1 "
              label={errorPhoneInput == true ? "" : "Número de documento"}
              type="text"
              placeholder="Número de documento"
              maxLength="8"
              value={userWeb.documentNumber}
              disabled={showPersonalData}
            />
          )}

          <Input
            id="idPhone"
            className="inputStep1"
            label={errorPhoneInput == true ? "" : "Número de celular"}
            type="text"
            placeholder="Ingresa tu número de celular"
            maxLength="9"
            value={phoneNumber}
            onChange={(e) =>
              dispatch({
                type: "FIELD_SECOND",
                payload: {
                  field1: "userWeb",
                  field2: "phoneNumber",
                  value: e.target.value.replace(/[^0-9]/g, ""),
                },
              })
            }
            error={errorPhoneInput}
            errorMessage="Número de celular inválido"
            disabled={showPersonalData}
          />
        </div>

        <div className="inputGroupStep1 row_2">
          <Input
            id="idMail"
            className="inputStep1 "
            label={errorEmailInput == true ? "" : "E-mail"}
            type="email"
            placeholder="Ingresa tu e-mail"
            value={email}
            onChange={(e) =>
              dispatch({
                type: "FIELD_SECOND",
                payload: {
                  field1: "userWeb",
                  field2: "email",
                  value: e.target.value,
                },
              })
            }
            error={errorEmailInput}
            errorMessage="e-mail inválido"
            disabled={showPersonalData}
          />
          <Input
            id="idMacilC"
            className="inputStep1 "
            label={errorEmailCinput == true ? "" : "Confirma E-mail"}
            type="email"
            placeholder="Confirma tu e-mail"
            value={emailConfirmation}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onChange={(e) =>
              dispatch({
                type: "FIELD_SECOND",
                payload: {
                  field1: "userWeb",
                  field2: "emailConfirmation",
                  value: e.target.value,
                },
              })
            }
            error={errorEmailCinput}
            errorMessage="El e-mail no coincide"
            disabled={showPersonalData}
          />
        </div>
      </ContainerInputs>

      {showPersonalData && isConsultedReniec && validReniecActive && validSecontReniecActive && ( 
        <div>
            <FramePersonalData id="idPersonalData" /> 
        </div>
      )}
      {isAddressDNI == false && validReniecActive  && validSecontReniecActive && <FrameAddress id="idOtherAddress" />}
    </ContainerIdentity>
  );
};
