import PropTypes from "prop-types";
import { Input, Button, CheckBox } from "@prima-afp/prima-ui-elements/dist/core";
import React, { useState, useEffect } from "react";
import { WrapperModalCTA, ButtonsContainer, ButtonClose, ModalContent, FooterButton } from "./styles";
import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from "../../../digital-transfers/context/DigitalTransfersContext";
import { Link } from 'react-router-dom';
import { sendToData } from "../../../digital-transfers/constants/sendToDataLayer";
import backgroundImageModal from "./backgroundImageModal.png";
import backgroundImageModalM from "./backgroundImageModalM.png";
import { helpMail } from "../../../digital-transfers/services";
import { useCatchFetch } from '../../../digital-transfers/hooks/useCatchFetch';
import imageLike from "../../../digital-transfers/assets/images/like.svg";


import {
    Modal as BaseModal,
} from "@prima-afp/prima-ui-elements/dist/core";

export function CallToModal() {
    const {showModalCTA, withoutHelp, userWeb } = useStateDigitalTransfersContext();
    const { SendFetch } = useCatchFetch();
    const dispatch = useDispatchDigitalTransfersContext();    
    const {
        phoneNumberCTA,
        errorPhoneInputCTA,
    } = userWeb;
    const [emailSend, setEmailSend] = useState(false);
    useEffect(() => {
        if( phoneNumberCTA.length == 9 && phoneNumberCTA[0] != '9' ){
            dispatch({
                type: "FIELD_SECOND",
                payload: { field1: "userWeb", field2: "errorPhoneInputCTA", value: true },
              });
        }else if(phoneNumberCTA.length == 9 && phoneNumberCTA[0] == '9'){
            dispatch({
                type: "FIELD_SECOND",
                payload: { field1: "userWeb", field2: "errorPhoneInputCTA", value: false },
              });
        }
      }, [phoneNumberCTA]);   
    
    const closeModal = (type) => {
        if(type ==1 ){
            const currentUrl = window.location.href;
            var payload = {
                section: 'paso 0', 
                sub_section: 'modal c2c',
                option: 'lo hare yo 100% online',
                telefono: phoneNumberCTA,
            };
            sendToData(currentUrl, "button_click", "button_click", payload);  
        }
        if(type ==2 ){
            const currentUrl = window.location.href;
            var payload2 = {
                section: 'paso 0', 
                sub_section: 'modal c2c',
                option: 'cerrar',
                telefono: phoneNumberCTA,
            };
            sendToData(currentUrl, "button_click", "button_click", payload2);  
        }
        dispatch({ type: 'FIELD', payload: { field: 'withoutHelp', value: true } });
    };
    const Send = async () => {
        var body = {
            indicator: 1,
            parameters: {
                phoneNumber: phoneNumberCTA
            }
        };
        await SendFetch(helpMail(body)).then((res) => {
            if(res.code == 1){
                setEmailSend(true); 
                const currentUrl = window.location.href;
                var payload = {
                    section: 'paso 0', 
                    sub_section: 'modal c2c',
                    option: 'quiero que me llamen',
                    telefono:phoneNumberCTA,
                };
                sendToData(currentUrl, "button_click", "button_click", payload);  
            }
        });
    };
    const isDisabled = () => {
        if (
            phoneNumberCTA.length == 9 && !errorPhoneInputCTA
        ) {
          return false;
        }
        return true;
    };

    const OpenModal_TC = () => {
        const title = "Cláusula de protección de datos informativa";
        const type = "TC";
        dispatch({
            type: "SHOW_TCMODAL",
            payload: {
            title: title,
            type: type,
            changeLoading: false,
            value: userWeb.tcConfirmation,
            },
        });
        const currentUrl = window.location.href;
        var payload = {
            section: 'paso 0', 
            sub_section: 'modal c2c',
            option: 'terminos y condiciones',
        };
        sendToData(currentUrl, "link_click", "link_click", payload);  
    };

    const openPagePolitica = ()=>{
        const currentUrl = window.location.href;
        var payload = {
            section: 'paso 0', 
            sub_section: 'modal c2c',
            option: 'politica de privacidad',
        };
        sendToData(currentUrl, "link_click", "link_click", payload);  
        window.open("https://www.prima.com.pe/public-zone/politica-de-privacidad/#tratamiento-y-transferencia-de-tus-datos-personales", "_blank", "noopener");
    };

    const returnPrevious = () => {
        dispatch({ type: 'FIELD', payload: { field: 'withoutHelp', value: true } });
    };


    return (

        <WrapperModalCTA >
            {showModalCTA && !withoutHelp  &&                
            <div className="ModalGrayBackground ">
                <div className="Modal basewidth">

                        <div className="ModalWrapper">
                                
                                { !emailSend && 
                                <div>
                                <ButtonClose className="closeX" onClick={() =>closeModal(2)}>x</ButtonClose> 
                                <div style={{textAlign: 'center'}}>     
                                        {
                                            window.innerWidth< 450 && <div className="imgModal" style={{ backgroundImage: "url(" + backgroundImageModalM + ")"}} > </div>
                                        }                                                                    
                                        {
                                            window.innerWidth> 450 &&<div className="imgModal" style={{ backgroundImage: "url(" + backgroundImageModal + ")"}} > </div>
                                        }
                                        <ModalContent style={{height: '300px',}}>
                                            <div className="greenText title1"> <b>¡Te ayudamos con tu traspaso!</b></div>
                                            <div className="greenText title2">Déjanos tus datos</div>                    
                                            { window.innerWidth> 720 && <div style={{  paddingTop: '8px'}}>Queremos acompañarte y darte la mejor experiencia Prima</div>}
                                            <div className="buttonWrapperCTA">
                                                <Input
                                                    id="idPhone"
                                                    className="inputStep1"
                                                    label={errorPhoneInputCTA == true ? "" : "Número de celular"}
                                                    type="text"
                                                    placeholder="Ingresa tu número de celular"
                                                    maxLength="9"
                                                    value={phoneNumberCTA}
                                                    onChange={(e) =>
                                                    dispatch({
                                                        type: "FIELD_SECOND",
                                                        payload: {
                                                        field1: "userWeb",
                                                        field2: "phoneNumberCTA",
                                                        value: e.target.value.replace(/[^0-9]/g, ""),
                                                        },
                                                    })
                                                    }
                                                    error={errorPhoneInputCTA}
                                                    errorMessage="Número de celular inválido"
                                                />
                                            </div>
                                            <div id="textTC" style={{ paddingBottom: '16px' }}>
                                                {"Al cliquear en ''Quiero que me llamen'' acepto los"}
                                                <span onClick={OpenModal_TC} className="textBold">
                                                    <b> Términos y Condiciones</b>
                                                </span>
                                                y
                                                <span onClick={openPagePolitica} className="textBold">
                                                    <b> Política de Privacidad</b>
                                                </span>
                                            </div>

                                        </ModalContent>
                                        <FooterButton style={{height: '66px', borderTop: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            {
                                                window.innerWidth> 20 &&
                                                <div className="wrapperButtonCTA">                                                    
                                                    <Button
                                                        id="btn1"
                                                        className="buttonNCTA"
                                                        style={{backgroundColor: '#00CCCC !important', boxShadow: 'none'}}
                                                        onClick={() =>closeModal(1)}
                                                    >
                                                        Lo haré yo 100% online
                                                    </Button>
                                            
                                                    <Button
                                                        id="btn2"
                                                        className="buttonCTA"
                                                        onClick={Send}
                                                        disabled={isDisabled()}
                                                    >
                                                        Quiero que me llamen
                                                    </Button>
                                                </div>
                                            }
                                        </FooterButton>
                                </div>   
                                </div>
                                }    
                                { emailSend &&   
                                    <div className="emailSendContent" style={{textAlign: 'center'}}>
                                        <img
                                        src={imageLike}
                                        alt="modal_image"
                                        className="modal-image"
                                        />
                                        <h2 className="greenText title1">Listo</h2>
                                        <p className="emailSendDescription">
                                        En unos momentos un asesor se comunicará contigo.
                                        </p>
                                        <Button onClick={returnPrevious} className="modal-button">
                                        Entendido
                                        </Button>
                                    </div>
                                }      
                        </div>
                    


                </div>
                <style>
                {`
                    @media only screen and (max-width: 450px) {
                        .basewidth{                     
                            max-width: 335px !important; 
                        }
                    }
                    @media only screen and (min-width : 450px) and (max-width: 769px) {
                        .basewidth{                      
                            max-width: 704px !important; 
                        }
                    }
                    @media only screen and (min-width : 800px) and (max-width: 1000px) {
                        .basewidth{                            
                            max-width: 704px !important; 
                        }
                        
                    }
                    @media only screen and (min-width : 1000px) and (max-width: 1367px) {
                        .basewidth{                            
                            max-width: 858px !important;  
                        }
                    }
                    @media only screen and (min-width : 1367px)  {
                        .basewidth{                            
                            max-width: 858px !important;       
                            min-width: none !important;
                        }
                    }
                `}
                </style>
            </div>            
            }
        </WrapperModalCTA>

       
    );
}

