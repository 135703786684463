import styled from "styled-components"
import fondo1 from '../../../assets/images/backTagMobile3.svg'
import fondo2 from '../../../assets/images/backTagMobile2.svg'
import fondo3 from '../../../assets/images/backTagMobile1.svg'

export const SliderContainer = styled.div`
  height: 465px;
  .slider-container {
    width: 100%;
  }

  .slide-item {
    width: 100%;
    height: 465px;
    display: flex !important;
    justify-content: center;
    box-sizing: border-box;
    .containerItem {
      width: 100%;
      padding-top: 34px;
      padding-left: 24px;
      .globo {
        background-color: #fff;
        display: inline-flex;
        font-family: Lexend;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.0012em;
        text-align: left;
        padding: 4px 12px 4px 6px;
        border-radius: 999px;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        color: var(--Text-Gray-Default, #4e5258);
        .imgLogo {
          padding-right: 4px;
        }
      }
      .titleSlider {
        font-family: Lexend;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
        color: #45494e;
        padding-bottom: 16px;
      }
      .textSlider {
        max-width: 302px;
        font-family: Calibri;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #45494e;
        padding-bottom: 16px;
      }
      .redirecSlider {
        display: flex;
        align-items: center;
        font-family: Calibri;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: rgba(255, 79, 0, 1);
        text-decoration: underline;
        text-underline-offset: 0.16em; /* Espacio entre el texto y la línea subrayada */  

        cursor: pointer;
        img {
          padding-left: 4px;
        }
      }
      .conteinerImage {
        display: flex;
        width: 100%;
        justify-content: center;
        .imageSlider {
          display: flex;
          bottom: 0px;
          position: absolute;
        }
      }
    }
  }

  #slider1 {
    background: rgba(255, 247, 217, 1);
  }

  #slider2 {
    background: rgba(229, 250, 250, 1);
  }

  #slider3 {
    background: rgba(255, 237, 229, 1);
  }

  @media only screen and (max-width: 540px) {
    .slide-item {
      .containerItem {
        padding-top: 25px;
        padding-left: 37px;
        padding-right: 37px;
        .globo {
        }
        .titleSlider {
        }
        .textSlider {
        }
        .conteinerImage {
          .imageSlider {
            width: 333px;
            height: 254px;
          }
        }
      }
    }
    #slider1 {
    background: url(${fondo1});
  }

  #slider2 {
    background: url(${fondo2});
  }

  #slider3 {
    background: url(${fondo3});
  }
    .slick-dots {
      position: absolute;
      bottom: 16px; /* Ajusta según sea necesario */
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .slick-dots li {
      margin: 0px; /* Ajusta el espaciado entre puntos */
    }

    .slick-dots li button:before {
      font-size: 8px; /* Tamaño de los puntos */
      color: #FFFFFF !important; /* Color de los puntos */
    }

    .slick-dots li.slick-active button:before {
      font-size: 12px; /* Tamaño mayor para el punto activo en móvil */
      color: #FF4F00 !important; /* Color del punto activo */
      opacity: 1;
    }
  }

  @media screen and (min-width: 1600px) and (max-width: 1727px) {
    .slide-item {
      .containerItem {
        padding-left: 98px;

        .globo {
        }
        .titleSlider {
        }
        .textSlider {
          max-width: 350px;
        }
        .conteinerImage {
          justify-content: start;
          .imageSlider {
            padding-left: 46.15px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1728px) {
    .slide-item {
      .containerItem {
        padding-left: 162px;
        .globo {
        }
        .titleSlider {
        }
        .textSlider {
          max-width: 350px;
        }
        .conteinerImage {
          justify-content: start;
          .imageSlider {
          }
        }
      }
      .classContainer {
        padding-left: 120px;
      }
    }
  }
`
