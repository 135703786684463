import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Wrapper,ContentStepInitial, DivPage,  } from "../../components/Banner/styles";
import { Banner } from "../../components/Banner/Banner";

let user = null;

export const UserContext = React.createContext(user);

const StepInitialLayoutAF = ({ children }) => {
  const contentRef = useRef(null);
  const { pathname } = useLocation();

    return (
      <Wrapper>
        <ContentStepInitial ref={contentRef} id="app-main-content">       
            <Banner />
            <DivPage id="idDivPage" >                  
                  {children}            
            </DivPage> 
        </ContentStepInitial>
      </Wrapper>
    );




};

StepInitialLayoutAF.propTypes = {
  children: PropTypes.any
};

export default StepInitialLayoutAF;
