import styled, { css } from "styled-components";

export const ModalHelpDiv = styled.div`
    .helpSection {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 32px;
        margin-bottom: 32px;
        padding-top: 32px;
    }

    .linkHelp{
        cursor: pointer;
        color: var(--Text-Primary-Default, #ff4f00);
        text-align: center;
        letter-spacing: 0.02px;
        text-decoration-line: underline;
        font: 700 16px/150% Calibri, -apple-system, Roboto, Helvetica,
        sans-serif;
    }

        .modal-button{
        justify-content: center;
        border-radius: var(--Radius-Radius-16, 16px);
        white-space: nowrap;
        text-align: center;
        letter-spacing: 0.02px;
        padding: 16px 20px;
        font: 400 16px / 133% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
        background-color: var(--orangeColorMain);
        color: #FFFFFF;
    }

    .modal-content-inner{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .modal-title{
        font: 600 24px/108% Lexend, -apple-system, Roboto, Helvetica, sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 26px;
        -webkit-letter-spacing: 0em;
        -moz-letter-spacing: 0em;
        -ms-letter-spacing: 0em;
        letter-spacing: 0em;
        text-align: center;
        color: var(--Text-Gray-Default, #4e5258);
        padding-bottom: 16px;
    }
    .modal-description{    
        max-width: 252px;
        font-family: Calibri;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        -webkit-letter-spacing: 0em;
        -moz-letter-spacing: 0em;
        -ms-letter-spacing: 0em;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 47px;
    }

`;