import styled from "styled-components";

export const WrapperReco = styled.div`

    @media only screen and (max-width : 450px) { 
        width: 312px;
        padding: 0px 0px;
        .tableReco {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
            width: 281px !important;
            height: 216px !important;
            justify-content: space-between !important;
        }
        .column0 {
            width: 100%;
        }
        .containerReco {
            margin-bottom: 32px;
            align-self: center !important;
            margin-top: 40px;
            max-width: 100%;
        }
        .contentColumn {
            display: flex !important;
            flex-direction: row !important;
            align-items: center !important;
        }
        .textReco{
            text-align: left;
            margin-top: 0px !important;
        }
        .imgReco {
            width: 56px !important;
            margin-right: 24px;
        }
        .ABtext1Reco{
            font: 600 18px / 1 Lexend, sans-serif;
            color: var(--Text-Gray-Default, #4e5258);
            letter-spacing: 0.02px;
            font-variant-numeric: lining-nums proportional-nums;
            text-align: center;
        }
    }
    @media only screen and (min-width : 451px) {     
        margin-top: 15px;
        margin-bottom: 48px;
        padding-left: 0px;
        width: 602px;
        .ABtext1Reco{
            font: 600 18px / 1 Lexend, sans-serif;
            font-size: 24px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            font-weight: 600; 
            line-height: 32px; 
            color: #4E5258;
        }
        .testABimg{
            width: 109px !important;
        }
    }
    display: flex;
    max-width: 624px;
    flex-direction: column;
    padding: 0 20px;
    .text1Reco {
        color: var(--Text-Gray-Default, #4e5258);
        width: 100%;
        max-width: 100%;
        font: 700 16px Calibri, sans-serif;
    }
    .containerReco {
        align-self: start;
        margin-top: 40px;
        max-width: 100%;
    }
    .tableReco {
        gap: 20px;
        display: flex;
    }
    .contentColumn {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        font-size: 16px;
        color: var(--Text-Gray-Default, #4e5258);
        font-weight: 400;
        text-align: center;
        line-height: 20px;
    }
    .imgReco {
        aspect-ratio: 1;
        object-fit: auto;
        object-position: center;
        width: 80px;
        fill: var(--Background-Secondary-Lighter, #bff2f2);
        align-self: center;
    }
    .textReco {
        font-family: Calibri, sans-serif;
        margin-top: 24px;
    }
`;