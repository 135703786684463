import styled, { css } from "styled-components";
import { mediaQuery } from "modules/utils/responsive";

export const Step3Wrapper = styled.div`
    img{
        max-width: none !important;
    }
    .titleStep3{
        font-family: 'FS Emeric';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #00A499;
        margin-bottom: 10px;
    }
    .descriptionStep3{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        color: #696158;
        padding-bottom: 24px;
    }
    .cardStep3{
        box-sizing: border-box;
        width: 100%;
        height: 308px;
        border: 1px solid #D6D6D6;
        border-radius: 16px;
        padding: 32px 88px;    
        margin-bottom: 24px;
    }

    .alertStep3{
        display:flex;
        width: 100%;
        height: 56px;
        background: #EEFBF9;
        border-radius: 16px;
        margin-bottom: 32px;
    }
    .recoStep3{
        display:flex;
        width: 100%;
        height: 40px;
        font-size: 14px;
    }
    #reco1{
        margin-bottom: 24px;
    }
    #reco2{
        width: 284px !important;
        margin-bottom: 24px;
    }
    #start-biometric{
        width: 164px;
        height: 40px;   
        margin-bottom: 40px; 
    }
    .wrapperReco{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 22px;
    }
    .recoTitle{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #696158;
        justify-content: center;
        padding: 0px !important;
    }
    .recotext{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #696158;
    }
    .icon{
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .alertText{
        padding-top: 20px;
        padding-bottom: 20px;
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #696158;
    }
    .wrapperApproved{
        margin-top: 136px;
        text-align: center;
    }
    .msgApproved{
        font-size: 24px;
        margin-bottom: 16px;
    }
    .spanApproved{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #696158;
    }
    .buttonSend{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 48px;
    }
    .wrapperAlert{
        margin-top: 48px;
        text-align: center;
    }
    .imgApproved{
        width: 56px;
        height: 56px;
        margin-bottom: 24px;
    }
    .msgAlert{
        font-family: 'FS Emeric';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #696158;
        width: 413px;
        margin: auto;
        margin-bottom: 24px;
    }
    .buttonReset{
        width: 224px;
        height: 40px;
        margin-top: 16px;
    }
    .imgAlert{
        width: 56px;
        height: 56px;
        margin-bottom: 24px;
    }

    .contentImg{        
        height: 100%; 
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imgSigning{
        width: 193.98px;
        height: 178px;
        margin: 24px;
    }

    .wrapperRecords{
        padding-right: 106px;
        width: 450px;
    }
    .col1{
        width: 75%;
    }

    .wrapperButton{
        margin-bottom: 10px !important;
    }

    @media only screen and (min-width : 1728px){
        max-width:100%;
    }

    @media only screen and (min-width : 1366px) and (max-width: 1727px) {
        
        .col1{
            width: auto;
        }
        .cardStep3{
            padding: 32px 40px;    
            margin-bottom: 24px;
        }
        .wrapperRecords{
            padding-right: 32px;
        }        
        .col1{
            width: 68%;
        }
        .recoTitle{
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        }
        .recotext{
            font-size: 16px;
            line-height: 20px;
        }
    }

    @media only screen and (min-width : 451px) and (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        margin: auto;  
        .col1{
            width: auto;
        }
        .titleStep3{
            margin-top: 8px;
            margin-bottom: 10px;
            text-align: center;
        }
        .descriptionStep3{
            max-width: 478px;
            height: 40px;
            text-align: center;
            margin: auto;
            margin-bottom: 24px;
        }
        .cardStep3{
            box-sizing: border-box;
            width: 100%;
            height: 308px;
            padding: 32px 16px;            
        }
        .alertStep3{
            display:flex;
            width: 100%;
            height: 56px;
            margin-bottom: 40px;
        }
        .recoStep3{
            display:flex;
            width: 100%;
            font-size: 14px;
        }
        #reco1{
            margin-bottom: 24px;
        }
        #reco2{
            width: 284px !important;
            margin-bottom: 24px;
        }
        #start-biometric{
            width: 160px;
            height: 48px;    
        }
        .wrapperReco{
            display:flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 22px;
        }
        .wrapperRecords{
            width: 100% !important;
            padding-right: 2% !important;
        }
        .recoTitle{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #696158;
            padding-top: 0px !important; 
        }
        .recotext{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #696158;
        }
        .icon{
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 16px;
            padding-right: 16px;
        }
        .alertText{
            padding-top: 20px;
            padding-bottom: 20px;
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #696158;
        }
        .wrapperApproved{
            margin-top: 216px;
        }
        .msgApproved{
            font-size: 24px;
            margin-bottom: 16px;
        }
        .buttonSend{
            display: flex !important;
            text-align: center !important;
            justify-content: space-around !important;
            width: 100% !important;
            padding-bottom:80px;
        }
        #btn-biometric-send{
            width: 164px;
            height: 40px;
        }
        .wrapperAlert{
            margin-top: 216px;
        }
        .imgApproved{
            width: 104px;
            height: 104px;
            margin-bottom: 16px;
        }
        .msgAlert{
            font-family: 'FS Emeric';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #696158;
            width: 704px;
            margin: auto;
            margin-bottom: 24px;
        }
        .buttonReset{
            width: 224px;
            height: 40px;
        }
        .imgAlert{
            width: 56px;
            height: 56px;
            margin-bottom: 24px;
        }
        .wrapperButton{
            margin-top: 40px !important;
            margin-bottom: 40px !important;
            width: 100% !important;
            display: flex;
            justify-content: center;
        }
    } 

    @media only screen and (max-width : 450px) {  
        .titleStep3{
            width: 247px;
            height: 64px;
            margin: auto;
            text-align: center;
        }
        .descriptionStep3{
            text-align: center;
            margin-top: 10px;
        }
        .cardStep3{
            width: 100%;
            height: 499px;
            padding: 24px; 
        }
        .alertStep3{
            width: 100%;
            height: 64px;
        }
        .alertText{
            padding-top: 16px;
            padding-bottom: 16px;
        }
        .icon{
            padding-left: 19px;
            padding-right: 19px;
            padding-top: 19px;
        }
        .wrapperButton{
            margin-top: 20px !important;
        }
        .recoStep3{
            display:flex;
            width: 100%;
            height: 40px;
            font-size: 14px;
        }
        #reco1{
            margin-bottom: 24px;
        }
        #reco2{
            width: 284px !important;
            margin-bottom: 24px;
        }
        #spanReco2{
            width: 228px;
        }
        #start-biometric{
            width: 100%;
            height: 48px;    
            margin-bottom: 0px !important; 
        }
        .wrapperReco{
            display:flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 22px;
        }
        .wrapperRecords{
            width: 100% !important;
            padding-right: 0px !important;
        }

        .recoTitle{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #696158;
            padding: 0px !important;
        }
        .recotext{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #696158;
        }
        .imgInfo{
            width: 18px;
            height: 18px;
        }
        .wrapperApproved{
            margin-top: 72px;
        }
        .imgApproved{
            width: 104px;
            height: 104px;
            margin-bottom: 16px;
        }
        .msgApproved{
            width: 291px;
            margin-bottom: 8px;
            text-align: center;
            margin: auto;
            margin-bottom: 8px;
        }
        .buttonSend{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
        .wrapperAlert{
            margin-top: 48px;
        }
        .msgAlert{
            width: 291px;
            margin-bottom: 8px;
            text-align: center;
            margin: auto;
            margin-bottom: 8px;
        }
        .buttonReset{
            width: 224px;
            height: 40px;
            margin-bottom: 48px;
        }
        .imgAlert{
            width: 56px;
            height: 56px;
            margin-bottom: 24px;
        }        
        #btn-biometric-send{
            width: 100%;
            height: 40px;
            margin-top: 100px;
            margin-bottom: 0px !important;
        }
        .col1{
            width: auto;
            padding: 0px !important;
            padding-top: 24px !important;
        }
        .col2{
            padding: 0px !important;
            padding-top: 24px !important;
        }
        .div-weapper{
            flex-direction: column-reverse;
            margin-top: 0px !important;
          }
          
    }

    @media only screen and ${mediaQuery.mobileS} { 
        .msgApproved{
          width: 100% !important;
        }
      }

`;