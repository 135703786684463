import React, { useState, useEffect } from 'react';
import Recomendation from './resources/Recomendation.svg'
import reco1 from '../../components/SelfieRecomendations/reco1.gif';
import reco2 from '../../components/SelfieRecomendations/reco2.gif';
import reco3 from '../../components/SelfieRecomendations/reco3.gif';
import { updateUrl } from "../../constants/updateUrl";
import { useHistory } from 'react-router-dom';
import { RecoSelfieDiv } from "./style";

export default function RecoSelfie  ({showCounter}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [countdown, setCountdown] = useState(10);
    const history = useHistory();
    let message= 'Encuentra <b>un lugar bien iluminado</b> y asegúrate de <b>no cubrir tu rostro</b>, estar quieto y <b>sin lentes.</b>'; 

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        if (countdown === 0 && showCounter) {
            updateUrl(history,  window.location.href,'/pasos3validacion-biometrica');
        }

        return () => clearInterval(countdownInterval);
    }, [countdown]);


    return (
        <RecoSelfieDiv >
            <img
                loading="lazy"
                src={Recomendation}
                className="imgRecoSelfie"
            />
            {
                showCounter === true && <div className="counterDiv">
                    <span className='textA' style={{color:'#4E5258'}} >Iniciaremos la validación en </span> <p className='textB' style={{color:'#00A499'}}> {countdown} segundos </p> 
                </div>
            }
            <div className="messageDiv" dangerouslySetInnerHTML={{ __html: message }}  />

        </RecoSelfieDiv>
    );
}