import React, { useState, useEffect } from "react"
import { logoPrima2, fb, yt, lin, inst, openBook } from "../../assets/index"
import ReactGA from "react-ga"
import { FooterStyle } from "./style"
import { sendToDataLanding } from "../../../digital-transfers/constants/sendToDataLayer"

const Footer = () => {
  const [state, setState] = useState(null)
  const [isDesktop, setIsDesktop] = useState(false)

  // MENU FLECHAS
  const handleIcoArrow = index => <></>

  const handleResize = () => {
    let footerCopy = document.querySelector("#footerCopy")
    let footerBottom = document.querySelector("#footerBottom")
    let footerLogo = document.querySelector("#footerLogo")
    let footerLibroRelamaciones = document.querySelector(
      "#footerLibroRelamaciones"
    )
    let footerTelefonos = document.querySelector("#footerTelefonos")
    // SI es DESKTOP
    if (window.innerWidth > 949) {
      // MEDIA min @950
      setIsDesktop(true)
      footerLogo.append(footerCopy) // MOVIENDO EL DOM DEL FOOTER
      footerTelefonos.after(footerLibroRelamaciones) // MOVIENDO EL DOM DEL FOOTER
    } else {
      // SI es MOBILE
      setIsDesktop(false)
      footerBottom.after(footerLibroRelamaciones) // MOVIENDO EL DOM DEL FOOTER
      footerLibroRelamaciones.after(footerCopy) // MOVIENDO EL DOM DEL FOOTER
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize, false)
  }, [])

  useEffect(() => {
    handleResize()
  }, [isDesktop])

  return (
    <FooterStyle>
      <img src={logoPrima2} className="logoMovil" alt="Prima AFP" />
      <div className={`footerTop ${isDesktop ? "containerWrap" : ""}`}>
        <div className="footerColumn">
          <p className="listTitle">Nosotros</p>
          <p
            className="listTitleMobileClick"
            onClick={() => (state === 1 ? setState(null) : setState(1))}
          >
            <span>Nosotros</span> {handleIcoArrow(1)}
          </p>
          <ul
            className={`navSecondLevel ${
              state === 1 ? "submenu_open" : "submenu_close"
            }`}
          >
            <li
              className="mainLinksItem"
              onClick={() =>
                sendToDataLanding({
                  event: "footer_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "footer",
                    option: "nosotros - sobre prima afp",
                  },
                })
              }
            >
              <a
                onClick={() =>
                  ReactGA.event({
                    category: "Cabecera",
                    action: "clic",
                    label: "sobreprimaafp",
                  })
                }
                href="https://www.prima.com.pe/public-zone/sobre-prima-afp/"
              >
                Sobre Prima AFP
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({
                  event: "footer_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "footer",
                    option: "nosotros - quien es prima afp",
                  },
                })
              }
              className={"mainLinksItem"}
            >
              <a
                onClick={() =>
                  ReactGA.event({
                    category: "Cabecera",
                    action: "clic",
                    label: "sobreprimaafp",
                  })
                }
                href="https://www.prima.com.pe/public-zone/sobre-prima-afp/quien-es-prima-afp/"
              >
                Quien es prima afp
              </a>
            </li>
          </ul>
        </div>
        <div className="footerColumn">
          <p className="listTitle">
            <span>Ayuda y contacto</span>
          </p>
          <p
            className="listTitleMobileClick"
            onClick={() => (state === 2 ? setState(null) : setState(2))}
          >
            <span>Ayuda y contacto</span> {handleIcoArrow(2)}
          </p>
          <ul
            className={`navSecondLevel ${
              state === 2 ? "submenu_open" : "submenu_close"
            }`}
          >
            <li className="fw-1"></li>
            <li
              onClick={() =>
                sendToDataLanding({
                  event: "footer_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "footer",
                    option: "ayuda y contacto - agencias",
                  },
                })
              }
              className="mainLinksItem"
            >
              <a href="https://www.prima.com.pe/public-zone/canales-de-atencion/">Agencias</a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({
                  event: "footer_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "footer",
                    option: "ayuda y contacto - app movil",
                  },
                })
              }
              className="mainLinksItem"
            >
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=prima.android.splashscreentest&hl=es_PE"
              >
                App móvil
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({
                  event: "footer_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "footer",
                    option: "ayuda y contacto - chat online",
                  },
                })
              }
              className="mainLinksItem"
            >
              <a
                href="https://salesiq.zoho.com/signaturesupport.ls?widgetcode=49c13587d730313d939d75a12056e4cf0cbeab76ff2c5be8ea90353528a397b955e25b04c7489d5ea523f33b8cf0da45"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chat online
              </a>
            </li>
          </ul>
        </div>
        <div className="footerColumn" id="footerWrapOtrosEnlaces">
          <p className="listTitle">Otros enlaces</p>
          <p
            className="listTitleMobileClick"
            onClick={() => (state === 3 ? setState(null) : setState(3))}
          >
            <span>Otros enlaces</span> {handleIcoArrow(3)}
          </p>
          <ul
            className={`navSecondLevel ${
              state === 3 ? "submenu_open" : "submenu_close"
            }`}
          >
            <li
              onClick={() =>
                sendToDataLanding({
                  event: "footer_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "footer",
                    option: "otros enlaces - politica de seguridad y salud en el trabajo",
                  },
                })
              }
              className="mainLinksItem"
            >
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={
                  process.env.REACT_APP_PREFIX_REPOSITORY_FILE +
                  "2023/pdf/" +
                  "Politica_SST_2023.pdf" +
                  process.env.REACT_APP_POSTFIX_REPOSITORY_FILE
                }
              >
                Política de Seguridad y Salud en el trabajo
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({
                  event: "footer_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "footer",
                    option: "otros enlaces - politica de calidad",
                  },
                })
              }
              className="mainLinksItem"
            >
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={
                  process.env.REACT_APP_PREFIX_REPOSITORY_FILE +
                  "2020/pdf/" +
                  "Nuestra_Politica_Calidad.pdf" +
                  process.env.REACT_APP_POSTFIX_REPOSITORY_FILE
                }
              >
                Política de calidad
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({event:'footer_click', payload: {
                  section: "landing traspaso",
                  sub_section: "footer",
                  option: "otros enlaces - politicas de privacidad"
                }})
              }
               className="mainLinksItem">
              <a href="https://www.prima.com.pe/public-zone/sobre-prima-afp/politica-de-privacidad/">
                Políticas de Privacidad
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({event:'footer_click', payload: {
                  section: "landing traspaso",
                  sub_section: "footer",
                  option: "otros enlaces - campana traspasos - terminos y condiciones"
                }})  
              }
               className="mainLinksItem">
              <a href="https://www.prima.com.pe/public-zone/traspasos-tyc/">
                Campaña traspasos - Términos y condiciones
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({event:'footer_click', payload: {
                  section: "landing traspaso",
                  sub_section: "footer",
                  option: "otros enlaces - comprobante electronico"
                }})  
              }
               className="mainLinksItem">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://asp402r.paperless.com.pe/BoletaPrimaAfp/"
              >
                Comprobante electrónico
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({event:'footer_click', payload: {
                  section: "landing traspaso",
                  sub_section: "footer",
                  option: "otros enlaces - sistemas de denuncias alerta genetica"
                }})
              }
               className="mainLinksItem">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://secure.ethicspoint.com/domain/media/es/gui/56087/index.html"
              >
                Sistemas de Denuncias Alerta Genética
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({event:'footer_click', payload: {
                  section: "landing traspaso",
                  sub_section: "footer",
                  option: "otros enlaces - prima afp concursos"
                }})
              }
               className="mainLinksItem">
              <a href="https://www.prima.com.pe/public-zone/concursos-prima/">
                Prima AFP Concursos
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({event:'footer_click', payload: {
                  section: "landing traspaso",
                  sub_section: "footer",
                  option: "otros enlaces - pago de aportes"
                }})
              }
               className="mainLinksItem">
              <a href="https://www.prima.com.pe/public-zone/acerca-de-afpnet/">
                Pago de aportes
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({event:'footer_click', payload: {
                  section: "landing traspaso",
                  sub_section: "footer",
                  option: "otros enlaces - calendario laboral"
                }})
              }
               className="mainLinksItem">
              <a href="https://www.prima.com.pe/public-zone/calendario-laboral/">
                Calendario Laboral
              </a>
            </li>
            <li
              onClick={() =>
                sendToDataLanding({event:'footer_click', payload: {
                  section: "landing traspaso",
                  sub_section: "footer",
                  option: "otros enlaces - afp net"
                }})
              }
               className="mainLinksItem">
              <a href="https://www.prima.com.pe/public-zone/afpnet/">AFP Net</a>
            </li>
          </ul>
        </div>
        <div className={`footerColumn relative`}>
          <div className="mainPhone" id="footerTelefonos">
            <p className="listTitle">Teléfonos</p>
            <p
              className="listTitleMobileClick"
              onClick={() => (state === 4 ? setState(null) : setState(4))}
            >
              <span>Teléfonos</span> {handleIcoArrow(4)}
            </p>
            <ul
              className={`navSecondLevel ${
                state === 4 ? "submenu_open" : "submenu_close"
              }`}
            >
              <li
                onClick={() => 
                  sendToDataLanding({event:'footer_click', payload: {
                    section: "landing traspaso",
                    sub_section: "footer",
                    option: "telefonos - lima"
                  }})
                }
                 className="mb-1 textPhone">
                Lima: <a href="tel:016157272">(01) 615-7272</a>
              </li>
              <li
                onClick={() =>
                  sendToDataLanding({event:'footer_click', payload: {
                    section: "landing traspaso",
                    sub_section: "footer",
                    option: "telefonos - provincias"
                  }})
                }
                className="textPhone">
                Provincias: <a href="tel:080118010">0-801-18010</a>
              </li>
            </ul>
          </div>
          <div
            onClick={() =>
              sendToDataLanding({event:'footer_click', payload: {
                section: "landing traspaso",
                sub_section: "footer",
                option: "libro de reclamaciones - lima"
              }})
            }
            
            className={`mainDisclaimerBook relative`}
            id="footerLibroRelamaciones"
          >
            <a
              href="https://www.prima.com.pe/public-zone/formularios/libro-de-reclamaciones-v2/"
              className={`db@lg ml-auto mr-auto ml-0@lg mr-0@lg conteinerLibro`}
            >
              <span>Libro de reclamaciones</span>
              <img
                src={openBook}
                className="reclamaciones"
                alt="reclamaciones"
              />
              <span className="textRuc">Prima AFP S.A. - RUC: 20510398158.</span>
            </a>
          </div>
        </div>
      </div>
      <div className="footerBottom" id="footerBottom">
        <div className={`containerWrap flex justify-between items-center ph-0`}>
          <div
            className={`footerLogo flex justify-between items-center`}
            id="footerLogo"
          >
            <img src={logoPrima2} className="logo" alt="Prima AFP" />
          </div>
          <div className="social">
            <div className={`socialSiguenos mr-3`}>Síguenos:</div>
            <a
              onClick={() => sendToDataLanding({event:'footer_click', payload: {
                section: "landing traspaso",
                sub_section: "footer",
                option: "socials - facebook"
              }})
            }
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/PrimaAFP/"
            >
              <img src={fb} alt="facebook" className="socialicon" />
            </a>
            <a
              onClick={() => sendToDataLanding({event:'footer_click', payload: {
                section: "landing traspaso",
                sub_section: "footer",
                option: "socials - instagram"
              }})
            }
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/primaafp/"
            >
              <img src={inst} alt="Instagram" className="socialicon" />
            </a>
            <a
              onClick={() => sendToDataLanding({event:'footer_click', payload: {
                section: "landing traspaso",
                sub_section: "footer",
                option: "socials - youtube"
              }})
              }
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCU6o1n74cGuZqL555IDlkwg"
            >
              <img src={yt} className="socialicon" alt="Youtube" />
            </a>
            <a
              onClick={() => sendToDataLanding({event:'footer_click', payload: {
                section: "landing traspaso",
                sub_section: "footer",
                option: "socials - linkedin"
              }})
            }
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/prima-afp/"
            >
              <img src={lin} className="socialicon" alt="Linkedin" />
            </a>
          </div>
        </div>
      </div>
      <div className="footerCopy" id="footerCopy">
        © Prima AFP 2023 - Todos los derechos reservados
      </div>
    </FooterStyle>
  )
}

export default Footer
