import styled from "styled-components";
import { media } from "@prima-afp/prima-ui-elements/dist/utils/media/styledConfig";
import { mediaQuery } from "modules/utils/responsive";


export const ContarinerStep2 = styled.div`
  .sliderAlert {
    margin-bottom: 40px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-left: 16px;
    background-color: #eefbf9;
  }
  .epKWNl path {
    fill: #00a499 !important;
  }
  #divStep2 {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 450px) {
    .inputStep2 {
      width: 335px !important;
      height: 64px;
      margin-bottom: 16px;
    }
    .step2ButtonContainer {
    }
    .fundType {
      --margin-top: 16px;
    }
    #titleStep2 {
      margin-bottom: 0px !important;
    }
    .searchButton {
      margin-top: 16px !important;
    }
    #WrapperTypeFoundD {
      margin-top: 24px;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 700px) {
    max-width: 100vw;
    #divStep2{
      max-width: 100vw;
      width: 90vw;
    }
    .fundType{
      width: 90vw;
    }
  }

  @media only screen and (min-width : 700px) and (max-width: 1000px) {
    .searchButton {
      margin-top: 40px !important;
      #searchButtonP {
        text-align: initial;
        margin-bottom: 8px;
      }
    }
    #foundTypeP {
      text-align: initial;
      margin-bottom: 8px;
    }
    .inputStep2 {
      width: 316px !important;
      height: 64px;
      margin-bottom: 16px;
    }
    .fundType {
      --margin-top: 40px;
    }
    #titleStep2 {
      text-align: inherit;
    }
    #WrapperTypeFoundD {
      margin-top: 24px;
    }
  }

  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    width: 635px;
    #buttonWrapper {
      display: flex;
    }
    .inputStep2 {
      width: 269px !important;
      margin-right: 32px;
    }
    .secondRadio {
      margin-left: 40px;
    }
    .searchButton {
      margin-top: 40px;
    }
    #searchButtonP {
      text-align: initial;
      margin-bottom: 8px;
    }
    .fundType {
      --margin-top: 40px;
    }
    #foundTypeP {
      text-align: initial;
    }
  }

  @media only screen and (min-width: 1728px) {
    width: 816px;
    #buttonWrapper {
      display: flex;
    }
    #WrapperTypeFoundD {
      margin-top: 40px;
    }
    .inputStep2 {
      width: 364px !important;
      margin-right: 32px;
    }
    .secondRadio {
      margin-left: 40px;
    }
    .searchButton {
      margin-top: 40px;
    }
    #searchButtonP {
      text-align: initial;
      margin-bottom: 8px;
    }
    .fundType {
      --margin-top: 40px;
    }
    #foundTypeP {
      text-align: initial;
    }
  }
`;

export const DivTextCard = styled.div`
  @media only screen and (max-width: 450px) {
    width: 255px;
  }
  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    display: flex !important;
    justify-content: space-between !important;
  }
  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    width: 204px;
    text-align: left;
  }
  @media only screen and (min-width: 1728px) {
    margin-top: 0px;
    text-align: left;
  }
`;

export const TextCard = styled.p`
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #696158;
  @media only screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    width: 203px;
    height: 40px;
    text-align: initial;
  }
  @media only screen and (min-width: 1728px) {
    width: 280px;
    font-size: 18px;
  }
`;

export const TypeCard = styled.div`
    text-align: center;
    background: #fff;
    box-shadow: 2px 4px 10px 2px #EBEBEB;
    border-radius: 16px;
    padding-top: 8px;
    padding-bottom: 36px;
    padding-right: 32px;
    padding-left: 32px;
    cursor: pointer;
    position: relative;
    height: 112px;
    width: 301px;
    ${(props) => props.active && "border: 2px solid var(--greenColor)"};

    @media only screen and ${mediaQuery.mobileS}{
      width: 100% !important;
      .descriptionTypeCard{
        width: 76% !important;
      }
    }

  @media only screen and (max-width: 376px) {
    width: 335px;
    height: 88px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    justify-content: center;
  }

  @media only screen and (min-width: 377px) and (max-width: 450px) {
    width: 100%;
    height: 88px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    justify-content: center;
  }

    @media only screen and (min-width : 451px) and (max-width: 700px) {
      width: 90vw;
      max-width: 93vw;
      height: 88px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;
      flex-direction: column;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 24px;
      padding-bottom: 24px;
      justify-content: center;
    }

    @media only screen and (min-width : 701px) and (max-width: 1000px) {
      width: 340px;
      height: 112px;
      padding-top: 36px;
      #id2{
        padding-top: 46px;
        padding-bottom: 46px;
      }
      #span2{
        width: 238px;
        height: 20px;
      }
    }

  @media only screen and (min-width: 1728px) {
    width: 392px;
    padding-top: 36px;
  }
`;

export const Check = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const _buttonMobile = `
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    justify-content: normal;

    button:last-child {
        width: 100%;
        order: -1;
        height: 48px;
        margin-bottom: 1rem;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    
    ${media.sm`
        ${_buttonMobile}
    `};
    
    ${media.xs`
        ${_buttonMobile}
    `};
    @media only screen and ${mediaQuery.mobileS}{
      #btn-fund-type-send{
        width: 100%;
      }
    }


    @media only screen and (max-width : 450px) {   
        margin-top: 32px  !important;
        #btn-fund-type-send{
          margin-bottom: 10px;
        }
        #btn-no-show-types{
          margin-bottom: 56px;
        }
      }
    }

    @media only screen and (min-width : 451px) and (max-width: 1000px) {
        margin-top: 0px !important;
        margin-bottom: 48px !important;
        max-width: 704px !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center;
        .buttonStep1{
            width: 160px !important;
        }
        #btn-fund-type-send{
          width: 160px;
          height: 48px;
        }
        #btn-no-show-types{
          width: 160px;
          height: 48px;
        }
    }

    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        #identity-btn-env{
            width:142px;
            height: 40px;
            margin-top: 40px;
        }
        #btn-fund-type-send{
          margin-bottom: 56px;
        }
        #btn-no-show-types{
          margin-top: 48px;
          margin-bottom: 56px;
        }
    }
    
    @media only screen and (min-width : 1728px) {
        margin-top: 48px !important;
        margin-bottom: 64px;
        #btn-no-show-types{
          margin-top: 48px;
          margin-bottom: 56px;
        }
    }

`;

export const DivFramePEP = styled.div`
  .textfield-input {
    border-width: 1px;
  }

.styles__ContainerTooltipText-sc-k5wj9h-0 {
  width: 70vw;
  max-width: 320px;
}
  @media only screen and (max-width: 450px) {
    margin-top: 40px;
    .wrapperButton {
      display: flex;
      flex-direction: column;
      margin-top: 0px !important;
      margin-bottom: 14px;
    }
    .radioDiv {
      margin-bottom: 8px;
    }
    #disclaimer {
      margin-top: 24px;
      margin-bottom: 8px;
    }
    #disclaimerSpan {
      font-family: "Calibri";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #696158;
    }
    #FramePEPid {
      text-align: initial;
      margin-bottom: 24px;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    margin-top: 40px;
    #FramePEPid {
      text-align: initial;
      margin-bottom: 24px;
    }
    .wrapperButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 0px !important;
    }
    #disclaimer {
      margin-top: 24px;
      margin-bottom: 48px;
    }
    #disclaimerSpan {
      font-family: "Calibri";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #696158;
    }
    #wrapperInput {
      display: flex;
      margin-top: 14px;
      justify-content: space-between;
    }
    .input-wrapper {
      width: 340px !important;
    }
  }

  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    margin-top: 40px;
    margin-bottom: 48px;
    #disclaimer {
      margin-top: 24px;
    }
    #wrapperInput {
      display: flex;
    }
    .textfield-input {
      width: 301px;
    }
    .wrapperButton {
      margin-top: 0px !important;
      margin-bottom: 14px;
      .box {
        min-width: 40px;
      }
    }
    .radioDiv {
      margin-top: 8px;
    }
    #disclaimerSpan {
      font-family: "Calibri";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #696158;
    }
  }
  @media only screen and (min-width: 1728px) {
    --margin-top: 40px;
    .radioDiv {
      margin-top: 8px;
    }
    #disclaimer {
      margin-bottom: 40px;
      margin-top: 24px;
    }
    #FramePEPid {
      margin-top: 40px;
    }
    .wrapperButton {
      margin-bottom: 14px;
    }
  }
`;

export const DivFrameAttention = styled.div`
  .textfield-input {
    border-width: 1px;
  }

  @media only screen and (max-width: 376px) {
    .inputFrameAttention {
      margin-top: 8px !important;
      margin-bottom: 16px !important;
    }
  }

  @media only screen and (min-width: 377px) and (max-width: 450px) {
    .inputFrameAttention {
      margin-top: 8px !important;
      margin-bottom: 16px !important;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 1000px) {
    .inputFrameAttention {
      width: 340px;
    }
    #messageFrame {
      margin-bottom: 14px;
      text-align: initial;
    }
  }

  @media only screen and (min-width: 1001px) and (max-width: 1727px) {
    #messageFrame {
      margin-bottom: 14px;
    }
    .textfield-input {
      width: 301px;
    }
  }

  @media only screen and (min-width: 1728px) {
    #messageFrame {
      margin-bottom: 14px !important;
    }
    .textfield-input {
      width: 392px;
    }
  }
`;

export const DivTypeCard = styled.div`
    margin-top: 24px;
    @media only screen and ${mediaQuery.mobileS}{
      width: 100%;
    }

    @media only screen and (min-width : 451px) and (max-width: 700px) {
      width: 100%;
      display: grid;
      justify-content: space-between;
    }
    @media only screen and (min-width : 701px) and (max-width: 1000px) {
        display: flex !important;
        justify-content: space-between !important;
        display: flex !important;
        justify-content: space-between !important;
    }
    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        display: flex !important;
        justify-content: space-between !important;
        #id1{
            padding-top: 36px;
        }  
        #id2{
            padding-top: 46px;
            padding-bottom: 46px;
        }        
        #span2{
            width: 238px;
        }
    }
    @media only screen and (min-width : 1728px){
      display: flex !important;
      justify-content: space-between !important;
    }
`;
