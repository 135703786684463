import React, { useRef } from "react"
import { AcordionContent } from "./style.jsx"
import { sumaImage, restaImage } from "../../assets"
import { sanitizar, sendToDataLanding } from "../../../digital-transfers/constants/sendToDataLayer.js"

const AccordionQuestions = ({ items }) => {
  const refs = useRef([])
  const openAccordion = (title, index) => {
    sendToDataLanding({event:"desplegable_click", payload: {
      section: "landing traspaso",
      sub_section: "te ayudamos a resolver tus preguntas frecuentes",
      option: sanitizar(title)
    }})
    const imagesMas = refs.current[index].querySelector(".imageMas")
    const imagesMenos = refs.current[index].querySelector(".imageMenos")
    const accordionContent =
      refs.current[index].querySelector(`#content${index}`)
    if (accordionContent.classList.contains("active")) {
      accordionContent.classList.remove("active")
      accordionContent.classList.add("hideResponse")
      imagesMenos.classList.remove("active")
      imagesMenos.classList.add("hideResponse")
      imagesMas.classList.remove("hideResponse")
      imagesMas.classList.add("active")
    } else {
      accordionContent.classList.remove("hideResponse")
      accordionContent.classList.add("active")
      imagesMenos.classList.add("active")
      imagesMenos.classList.remove("hideResponse")
      imagesMas.classList.remove("active")
      imagesMas.classList.add("hideResponse")
    }
  }
  return (
    <AcordionContent>
      {items.map(({ title, content }, index) => (
        <div
          className="accordion-item"
          href={`item${index}`}
          key={index}
          ref={el => (refs.current[index] = el)}
        >
          <div
            className="accordion-title"
          >
            <a className="contentHead" onClick={() => openAccordion(title, index)}>
              <h3>{title}</h3>
              <div className="icon-row">
                <img src={sumaImage} alt="" className="imageMas" />
                <img
                  src={restaImage}
                  alt=""
                  className="imageMenos hideResponse"
                />
              </div>
            </a>
            <div id={`content${index}`} className="accordion-content hideResponse">{content}</div>
          </div>
        </div>
      ))}
    </AcordionContent>
  )
}

export default AccordionQuestions
