import React from "react";
import { StatusLineTime } from "../../Component/statusStepTransfer/StatusLineTime";
import { ContentMovileDesktop } from "./StyleDesktop";
import { sendToData } from "../../../../constants/sendToDataLayer"
import { useHistory } from "react-router-dom";
import { useDispatchDigitalTransfersContext } from "../../../../../digital-transfers/context/DigitalTransfersContext"

export const ResponseDesktop = ({response}) => {  
  const history = useHistory();
  const dispatch = useDispatchDigitalTransfersContext();
  const redirectStep = () => {
    sendToData(window.location.href, "button_click", "button_click", {
      section: "Landing consulta",
      option: "comienza tu solicitud",
    });
    dispatch({ type: "INITIAL_STATE" });
    history.push("/");
  };
  return (
    <ContentMovileDesktop>
      { !response.noRequest && (
        <div className="contentResponseQuery">
          <div className="titleResponseQuery">Hola {response.name},</div>
          <div className="descriptionResponseQuery">{response.subTitle}</div>
          <div className="statusResponseQuery">
            <StatusLineTime steps={response.steps} />
          </div>
          <div className="messageResponseQuery">
            <div className="contentImageQuery">
              <img src={response.imageMessage} />
            </div>
            <div className="messageQuery">
              <h2 className="titleMessageQuery"> {response.messageTitle} </h2>
              <h2 className="contentMessageQuery">{response.message}</h2>
            </div>
          </div>
        </div>
      )}

      { response.noRequest && (
        <div className="contentResponseQuery">
          <div className="messageResponseQuery">
            <div className="contentImageQuery">
              <img src={response.imageMessage} />
            </div>
            <div className="messageQuery">
              <div>
                <h2 className="contentMessageQueryNo">
                  {response.message}
                </h2>
              </div>
              <div style={{ paddingTop: "15px" }}>
                <a
                  onClick={ redirectStep }
                  className="btnChangeNow"
                >
                  Cámbiate a Prima AFP
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </ContentMovileDesktop>
  );
};
