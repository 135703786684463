import styled from "styled-components";
import imgBackMovil from "./images/imgBackMovil.svg";

export const QueryPageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  .headLogos {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 100%;
    padding: 40px 52px 10px 35px;
    background-color: ${({ isMovil }) =>
      isMovil ? "transparent" : "rgba(255, 250, 230, 1)"};
  }
  .headLogos p {
    color: #ff4f00;
    font-weight: 700;
    font-size: 16px;
    margin-top: 5px;
    font-style: normal;
    line-height: 20px;
    text-align: inherit;
  }
  .containerBackColor {
    background-color: ${({ isMovil }) =>
      isMovil ? "#FFF" : "rgba(255, 250, 230, 1)"};
    background-image: ${({ isMovil }) =>
      isMovil ? `url(${imgBackMovil})` : "none"};
    background-repeat: no-repeat;
    min-height: 643.84px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .containerCenter {
    background-color: #fff;
    max-width: 894px;
    width: 100%;
    border-radius: 24px;
    height: max-content;
    margin-top: 104px;
    margin-bottom: 69px;
    z-index: 1000;
  }
  .divCenter {
    border: 1px solid #e7e7e8;
    border-radius: 24px;
    padding: 72px 123px;
  }
  .dni-input {
    font-family: Lexend;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0012em;
    text-align: left;
    color: #4e5258;
  }
  .componentSearch {
    display: flex;
    justify-content: space-between;
    .contentInputSearch {
      max-width: 312px;
    }
  }
  .btnSearch {
    font-family: Lexend;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0012em;
    text-align: center;
    border-radius: 12px;
    width: 312px;
  }
  .disabledBtn {
    background-color: var(--Background-Disabled-Subtle, #e7e7e8);
    color: var(--Text-Disable-Disabled, #84878b);
  }
  .enabledBtn {
    background-color: var(--orangeColorMain);
    color: #ffffff;
  }
  .titleQuery {
    font-family: Lexend;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.0012em;
    text-align: left;
    color: #ff4f00;
    width: 509px;
  }
  .divSearch {
    margin-top: 48px;
  }
  .titleSearch {
    font-family: Lexend;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0012em;
    text-align: left;
    color: #4e5258;
    padding-bottom: 12px;
  }
  .textBoldQuery {
    font-family: Lexend;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.0012em;
    text-align: center;
  }

  .textBoldContactQuery {
    font-family: Calibri;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.0012em;
    text-align: center;
  }
  .textColorQuery {
    color: #04a6a2;
  }
  .textRedQuery {
    color: #ff0000;
  }
  .textBold {
    font-family: Rozanova GEO;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #4e5258;
  }

  .containerWidth {
    width: 100%;
    height: 87.47px;
    position: relative;
    z-index: 1;
    .imgPage1 {
      position: absolute;
      bottom: 52.31px;
      left: 0px;
      z-index: 100;
    }
    .imgPage2 {
      position: absolute;
      bottom: 54.67px;
      right: 15.03px;
    }
  }

  .btnChangeNow {
    background-color: #ff4f00;
    color: #fff;
    width: 210px;
    height: 40px;
    padding: 8px 16.5px;
    border-radius: 12px;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0012em;
    text-align: center;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    .headLogos p {
      display: none;
    }
    .containerCenter {
      width: auto;
    }
    .divCenter {
      padding: 30px 25.5px;
      width: 90vw;
      min-height: 511px;
      border: 1px solid #e7e7e8;
      .titleQuery {
        width: auto;
        max-width: 350px;
      }
      .divSearch {
        .titleSearch {
        }
        .componentSearch {
          display: flex;
          flex-direction: column;
          gap: 32px;
          width: 100%;
          .contentInputSearch {
            max-width: 100%;
          }
          .dni-input {
            width: 100%;
          }
          .btnSearch {
            border-radius: 16px;
            width: 100%;
            padding: 11px 16px;
            height: 56px;
          }
        }
      }
    }
    .btnChangeNow {
      padding: 12px 20px;
      width: 261px;
      height: 48px;
      font-family: Lexend;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: center;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 950px) {
    .containerCenter {
      max-width: auto;
      width: 90%;
      .divCenter {
        padding: 69px 30px 55px 30px;
        .titleQuery {
          max-width: 100%;
        }
        .componentSearch {
          width: 100%;
          justify-content: left;
          .contentInputSearch {
            width: 100vw;
          }
          .btnSearch {
            max-width: 245px;
            margin-left: 32px;
          }
        }
      }
    }
  }
`;
