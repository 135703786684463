import { Wrapper } from 'modules/App/components/Banner/styles';
import React, { useEffect, useState } from 'react';
import { WrapperIput, Tooltip } from './styles2';
import mailIcon from './mailIcon.svg';
import prefixNumberIcon from './prefixNumberIcon.svg';
import documentNumberIcon from './documentNumberIcon.svg';
import closeIcon from './closeIcon.svg';

export default function SimpleInput({
  label,
  placeholder,
  saveValueInput,
  value,
  name = '',
  onlyNumbers = false,
  flagError, 
  errorMessage,
  type,
  maxLength,
  haveIcon,
  textIcon,
  showTooltipInput = false,
  tooltipMessage,
  reset = false,
  NoCopyPaste = false
}) {
  const [inputColor, setInputColor] = useState('#AAA9A9');
  const [boxShadow, setBoxShadow] = useState('');
  const [borderWidth, setBorderWidth] = useState('1px');
  const [inputValue, setInputValue] = useState('');
  const [imgIcon, setImgIcon] = useState(mailIcon);
  const [showIcon, setShowIcon] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (flagError === false) {
      setInputColor('#AAA9A9');
      setBorderWidth('1px');
    } else if (flagError === true) {
      setInputColor('#FF4B4B');
      setBorderWidth('2px');
    }
  }, [flagError]);

  useEffect(() => {
    setInputValue(value);
    if (type === 'simpleInput') setShowIcon(false);
    switch (type) {
      case 'phone': setImgIcon(prefixNumberIcon);
      break;          
      case 'mail': setImgIcon(mailIcon);
      break;
      case 'docNumber': setImgIcon(documentNumberIcon);
      break;
      default: setImgIcon(mailIcon);
    }
  }, [type, value]);

  const handleOnFocus = () => {
    setInputColor('#FF4F00');
    setBoxShadow('rgba(255, 79, 0, 0.2) 0px 0px 0px 3px');
    setBorderWidth('2px');
    setShowTooltip(true);
  };

  const handleOnBlur = () => {
    setInputColor('#AAA9A9');
    setBoxShadow('');
    setBorderWidth('1px');
    setShowTooltip(false);
  };

  const handleInputChange = (event) => {
    const valNumbers = /^[0-9\b]+$/;
    const value = event.target.value;
    
    if (type === 'number' || type === 'phone' || type === 'docNumber') {
      // Eliminar espacios en blanco
      const valueNumber = event.target.value.replace(/[^0-9]/g, "");
      if (valNumbers.test(Number(valueNumber))) {
        setInputValue(valueNumber);
        saveValueInput(event);
      }
    }else if(type === 'mail'){
      const cleanedValue = value.replace(/\s/g, '');
      setInputValue(cleanedValue);
      saveValueInput(event);

    } else {
      setInputValue(value);
      saveValueInput(event);
    }
  };

  const handlePaste = (event) => {
    if (NoCopyPaste) {
      event.preventDefault();
    }
  };

  return (
    <WrapperIput>
      <div className="label">{label}</div>
      <div className="container" style={{ borderColor: inputColor, borderLeftStyle: 'solid', borderWidth: borderWidth, boxShadow: boxShadow }}>
        {showIcon && <div className="icon" style={{ display: 'flex', alignItems: 'center' }}>
          {haveIcon && <div className="icon" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              loading="lazy"
              src={imgIcon}
              className="img"
            />
          </div>}
          {!haveIcon && <div className="noIcon">{textIcon}</div>}
        </div>}
        <input
          className='input-container'
          type={'text'}
          value={inputValue}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleInputChange}
          onPaste={handlePaste}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      </div>
      {showTooltip && showTooltipInput && (
        <Tooltip>
          <div className="contentTooltip">
            <span className="tooltiptext">{tooltipMessage}</span>
          </div>
        </Tooltip>
      )}
      {flagError && <span className="spanError">
        <img
          loading="lazy"
          src={closeIcon}
          className="imgError"
        />
        {errorMessage}
      </span>}
    </WrapperIput>
  );
}
