import styled from "styled-components";

export const ContentMovileQuery = styled.div`
  .contentResponseQueryM {
    padding-top: 48px;
    .titleResponseQueryM {
      color: #04a6a2;
      text-align: center;
      font-family: Lexend;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.0012em;
    }
    .descriptionResponseQueryM {
      color: #4e5258;
      font-family: Lexend;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.0012em;
      text-align: center;
    }
    .statusResponseQueryM {
      .timeline {
        position: relative;
        margin: 20px 0;
      }

      .timeline::before {
        content: "";
        position: absolute;
        left: 45px; 
        top: 0;
        bottom: 0;
        width: 2px;
        background: #9B9DA0; 
      }
    }
  }
  .contentResponseQueryMS {
    .messageResponseQueryMS {
      background-color: #fbfbfb;
      border-radius: 24px;
      display: flex;
      margin-top: 41px;
      padding: 46px 36px;
      .messageQueryMS {
        display: flex;
        width: 100%;
        flex-direction: column;
        .contentMessageQueryMS{
          width: 100%;
          padding-bottom: 31px;
          p{
            text-align:center;
          }
        }
      }
      .contentImageQueryMS {
        display: flex;
        justify-content: center;
        padding-top: 36px;
      }
    }
  }
`;

export const TimelineItem = styled.div`
  .timeline-item {
    position: relative;
    margin-bottom: 16px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(69, 73, 78, 0.04),
      0px 24px 48px -8px rgba(69, 73, 78, 0.12);
  }

  .timeline-item::before {
    content: "";
    position: absolute;
    left: 20px; /* Position the circle 20px from the left */
    width: 10px;
    height: 10px;
    background: #fff;
    top: 0;
  }

  .timeline-content {
    background-color: ${({ backGroundColor }) => backGroundColor};

    padding: ${({ status }) =>
      status ? "18px 32px 18px 32px" : "18px 32px 0px 32px"};
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    .circleMovil {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: ${({ color }) => (color ? "#fff" : "#84878B")};
      border: 2px solid ${({ color }) => color || "#84878B"};
      background-color: ${({ color }) => (color ? color : "#fff")};
      font-weight: bold;
      font-family: Calibri;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: center;
    }
    .circleDescriptionMovil {
      margin-left: 8px;
      color: ${({ color }) => color || "#8F9295"};
      font-weight: ${({ status }) =>
        status === "cancelada" ? "bold" : "normal"};
      font-family: Lexend;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0012em;
      text-align: left;
    }
    .messageResponseQueryM {
      .messageQueryM {
        .titleMessageQueryM {
          font-family: Lexend;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.0012em;
          text-align: left;
          color: #4e5258;
          padding-bottom: 16px;
        }
        .contentMessageQueryM {          
          color: #4E5258;
          p {
          font-family: Calibri;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #4E5258;
          .textBoldQuery {
            font-family: Calibri;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
          }
        }
        } 
      }
      .contentImageQueryM {
        display: flex;
        align-items: end;
        justify-content: center;
        padding-top: 16px;
      }
    }
  }
`;
