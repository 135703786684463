import React, { useEffect } from 'react';
import { MainContainer } from '../../components/MainContainer';
import { PhoneVerification } from '../../componentes-con-asesor/PhoneVerification';

export const PhoneVerificationPage = ({ buttonRef }) => {
    useEffect(() => {
    }, []);

    return (
        <>
            <MainContainer>

                <PhoneVerification buttonRef = { buttonRef } />

            </MainContainer>
        </>
    );
};
