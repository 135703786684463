import React, { useState, useRef, useEffect } from "react";
import { RecordWrapper } from "./styles";
import tutorial1 from "./resources/tutorial2.mp4"; // centrar cara
import tutorial2 from "./resources/tutorial3.mp4"; // pestañea
import tutorial3 from "./resources/tutorial1.mp4"; // no uses fotos
import tutorial4 from "./resources/tutorial1.mp4"; // no uses fotos
import { RecoSelfie } from "./RecoSelfie";
import {
  CentradoSVG,
  ParpadeaSVG,
  IluminadoSVG,
  AccesoriosSVG,
} from "./resources/inconsSVG";

export const ListRecommendations = () => {
  const colorGray = "var(--grayColorText)";
  const videoList = [tutorial1, tutorial2, tutorial3, tutorial4];
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef();
  const handleVideoEnd = () => {
    setCurrentVideoIndex((currentVideoIndex + 1) % videoList.length);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
      videoRef.current.play();
    }
  }, [currentVideoIndex]);
  return (
    <RecordWrapper>
      <span className="recoTitle">Recomendaciones para tomarte la foto</span>
      <div className="div-weapper">
        <div className="column col1">
          <div className="wrapperRecords">
            <RecoSelfie
              img={
                <CentradoSVG
                  color={
                    currentVideoIndex == 0
                      ? "var(--orangeColorMain)"
                      : colorGray
                  }
                />
              }
              title="Mantén tu rostro centrado y ubicado dentro de la máscara."
              className={
                currentVideoIndex == 0 ? "recotext recotextbold" : "recotext"
              }
            />
            <RecoSelfie
              img={
                <ParpadeaSVG
                  color={
                    currentVideoIndex == 1
                      ? "var(--orangeColorMain)"
                      : colorGray
                  }
                />
              }
              title="Parpadea una vez y luego mira de frente a la cámara."
              className={
                currentVideoIndex == 1 ? "recotext recotextbold" : "recotext"
              }
            />

            <RecoSelfie
              img={
                <IluminadoSVG
                  color={
                    currentVideoIndex == 2
                      ? "var(--orangeColorMain)"
                      : colorGray
                  }
                />
              }
              title="Busca un espacio iluminado y un color de fondo plano."
              className={
                currentVideoIndex == 2
                  ? "recotext recotextbold imgbold"
                  : "recotext"
              }
            />
            <RecoSelfie
              img={
                <AccesoriosSVG
                  color={
                    currentVideoIndex == 3
                      ? "var(--orangeColorMain)"
                      : colorGray
                  }
                />
              }
              title="No uses accesorios como lentes o sombreros."
              className={
                currentVideoIndex == 3
                  ? "recotext recotextbold imgbold"
                  : "recotext"
              }
            />
          </div>
        </div>
        <div className="column col2">
          <video
            ref={videoRef}
            className="videorecord"
            autoPlay
            muted
            onEnded={handleVideoEnd}
            data-testid='input-field'
          >
            <source src={videoList[currentVideoIndex]} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
        </div>
      </div>
    </RecordWrapper>
  );
};
