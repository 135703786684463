import { Button } from "@prima-afp/prima-ui-elements/dist/core";
import {
  ValidZone2,
  ValidZone1,
  ValidNotNullContactData,
} from "../../constants/validate";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import { SendDocumentIdentity, AcceptTermsConditions } from "../../services";
import { ParseResponse } from "../../constants/parse";
import { buildBodyStep1, BuildBodyForTC } from "../../constants/buildBody";
import { ButtonsContainer, ContainerIdentity } from "./styles";
import { CONTENT_CLIENT_PRIMA } from "../../../shared/constants";
import { FrameAuthorization } from "./FrameAuthorization";
import { FrameAsesor } from "./FrameAsesor";
import found1 from "../../assets/images/found1.svg";
import found2 from "../../assets/images/found2.svg";
import found3 from "../../assets/images/found3.svg";
import found1Green from "../../assets/images/found1Green.svg";
import found2Green from "../../assets/images/found2Green.svg";
import found3Green from "../../assets/images/found3Green.svg";
import { useHistory } from 'react-router-dom';
import Bowser from 'bowser';
import platform from 'platform';
import {sendToData} from '../../constants/sendToDataLayer';
import {updateUrl} from '../../constants/updateUrl';


export const SendButton = ({ buttonRef }) => {
  const state = useStateDigitalTransfersContext();
  const { listGenerals,urlFlow, lastStep, stepCurrent  } = useStateDigitalTransfersContext();
  const { SendFetch, SetLoading } = useCatchFetch();
  const history = useHistory();  
  const {
    showPersonalData,
    showContactData,
    isAddressDNI,
    haveAsesor,
    confirmationTC,
    userWeb,
    validReniecActive,
    validSecontReniecActive
  } = state;

  const {
    documentType,
    documentNumber,
    email,
    emailConfirmation,
    phoneNumber,
    tcConfirmation,
    adpConfirmation,
  } = userWeb;

  const dispatch = useDispatchDigitalTransfersContext();

  const reCaptchaRef = useRef();
  const [indPath, setindPath] = useState(0);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('mitraspaso') || currentUrl.includes('transactions') || currentUrl.includes('localhost')) {
      setindPath(0);
    } else {
      setindPath(1);
    }
  }, []);

  const Send = () => {  
    if (showPersonalData) {
        onSubmitContactData();
        return true;
    }else{
      onSubmitDocument();
    }

  };

  const getDataDevice = () => {
    // Obtenemos información del dispositivo y del navegador
    const deviceType = getDeviceType();
    const os = getOperatingSystem();
    const browserInfo = getBrowserInfo();    
    const deviceInfo = deviceType + "|" + os + "|" + browserInfo.name + "|" + browserInfo.version;
    return deviceInfo;
  };

  const getDeviceType = () => {
      // Detectar el tipo de dispositivo basado en el ancho de la ventana
      const width = window.innerWidth;
      if (width <= 480) {
        return 'Móvil';
      } else if (width <= 1024) {
        return 'Tablet';
      } else {
        return 'PC';
      }
    };

  const getValue = (str, currentURL) => {
      const startIndex = currentURL.indexOf(str);
      if (startIndex !== -1) {
        // Encontrar la posición de "&" después de "utm_source="
        const endIndex = currentURL.indexOf("&", startIndex);    
        // Extraer la subcadena entre "utm_source=" y "&"
        const strValue = endIndex !== -1 ? currentURL.substring(startIndex + str.length, endIndex) : currentURL.substring(startIndex + str.length);
        return strValue;
      } else {
        return "not set";
      }       
  };

  const getOperatingSystem = () => {
      // Obtener información sobre el sistema operativo
      const os = platform.os;
      return `${os.family} ${os.version}`;
  };

  const getBrowserInfo = () => {
      const browserInfo = Bowser.getParser(window.navigator.userAgent);
      return {
          name: browserInfo.getBrowserName(),
          version: browserInfo.getBrowserVersion(),
      };
  };

  const onSubmitDataNoReniec = async () => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "errorConfirmationTC",
        value: false,
      },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "descriptionHousingInput",
        value: false,
      },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "descriptionViaInput",
        value: false,
      },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "districtCodeInput", value: false },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "provinceCodeInput", value: false },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "departmentCodeInput",
        value: false,
      },
    });

    SetLoading(true);
    const validation = ValidZone2(isAddressDNI, confirmationTC, userWeb, validSecontReniecActive);

    if (validation.length == 0) {
      const body = buildBodyStep1(state);
      var listVias = listGenerals.listVias;
      var listSectors = listGenerals.listSectors;
      const bodyForTc = BuildBodyForTC(
        listVias,
        listSectors,
        state.userWeb,
        isAddressDNI,
        haveAsesor,
        validSecontReniecActive,
      );
      
      if(window.location.href.includes('asesor')){
        bodyForTc.byAssessor = true;
      }     

      SendFetch(AcceptTermsConditions(bodyForTc)).then((res) => {
        dispatch({
          type: "COMPLETE_IDENTITY_STEP",
          payload: body.userWebTransfer,
        });
        //Seccción para enviar atm.event
        const currentUrl = window.location.href;
        const metadata_1_ans =  "en la direccion registrada en mi dni";
        const metadata_2_ans = "si";
        var payload = {section: 'paso 1_2', metadata_1: "¿en donde vives actualmente? - " + metadata_1_ans,
          metadata_2: "¿llegaste aqui con ayuda de un asesor? - " + metadata_2_ans,
          option: 'siguiente', 
          count_1_2: 1,
          dni: userWeb.documentNumber,
          telefono: userWeb.phoneNumber,
          email: userWeb.email
        };
        sendToData(currentUrl, "step", "paso_1_2", payload, urlFlow);    
        if (lastStep < 2) {  dispatch({ type: 'FIELD', payload: { field: 'lastStep', value: 2 } });}   
        window.location.href.includes('asesor') ? updateUrl(history, urlFlow, '/asesor/pasos2' ) : updateUrl(history, urlFlow, '/pasos2' );
      });
    } else {
      if (validation.includes("errorConfirmationTC")) {
        dispatch({
          type: "SET_CONFIG_MODAL",
          payload: {
            content: "Opción no válida en el check de terminos y condiciones",
          },
        });
      }
      if (validation.includes("descriptionHousingInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "descriptionHousingInput",
            value: true,
          },
        });
      }
      if (validation.includes("descriptionViaInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "descriptionViaInput",
            value: true,
          },
        });
      }
      if (validation.includes("districtCodeInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "districtCodeInput",
            value: true,
          },
        });
      }
      if (validation.includes("provinceCodeInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "provinceCodeInput",
            value: true,
          },
        });
      }
      if (validation.includes("departmentCodeInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "departmentCodeInput",
            value: true,
          },
        });
      }

      SetLoading(false);
    }
  };

  const onSubmitDocument = async () => {
    SetLoading(true);
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "errorDocNumberInput",
        value: false,
      },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "errorPhoneInput", value: false },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "errorEmailInput", value: false },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "errorEmailCinput", value: false },
    });

    reCaptchaRef.current.reset();
    const recaptchaToken = await reCaptchaRef.current.executeAsync();
    const validation = ValidZone1(documentType, documentNumber, userWeb);

    if (validation.length == 0) {
      const body = {
        documentType,
        documentNumber,
        recaptchaToken,
        email,
        phoneNumber,
        validReniec: validReniecActive,
        byAssessor: true,
        device: getDataDevice(),  
        indPath: indPath,
      };
      SendFetch(SendDocumentIdentity(body))
        .then((res) => {
          const currentUrl = urlFlow;
          const haveAVal = haveAsesor;
          //limpiar todos los datos
          //dispatch({ type: "INITIAL_STATE" });
          dispatch({ type: 'FIELD', payload: { field: 'urlFlow', value: currentUrl } });  
          dispatch({ type: 'FIELD', payload: { field: 'haveAsesor', value: haveAVal } });   
          dispatch({ type: 'FIELD', payload: { field: 'lastStep', value: 1 } });   
          dispatch({ type: 'FIELD', payload: { field: 'stepCurrent', value: 1 } });   

          //guardar solo los sgtes datos: documentType, documentNumber, email, phoneNumber
          dispatch({
            type: "FIELD_SECOND",
            payload: {
              field1: "userWeb",
              field2: "documentType",
              value: body.documentType,
            },
          });
          dispatch({
            type: "FIELD_SECOND",
            payload: {
              field1: "userWeb",
              field2: "documentNumber",
              value: body.documentNumber,
            },
          });
          dispatch({
            type: "FIELD_SECOND",
            payload: { field1: "userWeb", field2: "email", value: body.email },
          });
          dispatch({
            type: "FIELD_SECOND",
            payload: {
              field1: "userWeb",
              field2: "emailConfirmation",
              value: body.email,
            },
          });
          dispatch({
            type: "FIELD_SECOND",
            payload: {
              field1: "userWeb",
              field2: "phoneNumber",
              value: body.phoneNumber,
            },
          });

          if (res.code === -1) {
            if (res.message.includes("ErrorAttemptsBio:")) {
              const auxString = res.message;
              const contentModal = auxString.replace("ErrorAttemptsBio:", "");
              dispatch({
                type: "NOT_VALID_DOCUMENT",
                payload: {
                  idModal: "btn-attempts-biometric",
                  content: contentModal,
                  changeLoading: false,
                },
              });
            } else {
              dispatch({
                type: "NOT_VALID_DOCUMENT",
                payload: {
                  idModal: "btn-error-documentIdentity",
                  content:
                    "Hubo un inconveniente con la consulta de tu documento de identidad, por favor vuelve a intentarlo.",
                  changeLoading: false,
                },
              });
            }
            SetLoading(false);
          } 
          else if (res.code === 0 || res.code === 1 || res.code === 4) {
            if(res.code === 4){
              dispatch({ type: "FIELD", payload: { field: "validSecontReniecActive", value: false, }, });
              dispatch({ type: "FIELD", payload: { field: "validReniecActive", value: false}});
              dispatch({ type: "FIELD", payload: { field: "isAddressDNI", value: true}}); 
              res.userWebTransfer = {};
              res.userWebTransfer.documentType = userWeb.documentType;
              res.userWebTransfer.documentNumber = userWeb.documentNumber;
              res.userWebTransfer.phoneNumber = userWeb.phoneNumber; 
              res.userWebTransfer.email = userWeb.email; 
              res.userWebTransfer.firstName = "";
            }
            res = ParseResponse(res);
            dispatch({
              type: "VALID_DOCUMENT",
              payload: {
                userWebTransfer: res.userWebTransfer,
                userWeb: res.userWeb,
                validReniec: res.userWebTransfer.validReniec,
                changeLoading: false,
                enabledAbandonmentStep1: true,
              },
            });
            if (res.userWebTransfer.age > 59) {
              const list = [
                {
                  id: 1,
                  type: 0,
                  name: "Fondo 0",
                  subtitle: "Ultraconservador",
                  content:
                    "Ya no quieres arriesgar tu capital y asumes bajos retornos de inversión, puedes elegirlo desde los",
                  content2: "60 años.",
                  img: found3,
                  imgSelect: found3Green,
                  reco: "Edad recomendada: Menos de 45 años.",
                },
                {
                  id: 2,
                  type: 1,
                  name: "Fondo 1",
                  subtitle: "Riesgo bajo",
                  content:
                    "Buscas asegurar tu capital aunque el retorno sea bajo, recomendado para",
                  content2: "mayores de 60 años.",
                  img: found1,
                  imgSelect: found1Green,
                  reco: "Edad recomendada: Más de 60 años.",
                },
                {
                  id: 3,
                  type: 2,
                  name: "Fondo 2",
                  subtitle: "Riesgo medio",
                  content:
                    "Buscas crecer sin prisa pero sin pausa con un retorno medio-bajo, ideal si tienes",
                  content2: "entre 45 a 60 años.",
                  img: found2,
                  imgSelect: found2Green,
                  reco: "Edad recomendada: De 45 a 60 años.",
                }
              ];

              dispatch({
                type: "FIELD",
                payload: { field: "typesList", value: list },
              });

              dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'fundType', value: 0}}); // item.type 
              dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'codeFundType', value: 0}}); // item.type 
  
            }

            var mail = email.replace("@", "#");
            mail = mail.replace(".", "&");
            dataLayer.push({
              docUser: documentNumber,
              mailUser: mail,
              contactUserPhone: phoneNumber,
            });

            var payload = {section: 'paso 1_1', 
              option: 'siguiente', count_1_1: 1,
              dni: userWeb.documentNumber,
              telefono: userWeb.phoneNumber,
              email: userWeb.email
            };
            sendToData(currentUrl, "step", "paso_1_1", payload, urlFlow);  


            SetLoading(false);
          } 
          else if (res.code === 2) {
            dispatch({
              type: "NOT_VALID_DOCUMENT",
              payload: {
                idModal: "btn-prima-client",
                type: "happy",
                title: "Eres cliente de Prima AFP",
                content: CONTENT_CLIENT_PRIMA,
                textButton: "Entendido",
                changeLoading: false,
              },
            });
            var payloadClient = {section: 'validacion_cliente', 
              message: 'ya es cliente',
              dni: userWeb.documentNumber,
              telefono: userWeb.phoneNumber,
              email: userWeb.email
            };
            sendToData(window.location.href, "mensaje_click", "mensaje", payloadClient); 
          } 
          else if (res.code === 3) {
            dispatch({
              type: "NOT_VALID_DOCUMENT",
              payload: {
                idModal: "btn-have-request",
                type: "happy",
                title: "Ya tienes una solicitud",
                content:
                  "Tu traspaso hacia Prima AFP está en proceso, para mayor información llámanos al 615-7272 en Lima o al 0-801-18010 en provincias",
                textButton: "Entendido",
                changeLoading: false,
              },
            });
            var payloadRequest = {section: 'validacion_cliente', 
              message: 'tiene solicitud',
              dni: userWeb.documentNumber,
              telefono: userWeb.phoneNumber,
              email: userWeb.email
            };
            sendToData(window.location.href, "mensaje_click", "mensaje", payloadRequest);  
          } 
          else {
            dispatch({              
              type: "NOT_VALID_DOCUMENT",
              payload: {
                idModal: 'btn-error-documentIdentity',
                content:
                  "Hubo un inconveniente con la consulta de tu documento de identidad, por favor vuelve a intentarlo.",
                changeLoading: false,
              },
            });  
          }
        })
        .catch((e) => {
          dispatch({            
            type: "NOT_VALID_DOCUMENT",
            payload: {
              idModal: 'btn-error-documentIdentity',
              content:
                "Hubo un inconveniente con la consulta de tu documento de identidad, por favor vuelve a intentarlo.",
              changeLoading: false,
              textButton: "Volver",
            },
          });  
        });
    } else {
      setErrorValidationIdentity(validation);
    }
  };

  const setErrorValidationIdentity = (validation) => {
    if (validation.includes("errorDocNumberInput")) {
      dispatch({
        type: "FIELD_SECOND",
        payload: {
          field1: "userWeb",
          field2: "errorDocNumberInput",
          value: true,
        },
      });
    }
    if (validation.includes("errorPhoneInput")) {
      dispatch({
        type: "FIELD_SECOND",
        payload: {
          field1: "userWeb",
          field2: "errorPhoneInput",
          value: true,
        },
      });
    }
    if (validation.includes("errorEmailInput")) {
      dispatch({
        type: "FIELD_SECOND",
        payload: {
          field1: "userWeb",
          field2: "errorEmailInput",
          value: true,
        },
      });
    }
    if (validation.includes("errorEmailCinput")) {
      dispatch({
        type: "FIELD_SECOND",
        payload: {
          field1: "userWeb",
          field2: "errorEmailCinput",
          value: true,
        },
      });
    }
    SetLoading(false);
  };

  const onSubmitContactData = async () => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "errorConfirmationTC",
        value: false,
      },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "descriptionHousingInput",
        value: false,
      },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "descriptionViaInput",
        value: false,
      },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "districtCodeInput", value: false },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "provinceCodeInput", value: false },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "departmentCodeInput",
        value: false,
      },
    });

    SetLoading(true);
    const validation = ValidZone2(isAddressDNI, confirmationTC, userWeb, validSecontReniecActive);

    if (validation.length == 0) {
      const body = buildBodyStep1(state);
      var listVias = listGenerals.listVias;
      var listSectors = listGenerals.listSectors;
      const bodyForTc = BuildBodyForTC(
        listVias,
        listSectors,
        state.userWeb,
        isAddressDNI,
        haveAsesor,
        validSecontReniecActive,
      );
      
      if(window.location.href.includes('asesor')){
        bodyForTc.byAssessor = true;
      }     

      SendFetch(AcceptTermsConditions(bodyForTc)).then((res) => {
        dispatch({
          type: "COMPLETE_IDENTITY_STEP",
          payload: body.userWebTransfer,
        });
        //Seccción para enviar atm.event
        const currentUrl = window.location.href;
        const metadata_1_ans =  "en la direccion registrada en mi dni";
        const metadata_2_ans = haveAsesor?  "si" : "no";
        var payload = {section: 'paso 1_2', metadata_1: "¿en donde vives actualmente? - " + metadata_1_ans,
          metadata_2: "¿llegaste aqui con ayuda de un asesor? - " + metadata_2_ans,
          option: 'siguiente', 
          count_1_2: 1,
          dni: userWeb.documentNumber,
          telefono: userWeb.phoneNumber,
          email: userWeb.email
        };
        sendToData(currentUrl, "step", "paso_1_2", payload, urlFlow);    
        if (lastStep < 2) {  dispatch({ type: 'FIELD', payload: { field: 'lastStep', value: 2 } });}   
        window.location.href.includes('asesor') ? updateUrl(history, urlFlow, '/asesor/pasos2' ) : updateUrl(history, urlFlow, '/pasos2' );
      });
    } else {
      if (validation.includes("errorConfirmationTC")) {
        dispatch({
          type: "SET_CONFIG_MODAL",
          payload: {
            content: "Opción no válida en el check de terminos y condiciones",
          },
        });
      }
      if (validation.includes("descriptionHousingInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "descriptionHousingInput",
            value: true,
          },
        });
      }
      if (validation.includes("descriptionViaInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "descriptionViaInput",
            value: true,
          },
        });
      }
      if (validation.includes("districtCodeInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "districtCodeInput",
            value: true,
          },
        });
      }
      if (validation.includes("provinceCodeInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "provinceCodeInput",
            value: true,
          },
        });
      }
      if (validation.includes("departmentCodeInput")) {
        dispatch({
          type: "FIELD_SECOND",
          payload: {
            field1: "userWeb",
            field2: "departmentCodeInput",
            value: true,
          },
        });
      }

      SetLoading(false);
    }
  };

  const getText = () => {
    if (showContactData || showPersonalData) {
      return "Siguiente";
    }

    return "Enviar";
  };

  const isDisabled = () => {
    if (showPersonalData) {
      if(!validSecontReniecActive){
        if(userWeb.tcConfirmation == false )
        {
          return true; 
        }else  {
          return false;
        }
      }
      return ValidNotNullContactData(isAddressDNI, confirmationTC, userWeb, haveAsesor, userWeb.minimumConfirmation);
    }

    if (
      userWeb.documentType.length > 0 &&
      userWeb.documentNumber.length == 8 &&
      phoneNumber.length == 9 &&
      email.length > 0 &&
      emailConfirmation.length > 0
    ) {
      return false;
    }
    return true;
  };

  const isDisabledvalidReniec = () => {
    if (
      userWeb.documentType.length > 0 &&
      userWeb.documentNumber.length == 8 &&
      phoneNumber.length == 9 &&
      email.length > 0 &&
      emailConfirmation.length > 0 &&
      tcConfirmation &&
      haveAsesor != null
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <ReCAPTCHA
        ref={reCaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
        size="invisible"
        style={{visibility: 'hidden'}}
      />

      {(showPersonalData && validReniecActive && 
                <div>                                  
                <FrameAuthorization />           
              </div>
              ) ||
        (validReniecActive == false && (
          <div>      
            <FrameAuthorization />           
          </div>
        ))}

      <ButtonsContainer>
        {getText() == "Enviar" && validReniecActive && (
          <Button
            id="identity-btn-env"
            className="buttonStep1"
            onClick={Send}
            ref={buttonRef}
            disabled={isDisabled()}
          >
            Siguiente
          </Button>
        )}

        {showPersonalData && validReniecActive && (
          <Button
            data-testid="identity-btn-send"
            id="identity-btn-send"
            className="buttonStep1 identity-btn-send"
            onClick={Send}
            ref={buttonRef}
            disabled={isDisabled()}
          >
            Siguiente
          </Button>
        )}

        {!validReniecActive && (
          <Button
            data-testid="identity-btn-send2"
            id="identity-btn-send"
            className="buttonStep1"
            onClick={onSubmitDataNoReniec}
            ref={buttonRef}
            disabled={isDisabledvalidReniec()}
          >
            Siguiente
          </Button>
        )}
      </ButtonsContainer>
    </>
  );
};
