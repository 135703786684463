import PropTypes from "prop-types";
import React from 'react';
import { ContentTooltipStyled, TextStyled } from "./styles";

export default function TooltipContent({ title, subtitle }) {
    return (
        <ContentTooltipStyled>
            <TextStyled title>
                {title}
            </TextStyled>

            <TextStyled subtitle>
                {subtitle}
            </TextStyled>
        </ContentTooltipStyled>
    );
}

TooltipContent.propTypes = {
    subtitle: PropTypes.any,
    title: PropTypes.any
};
