import styled from "styled-components";

export const Icon = styled.img`
  width: 56px;
  height: 56px;
`;

export const DivModal = styled.div`
  margin-bottom: 16px;
`;

export const DivTitle = styled.div`
display: flex;
justify-content: start;
margin-bottom: 48px;
h3{
  font-family: 'FS Emeric';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

@media only screen and (max-width : 375px) {
  margin-bottom: 32px;
  h3{
    display: flex;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
  }
}


`;

export const ButtonClose = styled.div`
    text-align: right;
    color: var(--orangeColorMain);
    font-weight: bold;
    margin-top: -2.5rem;
    cursor: pointer;
    font-size: 1.5rem;
`;

export const DivModalTC = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 600px;
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4E5258;

  #buttonDiv{
    margin-left: 15px;
    margin-right: 15px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid #D6D6D6;
    border-radius: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #B4B4B4;
    border-radius: 8px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .contentP{
    color: #4E5258;
  }
  .titleP{
    font-family: 'FS Emeric';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #4E5258;
  }
  @media only screen and (max-width : 450px) {
    height: 308px !important;
    .contentP{
      padding-right: 16px;
    }
  }
 
  @media only screen and (min-width : 450px) and (max-width: 769px) {
    height: 308px !important;
    
  }

  @media only screen and (min-width : 767px) and (max-width: 1366px) {
    height: 308px !important;
    
  }
   
  @media only screen and (min-width : 1366px)  {
    height: 404px !important;
  }

  // @media only screen and (max-height : 700px) {
  //   height: 418px !important;
  //   #contentDiv{
  //     margin-left: 0px !important;
  //     margin-right: 0px !important;
  //   }
  // }

`;


export const ButtonsContainer = styled.div`
    display: flex;
    justify-content:center
    button{
      margin: 12px;
    }

    @media only screen and (max-width : 375px) {
      display: grid;
      grid-template-rows: repeat(1, 1fr);
      justify-content: normal;
      button{
        margin: 0px;
        margin-bottom: 16px !important;
      }
    }
`;

