import React, { useState, useRef, useEffect } from 'react';
import { WrapperInput } from './styles1';
import dropdownIcon from './dropdownIcon.svg';

export default function DropdownPrima({
  listOption = [],
  defaultSelect,
  value,
  saveSelectOption,
  saveValueInput,
  flagError,
  errorMessage,
  status,
  inputType,
  maxLength,
  label,
  type,
  placeholder
}) {
  const [selectedOption, setSelectedOption] = useState({ id: 0, text: '' });
  const [inputValue, setInputValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const inputRef = useRef(null);

  const [inputColor, setInputColor] = useState('#E7E7E8');
  const [borderWidth, setBorderWidth] = useState('2px');

  // Seteo de valores
  useEffect(() => {
    if (listOption.length > 0) {
      setSelectedOption(listOption[0]);
    }
    setInputValue(value);
  }, [listOption]);

  // Seteo con el contexto
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Detectar error
  useEffect(() => {
    if (flagError === false) {
      setInputColor('#E7E7E8');
      setBorderWidth('1px');
    } else if (flagError === true) {
      setInputColor('#FF4B4B');
      setBorderWidth('2px');
    }
  }, [flagError]);

  // Detectar clic fuera del componente
  const handleClickOutside = () => {
    setDropdownOpen(false);
  };

  const handleFocus = () => {
    inputRef.current.classList.add('focused');
    //setInputColor('#FF4F00');
    //setBorderWidth('2px');
  };

  const handleBlur = () => {
    inputRef.current.classList.remove('focused');
    //setInputColor('#E7E7E8');
    //setBorderWidth('1px');
  };

  const useOutsideClick = (callback) => {
    const ref = React.useRef();
    React.useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick, true);
      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);
    return ref;
  };

  const ref = useOutsideClick(handleClickOutside);

  // Detectar cuando se hace clic en el dropdown
  const toggleDropdown = () => {
    if (status !== 'disabled') {
      setDropdownOpen(!dropdownOpen);
    }
  };

  // Guardar cambio en el input
  const handleInputChange = (event) => {
    const valNumbers = /^[0-9\b]+$/;
    const value = event.target.value;
    if (type === 'number') {
      if (valNumbers.test(Number(event.target.value))) {
        setInputValue(value);
        saveValueInput(event);
      }
    } else {
      setInputValue(value);
      saveValueInput(event);
    }
  };

  // Guardar opción seleccionada
  const handleClick = (item) => {
    setDropdownOpen(false);
    setSelectedOption(item);
    saveSelectOption(item.code);
  };

  const getText = (item) => {
    const textD = item.text != null ? item.text : item.description;
    return textD.length > 4 ? textD.substring(0, 3) + '...' : textD;
  };

  return (
    <WrapperInput className='WrapperInput'>
      <div className="label">{label}</div>
      <div
        className="container"
        style={{
          borderColor: inputColor,
          borderLeftStyle: 'solid',
          borderWidth: borderWidth,
          backgroundColor: status === 'disabled' ? '#E7E7E8' : 'white'
        }}
      >
        <div className="dropdown" onClick={toggleDropdown} style={{ cursor: status === 'disabled' ? 'not-allowed' : 'pointer' }}>
          <div className="dropdownText">{getText(selectedOption)}</div>
          <img className="dropdownIcon" src={dropdownIcon} />
        </div>
        {dropdownOpen && (
          <div className="list-container" ref={ref}>
            {listOption.map((item) => (
              <div
                key={item.id ? item.id : item.code}
                className="list-item"
                onClick={() => handleClick(item)}
              >
                {item.text ? item.text : item.description}
              </div>
            ))}
          </div>
        )}
        <div
          className="input-container"
          ref={inputRef}
          onClick={() => inputRef.current.querySelector('input').focus()}
          onFocus={handleFocus}
          onBlur={handleBlur}
          tabIndex={0}
          style={{ cursor: status === 'disabled' ? 'not-allowed' : 'text' }}
        >
          <input
            className='input-locker'
            type={'text'}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={status === 'disabled'}
            style={{
              backgroundColor: status === 'disabled' ? '#E7E7E8' : 'white',
              cursor: status === 'disabled' ? 'not-allowed' : 'text'
            }}
          />
        </div>
      </div>
      {flagError && <span className="spanError">{errorMessage}</span>}
    </WrapperInput>
  );
}
