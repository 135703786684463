import PropTypes from "prop-types";
import React from "react";

import { Progress } from "@prima-afp/prima-ui-elements/dist/core";

import { StyledModal, GifBiometric } from "./styles";
import biometria from "./biometria.gif";

export default function Loading(props) {

    const { show } = props;
    const showBiometricGif = () =>{
        if(window.location.href.includes('pasos3validacion-biometrica') && !window.location.href.includes('asesor') ){
            return true;
        }
        return false;
    };

    return (
        <>

            {
                showBiometricGif() == true && show && <GifBiometric >
                    <img
                        loading="lazy"
                        src={biometria}
                        className="imgLoading"
                    />
                    <span className="spanGif">Estamos validando tu identidad...</span>
                </GifBiometric>
            }
            {
                showBiometricGif() == false && <StyledModal show={show}>
                    <Progress showMessages={false}></Progress>
                </StyledModal>
            }
        </>

    );
}

Loading.propTypes = {
    show: PropTypes.any
};
