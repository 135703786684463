import React, { useState } from 'react';
import TooltipCustom from '../Tooltip';
import {WrapperSlider} from '../styles.jsx';
import { useStateDigitalTransfersContext } from '../../../context/DigitalTransfersContext';


const Slider = ({optionSilder = [], selectOption}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const { userWeb } = useStateDigitalTransfersContext();
  
    const handleOptionClick = (option) => {
      setSelectedOption(option.type);
      selectOption(option);
    };

    return (
        <WrapperSlider className={`wrapperSlider ${optionSilder.length == 4 ? 'wrapperSlider4' : ''}`}>
            <div className="line_back_slider" />
            <div className="slider">
                {optionSilder.map((option, index) => (
                <React.Fragment key={option.id}>
                    <TooltipCustom item={option} title={option.name} content={option.content} content2={option.content2} idOption={option.id} >
                    <div
                        className={`circle ${selectedOption === option.type || option.type === userWeb.fundType ? 'selected' : ''}`}
                        onClick={() => handleOptionClick(option)}
                        data-testid='input-field'
                    >                        
                    </div>

                    <div className={`description ${selectedOption === option.type || option.type === userWeb.fundType ? 'selected' : ''}`}>{option.name}</div>

                    </TooltipCustom>
                    {index < optionSilder.length - 1 && <div className="line" />}

                </React.Fragment>                
                ))}
            </div>
        </WrapperSlider>
    );

};

export default Slider;
