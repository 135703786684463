import { useDispatchDigitalTransfersContext, useStateDigitalTransfersContext } from '../../../digital-transfers/context/DigitalTransfersContext';
import React, { useState, useEffect } from 'react';
import {  DivHeader, TextStyledStep0  } from '../../../digital-transfers/componentes-sin-asesor/StepIdentity/styles';
import {sendToData} from '../../../digital-transfers/constants/sendToDataLayer';
import { Button } from "@prima-afp/prima-ui-elements/dist/core";
import { useHistory } from 'react-router-dom';
import { updateUrl} from "../../../digital-transfers/constants/updateUrl";

export const HeaderStep = ({isMobile}) => {
    const [idBtn, setIdBtn] = useState('');
    const state = useStateDigitalTransfersContext();
    const { stepCurrent, haveAsesor } = useStateDigitalTransfersContext();
    const history = useHistory();
    const {
        showPersonalData,
    } = state;

    const dispatch = useDispatchDigitalTransfersContext();

  
    useEffect(() => {
        switch (stepCurrent) {
            case 1: setIdBtn("btn-identity-back");
            break;
                
            case 2:setIdBtn("btn-fund-type-back");
            break;

            case 3: setIdBtn("btn-biometric-back");
            break;
                
            case 3.5:setIdBtn("btn-widget-back");
            break;

            case 4: setIdBtn("btn-sms-back");
            break;
                
            case 5:setIdBtn("btn-finish-back");
            break;

        }
    }, [stepCurrent]); 

    const sendToGTM = (eventNum, section) =>{    
        const currentUrl = window.location.href;
        var payload = {
            section: section, 
            option: 'atras',
        };
        sendToData(currentUrl, "link_click", "link_click", payload);  
    };

    const backStep = () => {    
        const currentUrl = window.location.href;
        switch (stepCurrent) {
            case 1:
                if(!showPersonalData){              
                    var value= null;
                    dispatch({ type: 'FIELD_SECOND',
                        payload: {
                            field1: 'userWeb',
                            field2: 'initialCheckbox',
                            value
                        }
                    });
                    sendToGTM(1, "paso 1");
                    if (window.location.href.includes('asesor')) {
                        updateUrl(history, currentUrl, '/asesor/pasos0');
                    }else{
                        updateUrl(history, currentUrl, '/pasos0');
                    }                 
                }else{ 
                    dispatch({ type: 'RETURN_STEP_1_INPUTS', });                     
                }
                break;
                
            case 2:
                dispatch({
                    type: 'BACK_FUND_TYPE_STEP',
                });
                dispatch({ type: 'FIELD', payload: { field: 'startFlow', value: true } });
                sendToGTM(2, "paso 2");
                window.location.href.includes('asesor') ?  updateUrl(history, currentUrl, '/asesor/pasos1') :  updateUrl(history, currentUrl, '/pasos1');
                break;

            case 3:
                dispatch({
                    type: 'BACK_BIOMETRIC_STEP',
                });
                sendToGTM(3, "paso 3");
                window.location.href.includes('asesor') ?  updateUrl(history, currentUrl, '/asesor/pasos2') :   updateUrl(history, currentUrl, '/pasos2');
                break;

            case 4:
                dispatch({
                    type: 'BACK_DOCUMENTATION_STEP',
                });
                dispatch({ type: 'FIELD', payload: { field: 'biometricVerified', value: false } });
                dispatch({ type: 'FIELD', payload: { field: 'imageBiometric', value: null } });
                dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'biometric', value: null} });
                sendToGTM(4, "paso 4");
                window.location.href.includes('asesor') ?  updateUrl(history, currentUrl, '/asesor/pasos3') : updateUrl(history, currentUrl, '/pasos3');
                break;

            case 3.5:
                dispatch({
                    type: 'BACK_DOCUMENTATION_STEP',
                });
                dispatch({ type: 'FIELD', payload: { field: 'showDivBenefits', value: true } });
                dispatch({ type: 'FIELD', payload: { field: 'biometricVerified', value: false } });
                dispatch({ type: 'FIELD', payload: { field: 'imageBiometric', value: null } });
                dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'biometric', value: null} });
                sendToGTM(3.5, "paso 3");
                window.location.href.includes('asesor') ?  updateUrl(history, currentUrl, '/asesor/pasos3') :  updateUrl(history, currentUrl, '/pasos3');
                break;

            case 5:
                dispatch({
                    type: 'BACK_TO_STEP_4',
                });
                dispatch({ type: 'FIELD', payload: { field: 'showDivBenefits', value: true } });
                if (window.location.href.includes('traspasos-digitales-asesor')) {
                    updateUrl(history, currentUrl, '/traspasos-digitales-asesor/pasos');
                }else{
                    updateUrl(history, currentUrl, '/traspasos-digitales/pasos');
                }
                window.location.href.includes('asesor') ?  updateUrl(history, currentUrl, '/asesor/pasos4') :  updateUrl(history, currentUrl, '/pasos4');
                break;

        }

    };

    return (
        <>
        {!isMobile &&
            <DivHeader id="idHeader" style={{display: 'flex'}}>
            <div style={{flex:1, display: 'flex'}} >

            {stepCurrent === 1 && 
                <div id="btn-identity-back" style={{flex:1, display: 'flex', textDecoration: 'none', cursor: 'pointer'}}  onClick={backStep}>
                    <div id="idDivBtn"> 
                    </div>  
                    <span id="idDivBtnText">Atrás</span>
                </div>
            }
            {stepCurrent === 2 && 
                <div id="btn-fund-type-back" style={{flex:1, display: 'flex', textDecoration: 'none' ,cursor: 'pointer'}}  onClick={backStep}>
                    <div id="idDivBtn"> 
                    </div>  
                    <span id="idDivBtnText">Atrás</span>
                </div>
            }
            {stepCurrent === 3 && 
                <div id="btn-biometric-back" style={{flex:1, display: 'flex', textDecoration: 'none' ,cursor: 'pointer'}}  onClick={backStep}>
                    <div id="idDivBtn"> 
                    </div>  
                    <span id="idDivBtnText">Atrás</span>
                </div>
            }
            {stepCurrent === 3.5 && 
                <div id="btn-widget-back" style={{flex:1, display: 'flex', textDecoration: 'none' ,cursor: 'pointer'}}  onClick={backStep}>
                    <div id="idDivBtn"> 
                    </div>  
                    <span id="idDivBtnText">Atrás</span>
                </div>
            }
            {stepCurrent === 4 && 
                <div id="btn-sms-back" style={{flex:1, display: 'flex', textDecoration: 'none' ,cursor: 'pointer'}}  onClick={backStep}>
                    <div id="idDivBtn"> 
                    </div>  
                    <span id="idDivBtnText">Atrás</span>
                </div>
            }
            {stepCurrent === 5 && 
                <div id="btn-finish-back" style={{flex:1, display: 'flex', textDecoration: 'none' ,cursor: 'pointer'}}  onClick={backStep}>
                </div>
            }
            {stepCurrent === 0 && 
                <div id="btn-finish-back" style={{flex:1, display: 'flex', textDecoration: 'none' ,cursor: 'pointer'}}>
                </div>
            }

            </div> 

            <div id="idSlogan" style={{display: 'flex', justifyContent: 'flex-end' }}>        
                <TextStyledStep0>
                Ayer, Hoy, Siempre
                </TextStyledStep0>  
            </div>

            </DivHeader>
        }
        {isMobile &&         
            <Button id={idBtn} className={'backButtonMobile'} hierarchy={'secundary'} onClick={backStep} style={{'width': '100%', 'marginBottom' : '25px'}}> Anterior </Button>
        }

        </>
    );
};
