import styled, { css } from "styled-components";
import { mediaQuery } from "modules/utils/responsive";

export const ContainerStepsPage = styled.div`

    @media only screen and (min-width : 1728px){
        align-items: flex-start !important;
    }

    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
    }

    @media only screen and (min-width : 451px) and (max-width: 1000px) {       
    } 

    @media only screen and (max-width : 450px) {  
        display: flex;
        flex-direction: column;
        align-items: center;
    }


`;

export const InfoContainer  = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    color: var(--Text-Gray-Subtle, #8f9295);
    letter-spacing: 0.02px;
    flex-wrap: nowrap;
    font: 400 14px / 20px var(--Font-Family-Body, Calibri);
    flex-direction: row;
    margin-top: 40px;
    @media only screen and (max-width: 450px) {
        margin-top: 0px;
        margin-bottom: 20px;
        margin-left: 8px;
        margin-right: 8px;
    }

    @media only screen and  (min-width: 451px) {
        width: 100%;
        margin-bottom: 35px;
    } 
`;
