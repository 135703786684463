import React, { useRef } from "react";
import { InputContainerSearch } from "./Style";
import imgClear from "../../images/imgClear.svg";
export const InputSearch = ({
  placeholder = "",
  maxLength,
  value = "",
  onChange,
  onKeyDown,
  type = "text",
}) => {
  const inputRef = useRef(null);
  const clearInput = () => {
    onChange({ target: { value: "" } });
    inputRef.current.focus();
  };
  const handleClick = () => {
    if (inputRef.current.value.length === 8) {
      inputRef.current.select();
    }
  };
  return (
    <InputContainerSearch>
      <div className="input-container">
        <span className="dni-prefix">DNI </span>
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          className="dni-input"
          maxLength={maxLength}
          ref={inputRef}
          onClick={handleClick}
        />
        {value !== "" && 
          <span className="dni-clear" onClick={clearInput}>
            <img src={imgClear} alt="" />
          </span>
        }
      </div>
    </InputContainerSearch>
  );
};
