import { RadioButton } from "@prima-afp/prima-ui-elements/dist/core";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import React, { useEffect, useRef, useState } from "react";
import { TextRegards, TextGray } from "./styles";

export const FramePersonalData = () => {
  const { isAddressDNI, userWeb } = useStateDigitalTransfersContext();
  const dispatch = useDispatchDigitalTransfersContext();
  const [addressRef, setAddressRef] = useState("");
  const first = useRef();
  const fullName = userWeb.firstName;

  useEffect(() => {
    const refAdd = "(" + userWeb.address + ")";
    setAddressRef(refAdd);
  }, []);

  const setDataAddress = () => {
    dispatch({
      type: "FIELD",
      payload: { field: "isAddressDNI", value: false },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "departmentCode", value: null },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "provinceCode", value: null },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "districtCode", value: null },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "codeViaValue", value: "" },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "codeVia", value: null },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "descriptionVia", value: "" },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "districtValue", value: "" },
    });
  };

  return (
    <>
      <div className="regards" ref={first}>
        <TextRegards>
          Hola
          <b style={{ marginLeft: "5px", fontWeight: "700" }}> {fullName},</b>
        </TextRegards>
      </div>

      <div className="regardContent">
        <TextGray>¿En dónde vives actualmente?</TextGray>

        <div className="radioGroupStep1 rgpersonalData">
          <div id="op1">
            <RadioButton
              label="En la dirección registrada en mi DNI"
              checked={isAddressDNI === true}
              onChange={() =>
                dispatch({
                  type: "FIELD",
                  payload: {
                    field: "isAddressDNI",
                    value: true,
                  },
                })
              }
            ></RadioButton>
            <div style={{ marginLeft: "36px" }}>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#696158",
                }}
              >
                {addressRef}
              </p>
            </div>
          </div>
          <div className="secondRadio" style={{ marginLeft: "40px" }}>
            <RadioButton
              label="Cambié de dirección"
              checked={isAddressDNI === false}
              onChange={setDataAddress}
            ></RadioButton>
          </div>
        </div>
      </div>
    </>
  );
};
