import styled from "styled-components";

export const LandingContainer = styled.div`
  width: 100%;

  /* SECCTION 1 */

  .section1 {
    display: grid;
    justify-content: center;
    padding: 72px 24px 0px 24px;
    .contentSecction1 {
      min-width: 100%;
      max-width: 1318px;
      .contentHeader1 {
        padding-bottom: 4px;
        h4 {
          color: var(--gray-gray-prima, #45494e);
          text-align: center;
          font-family: Calibri, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0px !important;
        }
      }
      .contentTitle1 {
        padding-bottom: 40px;
        h2 {
          color: #45494e;
          text-align: center;
          font-family: Lexend, sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          margin: 0px;
        }
      }
      .contentBody1 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        justify-content: center;
        padding-bottom: 120px;
        margin: 0 auto;
        .contentVideo {
          .videoPasos {
            width: 759px;
            height: 387px;
            border-radius: 16px;
            background: #999;
          }
        }
        .contentPharaf1 {
          display: block;
          align-items: start;
          position: relative;
          .btnSlide {
            position: absolute;
            bottom: 0;
            font-family: Lexend;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.0012em;
            text-align: center;
            border: 1.5px solid
              var(--Border-Primary-Default, rgba(255, 79, 0, 1));
            color: var(--Text-White-Default, rgba(255, 255, 255, 1));
            background-color: var(
              --Background-Primary-Default,
              rgba(255, 79, 0, 1)
            );
            width: 297px;
            padding: 12px 20px;
            gap: 8px;
            border-radius: 12px;
            opacity: 0px;
          }
        }
      }
    }
  }

  /* SECCTION 2 */
  .section2 {
    .contentSecction2 {
      .contentHeader2 {
        h4 {
          font-family: Calibri;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.0012em;
          text-align: center;
          margin: 0px;
          color: #45494e;
        }
      }
      .contentTitle2 {
        display: flex;
        justify-content: center;
        h2 {
          font-family: Lexend;
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0.0012em;
          text-align: center;
          margin: 0px;
          color: #45494e;
        }
      }
      .contentSilder {
        padding-top: 40px;
        padding-bottom: 63px;
      }
    }
  }
  /* SECCTION 3 */
  .sectionAsesor {
    display: flex;
    min-height: 224px;
    justify-content: center;
    .contentAsesor {
      background: var(--Shape-White-Lighter, rgba(248, 248, 248, 1));
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1318px;
      align-items: center;
      border-radius: 24px;
      .imgSectionAsesor {
        display: flex;
        align-items: end;
        img {
          width: 286.68px;
          height: 208.69px;
        }
      }
      .contentSectionAsesor {
        padding-left: 47.83px;
        text-align: start;
        .titleAsesor {
          color: #45494e;
          font-family: Lexend, sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          padding-bottom: 24px;
          margin: 0px;
          p {
            margin: 0px;
          }
        }
        .contentBtnAsesor {
          .btn-asesor {
            font-family: Lexend;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.0012em;
            text-align: center;
            border: 1.5px solid
              var(--Border-Primary-Default, rgba(255, 79, 0, 1));
            color: var(--Text-White-Default, rgba(255, 255, 255, 1));
            background-color: var(
              --Background-Primary-Default,
              rgba(255, 79, 0, 1)
            );
            padding: 12px 20px;
            gap: 8px;
            border-radius: 12px;
            opacity: 0px;
          }
        }
      }
    }
  }

  /* SECCTION 4 */
  .section4 {
    padding: 48px 0px 112px 0px;
    display: flex;
    justify-content: center;
    .containerSection {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .container {
        max-width: 571px;
        .topTitleT {
          font-family: Calibri;
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #45494e;
          padding-bottom: 8px;
          margin: 0px;
        }
        .sectionTitleT {
          max-width: 534px;
          font-family: Lexend;
          font-size: 30px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: 0.0012em;
          text-align: left;
          color: #45494e;
          margin: 0px;
        }
      }
      .box-accordion {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .section1 {
      display: flex;
      padding: 27px 20px 0px 20px;
      .contentSecction1 {
        min-width: auto;
        .contentHeader1 {
          padding-bottom: 8px;
        }
        .contentTitle1 {
          padding-bottom: 24px;
          h2 {
            font-weight: 600;
            font-size: 20px;
          }
        }
        .contentBody1 {
          display: flex;
          flex-direction: column;
          padding-bottom: 52px;
          .contentVideo {
            .videoPasos {
              width: 100%;
              height: 204px;
            }
          }
          .contentPharaf1 {
            position: static;
            .btnSlide {
              position: static;
              bottom: auto;
              width: 100%;
            }
          }
        }
      }
    }

    .section2 {
      .contentSecction2 {
        .contentHeader2 {
          h4 {
            padding-bottom: 8px;
          }
        }
        .contentTitle2 {
          h2 {
            font-size: 20px;
            max-width: 329px;
          }
        }
        .contentSilder {
          padding-bottom: 24px;
        }
      }
    }

    .sectionAsesor {
      .contentAsesor {
        display: flex;
        flex-direction: column;
        padding-bottom: 32px;
        margin-left: 19px;
        margin-right: 21px;
        .imgSectionAsesor {
          padding-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 133px;
          img {
            width: 161px;
            height: 133px;
          }
        }
        .contentSectionAsesor {
          padding: 0px;
          text-align: center;
          max-width: 268px;
          .titleAsesor {
            font-size: 18px;
            line-height: 22.5px;
            padding-bottom: 17px;
          }
        }
      }
    }

    .section4 {
      padding: 40px 23px 72px 23px;
      .containerSection {
        width: 100%;
        flex-direction: column;
        .container {
          width: 100%;
          .topTitleT {
            line-height: 24px;
            text-align: center;
          }
          .sectionTitleT {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            padding-bottom: 32px;
          }
        }
        .box-accordion {
          max-width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 540px) and (max-width: 1000px) {
    .section1 {
      .contentSecction1 {
        .contentHeader1 {
        }
        .contentTitle1 {
          h2 {
          }
        }
        .contentBody1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          .contentVideo {
            .videoPasos {
              width: auto;
              height: auto;
            }
          }
          .contentPharaf1 {
            position: static;
            .btnSlide {
              position: static;
              bottom: auto;
              width: 100%;
            }
          }
        }
      }
    }

    .section4 {
      padding: 32px 0px 72px 0px;
      justify-content: center;
      .containerSection {
        justify-content: center;
        flex-direction: column;
        .container {
          max-width: none;
          width: 100%;
          text-align: center;
          margin-bottom: 32px;
          .topTitleT {
            text-align: center;
          }
          .sectionTitleT {
            text-align: center;
            width: 100%;
            max-width: none;
          }
        }
        .box-accordion {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1600px) {
    .section1 {
      .contentSecction1 {
        .contentBody1 {
          .contentVideo {
            .videoPasos {
              width: 100%;
              height: 387px;
              min-width: 759px;
            }
          }
        }
      }
    }
    .section4 {
      .containerSection {
        max-width: 1318px;
        .container {
          width: 534px;
        }
        .box-accordion {
          max-width: 810px;
        }
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    .contentAsesor {
      margin-left: 23px;
      margin-right: 22px;
    }
    .section4 {
      .containerSection {
        margin-left: 23px;
        margin-right: 23px;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .contentSecction1 {
      max-width: 1404px !important;
    }

    .sectionAsesor {
      .contentAsesor {
        max-width: 1404px !important;
      }
    }

    .section4 {
      .containerSection {
        max-width: 1404px !important;
        width: 100%;
        .box-accordion {
          width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 1600px) and (max-width: 1727px) {
    .section1 {
      padding: 72px 0px 0px 0px;
      .contentSecction1 {
        .contentBody1 {
          .contentVideo {
            .videoPasos {
              width: 100%;
              height: 387px;
              min-width: 809px;
            }
          }
        }
      }
    }
    .section4 {
      .containerSection {
        .container {
          min-width: 571px;
          .topTitleT {
          }
          .sectionTitleT {
          }
        }
        .box-accordion {
          padding-left: 24px;
        }
      }
    }
  }

  @media screen and (min-width: 1728px) {
    .section1 {
      .contentSecction1 {
        .contentBody1 {
          .contentVideo {
            .videoPasos {
              width: 100%;
              height: 387px;
              min-width: 809px;
            }
          }
        }
      }
    }
    .section2 {
      .contentSecction2 {
        .contentHeader2 {
          h4 {
            padding-bottom: 4px;
          }
        }
        .contentTitle2 {
          h2 {
            max-width: 471px;
          }
        }
      }
    }
    .section4 {
      width: 100vw;
      .containerSection {
        min-width: auto;
        width: 100%;
        display: grid;
        grid-template-columns: 40% 60%;
        .container {
          width: 571px;
          .topTitleT {
          }
          .sectionTitleT {
          }
        }
        .box-accordion {
          padding-left: 24px;
        }
      }
    }
    .sectionAsesor {
      .contentAsesor {
        .imgSectionAsesor {
        }
        .contentSectionAsesor {
          padding-left: 64px;
          .titleAsesor {
          }
        }
      }
    }
  }
`;
