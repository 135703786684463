import styled from "styled-components";

export const WrapperIput = styled.div`
  @media only screen and (max-width : 450px) { 
    width: 312px;
    .container{            
        width: 312px !important;
        margin-top: 16px !important;
    }
    .label{
        display:none;
    }
    .spanError{
      top: 2px !important;
      left: 4px !important;
    }
  }
  @media only screen and (min-width : 451px) { 
      width: 300px;
  }
  .noIcon{
    color: var(--Text-Gray-Darker, #292b2e);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Lexend, sans-serif;
    align-self: stretch;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.02px;

  }
  .label {
    color: var(--Text-Gray-Default, #4e5258);
    font-variant-numeric: lining-nums proportional-nums;
    width: 100%;
    font: 600 16px/150% Lexend, -apple-system, Roboto, Helvetica,
    sans-serif;
    height: 22px;
  }
  .container{
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: 12px;
    border-color: rgba(231, 231, 232, 1);
    border-style: solid;
    border-width: 2px;
    background-color: #fff;
    display: flex;
    margin-top: 16px;
    gap: 12px;
    font-size: 18px;
    font-weight: 400;
    line-height: 133%;
    width: 300px;
    height: 48px;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;    
  }
  .input-container {
    color: #4e5258;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Lexend, sans-serif;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    width: 140px;
    outline: none;
    border: none;
    height: 100% !important;
    padding-right: 0px;
    padding-left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
input::placeholder {
    color: #c5c7c8;
    opacity: 1; /* Para asegurarse de que el color se vea */
}
input::-webkit-input-placeholder {
    color: #c5c7c8;
}
.div {
    display: flex;
    max-width: 300px;
    flex-direction: column;
    letter-spacing: 0.02px;
  }
  .div-2 {
    color: var(--Text-Gray-Default, #4e5258);
    font-variant-numeric: lining-nums proportional-nums;
    width: 100%;
    font: 600 16px/150% Lexend, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .div-3 {
    border-radius: var(--Radius-Radius-12, 12px);
    border-color: rgba(231, 231, 232, 1);
    border-style: solid;
    border-width: 2px;
    background-color: var(--Background-White-Default, #fff);
    display: flex;
    margin-top: 12px;
    gap: 12px;
    font-size: 18px;
    font-weight: 400;
    line-height: 133%;
    padding: 0px 16px;
  }

  .div-3:focus-visible  {
    border-color: rgb(28 28 195);
  }

  .div-4 {
    display: flex;
    gap: 4px;
    color: var(--Text-Gray-Darker, #292b2e);
    white-space: nowrap;
    align-items: center;
  }
  .div-5 {
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Lexend, sans-serif;
  }
  .img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
  }
  .div-6 {
    color: var(--Text-White-Darker, #c5c7c8);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Lexend, sans-serif;
    flex: 1;
    font-size:16px;
    padding:10px 10px 10px 10px;
    width: 190px;
    border:none;
    height: 100% !important;
    padding-right: 0px;
    padding-left: 15px; 
    display: flex;
  }
  .div-6:focus  {
    outline: none; 
  }
  .spanError{
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #FF4646;
    position: relative;
    top: 10px;
    left: 8px;
    flex: 1;

  }
  .imgError{
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
    margin: auto 0;
    margin-right: 3px;
  }

`;


export const Tooltip = styled.div`
  position: relative;
  top: -98px;
  box-shadow: 0px 2px 4px 0px rgba(69, 73, 78, 0.16);
  display: flex;
  max-width: 312px;
  flex-direction: column;
  font-size: 16px;
  color: var(--Text-Gray-Default, #4e5258);
  font-weight: 400;
  letter-spacing: 0.02px;
  line-height: 150%;
  border-radius: 12px;
  border-style: revert;

  :after {
    content: "";
    position: absolute;
    Z-INDEX: 0;
    top: 88%;
    left: 50%;
    border-color: var(--whiteColorBase) transparent transparent transparent;
    width: 11px;
    height: 11px;
    background-color: #FFF;
    transform: rotate(45deg);
    box-shadow: 0px 2px 4px 0px rgba(69, 73, 78, 0.16);
  }
  .contentTooltip{
    border-radius: var(--Radius-Radius-8, 8px);
    background-color: var(--Shape-White-Default, #fff);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
  }

  .tooltiptext{
    font-family: Calibri, sans-serif;
    justify-content: center;
    padding: 10px 16px;
    background-color: var(--Shape-White-Default, #fff);
    border-radius: var(--Radius-Radius-8, 8px);
  }

  @media only screen and (max-width : 450px) { 
    top: -100px;
  }

  @media only screen and (min-width : 451px) { 
    :after {
     top: 82%;
    }
  }

`;