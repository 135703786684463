import cancel from "./images/x.svg";
import check from "./images/Check.svg";
export const themes = [
  {
    name: "registered",
    color: "var(--Text-Secondary-Default, rgba(4, 166, 162, 1));",
    lineColor: "var(--Text-Secondary-Default, rgba(4, 166, 162, 1));",
    circleColor: "var(--Text-Secondary-Default, rgba(4, 166, 162, 1));",
    borderCircleColor: "var(--Text-Secondary-Default, rgba(4, 166, 162, 1));",
    backColor: "var(--Background-Secondary-Subtle, rgba(230, 250, 250, 1));",
    imgItem: check,
  },
  {
    name: "refused",
    color: "var(--Text-Error-Default, rgba(255, 0, 0, 1));",
    lineColor: "var(--Text-Error-Default, rgba(255, 0, 0, 1));",
    circleColor: "var(--Text-Error-Default, rgba(255, 0, 0, 1));",
    borderCircleColor: "var(--Text-Error-Default, rgba(255, 0, 0, 1));",
    backColor: "var(--Background-Error-Subtle, rgba(255, 230, 230, 1));",
    imgItem: cancel,
  },
  {
    name: "black",
    color: "var(--Text-Gray-Default, rgba(78, 82, 88, 1));",
    lineColor: "var(--Shape-Secondary-Default, rgba(4, 166, 162, 1));",
    circleColor: "var(--Background-Gray-Default, rgba(78, 82, 88, 1));",
    borderCircleColor: "var(--Background-Gray-Default, rgba(78, 82, 88, 1));",
    numberCircleColor:  "var(--Background-White-Default, rgba(255, 255, 255, 1));",
    backColor:  "var(--Background-White-Default, rgba(255, 255, 255, 1));",
  },
  {
    name: "default",
    color: "var(--Text-Gray-Subtle, rgba(143, 146, 149, 1));",
    lineColor: "var(--Text-Gray-Subtle, rgba(143, 146, 149, 1));",
    circleColor: "var(--Background-White-Default, rgba(255, 255, 255, 1));",
    borderCircleColor: "var(--Text-Gray-Subtle, rgba(132, 135, 139, 1));",
    backColor: "var(--Background-White-Default, rgba(255, 255, 255, 1));",
  },
];
