import React, { useEffect, useState } from 'react';
import { ContarinerStep2 } from './styles';
import { MainTitleGreen } from '../../../shared/components/MainTitle';
import { StepTitleGray } from '../../../shared/components/StepTitle';
import { TypesCard } from './TypesCard';
import { useStateDigitalTransfersContext, useDispatchDigitalTransfersContext } from '../../context/DigitalTransfersContext';
import { useGetDataApi } from '../../hooks/useGetDataApi';
import { FramePEP } from './FramePEP';
import { Text } from '../../../shared/components/Text';
import { Alert } from '@prima-afp/prima-ui-elements/dist/core';
import Slider from '../../components/Slider/Slider';
import { Abandon } from "../../services";
import { useCatchFetch } from "../../hooks/useCatchFetch";

export const StepFundType = () => {

    const { userWeb, stepCurrent, showTypesFound, typesList } = useStateDigitalTransfersContext();
    const {
        fundType
    } = userWeb;
    const { GetListEmployedTypes } = useGetDataApi();
    const dispatch = useDispatchDigitalTransfersContext();

    const [listEmployedTypes, setListEmployedTypes] = useState([]);
    const { SendFetch } = useCatchFetch();

    useEffect(() => {
        setListEmployedTypes(GetListEmployedTypes());
    }, []);

    useEffect(() => {
        // Agregar un event listener cuando el componente se monta
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Eliminar el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);
    
      const handleBeforeUnload = async (e) =>{
          const message = "¿Seguro que quieres salir?";
          e.returnValue = message;
          const userResponse = window.confirm(message);
          try {
                var BodyPayload = { step: 2, documentNumber: userWeb.documentNumber, documentType: "00", active: true };
                var abandonRes = await SendFetch(Abandon(BodyPayload));
              
            } catch (error) {
              console.error(error);
          }  
      };

    const selectFundType = (item) => {
        dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'codeFundType', value: item.id}}); 
        dispatch({ type: 'FIELD_SECOND', payload: { field1: 'userWeb', field2: 'fundType', value: item.type}}); 
    };
    
    return (
        <ContarinerStep2>

            <div id="divStep2">

                <MainTitleGreen>Indícanos los siguientes datos para crear tu cuenta</MainTitleGreen>

                <StepTitleGray id="foundTypeP" >Elige tu tipo de fondo</StepTitleGray>

                <Slider optionSilder={typesList} selectOption={selectFundType}/>

                <Alert className="sliderAlert" theme="green" type="info" style={{marginBottom: '40px', boxShadow: 'inset 0 0 0 0px'}}>
                    <Text>                
                        Ten en cuenta que puedes cambiar tu tipo de fondo en cualquier momento.
                    </Text>                
                </Alert>
            
            </div>
            
            { <div className="fundType">
                <StepTitleGray id="titleStep2">¿Qué tipo de trabajador eres?</StepTitleGray>
                <TypesCard/>
                {fundType  !=null && <div>
                    <FramePEP/>
                </div>                                   
                }
            </div>}        

           

        </ContarinerStep2>
    );

};