import styled from "styled-components";
import { Text } from "../../pages/Text";
import background from "../../components/Banner/Images/StepOne/background_mobile.jpg";
import background_2 from "../../components/Banner/Images/StepOne/background_tablet.jpg"; 
import flecha from "../../../../modules/App/assets/images/flecha2.svg";
import { mediaQuery } from "modules/utils/responsive";

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #FFFFFF;
  overflow-x: hidden;
  .fade-in {
    margin-top: 30px;
  }
`;

export const ContentStepInitial = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #FFFFFF;
  overflow-x: hidden;
  .fade-in {
    margin-top: 30px;
  }

  @media only screen and (max-width : 376px) {    
    flex-direction: column;
    flex-wrap: nowrap;
    height: 93vh;
    .benefitImg{
      width:100%;
      height: 290px;
    }
    #app-main-content-steps{
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media only screen and (min-width : 377px) and (max-width: 450px) {    
    flex-direction: column;
    flex-wrap: nowrap;
    height: 93vh;
    .benefitImg{
      width:100%;
      height: 318px;
    }
    #app-main-content-steps{
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media only screen and (min-width : 451px) and (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: nowrap;
    #benedfitid_content{
      flex-flow: column nowrap!important; 
      display: flex!important; 
      justify-content: space-between!important; 
      mix-blend-mode: normal;
    }
    div.headerMobile{
      display: flex !important; 
      margin-left: 40px !important; 
      margin-right: 40px !important; 
      margin-top: 32px !important; 
      height: 248px;
    }
    .benefitImg{
      width:100%;
      height: 400px !important;
      background-image: url(${background_2}) !important;
      justify-content: space-between !important;
    }
    #idDivPage{
      flex:62.184210526;
      display: flex;
      justify-content: center !important;
    }
    .rec-carousel-wrapper{
      margin-bottom: 32px !important;
    }
    .rec-slider-container{
      margin: 0 0px !important;  
    }
    .DbenefitContent{
      width: 100% !important;  
      margin-left: 56px !important;  
      margin-right: 56px !important;  
    }
  }

  //especial
  @media only screen and (max-width : 450px) {   
    .benefitImg{ 
      background-image: url(${background}) !important;
      justify-content: space-between !important;
      left: 0px !important;   
      top: 58px !important;   
      height: 290px !important;
    }
  }

`;

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;

  @media only screen and (max-width : 376px) {    
    #app-main-content-steps{
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media only screen and (min-width : 377px) and (max-width: 450px) {    
    #app-main-content-steps{
      display: flex;
      flex-direction: column-reverse;
    }
  }  
`;

export const DbenefitContent = styled.div`
  width: 73%;
  margin: auto;

  @media only screen and (max-width : 450px) {
    width: 100%;
  }
`;

export const DbenefitLogo = styled.div`
  margin-bottom: 24px;
  @media only screen and (max-width : 450px) {    
    margin-bottom: 8px !important;
  }
  @media only screen and (min-width : 1727px) {    
    .logoBenefit{
      width: 64px !important;
      height: 64px !important;
    }
  }
`;


export const Pbenefits = styled.p`
  font-family: 'FS Emeric';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #FFFFFF;
  margin-bottom: 32px;

  @media only screen and (max-width : 450px) {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-bottom: 16px;
  }

  @media only screen and (min-width : 1727px) {    
    font-size: 24px;
    line-height: 32px;
  }
`;


export const DivBenefits = styled.div`
    display: flex;
    flex: 33;
    min-Width:200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    .rec-pagination  {
      margin-top: 0px;
      button{
        background: #FFFFFF;
        opacity: 0.5;
        box-shadow: none;
      }
      .rec-dot_active{
        background: #FFFFFF !important; 
        opacity: 1 !important; 
      }
    }
    @media only screen and ${mediaQuery.mobileS}{
        .headerMobile{
          max-width: 70% !important;
          /* margin-left: 12px !important; */
        }
    }

    @media only screen and ${mediaQuery.tablet}{
        .headerMobile{
          display: none;
        }
        .headerDesktopBanner{
          display: none;
        }
    }
    
    @media only screen and (max-width : 376px) {    
        flex-direction: column;    
        height: 300px !important; 
        width: 375px;
       
        .headerMobile{
          margin-top: 24px !important;
          margin-left: 32px !important;
          margin-right: 32px !important;          
          height: 248px;
        }
        .rec-item-wrapper{
          margin-top: 38px;
        }
        .headerDesktopBanner{
          display: none;
        }
    }

    @media only screen and (min-width : 377px) and (max-width: 450px) {    
        flex-direction: column;    
        height: 300px !important; 
        .headerMobile{
          margin-top: 24px !important;
          margin-left: 32px !important;
          margin-right: 32px !important;
          height: 248px;
        }
        .rec-item-wrapper{
          margin-top: 38px;
        }
        .headerDesktopBanner{
          display: none;
        }
    }

    @media only screen and (max-width : 451px) {      
      
      justify-content: space-between !important;  

      div.benefit{
        width: 94% !important;         
      }

      div.headerMobile{
        display: flex !important; 
        height: auto;
      }
      
      .headerDesktopBanner{
        display: none;
      }

      div.logo{
        margin: 12px 12px 12px 12px !important;         
      }


      .logoBenefit{
        width:40px !important;  
        height:40px !important;  
      }

      .rec-item-wrapper{        
        height:108px !important;  
        margin-bottom: 16px !important;  
      }

      .rec-slider-container{
        margin: 0 32px !important;  
      }


    }

    @media only screen and (max-width : 769px){
      .headerDesktopBanner{
        display: none;
      }
    }

    @media only screen and (min-width : 1000px) {    
      width: 100% !important;
    }  
    
    @media only screen and (min-width : 1700px) {    
      width: 600px;
      min-Width: auto;
    }  

`;


export const TextStyledMobile = styled.p`
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
`;

export const DivPage = styled.div`
    flex:67;
    display: flex;
    min-width:300px;
    @media only screen and ${mediaQuery.mobileS}{
      display: flex;
      justify-content: center;
    }
`;


///nuevos
export const DivStep = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    height: 90%;
    justify-content: flex-start;
    flex-wrap: nowrap;
   
    /* Medidas responsivas */
    /* Small Devices, Phones */ 
    @media only screen and (max-width : 476px) {
        width: 100%;
        height: 85%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: 32px;
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export const DivHeader = styled.div`
    margin-top: 22px;
    margin-bottom: 22px;
    margin-left: 24px;
    margin-right: 20px;

    #idDivBtn{
        width: 24px;
        height: 24px;
        background: url(${flecha});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        overflow: hidden;
        opacity: 1;
        margin-right: 8px;
    }

    #idDivBtnText{
      width: 34px;
      color: rgba(255,79,0,1);

      font-family: Calibri,sans-serif;
      font-weight: Regular;
      font-size: 16px;
      opacity: 1;
      text-align: left;
    }

    /* Medidas responsivas */
    /* Small Devices, Phones */ 
    @media only screen and (max-width : 450px) {
        display: flex !important;
    }
`;

export const TextStyledStep0 = styled(Text)`
    color: #FF4F00;
    font-weight: 700;
    font-size: 16px;    
    line-height: 20px;
`;
export const DivContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;

    div.buttonDiv1{
        padding-bottom:32px;  
        padding-top: 16px;
    }

    #ButtonDiv1 {
        margin-top: 32px;
        width: 100%;
    }

    @media only screen and (max-width : 450px) {
        margin-bottom: 32px;
        #button1 {
            width:100%;
            padding-top: 15px;
            padding-bottom: 15px;
            line-height: 18px;
        }
        #text1{
            width:100%;
            display:flex;
            flex-direction:row;
            justify-content:center;
        }   
        #linkId{
            padding-left: 5px !important; 
        }
        
    }

    @media only screen and (min-width : 1727px) {    
        #text1Id{
            font-size: 18px !important; 
            line-height: 20px !important; 
        }
    }

`;

export const Disclaimer = styled.div`
  width: 100%;
  height: 289px;
  background: linear-gradient(179.9deg, #000000 0.08%, rgba(0, 0, 0, 0.53985) 32.68%, rgba(0, 0, 0, 0) 87.08%) !important;
  transform: rotate(-180deg);
  @media only screen and ${mediaQuery.mobileS}{
    height: 190px;
  }

  @media only screen and ${mediaQuery.tablet}{
    display: none;
  }

  .contentDisclaimer{
    padding:  15px 20px;
    font-family: 'Calibri';
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #D6D6D6 !important;
    mix-blend-mode: normal;
    opacity: 0.8 !important;
    transform: rotate(-180deg);
  } 

  position: relative;
  @media only screen and (max-width : 450px) {
    .contentDisclaimer{
      font-family: 'Calibri' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 8px !important;
      line-height: 10px !important;
      color: #D6D6D6;
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 12px;
      .last{
        font-family: 'Calibri' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 8px !important;
        line-height: 9px !important;
        color: #D6D6D6 !important;
      }
      span{
        display: block;
      }
    }
  }

  @media only screen and (min-width : 477px) and (max-width: 1000px) {
    height: 100% !important;
    .contentDisclaimer{
      position: absolute;
      width:100%;
      padding: 20px 24px !important;
      font-size: 12px !important;
    }
  }

  @media only screen and (min-width : 1366px)  {
    .contentDisclaimer{
      padding-right: 26px;
      padding-bottom: 20px;
    }
    
  }

`;
