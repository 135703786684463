import { media } from "@prima-afp/prima-ui-elements/dist/utils/media/styledConfig";
import styled from "styled-components";

export const ContainerPhoneVerification = styled.div`
    text-align: center;
    width: 70%;
    margin: 0 auto;

    ${media.xs`
        width: 100%;
    `}

    .incorrectCode{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FF0000;
    }

    .titlePV{
        font-family: 'FS Emeric';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #00A499;
        width: 635px;
        height: 32px;
        text-align: start;
        margin-bottom: 10px;
    }

    .contentPV{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #696158;
        width: 635px;
        height: 20px;
    }

    .contentPV2{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;        
        display: flex;
        align-items: center;        
        color: #696158;
    }

    #text2{        
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;        
        display: flex;
        align-items: center;        
        color: #696158;
    }

    #counter{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #FF4F00;
    }

    @media only screen and (max-width : 450px) { 

        .titlePV{
            width: 100%;
            height: 64px;
            text-align: center;
        }

        #wrapperVericationSMS{
            display: flex;
            flex-direction: column;
        }

        #paragraph1{
            width: 100%;
            height: 40px;
            margin-bottom: 40px;
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #696158;
        }

        #text2{
            width: 279px;
            height: 20px;
            margin-bottom: 16px;
            margin-top: 40px;
            color: #696158;
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        }

        #text3{
            margin-top: 32px; 
            margin-bottom: 12px;    
            justify-content: center; 
        }

        #wrapper1{
            display: flex;
            margin-bottom: 8px;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .btn-sms-val-send{
            width: 100%;
            height: 48px;
            margin-top: 40px;
            margin-bottom: 10px;
        }

        #btn-create-transfer{
            width: 100%;
            height: 48px;
            margin-top: 40px;
            margin-bottom: 10px;
        }

        .verifiedDiv{
            display: flex;
            margin: auto;
            align-items: flex-end;
            justify-content: center;
        }

        .verifiedCode{
            margin-top: 24px;
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #00A499;
        }

        .contentPV{
            width: 100%;
            justify-content: center;
        }

        .wrapperButton{
            justify-content: center !important;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        #verificationSMS{
            margin-top: 24px;
        }


    }

    @media only screen and (min-width : 451px) and (max-width: 700px) {
        text-align: center;
        width: 100%;
        max-width: 100vw;
        margin: 0 auto;

        .titlePV{
            max-width: 704px;
            width: 100%;
            height: 32px;
            text-align: center;
            margin-bottom: 10px;
        }

        #wrapperVericationSMS{
            display: flex;
            flex-direction: column;
        }

        .contentPV{
            max-width: 704px;
            width: 100%;
            justify-content: center;
        }

        #text1{
            text-align: center;
            justify-content: center;
            margin-bottom: 48px;
        }

        #text2{
            margin-bottom: 16px;
            margin-top: 40px;
        }

        #text3{
            margin-bottom: 12px;
            margin-top: 32px;
        }

        #wrapper1{
            display:flex;
            margin-bottom: 24px;
            margin-top: 32px;
            justify-content: center;
        }

        #verificationSMS{
            max-width: 376px;
            width: 100%;
        }

        .wrapperButton{
            max-width: 100%;
            justify-content: center !important;
            margin-top: 48px;
            margin-bottom: 103px;
        }

        .btn-sms-val-send{
            margin-top: 48px;
            width: 200px;
            height: 48px;
        }

        .verifiedDiv{
            display: flex;
            margin-top: 24px;
            margin-bottom: 32px;
            justify-content: center;
        }

        .verifiedCode{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #00A499;
        }

        #paragraph1{
            width: 80vw;
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #696158;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0px auto;
            margin-bottom: 40px;
        }
    }
    
    @media only screen and (min-width : 701px) and (max-width: 1000px) {
        text-align: center;
        width: 704px;
        margin: 0 auto;

        .titlePV{
            width: 704px;
            height: 32px;
            text-align: center;
            margin-bottom: 10px;
        }

        #wrapperVericationSMS{
            display: flex;
            flex-direction: column;
        }

        .contentPV{
            width: 704px;
            justify-content: center;
        }

        #text1{
            text-align: center;
            justify-content: center;
            margin-bottom: 48px;
        }

        #text2{
            margin-bottom: 16px;
            margin-top: 40px;
        }

        #text3{
            margin-bottom: 12px;
            margin-top: 32px;
        }

        #wrapper1{
            display:flex;
            margin-bottom: 24px;
            margin-top: 32px;
            justify-content: center;
        }

        #verificationSMS{
            width: 376px;
        }

        .wrapperButton{
            justify-content: center !important;
            margin-top: 48px;
            margin-bottom: 103px;
        }

        .btn-sms-val-send{
            margin-top: 48px;
            width: 200px;
            height: 48px;
        }

        .verifiedDiv{
            display: flex;
            margin-top: 24px;
            margin-bottom: 32px;
            justify-content: center;
        }

        .verifiedCode{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #00A499;
        }

        #paragraph1{
            width: 542px;
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #696158;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            margin-left: 81px;
            margin-right: 81px;
            margin-bottom: 40px;
        }

    }
    @media only screen and (min-width : 1001px) and (max-width: 1727px) {
        width: 635px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: start;

        #text1{
            margin-bottom: 32px;
        }

        #text2{
            width: 279px;
            height: 20px;
            margin-right: 8px;
        }

        #text3{
            margin-top: 26px;   
            margin-bottom: 0px;     
        }

        #wrapper1{
            display: flex;
        }

        .btn-sms-val-send{
            width: 200px;
            height: 40px;
        }

        #verificationSMS{
            width: 413px;
            margin-top: 0px !important;
            .textfield-input {
                margin-top: 18px !important;
            }
        }

        .wrapperButton{
            justify-content: center !important;
            margin-top: 48px;
        }

        .verifiedDiv{
            display: flex;
            margin-top: 0px;
            margin-bottom: 0px;
            justify-content: center;
            align-items: center;
            margin-left: 16px;
        }

        .verifiedCode{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #00A499;
        }

        #paragraph1{
            margin-bottom: 32px;
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #696158;
            text-align: initial !important;
        }
    }

    @media only screen and (min-width : 1727px) {
        width: 635px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: start;
        margin: 0;

        #text1{
            margin-bottom: 32px;
        }

        #text2{
            width: 279px;
            height: 20px;
            margin-right: 8px;
        }

        #text3{
            margin-top: 26px;   
            margin-bottom: 0px;     
        }

        #wrapper1{
            display: flex;
        }

        .btn-sms-val-send{
            width: 200px;
            height: 40px;
        }

        #verificationSMS{
            width: 413px;
            margin-top: 0px !important;
            .textfield-input {
                margin-top: 18px !important;
            }
        }

        .wrapperButton{
            justify-content: center !important;
            margin-top: 48px;
        }

        .verifiedDiv{
            display: flex;
            margin-top: 0px;
            margin-bottom: 0px;
            justify-content: center;
            align-items: center;
            margin-left: 16px;
        }

        .verifiedCode{
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #00A499;
        }

        #paragraph1{
            margin-bottom: 32px;
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #696158;
            text-align: initial !important;
        }
    }
`;