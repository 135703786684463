import styled from "styled-components";

export const WrapperInput = styled.div`
    @media only screen and (max-width : 450px) { 
        width: 312px;
        .container{            
            width: 312px !important;
            margin-top: 16px !important;
        }
        .label{
            display:none;
        }
    }
    @media only screen and (min-width : 451px) { 
        width: 300px;
    }
    .label {
        color: var(--Text-Gray-Default, #4e5258);
        font-variant-numeric: lining-nums proportional-nums;
        width: 100%;
        font: 600 16px/150% Lexend, -apple-system, Roboto, Helvetica,
        sans-serif;
        height: 22px;
    }
    .container{
        align-self: stretch;
        color: var(--Text-Gray-Default, #4e5258);
        white-space: nowrap;
        letter-spacing: 0.02px;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        border-radius: 12px;
        border-color: #E7E7E8;
        border-style: solid;
        border-width: 2px;
        background-color: #fff;
        display: flex;
        margin-top: 16px;
        gap: 12px;
        font-size: 18px;
        font-weight: 400;
        line-height: 133%;
        width: 300px;
        height: 48px;
        padding-right: 16px;
        padding-left: 16px;
        padding-top: 12px;
        padding-bottom: 12px;    
    }
    input::placeholder {
        color: #c5c7c8;
        opacity: 1; /* Para asegurarse de que el color se vea */
    }
    input::-webkit-input-placeholder {
        color: #c5c7c8;
    }
    .dropdown{
        cursor: pointer;
        display: flex;
        width: 266px;
        justify-content: space-between;
    }
    .dropdownText{
        display: flex;
        align-items: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Lexend, sans-serif;
    }
    .dropdownIcon{
        aspect-ratio: 1;
        object-fit: auto;
        object-position: center;
        width: 24px;
    }
    .list-container {
        max-height: 200px; 
        overflow-y: auto; 
        overflow-anchor: none;
        z-index: 2;
        position: absolute;
        flex-direction: column;
        flex-wrap: nowrap;
        border-radius: var(--Radius-Radius-12, 12px);
        border-color: rgba(231, 231, 232, 1);
        border-style: solid;
        border-width: 2px;
        background-color: var(--Background-White-Default, #fff);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        font-size: 18px;
        font-weight: 400;
        width: 300px;
        left: 0;
        top: 48px;
        gap: 6px;
        .inputSearch{
            width: 96%;
            box-sizing: border-box;
            margin-top: 6px;
            margin-left: 9px;
            margin-right: 3px;
            outline: none;
            border: none;
        }
    }
    .list-item {
        height: 25px;
        line-height: 25px; /* Centrar el texto verticalmente */
        cursor: pointer;
        padding: 0 10px; /* Añadir un poco de relleno a los lados */
        display: flex;
        justify-content: flex-start;
    }
    
    .list-item:hover {
        background-color: #f0f0f0; /* Color de fondo cuando el cursor pasa por encima */
    }
    .input-container input {
        color: #696158;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Lexend, sans-serif;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
        width: 140px;
        outline: none;
        border: none;
        height: 100% !important;
        padding-right: 0px;
        padding-left: 0px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .spanError{
        font-family: 'Calibri';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #FF4646;
        position: relative;
        top: 10px;
        left: 8px;
    }
`;