import React, { useEffect, useRef, useState } from "react";
import { SelectInline } from "./styles";
import { useGetDataApi } from "../../hooks/useGetDataApi";
import {
  useStateDigitalTransfersContext,
  useDispatchDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { Option } from "@prima-afp/prima-ui-elements/dist/core";
import { TextGray } from "./styles";
import DropdownInput from "../../components/InputSelect/DropdownInput";

export const FrameAddress = () => {
  const { GetVias, GetSectors, GetDepartments, GetProvinces, GetDistricts } =
    useGetDataApi();

  const { userWeb } = useStateDigitalTransfersContext();

  const {
    codeVia,
    descriptionVia,
    codeHousing,
    descriptionHousing,
    departmentCode,
    provinceCode,
    districtCode,
    descriptionHousingInput,
    descriptionViaInput,
    districtCodeInput,
    provinceCodeInput,
    departmentCodeInput,
  } = userWeb;

  const dispatch = useDispatchDigitalTransfersContext();

  const [listVias, setListVias] = useState([]);
  const [listSectors, setListSectors] = useState([]);
  const [listDepartments, setListDepartments] = useState([]);
  const [listProvinces, setListProvinces] = useState([]);
  const [listDistricts, setListDistricts] = useState([]);

  const first = useRef();

  // useEffect(() => {
  //   first.current && first.current.scrollIntoView({ behavior: "smooth" });
  // }, []);

  useEffect(() => {
    const vias = GetVias();
    setListVias(vias);
    if (vias.length > 0) {
      dispatch({
        type: "FIELD_SECOND",
        payload: { field1: "userWeb", field2: "codeVia", value: vias[0].code },
      });
    }
    const sectors = GetVias();
    GetSectors(sectors);
    if (sectors.length > 0) {
      dispatch({
        type: "FIELD_SECOND",
        payload: {
          field1: "userWeb",
          field2: "codeHousing",
          value: sectors[0].code,
        },
      });
    }
    setListSectors(GetSectors());
    setListDepartments(GetDepartments());
  }, []);

  useEffect(() => {
    (async () => {
      setListProvinces(await GetProvinces(departmentCode));
    })();
  }, [departmentCode]);

  useEffect(() => {
    (async () => {
      setListDistricts(await GetDistricts(departmentCode, provinceCode));
    })();
  }, [departmentCode, provinceCode]);

  return (
    <>
      <div className="addressForm" ref={first}>
        <TextGray>Ingresa los datos de tu nueva dirección:</TextGray>
        <div id="SelectInputDrop" className="inputGroupFrameAddress">
          <SelectInline
            label="Departamento"
            className="addressInput"
            value={departmentCode}
            onChange={(value) =>
              dispatch({
                type: "FIELD_SECOND",
                payload: { field1: "userWeb", field2: "departmentCode", value },
              })
            }
            data-testid="input-field"
            error={departmentCodeInput}
            errorMessage={"No has ingresado nigún departamento"}
          >
            {listDepartments.map((item) => (
              <Option key={item.code} value={item.code}>
                {item.description}
              </Option>
            ))}
          </SelectInline>
          <SelectInline
            label="Provincia"
            className="addressInput"
            value={provinceCode}
            onChange={(value) =>
              dispatch({
                type: "FIELD_SECOND",
                payload: { field1: "userWeb", field2: "provinceCode", value },
              })
            }
            error={provinceCodeInput}
            errorMessage={"No has ingresado niguna provincia"}
          >
            {listProvinces.map((item) => (
              <Option key={item.code} value={item.code}>
                {item.description}
              </Option>
            ))}
          </SelectInline>
        </div>
        <div id="SelectInputDrop" className="inputGroupStep1_v2">
          <SelectInline
            label="Distrito"
            className="addressInput"
            value={districtCode}
            onChange={(value) =>
              dispatch({
                type: "FIELD_SECOND",
                payload: { field1: "userWeb", field2: "districtCode", value },
              })
            }
            error={districtCodeInput}
            errorMessage={"No has ingresado ningún distrito"}
          >
            {listDistricts.map((item) => (
              <Option key={item.code} value={item.code}>
                {item.description}
              </Option>
            ))}
          </SelectInline>

          <div className="WrapperDropdownInput" id="viaInput">
            <DropdownInput
              className="mb-2em"
              onlyNumbers={false}
              onChange={(e) =>
                dispatch({
                  type: "FIELD_SECOND",
                  payload: {
                    field1: "userWeb",
                    field2: "descriptionVia",
                    value: e.target.value,
                  },
                })
              }
              registerSelect={(e) =>
                dispatch({
                  type: "FIELD_SECOND",
                  payload: {
                    field1: "userWeb",
                    field2: "codeVia",
                    value: e.code,
                  },
                })
              }
              noPadding={true}
              defaultValueSelect={codeVia}
              defaultValueInput={descriptionVia}
              name={"viaInput"}
              selectName={"viasType"}
              selectOptions={listVias}
              label={"Dirección"}
              placeholder={"Ingrese la dirección"}
              error={descriptionViaInput}
              errorText={"No has ingresado una vía "}
            />
          </div>
        </div>
        <div id="SelectInputDrop" className="inputGroupStep1">
          <div className="WrapperDropdownInput">
            <DropdownInput
              className="mb-2em"
              onlyNumbers={false}
              onChange={(e) =>
                dispatch({
                  type: "FIELD_SECOND",
                  payload: {
                    field1: "userWeb",
                    field2: "descriptionHousing",
                    value: e.target.value,
                  },
                })
              }
              registerSelect={(e) =>
                dispatch({
                  type: "FIELD_SECOND",
                  payload: {
                    field1: "userWeb",
                    field2: "codeHousing",
                    value: e.code,
                  },
                })
              }
              noPadding={true}
              defaultValueSelect={codeHousing}
              defaultValueInput={descriptionHousing}
              name={"documentNumber"}
              selectName={"documentType"}
              selectOptions={listSectors}
              label={"Sector"}
              placeholder={"Ingrese el sector"}
              error={descriptionHousingInput}
              errorText={"No has ingresado un sector"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
