export const TOKEN_REQUEST_OPTIONS = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      'client_id': process.env.REACT_APP_CLIENT_ID,
      'client_secret': process.env.REACT_APP_CLIENT_SECRET,
      'scope': process.env.REACT_APP_SCOPE,
    })
  };
  
  export const STORAGE_ACCESS_TOKEN_NAME = 'accessToken';